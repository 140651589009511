import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetDocumentByUuidQuery,
  useGetProjectByUUIDQuery,
} from '../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'

export default function useValidateURL() {
  const { projectId, documentId } = useParams()
  const navigate = useNavigate()
  const { currentData: project, isFetching: projectFetching } =
    useGetProjectByUUIDQuery(projectId ? projectId : skipToken)
  const { currentData: document, isFetching: documentFetching } =
    useGetDocumentByUuidQuery(documentId ? documentId : skipToken)

  useEffect(() => {
    if (!projectId && !documentId) {
      return
    }
    if (projectId && project === null && !projectFetching) {
      navigate('/404')
    }
    if (documentId && !document && !documentFetching) {
      navigate('/404')
    }
  }, [
    projectId,
    documentId,
    document,
    project,
    navigate,
    documentFetching,
    projectFetching,
  ])
}
