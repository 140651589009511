import React, {
  useContext,
  useEffect,
  useState,
  createRef,
  useCallback,
} from 'react'
import {
  selectCurrentPage,
  selectTotalPages,
} from '../redux/secondary-viewer-slice'
import { useSelector } from 'react-redux'
import { DocumentViewerCommentContext } from '../contexts/document-viewer-comment-instance-context'

function SecondaryPageCount() {
  const totalPages = useSelector(selectTotalPages)
  const documentViewerContext = useContext(DocumentViewerCommentContext)
  const { documentViewer } = documentViewerContext
  const currentPage = useSelector(selectCurrentPage)

  const [page, setPage] = useState(currentPage)
  const ref = createRef<HTMLInputElement>()

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  const onPageChange = useCallback((event) => {
    setPage(event.target.value)
  }, [])

  const onPressEnter = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        documentViewer?.setCurrentPage(parseInt(page), false)
        ref?.current?.blur()
      }
    },
    [documentViewer, page, ref]
  )

  return (
    <div
      className={
        'relative flex cursor-pointer items-center gap-2 rounded bg-gray-100 px-2 py-1 shadow-sm'
      }
    >
      <div>
        <input
          ref={ref}
          max={totalPages}
          min={1}
          onChange={onPageChange}
          onKeyUp={onPressEnter}
          className={
            'w-10 rounded border-0 bg-gray-200 p-0 text-center opacity-75 focus:bg-white'
          }
          type="text"
          value={page}
        />
      </div>
      <div>/ {totalPages}</div>
    </div>
  )
}

export default SecondaryPageCount
