import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { selectModal, setModal } from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

interface AccountLockedProps {
  open: boolean
}

function AccountLocked({ open }: AccountLockedProps) {
  const {
    data: user,
    refetch,
    isUninitialized,
  } = useGetUserProfileQuery(undefined)
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const timerIdRef = useRef<NodeJS.Timeout | undefined>()
  const modal = useSelector(selectModal)

  useEffect(() => {
    if (user?.is_active) {
      dispatch(setModal(null))
      clearInterval(timerIdRef.current)
    } else {
      if (!timerIdRef.current) {
        timerIdRef.current = setInterval(() => {
          if (!isUninitialized) {
            try {
              refetch()
            } catch (e) {
              // pass
            }
          }
        }, 1000)
      }
    }
  }, [user, refetch, dispatch, open, isUninitialized, modal?.modal])

  const onClose = useCallback(() => {
    // Overriding default close
  }, [])

  const onClickLogin = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }, [logout])

  const lockedModalContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <LockClosedIcon
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Welcome to your new account!
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                New accounts are disabled by default, please contact your
                account manager to enable your account.
              </p>
            </div>
            <div className="mt-4 text-xs">
              <span>Have another account? </span>
              <button
                onClick={onClickLogin}
                className="text-xs text-indigo-500 underline"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [onClickLogin])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {lockedModalContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AccountLocked
