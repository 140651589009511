import React, { useCallback } from 'react'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import UseUrlNavigate from '../../hooks/use-url-navigate'

interface DocumentRowPreviousVersionButtonProps {
  document: ProjectDocument
}

const DocumentRowPreviousVersionButton: React.FC<
  DocumentRowPreviousVersionButtonProps
> = ({ document }) => {
  const navigate = UseUrlNavigate()
  const onSelectPreviousVersion = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      navigate.navigateDocumentUrl(document?.uuid)
    },
    [document?.uuid, navigate]
  )
  return (
    <button
      key={`previous_version_${document.uuid}`}
      className={
        'flex items-center space-x-1 px-2 py-2 text-left hover:bg-gray-100'
      }
      onClick={onSelectPreviousVersion}
    >
      <div className="max-w-36 overflow-hidden overflow-ellipsis whitespace-nowrap">
        {document.title}
      </div>
      <div className="inline-flex items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
        Version {document?.document_version}
      </div>
    </button>
  )
}

export default DocumentRowPreviousVersionButton
