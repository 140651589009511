import React from 'react'

import { DocumentArrowDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ProjectRisk } from '../../../../shared/interfaces/project/risk/risk-inteface'
import { MenuPopover } from '../../../layout/menu-popover'
import { Paper, Text } from '@mantine/core'
import clsx from 'clsx'

export const ShareCardPopover = ({
  projectRisks,
  onExcelExport,
  riskListSearchQuery,
  risksToDisplay,
  onExcelPDF,
}: {
  projectRisks: ProjectRisk[]
  onExcelExport: () => void
  riskListSearchQuery: string
  risksToDisplay: ProjectRisk[]
  onExcelPDF: () => void
}) => {
  const SHARE_CARD_POPOVER_OPTIONS = [
    {
      label: 'Export to Excel/CSV',
      leftSectionIcon: (
        <DocumentArrowDownIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      disabled: (projectRisks ?? []).length === 0,
      onClickMethod: onExcelExport,
    },

    {
      label: 'Export to PDF',
      leftSectionIcon: (
        <DocumentArrowDownIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      disabled: (projectRisks ?? []).length === 0,
      onClickMethod: onExcelPDF,
    },
    {
      label: 'Close Menu',
      leftSectionIcon: (
        <XMarkIcon className="ml-0.5 h-5 w-5 cursor-pointer stroke-gray-600 group-hover:text-black" />
      ),
      rightSectionText: '',
    },
  ]
  const nothingToExport =
    riskListSearchQuery !== '' && risksToDisplay.length === 0
  return (
    <MenuPopover
      disabled={nothingToExport}
      type="Settings"
      isSearchable={false}
      options={SHARE_CARD_POPOVER_OPTIONS}
      width={300}
      topLabel="Export Risks"
    >
      <div className="cursor-pointer">
        <Paper
          className={clsx('group !rounded-lg border p-2.5 transition-colors', {
            'hover:!border-blue-400 hover:!bg-blue-100 hover:bg-gray-50 active:border-blue-500 active:!bg-white':
              !nothingToExport,
          })}
          styles={() => ({
            root: {
              backgroundColor: '#e5e7eb',
              cursor: nothingToExport ? 'default' : 'pointer',
            },
          })}
        >
          <div className="flex items-center justify-center">
            <div
              className={clsx('flex text-gray-500', {
                'group-hover:text-blue-600': !nothingToExport,
              })}
            >
              <DocumentArrowDownIcon className="h-5 w-5" />
              <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
                Export
              </Text>
            </div>
          </div>
        </Paper>
      </div>
    </MenuPopover>
  )
}
