import React, { createContext, useEffect, useState } from 'react'
import { DocumentViewerContextType } from './document-viewer-instance-context'
import { Core } from '@pdftron/webviewer'

export const DocumentViewerCommentContext =
  createContext<DocumentViewerContextType>({})

interface DocumentViewerCommentProviderProps {
  children: React.ReactNode
}

export function DocumentViewerCommentProvider(
  props: DocumentViewerCommentProviderProps
) {
  const [documentViewer, setDocumentViewer] =
    useState<Core.DocumentViewer | null>(null)

  useEffect(() => {
    const Core = window.Core
    Core.setWorkerPath('/webviewer')
    Core.enableFullPDF()
    Core.Tools.Tool.ENABLE_AUTO_SWITCH = false
    const documentViewer = new Core.DocumentViewer()
    setDocumentViewer(documentViewer)
  }, [])

  return (
    <DocumentViewerCommentContext.Provider
      value={{
        documentViewer,
      }}
    >
      {props.children}
    </DocumentViewerCommentContext.Provider>
  )
}
