import React, { createContext, useEffect, useState } from 'react'
import * as PDFTron from '@pdftron/webviewer'

export type Core = typeof PDFTron.Core
export interface DocumentViewerContextType {
  documentViewer?: PDFTron.Core.DocumentViewer | null
}

export const DocumentViewerContext = createContext<DocumentViewerContextType>(
  {}
)

declare global {
  interface Window {
    Core: Core
    $chatwoot: {
      toggle: (toggle: 'open' | 'close') => void
      setUser(
        sub: string | undefined,
        param2: { name: string | undefined; email: string | undefined }
      ): void
    }
  }
}

interface DocumentViewerProviderProps {
  children: React.ReactNode
}

export const DocumentViewerProvider = (props: DocumentViewerProviderProps) => {
  const [documentViewer, setDocumentViewer] =
    useState<PDFTron.Core.DocumentViewer | null>(null)

  useEffect(() => {
    const Core: Core = window.Core
    Core.setWorkerPath('/webviewer')
    Core.enableFullPDF()
    Core.Tools.Tool.ENABLE_AUTO_SWITCH = false
    const documentViewer = new Core.DocumentViewer()
    setDocumentViewer(documentViewer)
  }, [])

  return (
    <DocumentViewerContext.Provider
      value={{
        documentViewer,
      }}
    >
      {props.children}
    </DocumentViewerContext.Provider>
  )
}
