import React from 'react'
import { Badge, Text } from '@mantine/core'
import clsx from 'clsx'

interface CircleDotProps {
  number: number
  size?: 'sm' | 'md' | 'lg'
  color?: string
  opacity?: number
  className?: string
}

export const CircleDot: React.FC<CircleDotProps> = ({
  number,
  size = '26px',
  color = 'yellow',
  opacity = 1,
  className = '',
}) => {
  const opacityClass = `flex-shrink-0 flex justify-center items-center opacity-${Math.round(opacity * 100)}`
  const badgeClasses = clsx(opacityClass, className)

  return (
    <Badge color={color} size={size} circle mx="4px" className={badgeClasses}>
      <Text c="black" size="xs" fw="500">
        {number}
      </Text>
    </Badge>
  )
}
