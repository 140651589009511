import React, { useCallback } from 'react'
import { Tooltip } from 'react-tooltip'
import { useNavigate, useParams } from 'react-router-dom'
import CreateRiskForm from './create-risk-form'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { useGetRiskListByIdQuery } from '../../../../redux/api/project-risk-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'
import { setSharedFromChatRiskQueryText } from '../../../../redux/application-slice'

const CustomRiskPage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleCloseCreateRiskListForm = useCallback(() => {
    navigate('..', { relative: 'path' })
    dispatch(setSharedFromChatRiskQueryText(''))
  }, [navigate, dispatch])
  const { riskListId } = useParams<{ riskListId: string }>()
  const { data: riskList } = useGetRiskListByIdQuery(
    riskListId ? parseInt(riskListId) : skipToken
  )

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="w-full flex-auto space-y-2 overflow-auto p-2">
        <div className="mb-2 flex items-center justify-between">
          <button
            type="button"
            className="justify-left flex items-center rounded px-3 py-1.5 text-xs font-semibold text-gray-700 hover:bg-gray-100"
            onClick={handleCloseCreateRiskListForm}
          >
            <ArrowLeftIcon className="mr-2 h-5 w-5" />
            Back to Risk Lists
          </button>
        </div>
        <CreateRiskForm riskListName={riskList?.name ?? ''} />
      </div>

      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default CustomRiskPage
