import { HandRaisedIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { BsCursorText } from 'react-icons/bs'
import { Tooltip as MantineTooltip } from '@mantine/core'

interface DocumentViewerCursorModeProps {
  onClickTextSelect: () => void
  onClickPan: () => void
  documentId: string | null
  cursorTool: string
}

const DocumentViewerCursorMode: React.FC<DocumentViewerCursorModeProps> = ({
  onClickTextSelect,
  onClickPan,
  cursorTool,
  documentId,
}) => {
  return (
    <div className="inline-flex rounded-md transition-colors">
      <MantineTooltip
        label={
          <div className="flex flex-col justify-center p-1 text-center">
            <div className="mb-2 mt-1 flex items-center justify-center border-b pb-2 text-lg font-semibold">
              Mouse Mode: <BsCursorText width={24} /> Text Select
            </div>
            <div className="mb-2 pt-1 text-sm">
              Highlight text and access the comments menu.
            </div>
          </div>
        }
        position="bottom"
        w="270px"
        multiline
        withArrow
      >
        <button
          disabled={!documentId}
          type="button"
          className={`relative inline-flex items-center rounded-l-lg ${
            cursorTool === 'TextSelect' ? 'bg-white' : 'bg-gray-50'
          } px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-white focus:z-10`}
          onClick={onClickTextSelect}
        >
          <BsCursorText width={20} />
        </button>
      </MantineTooltip>
      <MantineTooltip
        label={
          <div className="flex flex-col justify-center p-1 text-center">
            <div className="mb-2 mt-1 flex items-center justify-center border-b pb-2 text-lg font-semibold">
              Mouse Mode: <HandRaisedIcon width={20} className="mx-1" /> Click
              and Drag
            </div>
            <div className="mb-2 pt-1 text-sm">
              Click to move the document around inside the viewport.
            </div>
          </div>
        }
        position="bottom"
        w="300px"
        multiline
        withArrow
      >
        <button
          disabled={!documentId}
          type="button"
          className={`shadow-l-0 relative -ml-px inline-flex items-center rounded-r-lg ${
            cursorTool === 'Pan' ? 'bg-white' : 'bg-gray-50'
          } px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-white focus:z-10`}
          onClick={onClickPan}
          data-tooltip-id="pan-id"
          data-tooltip-content="Pan"
        >
          <HandRaisedIcon width={20} />
        </button>
      </MantineTooltip>
    </div>
  )
}
export default DocumentViewerCursorMode
