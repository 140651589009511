import {
  ChatHistory,
  ChatSessionMessage,
} from '../../shared/interfaces/chat/chat-history.interface'
import { apiSlice } from '../api-slice'

interface UpdateChatHistory {
  uuid: string
  isStarred?: boolean
  session_name?: string
  project_uuid: string
}

interface DeleteChatHistory {
  uuid: string
  project_uuid: string
}

const chatApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChatHistoryByUUID: builder.query<ChatHistory[], string>({
      query: (uuid) => `/chatsessions/${uuid}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Chat' as const,
                id,
              })),
              { type: 'Chat' as const, id: 'LIST' },
            ]
          : [{ type: 'Chat' as const, id: 'LIST' }],
    }),
    getChatHistory: builder.query<ChatHistory[], string>({
      query: (project_uuid) => `/chatsessions/?project_id=${project_uuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Chat' as const,
                id,
              })),
              { type: 'Chat' as const, id: 'LIST' },
            ]
          : [{ type: 'Chat' as const, id: 'LIST' }],
    }),
    createChatMessage: builder.mutation<ChatSessionMessage, ChatSessionMessage>(
      {
        query: (chatSessionMessage) => ({
          url: '/chatsessionmessages/createadd/',
          method: 'POST',
          body: chatSessionMessage,
        }),
      }
    ),
    starChatHistory: builder.mutation<ChatHistory[], UpdateChatHistory>({
      query: ({ isStarred, uuid }) => ({
        url: `/chatsessions/${uuid}/`,
        method: 'PATCH',
        body: {
          saved: isStarred,
        },
      }),
      async onQueryStarted(updateChatHistory, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          chatApi.util.updateQueryData(
            'getChatHistory',
            updateChatHistory.project_uuid,
            (draft) => {
              const foundHistory = draft?.find(
                (d) => d.uuid === updateChatHistory.uuid
              )
              if (foundHistory) {
                foundHistory.saved = updateChatHistory.isStarred
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    renameChatHistory: builder.mutation<ChatHistory[], UpdateChatHistory>({
      query: ({ session_name, uuid }) => ({
        url: `/chatsessions/${uuid}/`,
        method: 'PATCH',
        body: {
          session_name,
        },
      }),
      async onQueryStarted(updateChatHistory, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          chatApi.util.updateQueryData(
            'getChatHistory',
            updateChatHistory.project_uuid,
            (draft) => {
              const foundHistory = draft?.find(
                (d) => d.uuid === updateChatHistory.uuid
              )
              if (foundHistory) {
                foundHistory.session_name = updateChatHistory.session_name
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    deleteChatHistory: builder.mutation<ChatHistory[], DeleteChatHistory>({
      query: ({ uuid }) => ({
        url: `/chatsessions/${uuid}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(deleteChatHistory, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          chatApi.util.updateQueryData(
            'getChatHistory',
            deleteChatHistory.project_uuid,
            (draft) => {
              const foundHistoryIndex = draft?.findIndex(
                (d) => d.uuid === deleteChatHistory.uuid
              )
              if (foundHistoryIndex !== -1) {
                draft?.splice(foundHistoryIndex, 1)
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useGetChatHistoryByUUIDQuery,
  useGetChatHistoryQuery,
  useStarChatHistoryMutation,
  useDeleteChatHistoryMutation,
  useCreateChatMessageMutation,
  useRenameChatHistoryMutation,
} = chatApi
