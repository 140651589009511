import React from 'react'
import { Outlet } from 'react-router-dom'
import { DocumentViewerProvider } from '../contexts/document-viewer-instance-context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ModalController from '../components/modals/modal-controller'
import NavHeader from '../components/layout/nav-header'
import TermsAndConditions from '../components/terms/terms-and-conditions'
import { TypsenseClientProvider } from '../contexts/typesense-client-context'
import { DocumentViewerCommentProvider } from '../contexts/document-viewer-comment-instance-context'
import useValidateURL from '../hooks/use-validate-url'

export default function Root() {
  useValidateURL()
  return (
    <TypsenseClientProvider>
      <DocumentViewerCommentProvider>
        <DocumentViewerProvider>
          <div className={'flex h-screen flex-col'}>
            <NavHeader />
            <Outlet />
          </div>

          <ToastContainer />
          <ModalController />
          <TermsAndConditions />
        </DocumentViewerProvider>
      </DocumentViewerCommentProvider>
    </TypsenseClientProvider>
  )
}
