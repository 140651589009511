import React, { useCallback, useMemo, useState } from 'react'
import {
  useGetOrganizationUsersQuery,
  useGetProjectPermissionsByIdQuery,
  useInviteOrgToProjectMutation,
  useInviteUserToProjectMutation,
} from '../redux/api-slice'
import { Tooltip } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePostHog } from 'posthog-js/react'
import { toast } from 'react-toastify'
import LoadingCircle from './loading/loading-circle'
import { ProvisionUser } from '../shared/interfaces/user/user.inteface'
import SharingModalOrgInviteButton from './modals/sharing/sharing-modal-org-invite-button'
import { Project } from '../shared/interfaces/project/project.interface'
import { POSTHOG } from '../utils/posthog-constants'
import { EnvelopeIcon } from '@heroicons/react/24/outline'

interface ProjectSharingSectionProps {
  project: Project
  isEnabled: boolean
}

const ProjectSharingSection: React.FC<ProjectSharingSectionProps> = ({
  project,
  isEnabled,
}) => {
  const [emails, setEmails] = useState<string[]>([])
  const [userEmail, setUserEmail] = useState<string>('')
  const [inviteUsers] = useInviteUserToProjectMutation()
  const [inviteOrg] = useInviteOrgToProjectMutation()
  const posthog = usePostHog()
  const [orgSubmitting, setOrgSubmitting] = useState(false)

  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    project ? project?.id ?? skipToken : skipToken
  )
  const { data: organizationUsers } = useGetOrganizationUsersQuery(undefined)

  const reset = useCallback(() => {
    setEmails([])
    setUserEmail('')
  }, [])

  const isValidEmail = useCallback((email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }, [])

  const isDisabled = useMemo(() => {
    return (
      !isEnabled ||
      (!userEmail && emails?.length === 0) ||
      (Boolean(userEmail) && !isValidEmail(userEmail))
    )
  }, [emails?.length, isValidEmail, userEmail, isEnabled])

  const organizationUsersToInvite = useMemo(() => {
    if (!organizationUsers || !permissions) {
      return []
    }
    const orgUsers = organizationUsers.filter(
      (user) =>
        permissions.users?.find(
          (permission) => permission.email === user.email
        ) === undefined &&
        user.email?.toLowerCase().includes(userEmail.toLowerCase())
    )
    return orgUsers
  }, [organizationUsers, permissions, userEmail])

  const onInviteEntireOrganisationClick = useCallback(async () => {
    if (!project?.id || !isEnabled) {
      return
    }
    setOrgSubmitting(true)
    await inviteOrg(project?.id)
    setOrgSubmitting(false)
  }, [project?.id, inviteOrg, isEnabled])

  const onChangeUserEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserEmail(e?.target?.value)
    },
    []
  )

  const onInviteNewEmail = useCallback(async () => {
    if (userEmail && isValidEmail(userEmail) && isEnabled) {
      if (!project?.id) {
        return
      }
      await inviteUsers({
        projectID: project?.id,
        emails: [{ email: userEmail }],
      }).unwrap()
      posthog?.capture(POSTHOG.invite_users, {
        project_uuid: project?.id,
      })
      toast.success(`Project ${project?.title} was successfully shared`)
      reset()
    }
  }, [
    project?.id,
    project?.title,
    inviteUsers,
    isValidEmail,
    posthog,
    reset,
    userEmail,
    isEnabled,
  ])

  const sectionContent = (
    <div className={`space-y-4 ${!isEnabled ? 'opacity-50' : ''}`}>
      <div className="flex flex-col space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Project Sharing
        </label>
        <div className="mt-1">
          <input
            tabIndex={isEnabled ? 0 : -1}
            placeholder="Add people by email"
            type="email"
            name="sharing_name"
            id="sharing_name"
            value={userEmail}
            onChange={onChangeUserEmail}
            disabled={!isEnabled}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 sm:text-sm"
          />
        </div>
        {!isDisabled && organizationUsersToInvite.length === 0 && (
          <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex w-0 flex-1 justify-between">
                  <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                    {userEmail}
                  </p>
                  <button
                    type="button"
                    className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={onInviteNewEmail}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-sm">
        <hr className="py-2" />
        <div className="mb-4 block text-base font-medium text-gray-700">
          People with access
        </div>
        <div className="flex max-h-48 flex-col space-y-2 overflow-auto">
          {permissions?.users?.map((permission) => (
            <div
              key={`permission_${permission.id}`}
              className="flex items-center space-x-2"
            >
              <span className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-gray-500">
                <span className="text-md font-medium uppercase leading-none text-white">
                  {permission?.email[0]}
                </span>
              </span>
              <div>
                <div className="text-sm">{permission.email}</div>
              </div>
            </div>
          ))}
          {permissions?.invited_users?.map((permission) => (
            <div
              key={`permission_${permission.email}`}
              className="my-1 flex items-center space-x-2"
            >
              <span className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-gray-500">
                <span className="text-md font-medium leading-none text-white">
                  {permission?.email[0]?.toLocaleUpperCase()}
                </span>
              </span>
              <div className="flex flex-1 justify-between">
                <div className="text-sm text-gray-400">{permission.email}</div>
                <div className="flex items-center space-x-1">
                  <EnvelopeIcon className="mr-2 h-5 w-5 " /> Invite Sent
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="text-sm">
        <hr className="py-2" />
        <div className="mb-2 flex justify-between">
          <div className="mb-4 block text-base font-medium text-gray-700">
            People without access
          </div>
          <button
            onClick={onInviteEntireOrganisationClick}
            disabled={!isEnabled}
            className="max-h-[28px] rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-gray-300"
          >
            {orgSubmitting ? (
              <LoadingCircle
                className="h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                isSpinning={orgSubmitting}
              />
            ) : (
              <>Add All</>
            )}
          </button>
        </div>
        <div className="flex h-72 flex-col space-y-3 overflow-y-scroll">
          {organizationUsersToInvite.length === 0 ? (
            <div className="flex h-full flex-col items-center justify-center text-center text-sm text-gray-500">
              <p className="max-w-[60%]">
                No other users were found in your organization. If you know this
                to be a mistake, please contact support.
              </p>
            </div>
          ) : (
            organizationUsersToInvite.map((user: ProvisionUser) => (
              <div
                key={`org_users_${user.id}`}
                className="flex items-center justify-between"
              >
                <div className="flex items-center space-x-2">
                  <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-300">
                    <span className="text-md font-medium uppercase leading-none text-white">
                      {user?.email ? user?.email[0] : 'U'}
                    </span>
                  </span>
                  <div className="flex flex-col overflow-hidden">
                    {user?.first_name && user?.last_name ? (
                      <>
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {`${user?.first_name} ${user?.last_name}`}
                        </p>
                        <p className="truncate text-xs leading-5 text-gray-500">
                          {user.email}
                        </p>
                      </>
                    ) : (
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {user.email}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <SharingModalOrgInviteButton
                    user={user}
                    project={project}
                    disabled={!isEnabled}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )

  return (
    <Tooltip
      label="You do not have permissions to perform this action"
      disabled={isEnabled}
      position="top"
      multiline
      withArrow
    >
      <div
        className={
          !isEnabled ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
        }
        style={{ width: '100%' }}
      >
        {sectionContent}
      </div>
    </Tooltip>
  )
}

export default ProjectSharingSection
