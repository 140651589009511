import { Popover } from '@headlessui/react'
import React, { useState, useCallback } from 'react'
import { usePopper } from 'react-popper'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { useDispatch } from 'react-redux'
import { setEditorMode } from '../../redux/secondary-viewer-slice'

const CommentViewerCardPopover: React.FC = () => {
  const dispatch = useDispatch()
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  const onEditModeClick = useCallback(() => {
    dispatch(setEditorMode(true))
    referenceElement?.click()
  }, [dispatch, referenceElement])

  return (
    <Popover>
      <Popover.Button
        className={'px-2 py-2 hover:bg-gray-200'}
        tabIndex={-1}
        ref={setReferenceElement}
      >
        <EllipsisVerticalIcon className="h-5 w-5" />
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 flex flex-col rounded border bg-white shadow"
      >
        <button
          className="cursor-pointer px-4 py-2 text-left text-xs hover:bg-gray-100"
          onClick={onEditModeClick}
        >
          Toggle Edit Mode
        </button>
      </Popover.Panel>
    </Popover>
  )
}

export default CommentViewerCardPopover
