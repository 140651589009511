import React, { CSSProperties } from 'react'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import FilteringCard from './filtering-card'
import { useGetDocumentsListByProjectQuery } from '../../../redux/api-slice'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/dist/query'

export interface FilteringScrollerRowProps {
  documentSegments: DocumentSegment[]
  index: number
  style: CSSProperties
  setSelectedDocumentSegment: React.Dispatch<
    React.SetStateAction<DocumentSegment | null>
  >
  selectedDocumentSegment: DocumentSegment | null
  page: number
}

const FilteringScrollerRow: React.FC<FilteringScrollerRowProps> = ({
  documentSegments,
  index,
  style,
  setSelectedDocumentSegment,
  selectedDocumentSegment,
  page,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  return documentSegments[index] ? (
    <div style={style}>
      <FilteringCard
        page={page}
        index={index}
        setSelectedDocumentSegment={setSelectedDocumentSegment}
        isSelectedDocumentSegment={
          selectedDocumentSegment?.id === documentSegments[index]?.id
        }
        key={`document_segment_${documentSegments[index].id}`}
        documentSegment={documentSegments[index]}
        documentTitle={
          documents?.find(
            (doc) => doc.uuid === documentSegments[index].document_uuid
          )?.title ?? ''
        }
      />
    </div>
  ) : null
}

export default FilteringScrollerRow
