import React, { useState, useCallback } from 'react'
import LoadingCircle from '../../loading/loading-circle'
import { useInviteUserToProjectMutation } from '../../../redux/api-slice'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { Project } from '../../../shared/interfaces/project/project.interface'

interface SharingModalOrgInviteButtonProps {
  user: ProvisionUser
  project: Project
  disabled?: boolean
}

const SharingModalOrgInviteButton: React.FC<
  SharingModalOrgInviteButtonProps
> = ({ user, project, disabled = false }) => {
  const posthog = usePostHog()
  const [inviteUsers] = useInviteUserToProjectMutation()
  const [orgUserSubmitting, setOrgUserSubmitting] = useState(false)

  const onSubmitUser = useCallback(async () => {
    if (!project?.id || disabled) {
      return
    }
    setOrgUserSubmitting(true)
    await inviteUsers({
      projectID: project?.id,
      emails: [{ email: user?.email ?? '' }],
    }).unwrap()
    setOrgUserSubmitting(false)
    posthog?.capture(POSTHOG.invite_users, {
      project_uuid: project?.id,
    })
  }, [project?.id, inviteUsers, posthog, user?.email, disabled])

  return (
    <button
      onClick={onSubmitUser}
      disabled={disabled || orgUserSubmitting}
      className={`rounded px-2 py-1 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
        ${
          disabled
            ? 'cursor-not-allowed bg-gray-300 text-gray-500'
            : 'bg-indigo-600 text-white hover:bg-indigo-500'
        }`}
    >
      {orgUserSubmitting ? (
        <LoadingCircle
          className="mt-0.5 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
          isSpinning={orgUserSubmitting}
        />
      ) : (
        <div>Add</div>
      )}
    </button>
  )
}

export default SharingModalOrgInviteButton
