import React, { useCallback } from 'react'
import {
  TemporaryHighlight,
  selectTextSelected,
} from '../../redux/viewer-slice'
import { v4 as uuidv4 } from 'uuid'
import { useCoords } from '../../hooks/use-coords'
import { Quads } from '../../shared/interfaces/quads.interface'
import { useSelector } from 'react-redux'

interface TemporaryHighlightProps {
  highlight: TemporaryHighlight
  page: number
  documentViewerID: 'document-viewer' | 'document-viewer-secondary'
}

const TemporaryHighlightContainer: React.FC<TemporaryHighlightProps> = ({
  highlight,
  page,
  documentViewerID,
}) => {
  const textSelected = useSelector(selectTextSelected)
  const { getCoords } = useCoords()

  const coords = useCallback(
    (quad: Quads) => {
      return getCoords(
        quad,
        page,
        documentViewerID,
        Boolean(highlight.pdfCoords)
      )
    },
    [getCoords, page, highlight.pdfCoords, documentViewerID]
  )

  return (
    <div className="opacity-50">
      {highlight.quads.map((quad) => (
        <div
          id={highlight.id ? highlight.id : uuidv4()}
          key={`temp_highlight_${
            highlight.id ? `${highlight.id}_${quad?.x1}_${quad?.y1}` : uuidv4()
          }`}
          className={`absolute ${
            highlight?.colourClass ? highlight?.colourClass : 'bg-yellow-400'
          } ${textSelected ? 'z-30' : 'z-50'}`}
          style={{
            width: `${coords(quad).width}%`,
            height: `${coords(quad).height}%`,
            top: `${coords(quad).topOffset}%`,
            left: `${coords(quad).leftOffset}px`,
          }}
        />
      ))}
    </div>
  )
}
export default TemporaryHighlightContainer
