import React from 'react'
import { ProjectPermissionUser } from '../../../shared/interfaces/project/permissions/project-permissions.interface'

export interface FilteringCardUserBadgeProps {
  user: ProjectPermissionUser
}

const FilteringCardUserBadge: React.FC<FilteringCardUserBadgeProps> = ({
  user,
}) => {
  return (
    <div
      title={user.email}
      className="flex w-fit justify-between overflow-hidden rounded-full bg-gray-200 p-2 px-2.5 py-0.5"
    >
      <div
        color="info"
        className="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-gray-800"
      >
        {user.email}
      </div>
    </div>
  )
}

export default FilteringCardUserBadge
