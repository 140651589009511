import React, { useCallback, useMemo, useState } from 'react'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { Combobox } from '@headlessui/react'
import {
  selectCommentWorkflowFilterState,
  setCommentWorkflowFilterState,
} from '../../../redux/workflow-slice'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
interface RevisionAuthorFilterProps {
  revisionsData: Revision[]
}

const RevisionAuthorFilter: React.FC<RevisionAuthorFilterProps> = ({
  revisionsData,
}) => {
  const [query, setQuery] = useState('')
  const { authorsFilter, ...filters } = useSelector(
    selectCommentWorkflowFilterState
  )
  const dispatch = useDispatch()

  const revisionAuthors = useMemo(() => {
    return (
      revisionsData?.reduce<ProvisionUser[]>((acc, r) => {
        if (!r?.user_created) {
          return acc
        }
        if (!acc.find((a) => a.id === r.user_created?.id)) {
          acc.push(r.user_created)
        }
        return acc
      }, []) ?? []
    )
  }, [revisionsData])

  const onChangeAuthorsFilter = useCallback(
    (selectedAuthors: any) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...filters,
          authorsFilter:
            revisionAuthors.filter((d) => selectedAuthors.includes(d.id)) ?? [],
        })
      )
    },
    [dispatch, filters, revisionAuthors]
  )

  const filterAuthorsByQuery = useCallback(
    (authors: ProvisionUser[]) => {
      return authors.filter(
        (a) =>
          a.first_name.toLowerCase().includes(query.toLowerCase()) ||
          a.last_name?.toLowerCase().includes(query.toLowerCase()) ||
          a.email?.toLowerCase().includes(query.toLowerCase())
      )
    },
    [query]
  )

  const revisionAuthorsToDisplay = useMemo(() => {
    return filterAuthorsByQuery(revisionAuthors)
  }, [filterAuthorsByQuery, revisionAuthors])

  const onClearAuthorsFilter = useCallback(() => {
    dispatch(
      setCommentWorkflowFilterState({
        ...filters,
        authorsFilter: [],
      })
    )
  }, [filters, dispatch])

  return (
    <Combobox
      as="div"
      value={authorsFilter.map((d) => d.id)}
      multiple={true}
      onChange={onChangeAuthorsFilter}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-0.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          placeholder={
            authorsFilter.length > 0
              ? `${authorsFilter.length} users`
              : 'All users'
          }
        />
        {authorsFilter.length > 0 && (
          <button
            className="absolute right-6 top-0 z-10 flex h-full items-center"
            onClick={onClearAuthorsFilter}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pl-8 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-96 w-[200px] divide-y overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {revisionAuthorsToDisplay.map((user) => (
            <Combobox.Option
              key={user.id}
              value={user.id}
              className={`relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white ${
                authorsFilter.find((d) => d.id === user.id)
                  ? 'bg-indigo-600 text-white'
                  : ''
              }`}
            >
              {user.first_name} {user.last_name}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default RevisionAuthorFilter
