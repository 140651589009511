import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { workflows } from '../components/workflows/workflow-popover/workflow.data'
import {
  Icon,
  Workflow,
} from '../components/workflows/workflow-popover/workflow-popover'
import { BoltIcon, EyeIcon } from '@heroicons/react/24/outline'

export interface LoadingWorkflowProps {
  title: string
  Icon?: Icon
  route: string
  featureFlag?: boolean
}

const loadingWorkflow: LoadingWorkflowProps = {
  title: 'Select a Project',
  Icon: undefined,
  route: '',
  featureFlag: true,
}

const customRiskListsWorkflow: Workflow = {
  title: 'Custom Risk Lists',
  Icon: BoltIcon,
  route: 'customrisklists',
  featureFlag: true,
}

const qualityAssuranceWorkflow: Workflow = {
  title: 'Quality Assurance',
  Icon: EyeIcon,
  route: 'customrisklists',
  featureFlag: true,
}

export const useWorkflow = () => {
  const [selectedWorkflow, setSelectedWorkflow] = useState<
    Workflow | LoadingWorkflowProps
  >(loadingWorkflow)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!pathname) {
      return
    }
    if (pathname.includes('customrisklists')) {
      setSelectedWorkflow(customRiskListsWorkflow)
      return
    }
    if (pathname.includes('quality-assurance')) {
      setSelectedWorkflow(qualityAssuranceWorkflow)
      return
    }
    const workflowMatch = workflows.find((w) => pathname.includes(w.route))
    if (workflowMatch) {
      setSelectedWorkflow(workflowMatch)
    } else {
      setSelectedWorkflow(loadingWorkflow)
    }
  }, [pathname])

  return { workflowMatch: selectedWorkflow }
}
