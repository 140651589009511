import React from 'react'
import { useNavigateWorkflow } from '../../hooks/use-navigate-workflow'
import { workflows } from '../workflows/workflow-popover/workflow.data'
import { useCallback } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

export const CustomRiskListsHeader: React.FC = () => {
  const { navigateWorkflow } = useNavigateWorkflow()

  const handleBackToRiskReview = useCallback(() => {
    navigateWorkflow(workflows[1])
  }, [navigateWorkflow])

  return (
    <div className="flex items-center justify-between">
      <button
        type="button"
        className="justify-left flex items-center rounded px-3 py-1.5 text-xs font-semibold text-gray-900 hover:border-gray-600 hover:bg-gray-200"
        onClick={handleBackToRiskReview}
      >
        <ArrowLeftIcon className="mr-2 h-5 w-5" />
        Back to Risk Review
      </button>
    </div>
  )
}
