import React, { useEffect } from 'react'
import DocumentList from '../document-list'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentProject } from '../../../redux/application-slice'

function ProjectHome() {
  const navigate = useNavigate()
  const currentProject = useSelector(selectCurrentProject)

  useEffect(() => {
    if (currentProject !== null) {
      navigate(`/${currentProject.uuid}/documents`)
    }
  }, [currentProject, navigate])

  return (
    <div
      id="documents-section"
      className="relative flex grow flex-col overflow-hidden"
    >
      <DocumentList />
    </div>
  )
}

export default ProjectHome
