import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import 'react-tooltip/dist/react-tooltip.css'
import { useGetDocumentByUuidQuery } from '../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../redux/application-slice'
import {
  selectCurrentPage,
  selectCursorTool,
  selectCustomLabelOpen,
  selectEditorMode,
  selectIconMenuOpen,
  selectNewRevision,
  selectOpenRevision,
  selectTextSelected,
  setCurrentPage,
  setIconMenuOpen,
  setNewRevision,
  setOpenRevision,
  setTemporaryHighlight,
  setTextSelected,
  setTotalPages,
  setZoomLevel,
} from '../redux/secondary-viewer-slice'
import debounce from 'lodash/debounce'
import { skipToken } from '@reduxjs/toolkit/query'
import NavigationHistoryPane from './viewer/navigation-history-pane'
import { POSTHOG } from '../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'
import { useHotkeys } from 'react-hotkeys-hook'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'
import { DocumentViewerCommentContext } from '../contexts/document-viewer-comment-instance-context'
import SecondaryPageOverlaySimpleController from './secondary-page-overlay-simple-controller'
import SecondaryPageCount from './secondary-page-count'
import { isRatio } from '../utils/convert-quads'
import { TemporaryHighlight } from '../redux/viewer-slice'
import PageCountSecondary from './page-count-secondary'
import useWindowDimensions from '../hooks/use-window-dimensions'
import ReactDOM from 'react-dom'
import SecondaryContextMenu from './overlay/secondary-context-menu'
import { useCoords } from '../hooks/use-coords'

interface DocumentViewerProps {
  selectedDocument: ProjectDocumentMetadata | null
  selectedSource: {
    source?: number
    source_document_uuid?: string
    shouldScroll: boolean
  } | null
  revisionSelected?: Revision | null
  documentChangeSelected?: DocumentChange | null
  overlay?: boolean
  onSelectReplaceText: () => void
  onSelectAddContext: () => void
  originalRevision?: Revision | null
  tabWidth?: number
  isChanging?: boolean
}

const SecondaryDocumentViewerSimple: React.FC<DocumentViewerProps> = ({
  selectedDocument,
  selectedSource,
  revisionSelected,
  documentChangeSelected,
  overlay = true,
  onSelectReplaceText,
  onSelectAddContext,
  originalRevision,
  tabWidth,
  isChanging,
}) => {
  const { width } = useWindowDimensions()
  const [renderedPages, setRenderedPages] = useState([])

  const cursorTool = useSelector(selectCursorTool)
  const editorMode = useSelector(selectEditorMode)

  const scrollView = useRef<HTMLDivElement | null>(null)
  const viewer = useRef<HTMLDivElement | null>(null)
  const renderedPagesRef = useRef<number[]>(renderedPages)

  const dispatch = useDispatch()
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const customLabelOpen = useSelector(selectCustomLabelOpen)
  const iconMenuOpen = useSelector(selectIconMenuOpen)
  const newRevision = useSelector(selectNewRevision)
  const openRevision = useSelector(selectOpenRevision)
  const customLabelRef = useRef({})
  const iconMenuOpenRef = useRef({})
  const openRevisionRef = useRef({})
  const textSelected = useSelector(selectTextSelected)
  const selectedSourceRef = useRef<{
    source?: number
    source_document_uuid?: string
  } | null>(null)
  const revisionSelectedRef = useRef<Revision | null>(null)
  const currentPage = useSelector(selectCurrentPage)

  const { getCoords, getTextSelectedTopOffset } = useCoords()

  const documentViewerContext = useContext(DocumentViewerCommentContext)
  const { documentViewer } = documentViewerContext

  const [searchParams] = useSearchParams()

  const { currentData: currentDocumentData } = useGetDocumentByUuidQuery(
    selectedDocument?.uuid ?? skipToken
  )

  const webviewer = document
    ?.getElementById('document-viewer-secondary')
    ?.querySelector('#virtualListContainer')

  useEffect(() => {
    const newLevel = documentViewer?.getZoomLevel() ?? 0
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer, width])

  const highlightSources = useCallback(
    (pageNumber?: number, shouldScroll: boolean = true) => {
      setTimeout(() => {
        if (selectedSourceRef.current) {
          if (shouldScroll) {
            documentViewer?.setCurrentPage(
              selectedSourceRef.current?.source ??
                revisionSelectedRef.current?.page ??
                1,
              false
            )
          }
          if (
            (!revisionSelectedRef.current ||
              pageNumber !== revisionSelectedRef.current?.page) &&
            selectedSourceRef.current &&
            pageNumber !== selectedSourceRef.current?.source
          ) {
            return
          }
          const highlights: TemporaryHighlight[] = []
          revisionSelectedRef.current?.segments?.forEach((segment, index) => {
            highlights.push({
              page: segment.page,
              quads: segment.quads ?? [],
              id: `highlight_${revisionSelectedRef.current?.id}_${segment.id}`,
              pdfCoords: isRatio(segment.quads ?? []),
            })
          })
          dispatch(setTemporaryHighlight(highlights))
          if (!shouldScroll) {
            return
          }
          setTimeout(() => {
            const el = window?.document?.getElementById(
              'webviewer-wrapper-second'
            )?.parentNode as HTMLDivElement
            if (!el) {
              return
            }
            const highlightEl = document.getElementById(
              highlights?.[0]?.id ?? ''
            )
            if (!highlightEl) {
              return
            }
            highlightEl.scrollIntoView({
              block: 'start',
            })
            el.scrollTop -= 200
          }, 200)
        }
      }, 200)
    },
    [dispatch, documentViewer]
  )

  useEffect(() => {
    highlightSources(selectedSource?.source, selectedSource?.shouldScroll)
  }, [selectedDocument, selectedSource, highlightSources])

  useEffect(() => {
    if (documentChangeSelected) {
      documentViewer?.setCurrentPage(documentChangeSelected.source.page, false)
    }
  }, [documentChangeSelected, documentViewer])

  useEffect(() => {
    setTimeout(() => {
      if (openRevision || newRevision) {
        openRevisionRef.current = true
      } else {
        openRevisionRef.current = false
      }
    }, 50)
  }, [openRevision, newRevision])

  useEffect(() => {
    customLabelRef.current = customLabelOpen
  }, [customLabelOpen])

  useEffect(() => {
    selectedSourceRef.current = selectedSource
  }, [selectedSource])

  useEffect(() => {
    revisionSelectedRef.current = revisionSelected ?? null
  }, [revisionSelected])

  useEffect(() => {
    //very small timeout to support order of operations between click callback and risk menu flag being updated
    setTimeout(() => {
      iconMenuOpenRef.current = iconMenuOpen
    }, 50)
  }, [iconMenuOpen])

  const setRenderedPagesRef = (data) => {
    renderedPagesRef.current = data
    setRenderedPages(data)
  }

  useHotkeys('ctrl+c', async () => {
    if (!textSelected) {
      return
    }
    await navigator.clipboard.writeText(textSelected.text ?? '')
  })

  useHotkeys('meta+c', async () => {
    if (!textSelected) {
      return
    }
    await navigator.clipboard.writeText(textSelected.text ?? '')
  })

  useEffect(() => {
    const pageCompleteCallback = (pageNumber: number) => {
      if (!renderedPagesRef.current.includes(pageNumber)) {
        setRenderedPagesRef([...renderedPagesRef.current, pageNumber])
      }
    }
    const textSelectedCallback = debounce((quads, text, pageNumber) => {
      if (customLabelRef.current) {
        return
      }
      if (quads && quads.length > 0) {
        const textSelected = documentViewer?.getSelectedText()
        const quadsSelected = documentViewer?.getSelectedTextQuads()
        dispatch(
          setTextSelected({
            quads: quadsSelected,
            text: textSelected,
            pageNumber,
          })
        )
        posthog?.capture(POSTHOG.text_selected, {
          document_uuid: selectedDocument?.uuid,
        })
      } else {
        dispatch(setTextSelected(null))
      }
    }, 50)
    const pageNumberUpdatedCallback = (pageNumber) => {
      dispatch(setCurrentPage(pageNumber))
      debounce(() => {
        posthog?.capture(POSTHOG.page_changed, {
          page: pageNumber,
          document_uuid: selectedDocument?.uuid,
          project_uuid: currentProject?.uuid,
        })
      }, 1000)()
    }
    const documentClickCallback = (e: React.MouseEvent<HTMLElement>) => {
      if (openRevisionRef.current) {
        let trueCount = 0
        const revisionSidelings =
          document.querySelectorAll('.revision-sideling')
        for (const revisionSideling of revisionSidelings) {
          if (revisionSideling.contains(e.target as HTMLElement)) {
            trueCount += 1
            break
          }
        }
        if (trueCount === 0 && !openRevisionRef.current) {
          dispatch(setOpenRevision(null))
          dispatch(setNewRevision(null))
        }
      }
      if (iconMenuOpenRef.current !== null) {
        dispatch(setIconMenuOpen(null))
      }
    }
    const documentLoadedCallback = () => {
      if (!documentViewer) {
        return
      }
      const fitMode = 'FitMode'
      documentViewer?.setFitMode(documentViewer[fitMode].FitWidth)
      setRenderedPagesRef([])
      highlightSources(revisionSelectedRef.current?.page)
    }

    if (documentViewer) {
      // Attach viewer elements
      if (!scrollView.current || !viewer.current) {
        return
      }
      documentViewer?.setScrollViewElement(scrollView.current)
      documentViewer?.setViewerElement(viewer.current)

      dispatch(setZoomLevel(documentViewer?.getZoomLevel()))
      documentViewer?.enableAnnotations()

      // Set default tool
      documentViewer?.setToolMode(documentViewer?.getTool('TextSelect'))

      // Hook up event listeners
      documentViewer?.addEventListener('pageComplete', pageCompleteCallback)
      documentViewer?.addEventListener('textSelected', textSelectedCallback)
      documentViewer?.addEventListener(
        'pageNumberUpdated',
        pageNumberUpdatedCallback
      )
      documentViewer?.addEventListener('documentLoaded', documentLoadedCallback)
      documentViewer?.addEventListener('click', documentClickCallback)
    }
    return () => {
      if (documentViewer) {
        documentViewer?.removeEventListener(
          'textSelected',
          textSelectedCallback
        )
        documentViewer?.removeEventListener(
          'pageNumberUpdated',
          pageNumberUpdatedCallback
        )
        documentViewer?.removeEventListener(
          'pageComplete',
          pageCompleteCallback
        )
        documentViewer?.removeEventListener(
          'documentLoaded',
          documentLoadedCallback
        )
        documentViewer?.removeEventListener('click', documentClickCallback)
      }
    }
    // eslint-disable-next-line
  }, [documentViewer, setZoomLevel])

  useEffect(() => {
    if (currentDocumentData && documentViewer) {
      const fileUrl =
        currentDocumentData.optimized_file ?? currentDocumentData.file
      documentViewer
        .loadDocument(fileUrl, {
          licenseKey: atob(
            'UHJvdmlzaW9uIFNvZnR3YXJlIENvcnBvcmF0aW9uIChnZXRwcm92aXNpb24uY28pOk9FTTpQcm92aXNpb246OkIrOkFNUygyMDIzMDcxNyk6RkFBNTQ5MkQwNDg3ODgwQUYzNjBCMTNBQzlBMjczNzg2MDYxMkY4NUY3MTgxQUEwREQwNDdCOEFBRDRDNkU5MDNBOTQzMUY1Qzc='
          ),
        })
        .then(() => {
          const page = searchParams.get('page')
          if (page) {
            documentViewer?.setCurrentPage(parseInt(page), false)
          }
          dispatch(setZoomLevel(documentViewer?.getZoomLevel()))
          try {
            dispatch(setTotalPages(documentViewer?.getPageCount()))
          } catch (e) {
            //
          }
        })
    }
    // eslint-disable-next-line
  }, [currentDocumentData, documentViewer])

  useEffect(() => {
    const page = searchParams.get('page')
    documentViewer?.setCurrentPage(parseInt(page ?? '1'), false)
  }, [searchParams, documentViewer])

  useEffect(() => {
    if (selectedDocument?.uuid) {
      posthog?.capture(POSTHOG.document_opened, {
        document_uuid: selectedDocument?.uuid,
      })
    }
    //   eslint-disable-next-line
  }, [selectedDocument?.uuid])

  useEffect(() => {
    if (cursorTool && documentViewer) {
      documentViewer?.setToolMode(documentViewer?.getTool(cursorTool))
    }
  }, [cursorTool, documentViewer])

  const onZoomIncrease = useCallback(() => {
    const newLevel = Math.min((documentViewer?.getZoomLevel() ?? 0) + 0.15, 2)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  const onZoomDecrease = useCallback(() => {
    const newLevel = Math.max((documentViewer?.getZoomLevel() ?? 0) - 0.15, 0.3)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  const documentViewerHeader = useMemo(() => {
    return (
      <div className={'flex w-full items-center justify-center space-x-2'}>
        <div>
          <NavigationHistoryPane />
        </div>
        {revisionSelected ? (
          <PageCountSecondary
            revision={revisionSelected}
            originalRevisionPage={originalRevision?.page ?? 1}
            totalPages={selectedDocument?.total_pages ?? 0}
            document={selectedDocument}
          />
        ) : null}
      </div>
    )
  }, [originalRevision?.page, revisionSelected, selectedDocument])

  const getResultsAbove = useMemo(() => {
    if (!currentPage) {
      return 0
    }
    const segments = revisionSelected?.segments ?? []
    return segments.filter((segment) => segment.page < currentPage).length
  }, [currentPage, revisionSelected?.segments])

  const getResultsBelow = useMemo(() => {
    if (!currentPage) {
      return 0
    }
    const segments = revisionSelected?.segments ?? []
    return segments.filter((segment) => segment.page > currentPage).length
  }, [currentPage, revisionSelected?.segments])

  const onClickGoToAboveResults = useCallback(() => {
    const nextPageAbove = revisionSelected?.segments
      ?.filter((segment) => segment.page < currentPage)
      .sort((a, b) => b.page - a.page)?.[0]?.page
    if (nextPageAbove) {
      documentViewer?.setCurrentPage(nextPageAbove, true)
    }
  }, [currentPage, documentViewer, revisionSelected?.segments])

  const onClickGotoBelowResults = useCallback(() => {
    const nextPageBelow = revisionSelected?.segments
      ?.filter((segment) => segment.page > currentPage)
      .sort((a, b) => a.page - b.page)?.[0]?.page
    if (nextPageBelow) {
      documentViewer?.setCurrentPage(nextPageBelow, true)
    }
  }, [currentPage, documentViewer, revisionSelected?.segments])

  const textSelectedPositions = useMemo(() => {
    if (!textSelected) {
      return {
        topOffset: 0,
        leftOffset: 0,
        width: 0,
        height: 0,
      }
    }
    const coords = getCoords(
      textSelected?.quads[textSelected.pageNumber][
        textSelected?.quads[textSelected.pageNumber].length - 1
      ],
      textSelected.pageNumber,
      'document-viewer-secondary',
      isRatio([
        textSelected?.quads[textSelected.pageNumber][
          textSelected?.quads[textSelected.pageNumber].length - 1
        ],
      ])
    )
    if (!coords) {
      return {
        topOffset: 0,
        leftOffset: 0,
        width: 0,
        height: 0,
      }
    }
    return coords
  }, [getCoords, textSelected])

  return (
    <div
      style={{
        width: tabWidth,
      }}
      className="flex h-full max-w-full items-stretch overflow-hidden"
    >
      <div className={'flex min-w-0 flex-1 flex-grow flex-col bg-gray-100'}>
        {overlay ? (
          <div
            className={
              'flex h-10 flex-shrink-0 items-center justify-center border-b border-gray-300  bg-gray-50 px-2'
            }
          >
            <div className={'flex items-center space-x-2'}>
              <button
                className={'rounded bg-gray-100 p-1.5 shadow-sm hover:bg-white'}
                onClick={onZoomIncrease}
              >
                <PlusIcon width={20} />
              </button>
              <button
                className={'rounded bg-gray-100 p-1.5 shadow-sm hover:bg-white'}
                onClick={onZoomDecrease}
              >
                <MinusIcon width={20} />
              </button>
              <SecondaryPageCount />
            </div>
          </div>
        ) : (
          documentViewerHeader
        )}
        <div className="relative h-0 w-full text-xs">
          {!isChanging && getResultsAbove ? (
            <div className="absolute top-5 z-50 flex w-full justify-center">
              <button
                onClick={onClickGoToAboveResults}
                className="relative flex w-36 items-center justify-between rounded bg-blue-100 p-2"
              >
                <div>{getResultsAbove} result(s) above</div>{' '}
                <ChevronUpIcon className="h-4 w-4" />
              </button>
            </div>
          ) : null}
          {!isChanging && getResultsBelow ? (
            <div className="absolute top-[82lvh] z-50 flex w-full justify-center">
              <button
                onClick={onClickGotoBelowResults}
                className="relative flex w-36 items-center justify-between rounded bg-blue-100 p-2"
              >
                <div>{getResultsBelow} result(s) below</div>{' '}
                <ChevronDownIcon className="h-4 w-4" />
              </button>
            </div>
          ) : null}
        </div>
        {webviewer &&
          ReactDOM.createPortal(
            <>
              {textSelected &&
                editorMode &&
                textSelected?.quads?.[textSelected.pageNumber] && (
                  <SecondaryContextMenu
                    onSelectReplaceText={onSelectReplaceText}
                    onSelectAddContext={onSelectAddContext}
                    positionY={`${getTextSelectedTopOffset(textSelected, 'document-viewer-secondary')}px`}
                    positionX={`${textSelectedPositions.leftOffset}px`}
                  />
                )}
            </>,
            webviewer
          )}
        <div
          id="document-viewer-secondary"
          className={'flex-1 overflow-auto bg-gray-200'}
          ref={scrollView}
        >
          <div
            className="webviewer z-10 mx-auto"
            id="webviewer-wrapper-second"
            ref={viewer}
          />
        </div>
      </div>
      {selectedDocument ? (
        <SecondaryPageOverlaySimpleController
          onSelectReplaceText={onSelectReplaceText}
          pages={renderedPages}
          revisionSelected={overlay ? revisionSelected : null}
          documentChangeSelected={documentChangeSelected}
          onSelectAddContext={onSelectAddContext}
        />
      ) : null}
    </div>
  )
}

export default SecondaryDocumentViewerSimple
