import React, { useCallback, useMemo, useState } from 'react'
import {
  useCreateCustomLabelMutation,
  useGetCustomLabelsQuery,
} from '../../redux/api/custom-label-api-slice'
import { useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { Project } from '../../shared/interfaces/project/project.interface'
import { skipToken } from '@reduxjs/toolkit/query'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { useUpdateRevisionCustomLabelsMutation } from '../../redux/api/api-revisions-slice'
import { CheckIcon } from '@heroicons/react/24/outline'
import { selectCommentWorkflowFilterStateQueryParams } from '../../redux/workflow-slice'
import { CustomLabel } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'

interface CustomLabelSelectorProps {
  revision?: Revision
}

const CustomLabelSelector: React.FC<CustomLabelSelectorProps> = ({
  revision,
}) => {
  const currentProject = useSelector(selectCurrentProject) as Project
  const [labelQuery, setLabelQuery] = useState<string>('')
  const [createCustomLabelAPI] = useCreateCustomLabelMutation()
  const currentDocument = useSelector(selectCurrentDocument)

  const { data: labels } = useGetCustomLabelsQuery(
    currentProject?.uuid
      ? { projectUUID: currentProject?.uuid, segmentsIncluded: false }
      : skipToken
  )

  const filteredLabels = useMemo(() => {
    if (!labelQuery) return labels
    return labels?.filter((label) =>
      label.name.toLowerCase().includes(labelQuery.toLowerCase())
    )
  }, [labelQuery, labels])

  const onLabelQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelQuery(e.target.value)
  }

  const revisionQueryParams = useSelector(
    selectCommentWorkflowFilterStateQueryParams
  )

  const onCreateCustomLabel = useCallback(() => {
    if (
      !currentProject ||
      !currentProject?.id ||
      !currentProject?.uuid ||
      !labelQuery
    )
      return
    createCustomLabelAPI({
      project: currentProject.id,
      projectUUID: currentProject.uuid,
      name: labelQuery,
    })
      .unwrap()
      .then(() => {
        setLabelQuery('')
      })
  }, [createCustomLabelAPI, currentProject, labelQuery])

  const renderCreateLabelItem = useMemo(() => {
    if (!labelQuery) return null
    return (
      <div
        className="overflow-hidden text-ellipsis whitespace-nowrap p-2"
        onClick={onCreateCustomLabel}
      >{`Create "${labelQuery}"`}</div>
    )
  }, [labelQuery, onCreateCustomLabel])

  const [updateRevisionLabels] = useUpdateRevisionCustomLabelsMutation()

  const isLabelChecked = useCallback(
    (label) => {
      return revision?.custom_labels?.some(
        (localLabel) => localLabel.id === label.id
      )
    },
    [revision?.custom_labels]
  )

  const onLabelClicked = useCallback(
    (e) => {
      e.stopPropagation()
      const existingLabels = revision?.custom_labels
      const label: CustomLabel | undefined = labels?.find(
        (label) => label.id === parseInt(e.currentTarget.value)
      )
      if (!label || !revision?.id || !existingLabels) return

      if (isLabelChecked(label)) {
        const newLabels = existingLabels.filter(
          (localLabel) => localLabel.id !== label.id
        )
        updateRevisionLabels({
          revisionId: revision?.id,
          labels: newLabels ?? [],
          queryParams: revisionQueryParams,
          currentDocumentId: currentDocument?.id,
        })
      } else {
        updateRevisionLabels({
          revisionId: revision?.id,
          labels: [...existingLabels, label],
          queryParams: revisionQueryParams,
          currentDocumentId: currentDocument?.id,
        })
      }
    },
    [
      currentDocument?.id,
      isLabelChecked,
      labels,
      revision?.custom_labels,
      revision?.id,
      revisionQueryParams,
      updateRevisionLabels,
    ]
  )

  return (
    <div className="w-52 rounded bg-white p-2">
      <input
        type="text"
        className="mb-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="Search/Create labels"
        onChange={onLabelQueryChange}
      />
      {!labelQuery && filteredLabels?.length === 0 && <div>No labels</div>}
      {labelQuery && filteredLabels?.length === 0 && renderCreateLabelItem}
      <div className="space-y-2">
        {filteredLabels?.map((label) => (
          <button
            title={label.name}
            key={label.id}
            className="flex w-full items-center justify-between rounded p-2 hover:bg-gray-100"
            value={label.id}
            onClick={onLabelClicked}
          >
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
              {label.name}
            </div>
            {isLabelChecked(label) && <CheckIcon className="w-5 shrink-0" />}
          </button>
        ))}
      </div>
    </div>
  )
}

export default CustomLabelSelector
