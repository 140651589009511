import React, { useCallback, useMemo } from 'react'
import {
  ChatBubbleBottomCenterIcon,
  CheckIcon,
  ChevronDoubleUpIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Pill, Text } from '@mantine/core'
import clsx from 'clsx'
import { useUpdateProjectRiskMutation } from '../../../../redux/api/project-risk-api-slice'

import { selectRiskWorkflowFilterState } from '../../../../redux/workflow-slice'
import { POSTHOG } from '../../../../utils/posthog-constants'
import { useSelector } from 'react-redux'
import { usePostHog } from 'posthog-js/react'

// TODO figure out if we want color to be togglable or not
const colorsEnabled = true

const pillColoursClassNames = {
  green: 'border-green-400 hover:border-emerald-500 hover:bg-emerald-50',
  yellow: 'border-amber-400 hover:border-amber-400 hover:bg-amber-50',
  red: 'border-rose-300 hover:border-rose-500 hover:bg-rose-50',
  blue: 'border-slate-300 hover:border-sky-500 hover:bg-sky-50',
  'blue-persistent':
    'border-blue-300 bg-sky-50 hover:border-sky-500 hover:bg-sky-100',
  gray: 'border-slate-300 hover:border-slate-300 hover:bg-slate-100',
}

type ColorKey = keyof typeof pillColoursClassNames | null

type GeneratedPillProps = {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | null
  title: string
  colorKey: ColorKey | string
  number?: number
  onClick?: (e: React.MouseEvent) => void
  testSlug?: string
  setRiskPriority?: (value: number) => void
  setRiskImportance?: (value: number) => void
  clickable?: boolean
}

export const GeneratedPill: React.FC<GeneratedPillProps> = ({
  icon: Icon,
  title,
  number,
  colorKey,
  onClick,
  testSlug,
  clickable,
}) => {
  return (
    <Pill
      c="black"
      variant="outline"
      size="lg"
      pt="1px"
      mr="8px"
      className={clsx(
        'flex items-center justify-center border-2 text-[#fafafa] transition-colors duration-200',
        colorsEnabled && colorKey
          ? pillColoursClassNames[colorKey]
          : 'border-slate-300',
        clickable && onClick && 'cursor-pointer'
      )}
      onClick={(e) => {
        e.stopPropagation()
        if (clickable && onClick) {
          onClick(e)
        }
      }}
      data-testid={`priority-selector-${testSlug ?? 'generic'}-pill`}
      style={{ height: 'auto' }}
    >
      <div className="flex items-center justify-center px-0 py-1">
        {Icon && <Icon className="mr-1.5 h-4 w-4 text-black" />}
        <Text className="capitalize" size="sm" c="black">
          {number ? `${number} ` : null}
          {title}
        </Text>
      </div>
    </Pill>
  )
}

const usePillData = (
  currentProject,
  projectRisk,
  setRiskPriority,
  setRiskImportance
) => {
  const posthog = usePostHog()
  const [updateProjectRisk] = useUpdateProjectRiskMutation()
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)

  const onClickApproved = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_status_approved, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
    })
    if (!currentProject?.uuid || !projectRisk?.id) {
      return
    }
    updateProjectRisk({
      id: projectRisk?.id,
      status: projectRisk?.status === 1 ? 0 : 1,
      projectUUID: currentProject?.uuid ?? '',
      sort: riskWorkflowFilterState?.sort,
    })
    setRiskPriority(1)
  }, [
    updateProjectRisk,
    riskWorkflowFilterState?.sort,
    currentProject?.uuid,
    posthog,
    projectRisk?.id,
    projectRisk?.risk_name,
    projectRisk?.status,
    setRiskPriority,
  ])

  const onClickNotApproved = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_status_not_approved, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
    })
    if (!currentProject?.uuid || !projectRisk?.id) {
      return
    }
    updateProjectRisk({
      id: projectRisk?.id,
      status: projectRisk?.status === 2 ? 0 : 2,
      projectUUID: currentProject?.uuid,
      riskStatusFilter: riskWorkflowFilterState.status,
      sort: riskWorkflowFilterState?.sort,
    })
    setRiskPriority(2)
  }, [
    updateProjectRisk,
    riskWorkflowFilterState.status,
    riskWorkflowFilterState?.sort,
    currentProject?.uuid,
    posthog,
    projectRisk?.id,
    projectRisk?.risk_name,
    projectRisk?.status,
    setRiskPriority,
  ])

  const onClickNeedsReview = useCallback(() => {
    if (!currentProject?.uuid || !projectRisk?.id) {
      return
    }
    updateProjectRisk({
      id: projectRisk?.id,
      status: projectRisk?.status === 3 ? 0 : 3,
      projectUUID: currentProject?.uuid,
      riskStatusFilter: riskWorkflowFilterState?.status,
      sort: riskWorkflowFilterState?.sort,
    })
    setRiskPriority(3)
  }, [
    riskWorkflowFilterState?.sort,
    riskWorkflowFilterState?.status,
    updateProjectRisk,
    currentProject?.uuid,
    projectRisk?.id,
    projectRisk?.status,
    setRiskPriority,
  ])

  const updateRiskRanking = useCallback(
    (value: number) => {
      if (!projectRisk?.id || !currentProject?.uuid) return
      updateProjectRisk({
        id: projectRisk?.id,
        riskRanking: value,
        projectUUID: currentProject?.uuid,
        sort: riskWorkflowFilterState?.sort,
      })
      setRiskImportance(value)
    },
    [
      updateProjectRisk,
      riskWorkflowFilterState?.sort,
      currentProject?.uuid,
      projectRisk?.id,
      setRiskImportance,
    ]
  )

  const onSetHighRiskRanking = useCallback(() => {
    updateRiskRanking(3)
  }, [updateRiskRanking])

  const onSetMediumRiskRanking = useCallback(() => {
    updateRiskRanking(2)
  }, [updateRiskRanking])

  const onSetLowRiskRanking = useCallback(() => {
    updateRiskRanking(1)
  }, [updateRiskRanking])

  return useMemo(
    () => ({
      approved: {
        testSlug: 'approved',
        icon: CheckIcon,
        title: 'Approved',
        colorKey: 'green',
        onClick: onClickApproved,
      },
      notApproved: {
        testSlug: 'not-approved',
        icon: XMarkIcon,
        title: 'Not Approved',
        colorKey: 'red',
        onClick: onClickNotApproved,
      },
      needsReview: {
        testSlug: 'needs-review',
        icon: () => (
          <svg
            className="mr-1"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM51.31,160,136,75.31,152.69,92,68,176.68ZM48,179.31,76.69,208H48Zm48,25.38L79.31,188,164,103.31,180.69,120Zm96-96L147.31,64l24-24L216,84.68Z"></path>
          </svg>
        ),
        title: 'Needs Review',
        colorKey: 'yellow',
        onClick: onClickNeedsReview,
      },
      high: {
        testSlug: 'high',
        icon: ChevronDoubleUpIcon,
        title: 'High',
        colorKey: 'red',
        onClick: onSetHighRiskRanking,
      },
      medium: {
        testSlug: 'medium',
        icon: ChevronUpIcon,
        title: 'Medium',
        colorKey: 'yellow',
        onClick: onSetMediumRiskRanking,
      },
      low: {
        testSlug: 'low',
        icon: () => (
          <svg
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        ),
        title: 'Low',
        colorKey: 'blue',
        onClick: onSetLowRiskRanking,
      },
      comments: {
        testSlug: 'comments',
        icon: ChatBubbleBottomCenterIcon,
        title: 'Comments',
        colorKey: null,
      },
      definitions: {
        testSlug: 'definitions',
        icon: null,
        title: '? Definition',
        colorKey: null,
      },
      notSet: {
        testSlug: 'not-set',
        icon: null,
        title: 'Not Set',
        colorKey: 'gray',
      },
    }),
    [
      onClickApproved,
      onClickNotApproved,
      onClickNeedsReview,
      onSetHighRiskRanking,
      onSetMediumRiskRanking,
      onSetLowRiskRanking,
    ]
  )
}

export default usePillData
