import React, { useContext, useEffect, useCallback, useMemo } from 'react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {
  ArrowUpRightIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  useGetDocumentsListByProjectQuery,
  useGetProjectsQuery,
} from '../../redux/api-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
  setModal,
} from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES } from '../modals/modal-controller'
import { useNavigate } from 'react-router-dom'
import { IoDocumentsOutline } from 'react-icons/io5'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { skipToken } from '@reduxjs/toolkit/query'
import { addNavigationHistory } from '../../redux/viewer-slice'
import {
  CommandBarOptions,
  Project,
} from '../../shared/interfaces/project/project.interface'
import UseUrlNavigate from '../../hooks/use-url-navigate'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const shortcuts = [
  { keys: ['CTRL/CMD', 'K'], description: 'Open command bar' },
  { keys: ['CTRL/CMD', 'F'], description: 'Open search/toggle search type' },
  { keys: ['CTRL/CMD', 'C'], description: 'Copy selected text' },
  { keys: ['C'], description: 'Create a revision' },
  { keys: ['H'], description: 'Create a highlight' },
  { keys: ['A'], description: 'Toggle Select/Pan' },
  { keys: ['S'], description: 'Toggle Single/Continous pages' },
  { keys: ['+'], description: 'Zoom in' },
  { keys: ['-'], description: 'Zoom out' },
]

interface CommandBarComboboxProps {
  open: boolean
  rawQuery: string
  setRawQuery: React.Dispatch<React.SetStateAction<string>>
  selectedProject: Project | null
  setSelectedProject: React.Dispatch<React.SetStateAction<Project | null>>
}

const CommandBarCombobox: React.FC<CommandBarComboboxProps> = ({
  open,
  rawQuery,
  setRawQuery,
  selectedProject,
  setSelectedProject,
}) => {
  const dispatch = useDispatch()
  const useUrlNavigate = UseUrlNavigate()
  const navigate = useNavigate()
  const currentProject = useSelector(selectCurrentProject)
  const { data: projects } = useGetProjectsQuery(undefined)
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    selectedProject ? { projectId: selectedProject?.id } : skipToken
  )

  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const currentDocument = useSelector(selectCurrentDocument)

  useEffect(() => {
    if (!open) {
      return
    }
    if (!currentProject) {
      return
    }
    setSelectedProject(currentProject)
  }, [open, currentProject, setSelectedProject])

  const actions = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Create new project',
        icon: PlusIcon,
        type: 'action',
        action: () => dispatch(setModal({ modal: MODAL_TYPES.CREATE_PROJECT })),
      },
      {
        id: 2,
        title: 'Go to file manager',
        icon: IoDocumentsOutline,
        type: 'action',
        action: () => navigate('/'),
      },
    ]
  }, [dispatch, navigate])

  const workflows = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Risk Review',
        icon: ChatBubbleBottomCenterTextIcon,
        type: 'workflow',
        url: `${currentProject?.uuid}/riskreview/${
          currentDocument?.uuid ?? ''
        }`,
      },
      {
        id: 2,
        title: 'Chat',
        icon: ChatBubbleBottomCenterTextIcon,
        type: 'workflow',
        url: `${currentProject?.uuid}/chat/${currentDocument?.uuid ?? ''}`,
      },
      {
        id: 3,
        title: 'Comments',
        icon: ChatBubbleBottomCenterTextIcon,
        type: 'workflow',
        url: `${currentProject?.uuid}/comments/${currentDocument?.uuid ?? ''}`,
      },
      {
        id: 4,
        title: 'Clause Filtering',
        icon: ChatBubbleBottomCenterTextIcon,
        type: 'workflow',
        url: `${currentProject?.uuid}/clause-filters/${
          currentDocument?.uuid ?? ''
        }`,
      },
      {
        id: 5,
        title: 'Supplementary Conditions',
        icon: ChatBubbleBottomCenterTextIcon,
        type: 'workflow',
        url: `${currentProject?.uuid}/supplementary-conditions/${
          currentDocument?.uuid ?? ''
        }`,
      },
    ]
  }, [currentProject?.uuid, currentDocument?.uuid])

  const query = useMemo(() => {
    return rawQuery.toLowerCase().replace(/^[/>]/, '')
  }, [rawQuery])

  const projectsQuery = rawQuery.startsWith('/') && !selectedProject
  const questionQuery = rawQuery === '?'
  const documentQuery = selectedProject
  const showActions = !(projectsQuery || questionQuery || documentQuery)
  const showWorkflows = !(projectsQuery || questionQuery) && selectedProject
  const numberOnlyQuery = /^\d+$/.test(rawQuery)

  const filteredActions = useMemo(() => {
    return showActions
      ? actions.filter((action) => action.title.toLowerCase().includes(query))
      : []
  }, [actions, query, showActions])

  const filteredProjects = useMemo(() => {
    return projectsQuery
      ? projects
          ?.filter((project) => project?.title?.toLowerCase().includes(query))
          ?.map((project) => ({
            ...project,
            type: 'project',
          }))
      : []
  }, [projectsQuery, projects, query])

  const filteredDocuments = useMemo(() => {
    return documentQuery && documents
      ? documents
          .filter((document) => document.title.toLowerCase().includes(query))
          .map((project) => ({
            ...project,
            type: 'document',
          }))
      : []
  }, [documentQuery, documents, query])

  const filteredWorkflows = useMemo(() => {
    return showWorkflows
      ? workflows.filter((workflow) =>
          workflow.title.toLowerCase().includes(query)
        )
      : []
  }, [showWorkflows, workflows, query])

  const noResultsQuery = useMemo(() => {
    return (
      query !== '' &&
      rawQuery !== '?' &&
      filteredActions?.length === 0 &&
      filteredProjects?.length === 0 &&
      filteredDocuments?.length === 0 &&
      !numberOnlyQuery
    )
  }, [
    query,
    rawQuery,
    filteredActions,
    filteredProjects,
    filteredDocuments,
    numberOnlyQuery,
  ])

  const onEscapeKey = () => {
    if (documentQuery) {
      setSelectedProject(null)
    }
  }
  useHotkeys('esc', onEscapeKey)

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setTimeout(() => {
      setRawQuery('')
      setSelectedProject(null)
    }, 300)
  }, [dispatch, setRawQuery, setSelectedProject])

  const onInputChange = useCallback(
    (e) => {
      setRawQuery(e.target.value)
    },
    [setRawQuery]
  )

  const onKeyDown = useCallback(
    (e) => {
      if (rawQuery === '' && selectedProject && e.key === 'Backspace') {
        setSelectedProject(null)
      }
    },
    [rawQuery, selectedProject, setSelectedProject]
  )

  const onDeselectProject = useCallback(() => {
    setSelectedProject(null)
  }, [setSelectedProject])

  const onComboBoxChange = useCallback(
    (item: CommandBarOptions) => {
      switch (item.type) {
        case 'project':
          setSelectedProject(item)
          setRawQuery('')
          break
        case 'action':
          dispatch(setModal(null))
          item.action()
          break
        case 'document':
          setSelectedProject(item.project)
          useUrlNavigate.navigateDocumentUrl(item.uuid)
          onClose()
          break
        case 'goto': {
          const page = documentViewer?.getCurrentPage()
          if (!page) {
            break
          }
          dispatch(
            addNavigationHistory({
              page,
            })
          )
          documentViewer?.setCurrentPage(item.page, false)
          onClose()
          break
        }
        case 'workflow':
          navigate(item.url)
          onClose()
          break
        default:
          break
      }
    },
    [
      dispatch,
      documentViewer,
      useUrlNavigate,
      navigate,
      onClose,
      setRawQuery,
      setSelectedProject,
    ]
  )

  const comboBoxClassName = useCallback(({ active }) => {
    return classNames(
      'flex cursor-default select-none items-center px-4 py-2',
      active ? 'bg-indigo-600 text-white' : ''
    )
  }, [])

  return (
    <Combobox onChange={onComboBoxChange}>
      <div className="relative flex items-center">
        <MagnifyingGlassIcon
          className="pointer-events-none h-5 w-10 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        {selectedProject && (
          <div className="flex flex-shrink-0 items-center rounded border p-1 text-xs text-gray-700">
            <div>{selectedProject?.title}</div>
            <button
              onClick={onDeselectProject}
              className={'text-md pl-1 font-bold'}
            >
              x
            </button>
          </div>
        )}
        <Combobox.Input
          className="h-12 w-full border-0 bg-transparent pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
          placeholder={selectedProject ? '' : 'Search'}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
        />
      </div>

      {
        <Combobox.Options
          static
          className="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2 pt-2"
        >
          {numberOnlyQuery && (
            <li>
              <ul className="-mx-4 text-sm text-gray-700">
                <Combobox.Option
                  value={{ type: 'goto', page: parseInt(rawQuery) }}
                  className={comboBoxClassName}
                >
                  {({ active }) => (
                    <>
                      <ArrowUpRightIcon
                        className={classNames(
                          'h-6 w-6 flex-none',
                          active ? 'text-white' : 'text-gray-400'
                        )}
                        aria-hidden="true"
                      />
                      <span className="ml-3 flex-auto truncate">
                        {`Go to page ${rawQuery}`}
                      </span>
                    </>
                  )}
                </Combobox.Option>
              </ul>
            </li>
          )}

          {filteredWorkflows.length > 0 && (
            <li>
              <ul className="-mx-4 text-sm text-gray-700">
                {filteredWorkflows.map((workflow) => (
                  <Combobox.Option
                    key={`workflow_id_${workflow.id}`}
                    value={workflow}
                    className={comboBoxClassName}
                  >
                    {({ active }) => (
                      <>
                        <workflow.icon
                          className={classNames(
                            'h-6 w-6 flex-none',
                            active ? 'text-white' : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <span className="ml-3 flex-auto truncate">
                          {workflow.title}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </ul>
            </li>
          )}
          {filteredDocuments.length > 0 && (
            <li>
              <ul className="-mx-4 mt-2 text-sm text-gray-700">
                {!documents && <div>Loading</div>}
                {filteredDocuments.map((document) => (
                  <Combobox.Option
                    key={`document_id_${document.id}`}
                    value={document}
                    className={comboBoxClassName}
                  >
                    {({ active }) => (
                      <>
                        <DocumentIcon
                          className={classNames(
                            'h-6 w-6 flex-none',
                            active ? 'text-white' : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <span className="ml-3 flex-auto truncate">
                          {document.title}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </ul>
            </li>
          )}
          {Boolean(filteredProjects?.length && filteredProjects.length > 0) && (
            <li>
              <h2 className="text-xs font-semibold text-gray-900">Projects</h2>
              <ul className="-mx-4 mt-2 text-sm text-gray-700">
                {filteredProjects?.map((project) => (
                  <Combobox.Option
                    key={`project_id_${project.id}`}
                    value={project}
                    className={comboBoxClassName}
                  >
                    {({ active }) => (
                      <>
                        <FolderIcon
                          className={classNames(
                            'h-6 w-6 flex-none',
                            active ? 'text-white' : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <span className="ml-3 flex-auto truncate">
                          {project.title}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </ul>
            </li>
          )}
          {filteredActions.length > 0 && (
            <li>
              <ul className="-mx-4 text-sm text-gray-700">
                {filteredActions.map((action) => (
                  <Combobox.Option
                    key={`action_id_${action.id}`}
                    value={action}
                    className={comboBoxClassName}
                  >
                    {({ active }) => (
                      <>
                        <action.icon
                          className={classNames(
                            'h-6 w-6 flex-none',
                            active ? 'text-white' : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <span className="ml-3 flex-auto truncate">
                          {action.title}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </ul>
            </li>
          )}
        </Combobox.Options>
      }
      {questionQuery && (
        <div className="m-4 flex flex-col space-y-4">
          {shortcuts.map((shortcut) => (
            <div
              key={`shortcut_${shortcut.description}`}
              className={'flex gap-4'}
            >
              <div className={'w-1/3'}>
                {shortcut.keys.map((key, index) => (
                  <div className="flex" key={`shortcut_${key}`}>
                    <kbd className={'rounded border bg-white p-1'}>{key}</kbd>
                    {index < shortcut.keys.length - 1 ? ' + ' : ''}
                  </div>
                ))}
              </div>
              <div className={'w-2/3'}>{shortcut.description}</div>
            </div>
          ))}
        </div>
      )}

      {noResultsQuery && (
        <div className="px-6 py-14 text-center text-sm sm:px-14">
          <ExclamationTriangleIcon
            className="mx-auto h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
          <p className="mt-4 font-semibold text-gray-900">No results found</p>
          <p className="mt-2 text-gray-500">
            We couldn’t find anything with that term. Please try again.
          </p>
        </div>
      )}

      <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
        Type{' '}
        <kbd
          className={classNames(
            'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
            rawQuery.startsWith('/')
              ? 'border-indigo-600 text-indigo-600'
              : 'border-gray-400 text-gray-900'
          )}
        >
          /
        </kbd>{' '}
        <span className="sm:hidden">for projects,</span>
        <span className="hidden sm:inline">to access projects,</span>
        <kbd
          className={classNames(
            'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
            rawQuery === '?'
              ? 'border-indigo-600 text-indigo-600'
              : 'border-gray-400 text-gray-900'
          )}
        >
          ?
        </kbd>{' '}
        for shortcuts.
      </div>
    </Combobox>
  )
}
export default CommandBarCombobox
