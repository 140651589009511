import React, { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { ChatSessionMessageSource } from '../../../shared/interfaces/chat/chat-history.interface'
import { SelectedSource, setSelectedSources } from '../../../redux/viewer-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'

interface AIChatSourceMessageButtonProps {
  chatSessionMessageSource: ChatSessionMessageSource
  sources: ChatSessionMessageSource[]
  sourceIndex: number
  indexOfParagraphMatch: number
}

const AIChatSourceMessageButton: React.FC<AIChatSourceMessageButtonProps> = ({
  chatSessionMessageSource,
  sources,
  sourceIndex,
  indexOfParagraphMatch,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const [searchParams, setSearchParams] = useSearchParams()
  const posthog = usePostHog()

  const onSourceClick = useCallback(() => {
    navigate(
      `/${currentProject?.uuid}/chat/${chatSessionMessageSource.source_document_id}`
    )
    const page = chatSessionMessageSource.source.toString()
    searchParams.set('page', page)
    setSearchParams(searchParams)
    const selectedSources: SelectedSource[] = []
    const distinctSources = sources.reduce<ChatSessionMessageSource[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment
        if (!acc.find((c) => c.document_segment === document_segment_id)) {
          acc.push(citation)
        }
        return acc
      },
      []
    )
    for (const chatSource of distinctSources) {
      const highlightID = uuidv4()

      selectedSources.push({
        id: highlightID,
        page: parseInt(chatSource.source.toString()),
        quads: chatSource.source_quads,
        isPrimary: chatSource.id === chatSessionMessageSource.id,
        documentUUID: chatSessionMessageSource.source_document_id,
      })
    }
    dispatch(setSelectedSources(selectedSources))
    posthog?.capture(POSTHOG.chat_source_clicked, {
      source: chatSessionMessageSource.source_document_id,
      page: chatSessionMessageSource.source,
      project_uuid: currentProject?.uuid,
    })
  }, [
    dispatch,
    navigate,
    currentProject,
    searchParams,
    setSearchParams,
    chatSessionMessageSource,
    sources,
    posthog,
  ])

  return (
    <button onClick={onSourceClick}>
      <sup
        className={
          'mr-0.5 cursor-pointer rounded-full bg-gray-300 px-1 text-center text-[0.55rem] text-gray-700 hover:bg-gray-500 hover:text-white'
        }
      >
        {sourceIndex + indexOfParagraphMatch + 1}
      </sup>
    </button>
  )
}
export default AIChatSourceMessageButton
