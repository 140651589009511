import React, { useCallback, useState } from 'react'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { usePopper } from 'react-popper'
import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useGetUserProfileQuery } from '../../../redux/api-slice'
import { ProjectDocument } from '../../../shared/interfaces/project/document/document.interface'
import {
  selectCurrentCommentDocuments,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSelectedRevision,
  setSelectedRevisionEdit,
} from '../../../redux/viewer-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import {
  useDeleteRevisionMutation,
  useUpdateRevisionMutation,
} from '../../../redux/api/api-revisions-slice'

interface CommentCardPopoverProps {
  revision: Revision
  selectedDocument?: ProjectDocument | null
  disabled?: boolean
  setEditRevision: React.Dispatch<React.SetStateAction<boolean>>
  onRevisionChange?: (page: number) => void
  source: 'comment_table' | 'document_viewer'
}

const CommentCardPopover: React.FC<CommentCardPopoverProps> = ({
  selectedDocument,
  revision,
  disabled = false,
  setEditRevision,
  onRevisionChange,
  source,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const [updateRevision] = useUpdateRevisionMutation()
  const [deleteRevision] = useDeleteRevisionMutation()
  const dispatch = useDispatch()
  const currentCommentDocuments = useSelector(selectCurrentCommentDocuments)
  const posthog = usePostHog()
  const { data: user } = useGetUserProfileQuery(undefined)

  const markAsAddressed = useCallback(
    (e) => {
      e?.stopPropagation()
      updateRevision({
        id: revision?.id,
        content: {
          ...revision,
          document: selectedDocument ? selectedDocument?.id : undefined,
          documents: selectedDocument ? [selectedDocument?.id] : undefined,
          project: currentProject?.id,
          parent: revision,
          previous_status: revision?.revision_status,
          revision_status: 'APPROVED',
        },
      })
    },
    [currentProject?.id, revision, selectedDocument, updateRevision]
  )

  const deleteSelectedRevision = useCallback(
    (e) => {
      e.stopPropagation()
      deleteRevision({
        ...revision,
        documents: currentCommentDocuments?.length
          ? currentCommentDocuments.map((d) => d.id)
          : undefined,
        project: !selectedDocument ? currentProject?.id : undefined,
      })
        .unwrap()
        .then(() => {
          dispatch(setSelectedRevision(null))
          dispatch(setSelectedRevisionEdit(false))
        })
      buttonRef?.click()
    },
    [
      buttonRef,
      currentCommentDocuments,
      currentProject?.id,
      deleteRevision,
      dispatch,
      revision,
      selectedDocument,
    ]
  )

  const editSelectedRevision = useCallback(
    (e) => {
      e.stopPropagation()
      setEditRevision(true)
      dispatch(setSelectedRevision(revision))
      if (onRevisionChange) {
        onRevisionChange(revision.page ?? 1)
      }
      buttonRef?.click()
    },
    [buttonRef, dispatch, revision, setEditRevision, onRevisionChange]
  )

  const onAddressedClick = useCallback(
    (e) => {
      e.stopPropagation()
      markAsAddressed(null)
      posthog.capture(POSTHOG.revision_status_changed, {
        project_uuid: currentProject?.uuid,
        document_uuid: selectedDocument?.uuid,
        revision_id: revision?.id,
        revision_status: 'APPROVED',
        workflow: 'comment_table',
      })
      buttonRef?.click()
    },
    [
      buttonRef,
      markAsAddressed,
      posthog,
      currentProject?.uuid,
      selectedDocument?.uuid,
      revision?.id,
    ]
  )

  return user?.id === revision.user_created?.id ? (
    <Popover className="relative flex items-center justify-center">
      <Popover.Button
        data-playwright={`comment_card_options_${source}_${revision?.id}`}
        disabled={disabled}
        className={`rounded text-gray-600 ${
          disabled ? 'opacity-50' : 'hover:bg-gray-100'
        }`}
        tabIndex={-1}
        ref={setButtonRef}
      >
        <EllipsisVerticalIcon className="h-5 w-5" />
      </Popover.Button>

      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="absolute left-0 z-50 flex flex-col text-nowrap rounded border bg-white text-xs shadow"
      >
        <button
          onClick={onAddressedClick}
          className={'p-2 text-left hover:bg-gray-100'}
        >
          Set status closed
        </button>
        <button
          onClick={editSelectedRevision}
          className={'p-2 text-left hover:bg-gray-100'}
        >
          Edit
        </button>
        <button
          onClick={deleteSelectedRevision}
          className={'p-2 text-left hover:bg-gray-100'}
        >
          Delete
        </button>
      </Popover.Panel>
    </Popover>
  ) : null
}

export default CommentCardPopover
