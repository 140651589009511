import React, { useCallback, useMemo, useState } from 'react'
import { RevisionStatus } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { Combobox } from '@headlessui/react'
import {
  selectCommentWorkflowFilterState,
  setCommentWorkflowFilterState,
} from '../../../redux/workflow-slice'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
interface RevisionStatusFilterProps {}

const RevisionStatusFilter: React.FC<RevisionStatusFilterProps> = () => {
  const [query, setQuery] = useState('')
  const { statusFilter, ...filters } = useSelector(
    selectCommentWorkflowFilterState
  )
  const dispatch = useDispatch()

  const onChangeStatusFilter = useCallback(
    (selectedStatuses: RevisionStatus[]) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...filters,
          statusFilter: selectedStatuses,
        })
      )
    },
    [dispatch, filters]
  )

  const filterStatusesByQuery = useCallback(
    (authors: string[]) => {
      return authors.filter((a) =>
        a.toLowerCase().includes(query.toLowerCase())
      )
    },
    [query]
  )

  const onClearStatusFilters = useCallback(() => {
    dispatch(
      setCommentWorkflowFilterState({
        ...filters,
        statusFilter: [],
      })
    )
  }, [filters, dispatch])

  const revisionStatusesToDisplay = useMemo(() => {
    const statuses = [
      'NO_STATUS',
      'APPROVED',
      'NOT_APPROVED',
      'IN_REVIEW',
      'NEEDS_RECON',
    ]
    return filterStatusesByQuery(statuses)
  }, [filterStatusesByQuery])

  const mapStatusToDisplayValue = (status: string) => {
    switch (status) {
      case 'APPROVED':
        return 'Closed'
      case 'NOT_APPROVED':
        return 'Carried Over'
      case 'IN_REVIEW':
        return 'In Review'
      case 'NEEDS_RECON':
        return 'Requires reconciliation'
      case 'NO_STATUS':
        return 'Open'
    }
  }

  return (
    <Combobox
      as="div"
      value={statusFilter}
      multiple={true}
      onChange={onChangeStatusFilter}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-0.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          placeholder={
            statusFilter.length > 0
              ? `${statusFilter.length} statuses`
              : 'All statuses'
          }
        />
        {statusFilter.length > 0 && (
          <button
            className="absolute right-6 top-0 z-10 flex h-full items-center"
            onClick={onClearStatusFilters}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pl-8 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-96 w-[200px] divide-y overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {revisionStatusesToDisplay.map((status) => (
            <Combobox.Option
              key={status}
              value={status}
              className={`relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white ${
                statusFilter.find((d) => d === status)
                  ? 'bg-indigo-600 text-white'
                  : ''
              }`}
            >
              {mapStatusToDisplayValue(status)}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default RevisionStatusFilter
