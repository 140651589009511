import React from 'react'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useGetOrganisationQuery } from '../../redux/api/organisation-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'

interface Props {}

const OrganisationGeneral: React.FC<Props> = () => {
  const { data: userProfile } = useGetUserProfileQuery(undefined)

  const { data: organisationData } = useGetOrganisationQuery(
    userProfile?.organisation?.id ?? skipToken
  )

  return (
    <div>
      <h2 className="py-4 text-base font-semibold leading-7 text-gray-900">
        Organisation
      </h2>
      <hr />
      <dl className="space-y-6 divide-y border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Organisation name
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="text-gray-900">{organisationData?.name}</div>
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default OrganisationGeneral
