import { apiSlice } from '../api-slice'
import {
  CustomQARisk,
  EvaluationProjectRisk,
  RiskCategory,
  RiskPipelineDefinition,
} from '../../shared/interfaces/qa.interface'

interface GetCustomRiskQAParams {
  projectUuid: string
  riskCategory: string
}

interface GetEvaluationProjectRiskQAParams {
  projectUuid: string
  riskPipelineDefinitionUuid: string
}

const qaApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomRiskQA: builder.query<CustomQARisk[], GetCustomRiskQAParams>({
      query: ({ projectUuid, riskCategory }) =>
        `/customqarisks/?project=${projectUuid}&risk_category=${riskCategory}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'CustomQARisk' as const,
                id,
              })),
              { type: 'CustomQARisk' as const, id: 'LIST' },
            ]
          : [{ type: 'CustomQARisk' as const, id: 'LIST' }],
    }),
    createCustomQARisk: builder.mutation<CustomQARisk, CustomQARisk>({
      query: (customRisk) => ({
        url: `/customqarisks/create_or_update/`,
        method: 'POST',
        body: {
          ...customRisk,
        },
      }),
      invalidatesTags: [{ type: 'CustomQARisk' as const, id: 'LIST' }],
    }),
    getRiskCategories: builder.query<RiskCategory[], string>({
      query: (projectUuid) => `/projectriskcategories/?project=${projectUuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'RiskCategory' as const,
                id,
              })),
              { type: 'RiskCategory' as const, id: 'LIST' },
            ]
          : [{ type: 'RiskCategory' as const, id: 'LIST' }],
    }),
    getRiskPipelineDefinitions: builder.query<RiskPipelineDefinition[], void>({
      query: () => `/riskpipelinedefinitions/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'RiskPipelineDefinition' as const,
                id,
              })),
              { type: 'RiskPipelineDefinition' as const, id: 'LIST' },
            ]
          : [{ type: 'RiskPipelineDefinition' as const, id: 'LIST' }],
    }),
    getEvaluationProjectRisk: builder.query<
      EvaluationProjectRisk[],
      GetEvaluationProjectRiskQAParams
    >({
      query: ({ projectUuid, riskPipelineDefinitionUuid }) =>
        `/evaluationprojectrisks/?project=${projectUuid}&risk_pipeline_definition=${riskPipelineDefinitionUuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'EvaluationProjectRisk' as const,
                id,
              })),
              { type: 'EvaluationProjectRisk' as const, id: 'LIST' },
            ]
          : [{ type: 'EvaluationProjectRisk' as const, id: 'LIST' }],
    }),
    createEvaluationProjectRisk: builder.mutation<
      EvaluationProjectRisk,
      EvaluationProjectRisk
    >({
      query: (evaluationProjectRisk) => ({
        url: `/evaluationprojectrisks/`,
        method: 'POST',
        body: {
          ...evaluationProjectRisk,
        },
      }),
      invalidatesTags: [{ type: 'EvaluationProjectRisk' as const, id: 'LIST' }],
    }),
    updateEvaluationProjectRisk: builder.mutation<
      EvaluationProjectRisk,
      EvaluationProjectRisk
    >({
      query: (evaluationProjectRisk) => ({
        url: `/evaluationprojectrisks/${evaluationProjectRisk.id}/`,
        method: 'PUT',
        body: {
          ...evaluationProjectRisk,
        },
      }),
      invalidatesTags: [{ type: 'EvaluationProjectRisk' as const, id: 'LIST' }],
    }),
  }),
})

export const {
  useGetCustomRiskQAQuery,
  useCreateCustomQARiskMutation,
  useGetRiskCategoriesQuery,
  useGetRiskPipelineDefinitionsQuery,
  useGetEvaluationProjectRiskQuery,
  useCreateEvaluationProjectRiskMutation,
  useUpdateEvaluationProjectRiskMutation,
} = qaApi
