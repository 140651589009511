import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  useCreateRiskPipelineDefinitionMutation,
  useGetRiskListByIdQuery,
  useGetRiskPipelineDefinitionsQuery,
} from '../../../../redux/api/project-risk-api-slice'
import Risk from './risk'
import { toast } from 'react-toastify'
import { CreateRiskFormProps } from '../../../../shared/interfaces/project/risk/risk-inteface'

import { CustomRisk } from '../../../../shared/interfaces/project/risk/risk-list-interface'
import {
  ClipboardDocumentListIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSharedFromChatRiskQueryText,
  setSharedFromChatRiskQueryText,
} from '../../../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { POSTHOG } from '../../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'

const CreateRiskForm: React.FC<CreateRiskFormProps> = ({ riskListName }) => {
  const sharedFromChatRiskQueryText = useSelector(
    selectSharedFromChatRiskQueryText
  )
  const [riskName, setRiskName] = useState('')
  const [riskDescription, setRiskDescription] = useState('')
  const [riskQueryText, setRiskQueryText] = useState(
    sharedFromChatRiskQueryText ?? ''
  )
  const [createRisk] = useCreateRiskPipelineDefinitionMutation()
  const { riskListId } = useParams<{ riskListId: string }>()
  const { data: riskPipelineDefinitions, isLoading: isLoadingRisks } =
    useGetRiskPipelineDefinitionsQuery(riskListId!)
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await createRisk({
        risk_name: riskName,
        query_text: riskQueryText,
        risk_description: riskDescription,
        risk_list_template_id: riskListId!,
      }).unwrap()
      posthog?.capture(POSTHOG.custom_riskpipelinedefinition_created)
      setRiskName('')
      setRiskQueryText('')
      setRiskDescription('')
      dispatch(setSharedFromChatRiskQueryText(''))
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }

  const handleRiskNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRiskName(e.target.value)
    },
    []
  )

  const handleRiskQueryTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRiskQueryText(e.target.value)
    },
    []
  )

  const handleRiskDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRiskDescription(e.target.value)
    },
    []
  )

  const { data: riskList, isLoading: riskListIsLoading } =
    useGetRiskListByIdQuery(riskListId ? parseInt(riskListId) : skipToken)

  return (
    <div className="mr-2 rounded-lg border border-[#ffffff00] bg-white p-2 px-4 shadow hover:border-yellow-400">
      <h2 className="mb-6 mt-2 text-lg font-semibold">
        <div className="mb-4 flex items-center">
          <PlusIcon className="text=gray-900 mr-2 h-5 w-5" /> Add a New Risk to
          <span className="ml-1.5 underline"> {riskListName ?? '...'}</span>
        </div>
      </h2>
      <form onSubmit={handleSubmit} className="mb-2 flex flex-col space-y-4">
        <div className="flex-grow">
          <label
            htmlFor="riskName"
            className="block text-sm font-medium text-gray-700"
          >
            Title*
          </label>
          <input
            type="text"
            id="riskName"
            value={riskName}
            onChange={handleRiskNameChange}
            className="mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Warranty"
            required
          />
        </div>
        <div className="flex-grow">
          <label
            htmlFor="riskQueryText"
            className="block text-sm font-medium text-gray-700"
          >
            Question*
          </label>
          <input
            type="text"
            id="riskQueryText"
            value={riskQueryText}
            onChange={handleRiskQueryTextChange}
            className="mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="How long is the warranty period?"
            required
          />
        </div>
        <div className="flex-grow">
          <label
            htmlFor="riskQueryText"
            className="block text-sm font-medium text-gray-700"
          >
            Description*
          </label>
          <input
            type="text"
            id="riskDescription"
            value={riskDescription}
            onChange={handleRiskDescriptionChange}
            className="mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Warranties tell us how long we need to guarantee products and workmanship are free from defects or issues. We do not provide warranties over one year."
            required
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            disabled={riskList?.user_permissions?.can_change !== true}
            type="submit"
            className="rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Create
          </button>
        </div>
      </form>

      <hr className="mb-6 mt-4" />

      <h3 className="mb-3 text-xl font-semibold">
        <div className="mb-6 flex items-center">
          <ClipboardDocumentListIcon className="text-0gray-900 mr-2 h-5 w-5" />{' '}
          Existing Risks
        </div>
      </h3>
      {isLoadingRisks ? (
        <p>Loading risks...</p>
      ) : riskPipelineDefinitions?.length ? (
        riskPipelineDefinitions.map((risk: CustomRisk) => (
          <Risk
            key={risk.id}
            id={risk.id}
            riskName={risk.risk_name}
            queryText={risk.query_text}
            description={risk.risk_description}
            permissions={riskList?.user_permissions}
            isLoadingRiskLists={riskListIsLoading}
          />
        ))
      ) : (
        <p>No risks found.</p>
      )}
    </div>
  )
}

export default CreateRiskForm
