import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectCurrentProject } from '../redux/application-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../utils/posthog-constants'
import { useGetDocumentsListByProjectQuery } from '../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'

interface DocURLOptions {
  page?: number
  replace?: boolean
}

enum Routes {
  COMMENT_VIEWER = 'commentviewer',
  COMMENTS = 'comments',
  DOCUMENTS = 'documents',
  CHAT = 'chat',
}

const UseUrlNavigate = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentProject = useSelector(selectCurrentProject)
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject?.id
      ? {
          projectId: currentProject?.id,
        }
      : skipToken
  )
  const posthog = usePostHog()
  const navigateUrl = (projectUUID: string) => {
    if (!projectUUID) {
      return
    }
    posthog?.capture(POSTHOG.project_opened, {
      project_uuid: projectUUID,
    })
    const uuidPattern =
      /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
    const paths = pathname.split('/')
    if (paths.every((path) => !path)) {
      navigate(`/${projectUUID}/${Routes.DOCUMENTS}`)
      return
    }
    if (!paths[1].match(uuidPattern)) {
      navigate(`/${projectUUID}/${Routes.DOCUMENTS}`)
      return
    }
    // COMMENT_VIEWER route is a subroute of comments. We want the user to to navigate back to the comments page when they change projects
    else if (paths[2] === Routes.COMMENT_VIEWER) {
      navigate(`/${projectUUID}/${Routes.COMMENTS}`)
      return
    }
    const pathMatches = paths[1].replace(uuidPattern, projectUUID)
    const newURL = `/${pathMatches}${
      paths.length >= 2 && paths[2] ? `/${paths[2]}` : `/${Routes.DOCUMENTS}/`
    }`
    navigate(newURL)
  }
  const navigateChatUrl = (projectUUID: string, documentUUID: string) => {
    if (!projectUUID || !documentUUID) {
      return
    }
    navigate(`/${projectUUID}/${Routes.CHAT}/${documentUUID}`)
  }
  const navigateDocumentUrl = (
    documentUUID: string,
    options?: DocURLOptions
  ) => {
    if (!currentProject?.uuid) {
      return
    }
    const matchedDocument = documents?.find(
      (document) => document.uuid === documentUUID
    )
    if (!matchedDocument) {
      return
    }
    posthog?.capture(POSTHOG.project_opened, {
      project_uuid: currentProject.uuid,
    })
    try {
      const route = pathname.split('/')[2]
      if (!route) {
        return
      }
      navigate(
        `/${currentProject.uuid}/${route}/${documentUUID}?page=${
          options?.page ?? 1
        }`,
        {
          replace: options?.replace,
        }
      )
    } catch (e) {
      return
    }
  }
  return { navigateUrl, navigateDocumentUrl, navigateChatUrl }
}
export default UseUrlNavigate
