import React, { useCallback, useState } from 'react'
import { selectCurrentDocument } from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDocumentChange,
  selectTextSelected,
  setSelectedDocumentChange,
  setTextSelected,
} from '../../redux/viewer-slice'
import {
  ArrowPathIcon,
  BarsArrowUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import { setNewAmendment } from '../../redux/editor-slice'
import { useQuads } from '../../hooks/use-quads'
import { useUpdateDocumentChangesMutation } from '../../redux/api-slice'

interface ContextMenuProps {
  positionX: string
  positionY: string
}

const EditorContextMenu: React.FC<ContextMenuProps> = ({
  positionX,
  positionY,
}) => {
  const document = useSelector(selectCurrentDocument)
  const textSelected = useSelector(selectTextSelected)
  const dispatch = useDispatch()
  const selectedDocumentChange = useSelector(selectDocumentChange)
  const [updateDocumentChange] = useUpdateDocumentChangesMutation()
  const { mappedQuads } = useQuads()
  const [creatingDestination, setCreatingDestination] = useState(false)

  const onClickAddToAddenda = useCallback(async () => {
    if (
      !selectedDocumentChange ||
      !mappedQuads ||
      !textSelected?.pageNumber ||
      !document?.id ||
      !document?.uuid
    ) {
      return
    }
    setCreatingDestination(true)
    const updatedDocumentChange = await updateDocumentChange({
      documentChange: {
        ...selectedDocumentChange,
        destinations: [
          {
            quads: mappedQuads,
            page: textSelected?.pageNumber,
            document_uuid: document?.uuid,
            document: document?.id,
            text: textSelected?.text,
            document_id: document?.id?.toString(),
          },
        ],
      },
      queryParams: {},
    }).unwrap()
    dispatch(setSelectedDocumentChange(updatedDocumentChange))
    setCreatingDestination(false)
  }, [
    dispatch,
    document?.id,
    document?.uuid,
    mappedQuads,
    selectedDocumentChange,
    textSelected?.pageNumber,
    textSelected?.text,
    updateDocumentChange,
  ])

  const onMouseDown = useCallback(
    (e) => {
      if (!textSelected?.pageNumber || !document) {
        return
      }
      dispatch(
        setNewAmendment({
          source: {
            page: textSelected.pageNumber,
            document_uuid: document.uuid,
            quads: mappedQuads ?? [],
          },
          original_clause: '',
          original_clause_reference: '',
          change_type: 'create',
          reconciled_clause: '',
          explanation: '',
        })
      )
      dispatch(setTextSelected(null))
      e.stopPropagation()
      e.preventDefault()
    },
    [dispatch, document, mappedQuads, textSelected?.pageNumber]
  )

  return (
    <div
      className={'absolute z-50 flex rounded border bg-white shadow-lg'}
      style={{
        top: positionY,
        left: positionX,
      }}
    >
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center  rounded hover:bg-gray-100'
        }
        onMouseDown={onMouseDown}
        data-tooltip-id="revision-id"
        data-tooltip-content="Create CCDC Amendment"
      >
        <BarsArrowUpIcon width={20} />
        <Tooltip id={'revision-id'} style={{ zIndex: 100 }} />
      </button>
      <div data-tooltip-id="addenda-id" data-tooltip-content="Add to Addenda">
        <button
          type={'button'}
          className={`z-50 flex h-8 w-8 cursor-pointer items-center justify-center  rounded ${
            !selectedDocumentChange
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-gray-100'
          }`}
          disabled={!selectedDocumentChange || creatingDestination}
          onMouseDown={onClickAddToAddenda}
        >
          {creatingDestination ? (
            <ArrowPathIcon width={20} className="animate-spin" />
          ) : (
            <PlusIcon width={20} />
          )}
        </button>
        <Tooltip id={'addenda-id'} style={{ zIndex: 100 }} />
      </div>
    </div>
  )
}

export default EditorContextMenu
