import React, { useCallback } from 'react'
import { ChatHistory } from '../../../shared/interfaces/chat/chat-history.interface'
import {
  ChatBubbleBottomCenterIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import AIChatPopover from './ai-chat-popover'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useStarChatHistoryMutation } from '../../../redux/api/chat-api-slice'

interface AIChatHistoryProps {
  history: ChatHistory
  onOpenChatHistory: (history: ChatHistory) => void
}

const AIChatHistory: React.FC<AIChatHistoryProps> = ({
  history,
  onOpenChatHistory,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const posthog = usePostHog()
  const [starChatHistory] = useStarChatHistoryMutation()
  const getChatTitle = (history: ChatHistory) => {
    return (
      history.session_name ||
      history?.history?.[0]?.[0].slice(0, 25) ||
      'New Chat'
    )
  }

  const onOpenChatHistoryClick = useCallback(() => {
    onOpenChatHistory(history)
  }, [history, onOpenChatHistory])

  const onStarClick = useCallback(() => {
    posthog?.capture(POSTHOG.chat_session_saved, {
      chat_session_uuid: history.uuid,
      project_uuid: currentProject?.uuid,
    })
    starChatHistory({
      uuid: history.uuid ?? '',
      isStarred: !history.saved,
      project_uuid: currentProject?.uuid ?? '',
    })
  }, [currentProject?.uuid, history, posthog, starChatHistory])

  return (
    <div className="flex">
      <button
        key={`chat_history_${history.uuid}`}
        onClick={onOpenChatHistoryClick}
        className={
          'group flex w-full cursor-pointer items-center rounded-md py-1 text-left text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900'
        }
      >
        <div className="flex justify-center items-center px-2">
          <ChatBubbleBottomCenterIcon
            className={
              'h-5 w-5 flex-shrink-0  text-gray-400 group-hover:text-gray-500'
            }
            aria-hidden="true"
          />
        </div>

        <span className="flex-1 w-48 text-ellipsis truncate whitespace-nowrap">
          {getChatTitle(history)}
        </span>
      </button>
      <button
        data-playwright={`chat-star-${history.uuid}`}
        aria-label={`${history.saved ? 'Unfavourite' : 'Favourite'} chat ${history.session_name}`}
        onClick={onStarClick}
      >
        <StarIcon
          className={`h-6 w-6 text-gray-400 ${
            history.saved ? 'fill-gray-700' : ' hover:fill-gray-500'
          }`}
        />
      </button>
      <AIChatPopover chatHistory={history} />
    </div>
  )
}

export default AIChatHistory
