import {
  CustomLabel,
  LabelSegment,
} from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { DocumentSegmentSearchParams } from '../../shared/interfaces/project/document/segments/document-segment.interface'
import { buildQueryParams } from '../../utils/build-query-params'
import { GetDocumentSegments, apiSlice } from '../api-slice'

interface CreateCustomLabel {
  name: string
  project: number
  projectUUID: string
}

interface DeleteCustomLabel extends CustomLabel {
  projectUUID: string
}

interface PatchCustomLabel extends Partial<CustomLabel> {
  projectUUID: string
  name: string
  id: number
}

export interface CreateCustomLabelSegment extends LabelSegment {
  customLabel: CreateCustomLabel
  getDocumentSegments: GetDocumentSegments
}

export interface DeleteCustomSegment {
  segmentID: number
  labelID: number
  projectUUID: string
  documentSegmentID: number
  documentSegmentsParams: DocumentSegmentSearchParams
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomLabels: builder.query<
      CustomLabel[],
      { projectUUID: string; segmentsIncluded?: boolean }
    >({
      query: ({ projectUUID, segmentsIncluded }) => {
        const queryParams = buildQueryParams({
          segments_included: segmentsIncluded,
          project: projectUUID,
        })
        return `/customlabels/?${queryParams.toString()}`
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CustomLabel' as const, id })),
              { type: 'CustomLabel' as const, id: 'LIST' },
            ]
          : [{ type: 'CustomLabel' as const, id: 'LIST' }],
    }),
    createCustomLabel: builder.mutation<CustomLabel, CreateCustomLabel>({
      query: (createCustomLabel) => ({
        url: '/customlabels/',
        method: 'POST',
        body: createCustomLabel,
      }),
      onQueryStarted: async (
        createCustomLabel,
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getCustomLabels',
            { projectUUID: createCustomLabel.projectUUID },
            (draft) => {
              const newCustomLabel: CustomLabel = {
                id: -1,
                project: createCustomLabel.project,
                name: createCustomLabel.name,
                segments: [],
              }
              draft.push(newCustomLabel)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [{ type: 'CustomLabel', id: 'LIST' }],
    }),
    updateCustomLabel: builder.mutation<CustomLabel, PatchCustomLabel>({
      query: (patchCustomLabel) => ({
        url: `/customlabels/${patchCustomLabel.id}/`,
        method: 'PATCH',
        body: patchCustomLabel,
      }),
      onQueryStarted: async (
        patchCustomLabel,
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getCustomLabels',
            { projectUUID: patchCustomLabel.projectUUID },
            (draft) => {
              const customLabelToUpdateIndex = draft?.findIndex(
                (l) => l.id === patchCustomLabel.id
              )
              if (
                customLabelToUpdateIndex !== -1 &&
                customLabelToUpdateIndex !== undefined
              ) {
                draft[customLabelToUpdateIndex].name = patchCustomLabel.name
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [{ type: 'CustomLabel', id: 'LIST' }],
    }),
    createCustomLabelSegment: builder.mutation<
      LabelSegment[],
      CreateCustomLabelSegment
    >({
      query: (customLabelSegment) => ({
        url: '/customlabelsegments/',
        method: 'POST',
        body: {
          label: customLabelSegment.label,
          text: customLabelSegment.text,
          quads: customLabelSegment.quads,
          document: customLabelSegment.document,
          page: customLabelSegment.page,
        },
      }),
      onQueryStarted: async (
        customLabelSegment,
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getCustomLabels',
            { projectUUID: customLabelSegment.customLabel.projectUUID },
            (draft) => {
              const foundLabel = draft.find(
                (p) => p.id === customLabelSegment.customLabel.project
              )
              if (foundLabel) {
                foundLabel.segments.push({
                  label: customLabelSegment.label,
                  text: customLabelSegment.text,
                  quads: customLabelSegment.quads,
                  document: customLabelSegment.document,
                  page: customLabelSegment.page,
                  id: -1,
                })
              }
            }
          )
        )
        try {
          const customLabels = (await queryFulfilled).data
          dispatch(
            extendedApi.util.updateQueryData(
              'getDocumentSegments',
              customLabelSegment.getDocumentSegments,
              (draft) => {
                const labelIds = customLabels.map(
                  (label) => label.document_segment?.id
                )
                const documentSegments = draft.results?.filter(
                  (documentSegment) => labelIds.includes(documentSegment.id)
                )
                if (!documentSegments) {
                  return
                }
                for (const documentSegment of documentSegments) {
                  const segmentLabel = customLabels.find(
                    (label) => label.document_segment?.id === documentSegment.id
                  )
                  if (segmentLabel) {
                    documentSegment.labels?.push(segmentLabel)
                  }
                }
              }
            )
          )
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [{ type: 'CustomLabel', id: 'LIST' }],
    }),
    createCustomLabelAndSegment: builder.mutation<
      LabelSegment[],
      CreateCustomLabelSegment
    >({
      query: (customLabelSegment) => ({
        url: '/customlabels/createadd/',
        method: 'POST',
        body: {
          project: customLabelSegment.customLabel.project,
          label: customLabelSegment.label,
          text: customLabelSegment.text,
          quads: customLabelSegment.quads,
          document: customLabelSegment.document,
          page: customLabelSegment.page,
          name: customLabelSegment.customLabel.name,
        },
      }),
      onQueryStarted: async (
        customLabelSegment,
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getCustomLabels',
            { projectUUID: customLabelSegment.customLabel.projectUUID },
            (draft) => {
              const newLabel: CustomLabel = {
                id: -1,
                project: customLabelSegment.customLabel.project,
                name: customLabelSegment.customLabel.name,
                segments: [
                  {
                    text: customLabelSegment.text,
                    quads: customLabelSegment.quads,
                    label: -1,
                    document: customLabelSegment.document,
                    date_created: new Date().toISOString(),
                    page: customLabelSegment.page,
                    id: -1,
                  },
                ],
              }
              draft.push(newLabel)
            }
          )
        )
        try {
          const customLabels = (await queryFulfilled).data
          dispatch(
            extendedApi.util.updateQueryData(
              'getDocumentSegments',
              customLabelSegment.getDocumentSegments,
              (draft) => {
                const labelIds = customLabels.map(
                  (label) => label.document_segment?.id
                )
                const documentSegments = draft.results?.filter(
                  (documentSegment) => labelIds.includes(documentSegment.id)
                )
                if (!documentSegments) {
                  return
                }
                for (const documentSegment of documentSegments) {
                  const segmentLabel = customLabels.find(
                    (label) => label.document_segment?.id === documentSegment.id
                  )
                  if (segmentLabel) {
                    documentSegment.labels?.push(segmentLabel)
                  }
                }
              }
            )
          )
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [
        { type: 'CustomLabel', id: 'LIST' },
        { type: 'DocumentSegment', id: 'LIST' },
      ],
    }),
    deleteCustomLabelSegment: builder.mutation<void, DeleteCustomSegment>({
      query: ({ segmentID }) => ({
        url: `/customlabelsegments/${segmentID}`,
        method: 'DELETE',
      }),
      // optimistic update
      async onQueryStarted(
        {
          segmentID,
          labelID,
          projectUUID,
          documentSegmentsParams,
          documentSegmentID,
        },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          extendedApi.util.updateQueryData(
            'getCustomLabels',
            { projectUUID: projectUUID },
            (draft) => {
              const label = draft.find(
                (customLabel) => customLabel.id === labelID
              )
              const index = label?.segments?.findIndex(
                (segment) => segment.id === segmentID
              )
              if (index !== -1 && index !== undefined) {
                label?.segments.splice(index, 1)
              }
            }
          )
        )
        const deleteResultSegments = dispatch(
          extendedApi.util.updateQueryData(
            'getDocumentSegments',
            documentSegmentsParams ? documentSegmentsParams : { page: 1 },
            (draft) => {
              const documentSegment = draft.results?.find(
                (documentSegment) => documentSegment.id === documentSegmentID
              )
              if (!documentSegment) {
                return
              }
              const labelIndex = documentSegment.labels?.findIndex(
                (label) => label.id === segmentID
              )
              if (labelIndex === undefined || labelIndex === -1) {
                return
              }
              documentSegment.labels?.splice(labelIndex, 1)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          deleteResult.undo()
          deleteResultSegments.undo()
        }
      },
      invalidatesTags: (result, error, { labelID, documentSegmentID }) => [
        { type: 'CustomLabel', id: labelID },
        { type: 'DocumentSegment' as const, id: documentSegmentID },
      ],
    }),
    deleteCustomLabel: builder.mutation<void, DeleteCustomLabel>({
      query: ({ id }) => ({
        url: `/customlabels/${id}/`,
        method: 'DELETE',
      }),
      // optimistic update
      async onQueryStarted({ id, projectUUID }, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          extendedApi.util.updateQueryData(
            'getCustomLabels',
            { projectUUID: projectUUID },
            (draft) => {
              const index = draft.findIndex(
                (customLabel) => customLabel.id === id
              )
              if (index !== -1 && index !== undefined) {
                draft.splice(index, 1)
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          deleteResult.undo()
        }
      },
      invalidatesTags: (result, error, id) => [
        { type: 'CustomLabel', id: id.projectUUID },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCustomLabelsQuery,
  useDeleteCustomLabelSegmentMutation,
  useCreateCustomLabelMutation,
  useCreateCustomLabelAndSegmentMutation,
  useCreateCustomLabelSegmentMutation,
  useDeleteCustomLabelMutation,
  useUpdateCustomLabelMutation,
} = extendedApi
