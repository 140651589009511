import React from 'react'
import { useGetUserProfileQuery } from '../../redux/api-slice'

const TableSkeleton = () => {
  const { data: profile } = useGetUserProfileQuery(undefined)
  const headerWidths = {
    rowNumber: 20,
    document: 50,
    page: 40,
    sectionReference: 50,
    originalClause: 100,
    revision: 100,
    comment: 75,
    checklist: 30,
  }

  const minHeaderWidths = {
    rowNumber: 30,
    document: 100,
    page: 40,
    sectionReference: 100,
    originalClause: 100,
    revision: 100,
    comment: 75,
    checklist: 30,
  }

  const sharedCss = 'border-b border-r border-gray-300 text-sm p-1'

  const widthTotal = Object.values(headerWidths).reduce((a, b) => a + b, 0)
  return (
    <div className="flex h-full w-full bg-gray-50">
      <div className={'flex h-full w-full flex-1 flex-col items-center'}>
        <div className="mx-8 w-full">
          <div className="custom-scroll items-start overflow-y-auto rounded bg-white shadow">
            {Array(38)
              .fill(0)
              .map((_, index) => index)
              .map((row) => (
                <div
                  key={`fake_revision_${row}`}
                  className={'flex justify-start bg-white'}
                >
                  <div
                    style={{
                      width: `${(headerWidths.rowNumber / widthTotal) * 100}%`,
                      minWidth: minHeaderWidths.rowNumber,
                    }}
                    className={`flex items-center justify-center  border-l  ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width: `${(headerWidths.document / widthTotal) * 100}%`,
                      minWidth: minHeaderWidths.document,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width: `${(headerWidths.page / widthTotal) * 100}%`,
                      minWidth: minHeaderWidths.page,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width:
                        (headerWidths.sectionReference / widthTotal) * 100 +
                        '%',
                      minWidth: minHeaderWidths.sectionReference,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width: `${
                        (headerWidths.originalClause / widthTotal) * 100
                      }%`,
                      minWidth: minHeaderWidths.originalClause,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width: `${(headerWidths.revision / widthTotal) * 100}%`,
                      minWidth: minHeaderWidths.revision,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width: `${(headerWidths.comment / widthTotal) * 100}%`,
                      minWidth: minHeaderWidths.comment,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div className="h-full w-11/12 animate-pulse rounded border-l bg-gray-200 " />
                  </div>
                  <div
                    style={{
                      width: `${(headerWidths.checklist / widthTotal) * 100}%`,
                      minWidth: minHeaderWidths.checklist,
                    }}
                    className={`animate-pulse ${sharedCss}`}
                  >
                    <div
                      className={
                        'h-full w-11/12 animate-pulse rounded border-l '
                      }
                    >
                      <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-500">
                        <span className="text-md leading-none text-white">
                          {profile?.first_name?.charAt(0)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableSkeleton
