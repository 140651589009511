import React, { useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import {
  TemporaryHighlight,
  selectTemporaryHighlight,
} from '../redux/secondary-viewer-slice'
import Diff from './workflows/comment-table/diff'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'
import { isRatio } from '../utils/convert-quads'
import { DocumentViewerCommentContext } from '../contexts/document-viewer-comment-instance-context'
import { useCoords } from '../hooks/use-coords'
import TemporaryHighlightContainer from './page-overlay/temporary-highlight'
interface SecondaryPageOverlaySimpleProps {
  page: number
  documentChangeSelected?: DocumentChange
  onSelectReplaceText: () => void
}

const SecondaryPageOverlaySimple: React.FC<SecondaryPageOverlaySimpleProps> = ({
  page,
  documentChangeSelected,
  onSelectReplaceText,
}) => {
  const { getCoords } = useCoords()
  const temporaryHighlight = useSelector(selectTemporaryHighlight)

  const documentViewerContext = useContext(DocumentViewerCommentContext)
  const { documentViewer } = documentViewerContext
  const pageWidgetContainer = window?.document
    ?.getElementById('webviewer-wrapper-second')
    ?.querySelector(`#pageWidgetContainer${page}`) as HTMLDivElement

  // TODO This doesnt check if a page exists, which shouldnt happen but it does occasionally
  const pageInfo = useMemo(() => {
    if (documentViewer && documentViewer?.getDocument()) {
      return documentViewer?.getDocument().getPageInfo(page)
    } else {
      return { width: 0, height: 0 }
    }
  }, [page, documentViewer])

  const renderDocumentChange = () => {
    if (!documentChangeSelected) {
      return null
    }
    const documentChange: DocumentChange = documentChangeSelected
    let coords: {
      topOffset?: number
      leftOffset?: number
      width?: number
      height?: number
    } = {}
    const quads = documentChange?.source?.quads ?? []
    coords = getCoords(
      quads[0],
      page,
      'document-viewer-secondary',
      isRatio(quads)
    )
    return (
      <div
        key={'temp_document_change'}
        className="absolute z-50 border bg-white p-2 text-xs"
        style={{
          top: `calc(${coords.topOffset}% + ${coords.height}%)`,
          left: `${coords.leftOffset}px`,
        }}
      >
        <div>
          <div className="mt-1">
            <Diff
              inputA={documentChange.original_clause ?? ''}
              inputB={documentChange.reconciled_clause ?? ''}
            />
          </div>
        </div>
      </div>
    )
  }

  if (pageWidgetContainer) {
    return ReactDOM.createPortal(
      <React.Fragment key={page}>
        {/* TEMPORARY HIGHLIGHT DATA */}
        {pageInfo &&
          temporaryHighlight &&
          temporaryHighlight
            .slice()
            .filter((h) => h.page === page)
            .map((highlight: TemporaryHighlight) => {
              return (
                <TemporaryHighlightContainer
                  key={highlight.id}
                  highlight={highlight}
                  page={page}
                  documentViewerID="document-viewer-secondary"
                />
              )
            })}
        {documentChangeSelected &&
          page === documentChangeSelected.source.page &&
          renderDocumentChange()}
      </React.Fragment>,
      pageWidgetContainer
    )
  } else {
    return null
  }
}

export default SecondaryPageOverlaySimple
