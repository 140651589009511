import React, { useState } from 'react'
import {
  Square2StackIcon,
  FunnelIcon,
  ChatBubbleLeftEllipsisIcon,
  InformationCircleIcon,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
  ClipboardDocumentCheckIcon,
  EyeIcon,
} from '@heroicons/react/24/solid'
import {
  Alert,
  Card,
  Kbd,
  Menu,
  Paper,
  SimpleGrid,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core'
import { Squares2X2Icon } from '@heroicons/react/24/outline'
import {
  useGetDocumentStatusByProjectQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { useNavigateWorkflow } from '../../hooks/use-navigate-workflow'
import { workflows } from '../workflows/workflow-popover/workflow.data'
import { ReactElement } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../redux/application-slice'

type AppMenuPopoverProps = {
  width: number
  menuTarget: ReactElement
}

const BluePrintIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="#A71FA7"
    viewBox="0 0 256 256"
  >
    <path d="M136,120h24v16H136ZM240,64V200a8,8,0,0,1-8,8H48a32,32,0,0,1-32-32V64A32,32,0,0,1,48,32H64a8,8,0,0,1,8,8V56H232A8,8,0,0,1,240,64ZM56,48H48A16,16,0,0,0,32,64v84.29A31.82,31.82,0,0,1,48,144h8Zm120,88V120h16a8,8,0,0,0,0-16H176V96a8,8,0,0,0-16,0v8H136V96a8,8,0,0,0-16,0v8H104a8,8,0,0,0,0,16h16v16H104a8,8,0,0,0,0,16h16v8a8,8,0,0,0,16,0v-8h24v8a8,8,0,0,0,16,0v-8h16a8,8,0,0,0,0-16Z"></path>
  </svg>
)

export const AppMenuPopover = ({ width, menuTarget }: AppMenuPopoverProps) => {
  const [opened, setOpened] = useState(false)

  const { data: user } = useGetUserProfileQuery(undefined)

  // TODO add a test to check if users with FF off can see QA tile
  const optionsData = [
    {
      title: 'Documents',
      icon: BluePrintIcon,
      color: 'violet',
      link: 'documents',
      index: '1',
      workflowObject: workflows[0],
    },
    {
      title: 'Risk Review',
      icon: ClipboardDocumentCheckIcon,
      color: 'indigo',
      link: 'riskreview',
      index: '2',
      workflowObject: workflows[1],
    },
    {
      title: 'Chat',
      icon: ChatBubbleLeftEllipsisIcon,
      color: 'blue',
      link: 'chat',
      index: '3',
      workflowObject: workflows[2],
    },
    {
      title: 'Comments',
      icon: ChatBubbleLeftRightIconSolid,
      color: 'green',
      link: 'comments',
      index: '4',
      workflowObject: workflows[3],
    },
    {
      title: 'Clause Filtering',
      icon: FunnelIcon,
      color: 'teal',
      link: 'clause-filters',
      index: '5',
      workflowObject: workflows[4],
    },
    {
      title: 'Supplementary Conditions',
      icon: Square2StackIcon,
      color: 'cyan',
      link: 'supplementary-conditions',
      index: '6',
      workflowObject: workflows[5],
    },
    ...(user?.feature_flags?.editor_mode
      ? [
          {
            title: 'Quality Assurance*',
            icon: EyeIcon,
            color: 'red',
            link: 'quality-assurance',
            index: '*',
            workflowObject: workflows[6],
          },
        ]
      : []),
  ]

  const ActionsGrid = () => {
    const currentProject = useSelector(selectCurrentProject)
    const {
      currentData: documentResponse,
      // isLoading: documentsAndFoldersLoading, TODO USE THIS VALUE
    } = useGetDocumentStatusByProjectQuery(
      currentProject?.id ? currentProject?.id : skipToken,
      {
        pollingInterval: 10000,
      }
    )

    const documentCount = documentResponse?.length || 0

    const theme = useMantineTheme()
    const { navigateWorkflow } = useNavigateWorkflow()
    const items = optionsData.map((item) => (
      <div
        key={item.title}
        className="group relative transition-transform duration-100 ease-in-out hover:scale-110"
      >
        <div className="absolute left-0 top-0 flex h-5 w-5 items-center justify-center rounded-br-lg rounded-tl-lg border border-[#dadada] bg-gray-50 text-xs text-gray-700 opacity-0 transition-opacity duration-150 ease-in-out group-hover:opacity-100">
          <div>{item.index}</div>
        </div>
        <Tooltip
          label={`${item.title} ${item.title === 'Supplementary Conditions' || item.title === 'Comments' ? 'are' : 'is'} disabled until Documents are loaded`}
          disabled={
            documentCount > 0 ||
            item.title === 'Documents' ||
            item.title === 'Quality Assurance*'
          }
        >
          <div>
            <UnstyledButton
              onClick={() => {
                if (
                  documentCount > 0 ||
                  item.title === 'Documents' ||
                  item.title === 'Quality Assurance*'
                ) {
                  navigateWorkflow(item.workflowObject)
                  setOpened(false)
                }
              }}
              style={{
                border: '1px solid #dadada',
                background:
                  documentCount === 0 &&
                  item.title !== 'Documents' &&
                  item.title !== 'Quality Assurance*'
                    ? '#dadada'
                    : '',
              }}
              disabled={
                documentCount === 0 &&
                item.title !== 'Documents' &&
                item.title !== 'Quality Assurance*'
              }
              className="flex min-h-[105px] w-full flex-col items-center justify-center rounded-lg border-2 border-black p-16 text-center group-hover:border-2 group-hover:border-black group-hover:bg-gray-100"
            >
              <item.icon
                color={
                  documentCount === 0 &&
                  item.title !== 'Documents' &&
                  item.title !== 'Quality Assurance*'
                    ? '#adadad'
                    : theme.colors[item.color][6]
                }
                className="h-7 w-7"
              />
              <Text
                ta="center"
                lh="1.15rem"
                size="1rem"
                mt={7}
                fw={500}
                c={
                  documentCount === 0 &&
                  item.title !== 'Documents' &&
                  item.title !== 'Quality Assurance*'
                    ? 'dimmed'
                    : ''
                }
              >
                {item.title}
              </Text>
            </UnstyledButton>
          </div>
        </Tooltip>
      </div>
    ))

    return (
      <>
        <div className="-mb-2 mt-3 flex w-full justify-center">
          <Paper radius="md" p="6px" bg="#ffffff00" className="group w-48">
            <div className="mr-4 flex items-center justify-center text-[18px]  font-medium !text-black group-hover:!text-black">
              <Squares2X2Icon className="mr-2 h-6 w-6 text-black group-hover:text-black" />
              All Apps
            </div>
          </Paper>
        </div>
        <Card p="lg" radius="md" className={'bg-[#f2f5fb]'}>
          <SimpleGrid cols={3} mt="0">
            {items}
          </SimpleGrid>
        </Card>
        {documentCount !== 0 ? (
          <div className="-mt-2 flex w-full items-center justify-center">
            <Alert
              variant="white"
              color="black"
              radius="md"
              m="sm"
              mx="lg"
              mt="0"
            >
              <div className="flex items-center">
                <InformationCircleIcon className="mr-2 h-5 w-5 text-blue-500" />
                <Text size="md">
                  Quickly switch between apps by typing <Kbd>1</Kbd> to{' '}
                  <Kbd>6</Kbd>
                </Text>
              </div>
            </Alert>
          </div>
        ) : null}
      </>
    )
  }

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      aria-label={`All Apps`}
      transitionProps={{ transition: 'scale', duration: 200 }}
      shadow="md"
      radius={'md'}
      width={width}
      withArrow
      arrowSize={16}
    >
      <Menu.Target>{menuTarget}</Menu.Target>
      <Menu.Dropdown>
        <ActionsGrid />
      </Menu.Dropdown>
    </Menu>
  )
}
