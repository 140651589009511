import React, { useCallback, useEffect } from 'react'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'
import { useDispatch, useSelector } from 'react-redux'
import { selectNewAmendment } from '../../redux/editor-slice'
import {
  selectDocumentChange,
  setSelectedDocumentChange,
  setSelectedSources,
} from '../../redux/viewer-slice'
import { CheckIcon } from '@heroicons/react/20/solid'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid'
import { Route, useBackButton } from '../../hooks/use-back-button'
import { useLocation, useSearchParams } from 'react-router-dom'

interface AddendaQACardProps {
  documentChange: DocumentChange
}

const AddendaQACard: React.FC<AddendaQACardProps> = ({ documentChange }) => {
  const selectedDocumentChange = useSelector(selectDocumentChange)
  const dispatch = useDispatch()
  const newAmendment = useSelector(selectNewAmendment) as DocumentChange
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { addToNavigationHistoryAndNavigate } = useBackButton()

  useEffect(() => {
    if (!selectedDocumentChange && !newAmendment) {
      return
    }
    const element = document.getElementById(
      `document_change_${selectedDocumentChange?.id ?? 'new_amendment'}`
    )
    if (!element) {
      return
    }
  }, [selectedDocumentChange, newAmendment])

  const onClickDocumentChange = useCallback(() => {
    dispatch(setSelectedDocumentChange(documentChange))
    const destination = documentChange?.destinations?.[0]
    if (!destination) {
      return
    }
    const highlightID = uuidv4()
    const destinationPage = destination.page
    if (!destination.document_uuid || !destinationPage) {
      return
    }
    const route = pathname.split('/')[2]
    if (!route || !destination.document_uuid) {
      return
    }
    addToNavigationHistoryAndNavigate(
      route as Route,
      destination.document_uuid,
      destinationPage
    )

    searchParams.set('page', destinationPage?.toString())
    setSearchParams(searchParams)

    const sources = documentChange?.destinations?.map((destination) => ({
      id: highlightID,
      page: destination.page,
      quads: destination.quads ?? [],
      isPrimary: true,
      documentUUID: destination.document_uuid,
    }))

    if (!sources) {
      return
    }

    dispatch(setSelectedSources(sources))
  }, [
    addToNavigationHistoryAndNavigate,
    dispatch,
    documentChange,
    pathname,
    searchParams,
    setSearchParams,
  ])

  const getBorderColor = (changeType) => {
    switch (changeType) {
      case 'create':
        return 'border-l-green-500'
      case 'delete':
        return 'border-l-red-500'
      case 'amend':
        return 'border-l-yellow-300'
      case 'replace':
        return 'border-l-orange-300'
      default:
        return 'border-l-gray-500'
    }
  }

  return (
    <button
      id={`document_change_${documentChange?.id ?? 'new_amendment'}`}
      className={`flex w-[150px] cursor-pointer items-center justify-between space-x-1 overflow-hidden rounded border border-l-4 ${getBorderColor(
        documentChange.change_type
      )} p-2 ${
        selectedDocumentChange?.id === documentChange?.id
          ? 'bg-gray-100'
          : 'bg-white'
      }`}
      onClick={onClickDocumentChange}
    >
      <div className={'overflow-hidden text-ellipsis whitespace-nowrap'}>
        {documentChange?.original_clause_reference}
      </div>
      {documentChange.qa_checked ? (
        <div>
          <CheckIcon width={18} />
        </div>
      ) : (
        <div>
          <QuestionMarkCircleIcon width={18} />
        </div>
      )}
    </button>
  )
}

export default AddendaQACard
