import React from 'react'
import PageOverlay from './page-overlay/page-overlay'

function PageOverlayController(props) {
  return props.pages.map((page) => (
    <PageOverlay
      onSelectBlackline={props.onSelectBlackline}
      key={page}
      page={page}
      setOverlaysClicked={props.setOverlaysClicked}
      overlaysClicked={props.overlaysClicked}
    />
  ))
}

export default PageOverlayController
