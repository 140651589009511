import React, { useState } from 'react'
import {
  Cog6ToothIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import MyProfile from './my-profile'
import OrganisationTeamMembers from './organisation-team-members'
import OrganisationGeneral from './organisation-general'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useGetOrganisationQuery } from '../../redux/api/organisation-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'

type SettingsHomeProps = {}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SecondaryNavigationItem: React.FC<{
  name: string
  current: boolean
  Icon: typeof UserCircleIcon
  setMenuItem: (name: string) => void
}> = ({ name, current, setMenuItem, Icon }) => {
  return (
    <li key={name}>
      <button
        onClick={() => setMenuItem(name)}
        className={classNames(
          current
            ? 'bg-gray-50 text-indigo-600'
            : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
          'group flex w-full gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
        )}
      >
        <Icon
          className={classNames(
            current
              ? 'text-indigo-600'
              : 'text-gray-400 group-hover:text-indigo-600',
            'h-6 w-6 shrink-0'
          )}
          aria-hidden="true"
        />
        {name}
      </button>
    </li>
  )
}

const SettingsHome: React.FC<SettingsHomeProps> = () => {
  const [menuItem, setMenuItem] = useState('My Profile')
  const { data: userProfile } = useGetUserProfileQuery(undefined)
  const { data: organisationData } = useGetOrganisationQuery(
    userProfile?.organisation?.id ?? skipToken
  )

  return (
    <div className="overflow-scroll">
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <h1 className="sr-only">General Settings</h1>

        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-72 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              <SecondaryNavigationItem
                key={'My Profile'}
                Icon={UserCircleIcon}
                name={'My Profile'}
                current={menuItem === 'My Profile'}
                setMenuItem={setMenuItem}
              />
              {userProfile?.feature_flags?.organisation_settings_view && (
                <>
                  <div className="px-3 py-4">
                    <p className="text-xs text-gray-400">Organisation</p>
                    <p className="truncate text-sm font-medium text-gray-900">
                      {organisationData?.name}
                    </p>
                  </div>
                  <SecondaryNavigationItem
                    key={'General'}
                    Icon={Cog6ToothIcon}
                    name={'General'}
                    current={menuItem === 'General'}
                    setMenuItem={setMenuItem}
                  />
                  <SecondaryNavigationItem
                    key={'Team Members'}
                    Icon={UsersIcon}
                    name={'Team Members'}
                    current={menuItem === 'Team Members'}
                    setMenuItem={setMenuItem}
                  />
                </>
              )}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {menuItem === 'My Profile' && <MyProfile />}
            {menuItem === 'General' && <OrganisationGeneral />}
            {menuItem === 'Team Members' && <OrganisationTeamMembers />}
          </div>
        </main>
      </div>
    </div>
  )
}

export default SettingsHome
