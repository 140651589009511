import React, { useEffect } from 'react'
import './App.css'
import { usePostHog } from 'posthog-js/react'
import * as Sentry from '@sentry/react'
import { POSTHOG } from './utils/posthog-constants'
import Router from './routes/router'
import LogRocket from 'logrocket'
import { useGetUserProfileQuery } from './redux/api-slice'
import { useAuth0 } from '@auth0/auth0-react'

function App() {
  const posthog = usePostHog()
  const { data: profile } = useGetUserProfileQuery()
  const { user: auth0User } = useAuth0()

  useEffect(() => {
    if (profile && auth0User) {
      if (
        auth0User.sub &&
        profile.email &&
        profile.first_name &&
        process.env.REACT_APP_LOGROCKET_ID
      ) {
        LogRocket.identify(auth0User.sub, {
          name: profile.first_name,
          email: profile.email,
        })
        LogRocket.getSessionURL((sessionURL) => {
          Sentry.setContext('logrocket', { sessionURL: sessionURL })
        })
      }
      posthog?.identify(auth0User.sub, {
        email: profile.email,
        name: profile.first_name,
      })
      if (profile?.organisation) {
        posthog?.group('company', profile?.organisation?.id, {
          name: profile?.organisation?.name,
        })
      }
      posthog?.capture(POSTHOG.user_logged_in)
      Sentry.setUser({
        email: profile.email,
        id: auth0User.sub,
        ip_address: auth0User.ip_address,
      })
      window.$chatwoot?.setUser(auth0User.sub, {
        name: profile?.first_name,
        email: profile.email,
      })
    }
  }, [auth0User, profile, posthog])

  return <Router />
}

export default App
