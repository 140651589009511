import React, { useCallback, useContext, useMemo } from 'react'
import {
  ArchiveBoxIcon,
  CheckIcon,
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useDeleteDocumentMutation,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
  setModal,
  setV1Document,
} from '../../redux/application-slice'
import { MODAL_TYPES } from '../modals/modal-controller'
import { FilePond } from 'react-filepond'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import DocumentRowPreviousVersionButton from './document-row-previous-version-button'

interface DocumentRowProps {
  documentData: ProjectDocument
  uploaderRef: React.MutableRefObject<FilePond | null>
  documentVersion: number
  previousDocumentVersions: ProjectDocument[] | null
  index: number
  showSortHandle?: boolean
}

const DocumentRow: React.FC<DocumentRowProps> = ({
  documentData,
  uploaderRef,
  documentVersion,
  previousDocumentVersions,
  index,
  showSortHandle = false,
}) => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const posthog = usePostHog()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteDocument] = useDeleteDocumentMutation()
  const [buttonRef, setButtonRef] = React.useState<HTMLButtonElement | null>(
    null
  )
  const [panelRef, setPanelRef] = React.useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const { data: user } = useGetUserProfileQuery(undefined)
  const documentSearchQuery = useMemo(() => {
    return searchParams.get('search')
  }, [searchParams])

  const dispatch = useDispatch()

  const onDelete = useCallback(async () => {
    posthog?.capture(POSTHOG.document_deleted, {
      document_uuid: documentData.uuid,
    })
    if (currentDocument?.uuid === documentData.uuid) {
      documentViewer?.closeDocument()
    }
    if (currentDocument?.uuid === documentData.uuid) {
      navigate(`/${currentProject?.uuid}/documents`)
    }
    if (documentData.folder) {
      searchParams.set('folderId', documentData?.folder?.id)
      setSearchParams(searchParams)
    }
    const searchQuery = searchParams.get('search') ?? undefined
    await deleteDocument({
      ...documentData,
      project: currentProject?.id,
      search_query: searchQuery,
    })
  }, [
    posthog,
    documentData,
    currentDocument?.uuid,
    navigate,
    currentProject?.uuid,
    currentProject?.id,
    deleteDocument,
    documentViewer,
    searchParams,
    setSearchParams,
  ])

  const onRenameDocument = useCallback(() => {
    dispatch(
      setModal({ modal: MODAL_TYPES.RENAME_DOCUMENT, document: documentData })
    )
  }, [dispatch, documentData])

  const onClickUploadRevision = useCallback(() => {
    if (!uploaderRef?.current) {
      return
    }
    dispatch(setV1Document(documentData?.id))
    uploaderRef?.current?.browse()
    buttonRef?.click()
    posthog.capture(POSTHOG.document_revision_uploaded, {
      project_uuid: currentProject?.uuid,
      document_uuid: documentData.uuid,
    })
  }, [
    uploaderRef,
    dispatch,
    documentData?.id,
    buttonRef,
    posthog,
    currentProject?.uuid,
    documentData?.uuid,
  ])

  const onNavigateDocument = useCallback(
    (e) => {
      e.stopPropagation()
      navigate(`/${currentProject?.uuid}/documents/${documentData.uuid}`)
      if (documentData.folder) {
        searchParams.set('folderId', documentData?.folder?.id)
        setSearchParams(searchParams)
      }
    },
    [
      navigate,
      currentProject?.uuid,
      documentData.uuid,
      documentData.folder,
      searchParams,
      setSearchParams,
    ]
  )

  const getDocumentStatusBadge = useMemo(() => {
    switch (documentData.job_status) {
      case 'COMPLETE':
        return (
          <span
            data-tooltip-id="document-title-id"
            data-tooltip-content={'Document processed successfully'}
            className="inline-flex items-center rounded-full bg-green-100 p-1 text-xs font-medium text-green-700"
          >
            <CheckIcon className="w-4" />
          </span>
        )
      case 'PENDING':
      case 'PROCESSING':
        return (
          <span
            data-tooltip-id="document-title-id"
            data-tooltip-content={'Document is being processed'}
            className="inline-flex items-center rounded-full bg-blue-100 p-1 text-xs font-medium text-blue-700"
          >
            <ClockIcon className="w-4" />
          </span>
        )
      case 'IN_REVIEW':
        return (
          <span
            data-tooltip-id="document-title-id"
            data-tooltip-content={
              'Document is in review, this may take up to 24 hours'
            }
            className="inline-flex items-center rounded-full bg-blue-100 p-1 text-xs font-medium text-blue-700"
          >
            <ClipboardDocumentCheckIcon className="w-4" />
          </span>
        )
      case 'ARCHIVED':
        return (
          <span
            data-tooltip-id="document-title-id"
            data-tooltip-content={'Document is archived'}
            className="inline-flex items-center rounded-full bg-blue-100 p-1 text-xs font-medium text-blue-700"
          >
            <ArchiveBoxIcon className="w-4" />
          </span>
        )
      case 'UNARCHIVING':
        return (
          <span
            data-tooltip-id="document-title-id"
            data-tooltip-content={
              'Document is unarchiving, this may take a few minutes'
            }
            className="inline-flex items-center rounded-full bg-blue-100 p-1 text-xs font-medium text-blue-700"
          >
            <ArchiveBoxIcon className="w-4" />
          </span>
        )
      case 'ERROR':
        return (
          <span
            data-tooltip-id="document-title-id"
            data-tooltip-content={
              'This document could not be processed. Please contact support.'
            }
            className="inline-flex items-center rounded-full bg-red-100 p-1 text-xs font-medium text-red-700"
          >
            <ExclamationTriangleIcon className="w-4" />
          </span>
        )
    }
  }, [documentData.job_status])

  const getDocumentVersionBadge = useMemo(() => {
    if (documentVersion > 1) {
      return (
        <div
          data-tooltip-id="document-title-id"
          data-tooltip-content={`Version ${documentVersion} of this document.`}
          className="inline-flex w-8 items-center justify-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
        >
          V{documentVersion}
        </div>
      )
    }
    return null
  }, [documentVersion])

  const onClickUpload = useCallback(
    (e) => {
      if (documentData?.job_status !== 'COMPLETE') {
        return
      }
      onClickUploadRevision()
    },
    [documentData?.job_status, onClickUploadRevision]
  )

  const documentOptions = useMemo(() => {
    return (
      <Popover className="h-full text-xs">
        <Popover.Button
          data-cy="document-options"
          className={'h-full rounded p-1 hover:bg-gray-200'}
          tabIndex={-1}
          ref={setButtonRef}
        >
          <EllipsisVerticalIcon className="h-5" />
        </Popover.Button>
        <Popover.Panel
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 flex flex-col rounded border bg-white shadow"
        >
          <button
            className={'px-2 py-2 text-left hover:bg-gray-100'}
            onClick={onRenameDocument}
          >
            Rename
          </button>
          {user?.feature_flags?.duplicator === true && (
            <button
              className={`px-2 py-2 text-left ${
                documentData?.job_status === 'COMPLETE'
                  ? 'hover:bg-gray-200'
                  : ' cursor-default bg-gray-200'
              }`}
              onClick={onClickUpload}
            >
              Upload Revision
            </button>
          )}
          {previousDocumentVersions?.map((document) => (
            <DocumentRowPreviousVersionButton
              key={`previous_version_${document.uuid}`}
              document={document}
            />
          ))}
          <button
            className={'px-2 py-2 text-left hover:bg-gray-100'}
            onClick={onDelete}
          >
            Delete
          </button>
        </Popover.Panel>
      </Popover>
    )
  }, [
    attributes.popper,
    documentData?.job_status,
    onClickUpload,
    onDelete,
    onRenameDocument,
    previousDocumentVersions,
    styles.popper,
    user?.feature_flags?.duplicator,
  ])

  const onClickNavigateParentFolder = useCallback(() => {
    searchParams.delete('search')
    if (!documentData?.folder?.id) {
      searchParams.delete('folderId')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('folderId', documentData?.folder?.id)
    setSearchParams(searchParams)
  }, [documentData, searchParams, setSearchParams])

  return (
    <div
      data-playwright="document-row"
      className="flex grow items-center overflow-hidden"
    >
      <button
        className={`flex grow items-center justify-between overflow-hidden`}
        onClick={onNavigateDocument}
      >
        <div
          title={documentData.title}
          className="flex grow items-center space-x-1 overflow-hidden text-left text-sm text-gray-900"
        >
          <DocumentIcon className="h-5 w-5 shrink-0 text-gray-500" />
          <div className="overflow-hidden text-ellipsis whitespace-nowrap">
            {documentData.title}
          </div>
        </div>
        {getDocumentVersionBadge}
        <div className="whitespace-nowrap px-2 text-sm text-gray-500">
          <div
            className="w-10 text-right text-xs"
            data-tooltip-id="document-title-id"
            data-tooltip-content={`${documentData.total_pages} pages`}
          >
            {documentData.total_pages > 1000
              ? '1000+'
              : documentData.total_pages}
          </div>
        </div>
        <div className="shrink-0 whitespace-nowrap px-3 text-sm text-gray-500">
          {documentSearchQuery ? (
            <button
              onClick={onClickNavigateParentFolder}
              className="flex items-center space-x-1 hover:text-gray-900"
            >
              {documentData?.folder?.name ? (
                <ChevronRightIcon className="h-4 w-4" />
              ) : null}
              <div className="w-36 justify-between overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
                {documentData?.folder?.name}
              </div>
            </button>
          ) : (
            getDocumentStatusBadge
          )}
        </div>

        <div className="whitespace-nowrap text-sm text-gray-500"></div>
      </button>
      {documentOptions}
    </div>
  )
}

export default DocumentRow
