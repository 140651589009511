import { createSlice } from '@reduxjs/toolkit'
import {
  Hit,
  SearchResults,
} from '../shared/interfaces/search-results.interface'
import { ProjectPermissionUser } from '../shared/interfaces/project/permissions/project-permissions.interface'

interface SearchState {
  searchQuery: string
  inputText: string
  searchResults: SearchResults | null
  isSearchAvailable: boolean
  selectedResult: Hit | null
  filterBy: string | null
  filterDocumentUuids: string[]
  filterLabelNames: string[]
  assignedUsers: ProjectPermissionUser[] | null
  searchPage: number
  definitionQuery: string
}

const initialState: SearchState = {
  searchQuery: '',
  inputText: '',
  searchResults: null,
  isSearchAvailable: false,
  selectedResult: null,
  filterBy: null,
  filterDocumentUuids: [],
  filterLabelNames: [],
  searchPage: 1,
  definitionQuery: '',
  assignedUsers: null,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setSearchPage: (state, action) => {
      state.searchPage = action.payload
    },
    setInputText: (state, action) => {
      state.inputText = action.payload
    },
    setSearchResults: (
      state,
      action: {
        payload: SearchResults | null
        type: string
      }
    ) => {
      state.searchResults = action.payload
    },
    setIsSearchAvailable: (state, action) => {
      state.isSearchAvailable = action.payload
    },
    setSelectedResult: (state, action) => {
      state.selectedResult = action.payload
    },
    setFilterBy: (state, action) => {
      state.filterBy = action.payload
    },
    setFilterDocumentUuids: (state, action) => {
      state.filterDocumentUuids = action.payload
    },
    setFilterLabelNames: (state, action) => {
      state.filterLabelNames = action.payload
    },
    setDefinitionQuery: (state, action) => {
      state.definitionQuery = action.payload
    },
    setAssignedUsers: (
      state,
      action: {
        payload: ProjectPermissionUser[] | null
        type: string
      }
    ) => {
      state.assignedUsers = action.payload
    },
  },
})

export const {
  setSearchQuery,
  setInputText,
  setSearchResults,
  setSearchPage,
  setIsSearchAvailable,
  setSelectedResult,
  setFilterBy,
  setFilterDocumentUuids,
  setFilterLabelNames,
  setDefinitionQuery,
  setAssignedUsers,
} = searchSlice.actions

export const selectSearchQuery = (state) => state.search.searchQuery
export const selectSearchPage = (state) => state.search.searchPage
export const selectInputText = (state) => state.search.inputText
export const selectSearchResults = (state: {
  search: { searchResults: SearchResults | null }
}) => state.search.searchResults
export const selectIsSearchAvailable = (state) => state.search.isSearchAvailable
export const selectSelectedResult = (state) => state.search.selectedResult
export const selectFilterBy = (state) => state.search.filterBy
export const selectFilterDocumentUuids = (state) =>
  state.search.filterDocumentUuids
export const selectFilterLabelNames = (state) => state.search.filterLabelNames
export const selectDefinitionQuery = (state) => state.search.definitionQuery
export const selectAssignedUsers = (state: {
  search: {
    assignedUsers: ProjectPermissionUser[] | null
  }
}) => state.search.assignedUsers

export const searchReducer = searchSlice.reducer
