import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setModal,
  setCurrentProject,
  selectCurrentProject,
} from '../../redux/application-slice'
import {
  Cog6ToothIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import {
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import ProjectSharingSection from '../project-sharing-section'
import ProjectSettingsTitleSection from './project-settings-title-section'
import ProjectSettingsActionButtons from './project-settings-action-buttons'
import { usePermissions } from '../../hooks/use-permissions'
interface ProjectSettingsModalProps {
  open: boolean
}

const ProjectSettingsModal: React.FC<ProjectSettingsModalProps> = ({
  open,
}) => {
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const [updateProject] = useUpdateProjectMutation()
  const [deleteProject] = useDeleteProjectMutation()
  const [submitting, setSubmitting] = useState(false)
  const [projectName, setProjectName] = useState<string>('')
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentProject = useSelector(selectCurrentProject)
  const currentProjectId = currentProject?.id?.toString()
  const currentProjectUuid = currentProject?.uuid
  const { data: userProfile } = useGetUserProfileQuery(undefined)

  const hasProjectNameChanged = useMemo(() => {
    return projectName !== currentProject?.title
  }, [projectName, currentProject?.title])
  const { canEdit, canDelete, canLeave } = usePermissions({
    currentProjectId: currentProjectId,
    userProfile: userProfile,
  })

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setSubmitting(false)
    setProjectName('')
    setShowDeleteConfirmation(false)
  }, [dispatch])

  useEffect(() => {
    setProjectName(currentProject?.title || '')
  }, [currentProject])

  const onUpdateProjectName = useCallback(async () => {
    if (submitting || !canEdit || !currentProject) return

    setSubmitting(true)
    try {
      await updateProject({
        ...currentProject,
        title: projectName,
      }).unwrap()
      posthog?.capture(POSTHOG.project_renamed, {
        project_uuid: currentProject.uuid,
      })
      toast.success('Project name updated successfully')
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
    setSubmitting(false)
  }, [canEdit, currentProject, posthog, projectName, submitting, updateProject])

  const onProjectDelete = useCallback(() => {
    if (!canDelete || !currentProjectId || !currentProjectUuid) return

    posthog?.capture(POSTHOG.project_deleted, {
      project_uuid: currentProjectUuid,
    })
    deleteProject(currentProjectId)
      .unwrap()
      .then(() => {
        if (pathname.includes(currentProjectUuid)) {
          navigate('')
        }
        dispatch(setCurrentProject(null))
        onClose()
        toast.success('Project deleted successfully')
      })
      .catch(() => {
        toast.error('Failed to delete project')
      })
  }, [
    canDelete,
    currentProjectId,
    currentProjectUuid,
    deleteProject,
    dispatch,
    navigate,
    pathname,
    posthog,
    onClose,
  ])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value)
  }, [])

  const onEnterPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onUpdateProjectName()
      }
    },
    [onUpdateProjectName]
  )

  const dialogContent = useMemo(() => {
    if (!currentProject) {
      return null
    }
    if (showDeleteConfirmation) {
      return (
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Delete project
                </Dialog.Title>
                <p className="mt-2 text-sm text-gray-500">
                  Are you sure you want to delete this project? This will remove
                  all project documents and attached data for all users involved
                  in this project.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              onClick={onProjectDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      )
    }

    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
        <div className="bg-white px-4 pb-4 pr-6 pt-5 sm:p-6 sm:pb-4 sm:pr-8">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="flex items-center text-lg font-semibold leading-6 text-gray-900"
              >
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <Cog6ToothIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">Project Settings</div>
              </Dialog.Title>
              <div className="mt-4 space-y-4">
                <ProjectSettingsTitleSection
                  projectName={projectName}
                  canEdit={canEdit}
                  submitting={submitting}
                  onChange={onChange}
                  onEnterPress={onEnterPress}
                  onUpdateProjectName={onUpdateProjectName}
                  hasChanged={hasProjectNameChanged}
                />

                <div>
                  <div className="mt-4">
                    <ProjectSharingSection
                      project={currentProject}
                      isEnabled={canEdit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:justify-between sm:px-6">
          <ProjectSettingsActionButtons
            canDelete={canDelete}
            canLeave={canLeave}
            onDeleteClick={() => setShowDeleteConfirmation(true)}
            projectId={currentProjectId}
          />
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    )
  }, [
    showDeleteConfirmation,
    onClose,
    projectName,
    canEdit,
    canDelete,
    canLeave,
    onChange,
    onEnterPress,
    onUpdateProjectName,
    submitting,
    onProjectDelete,
    currentProject,
    hasProjectNameChanged,
    currentProjectId,
  ])

  return (
    <Transition.Root show={open} as="div">
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ProjectSettingsModal
