import React, { useMemo, useState } from 'react'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { usePopper } from 'react-popper'
import { Popover } from '@headlessui/react'
import CustomLabelSelector from '../custom-labels/custom-label-selector'
import { PlusIcon } from '@heroicons/react/24/outline'

interface RevisionCustomLabelProps {
  revision: Revision
}

const RevisionCustomLabel: React.FC<RevisionCustomLabelProps> = ({
  revision,
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef, {
    placement: 'bottom-start',
  })

  const orderedLabels = useMemo(() => {
    const labels = [...(revision.custom_labels ?? [])]
    if (!labels) return []
    return labels.sort((a, b) => a.name.localeCompare(b.name))
  }, [revision.custom_labels])

  return (
    <Popover className="relative flex flex-shrink flex-grow-0 justify-end">
      <Popover.Button
        tabIndex={-1}
        ref={setButtonRef}
        className={
          'flex min-w-0 flex-shrink items-center justify-end gap-1 overflow-hidden'
        }
      >
        {orderedLabels.length === 0 && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-700">
            <svg
              className="h-1.5 w-1.5 fill-gray-500"
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            No label
          </span>
        )}
        {orderedLabels.slice(0, 2).map((label) => (
          <span
            key={label.id}
            title={label.name}
            className="inline-flex max-w-24 items-center gap-x-1.5  rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700"
          >
            <svg
              className="h-1.5 w-1.5 shrink-0 fill-blue-500"
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              {label.name}
            </div>
          </span>
        ))}
        {(orderedLabels.length ?? 0) > 2 && (
          <span className="gap inline-flex max-w-24 items-center gap-x-0.5  rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-700">
            <PlusIcon className="w-3 font-semibold" />
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              {(orderedLabels.length ?? 2) - 2} labels
            </div>
          </span>
        )}
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 flex flex-col text-nowrap rounded border bg-white text-sm shadow"
      >
        <CustomLabelSelector revision={revision} />
      </Popover.Panel>
    </Popover>
  )
}

export default RevisionCustomLabel
