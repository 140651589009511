import React, { useCallback } from 'react'
import { Folder } from '../../shared/interfaces/project/folder/folder.interface'
import { useSearchParams } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../redux/application-slice'

interface FolderHeaderProps {
  folder: Folder
}

const FolderHeader: React.FC<FolderHeaderProps> = ({ folder }) => {
  const currentProject = useSelector(selectCurrentProject)
  const [searchParams, setSearchParams] = useSearchParams()
  const onClickBack = useCallback(() => {
    if (folder.folder) {
      searchParams.set('folderId', folder.folder.id)
      setSearchParams(searchParams)
    } else {
      searchParams.delete('folderId')
      setSearchParams(searchParams)
    }
  }, [folder.folder, searchParams, setSearchParams])

  const onClickRoot = useCallback(() => {
    searchParams.delete('folderId')
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  return (
    <div className="flex items-center">
      <button
        className="max-w-48 overflow-hidden overflow-ellipsis whitespace-nowrap"
        onClick={onClickRoot}
      >
        {currentProject?.title}
      </button>
      {folder.folder ? (
        <>
          <ChevronRightIcon className="h-4 w-4" />
          <div className="flex items-end">...</div>
          <div className="flex items-center">
            <button onClick={onClickBack}>
              <ChevronRightIcon className="h-4 w-4" />
            </button>
            <button onClick={onClickBack}>{folder?.folder?.name}</button>
          </div>
        </>
      ) : null}
      <div>
        <ChevronRightIcon className="h-4 w-4" />
      </div>
      <div onClick={onClickBack}>{folder.name}</div>
    </div>
  )
}

export default FolderHeader
