import React, { forwardRef, useMemo } from 'react'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { useSelector } from 'react-redux'
import { selectSelectedRevision } from '../../redux/viewer-slice'

interface PageOverlayRevisionProps {
  revision: Revision
  coords: {
    width: number
    height: number
    topOffset: number
    leftOffset: number
  }
  shouldElevate: boolean
}

const PageOverlayRevision = forwardRef<
  HTMLButtonElement,
  PageOverlayRevisionProps
>(({ revision, coords, shouldElevate }, ref) => {
  const selectedRevision = useSelector(selectSelectedRevision)

  const isCommentOnly = useMemo(
    () => revision?.revised_text === null,

    [revision?.revised_text]
  )

  const getHighlightColor = useMemo(() => {
    if (selectedRevision?.id === revision?.id) {
      return isCommentOnly ? 'bg-blue-600' : 'bg-green-600'
    }
    return isCommentOnly ? 'bg-blue-200' : 'bg-green-200'
  }, [isCommentOnly, revision?.id, selectedRevision])

  return (
    <button
      ref={ref}
      data-id={`revision-${revision.id}`}
      className={`absolute ${getHighlightColor} rounded outline-none ${
        shouldElevate ? 'z-50' : 'z-30'
      }`}
      style={{
        width: `${coords.width}%`,
        height: `${coords.height}%`,
        top: `${coords.topOffset}%`,
        left: `${coords.leftOffset}px`,
      }}
    />
  )
})
PageOverlayRevision.displayName = 'PageOverlayRevision'
export default PageOverlayRevision
