import React, { useCallback, useMemo } from 'react'
import DocumentViewerRevisionCard from './sidelings/document-viewer-revision-card'
import { useCoords } from '../../hooks/use-coords'
import { SidelingData, SidelingTop } from '../../hooks/use-sideling'
import { DocumentSegment } from '../../shared/interfaces/project/document/segments/document-segment.interface'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import { selectDocumentLoaded } from '../../redux/viewer-slice'
import DocumentViewerAddendaCard from './components/addenda/document-viewer-addenda-card'

const newRevisionID = 'new_revision'

interface DocumentViewerSidelingProps {
  sidelingData: SidelingData
  isNewRevision: boolean
  changeSideling: () => void
  sidelingTop: SidelingTop | null
  setSidelingsRef: React.Dispatch<
    React.SetStateAction<(HTMLDivElement | null)[]>
  >
}

const DocumentViewerSideling: React.FC<DocumentViewerSidelingProps> = ({
  sidelingData,
  isNewRevision = false,
  changeSideling,
  sidelingTop,
  setSidelingsRef,
}) => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const { getTopOffset } = useCoords()
  const documentLoaded = useSelector(selectDocumentLoaded)
  const top = useMemo(() => {
    if (!documentLoaded) {
      return null
    }
    if (isNewRevision) {
      const minPage = Math.min.apply(
        Math,
        (sidelingData?.segments ?? []).map((s) => s.page)
      )
      const topSegment = (sidelingData?.segments ?? [])
        .filter((r) => r.page === minPage)
        ?.sort((a, b) => {
          return (a?.quads?.[0]?.y3 ?? 0) - (b?.quads?.[0]?.y3 ?? 0)
        })
      const quads = topSegment?.[0]?.quads?.[0]
      const newRevisionTopOffset = sidelingTop?.[newRevisionID]
        ? sidelingTop[newRevisionID]
        : getTopOffset(
            quads ?? sidelingData.quads[0],
            minPage ?? sidelingData?.page ?? 1,
            'document-viewer'
          ).topOffset
      return newRevisionTopOffset ? `${newRevisionTopOffset}px` : null
    }
    const minPage = Math.min.apply(
      Math,
      (sidelingData?.segments ?? []).map((s) => s.page)
    )
    const topSegment = (sidelingData?.segments ?? [])
      .filter((r) => r.page === minPage)
      ?.sort((a, b) => {
        return (a?.quads?.[0]?.y3 ?? 0) - (b?.quads?.[0]?.y3 ?? 0)
      })
    const quads = topSegment?.[0]?.quads?.[0]
    const id = sidelingData.id ? sidelingData.id : newRevisionID
    const sidelingTopOffset = sidelingTop?.[id]
      ? sidelingTop[id]
      : getTopOffset(
          quads ?? sidelingData.quads[0],
          minPage !== -Infinity ? minPage : sidelingData?.page ?? 1,
          'document-viewer'
        ).topOffset
    return sidelingTopOffset ? `${sidelingTopOffset}px` : null
  }, [getTopOffset, sidelingData, sidelingTop, isNewRevision, documentLoaded])

  const onRef = useCallback(
    (el: HTMLDivElement | null) => {
      if (!el) {
        return
      }
      setSidelingsRef((prev) => {
        if (!prev) {
          return [el]
        }
        const existingEls = prev.filter((p) => {
          return document.getElementById(
            p?.dataset?.id ?? newRevisionID.toString()
          )
        })
        return [...(existingEls ?? []), el]
      })
    },
    [setSidelingsRef]
  )

  return top ? (
    <>
      {sidelingData?.type === 'revision' ? (
        <DocumentViewerRevisionCard
          onRevisionChange={changeSideling}
          ref={onRef}
          revision={sidelingData.data as Revision}
          top={top}
        />
      ) : sidelingData?.type === 'addenda_segment' &&
        user?.feature_flags?.addenda ? (
        <DocumentViewerAddendaCard
          top={top}
          addendaSegment={sidelingData.data as DocumentSegment}
          ref={onRef}
        />
      ) : null}
    </>
  ) : null
}

export default DocumentViewerSideling
