import React, { useCallback, useMemo, useState } from 'react'
import { useGetCustomLabelsQuery } from '../../../redux/api/custom-label-api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { CustomLabel } from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { Combobox } from '@headlessui/react'
import {
  selectCommentWorkflowFilterState,
  setCommentWorkflowFilterState,
} from '../../../redux/workflow-slice'
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
interface CustomLabelFilterProps {}

const CustomLabelFilter: React.FC<CustomLabelFilterProps> = () => {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const { data: customLabels } = useGetCustomLabelsQuery(
    currentProject?.uuid
      ? { projectUUID: currentProject?.uuid, segmentsIncluded: false }
      : skipToken
  )
  const { customLabelsFilter, ...filters } = useSelector(
    selectCommentWorkflowFilterState
  )

  const onChangeCustomLabelFilter = useCallback(
    (selectedLabels: any) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...filters,
          customLabelsFilter:
            customLabels?.filter((d) => selectedLabels.includes(d.id)) ?? [],
        })
      )
    },
    [customLabels, dispatch, filters]
  )

  const filterLabelsByQuery = useCallback(
    (labels: CustomLabel[]) => {
      return labels.filter((l) =>
        l.name.toLowerCase().includes(query.toLowerCase())
      )
    },
    [query]
  )

  const onClearCustomLabelsFilter = useCallback(() => {
    dispatch(
      setCommentWorkflowFilterState({
        ...filters,
        customLabelsFilter: [],
      })
    )
  }, [dispatch, filters])

  const customLabelsToDisplay = useMemo(() => {
    return filterLabelsByQuery(customLabels ?? [])
  }, [customLabels, filterLabelsByQuery])

  return (
    <Combobox
      as="div"
      value={customLabelsFilter.map((d) => d.id)}
      multiple={true}
      onChange={onChangeCustomLabelFilter}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-0.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          placeholder={
            customLabelsFilter.length > 0
              ? `${customLabelsFilter.length} labels`
              : 'All labels'
          }
        />
        {customLabelsFilter.length > 0 && (
          <button
            className="absolute right-6 top-0 z-10 flex h-full items-center"
            onClick={onClearCustomLabelsFilter}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pl-8 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-96 w-[200px] divide-y overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {customLabelsToDisplay.map((label) => (
            <Combobox.Option
              key={label.id}
              title={label.name}
              value={label.id}
              className={`relative flex cursor-pointer select-none justify-between p-2 text-sm  text-gray-900 hover:bg-indigo-600 hover:text-white`}
            >
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                {label.name}
              </div>
              {customLabelsFilter.find((d) => d.id === label.id) && (
                <CheckIcon className="w-5 shrink-0" />
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default CustomLabelFilter
