import React, { useCallback } from 'react'
import {
  CustomLabel,
  LabelSegment,
} from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { useCoords } from '../../../hooks/use-coords'
import CustomLabelIcon from '../../custom-label/custom-label-icon'
import { useGetCustomLabelsQuery } from '../../../redux/api/custom-label-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import IconGroup from '../../icon-group/icon-group'
import { useNavigate } from 'react-router-dom'
import {
  setIconMenuOpen,
  setSelectedCustomLabel,
} from '../../../redux/viewer-slice'

interface CustomLabelGroup {
  [documentSegmentID: string]: {
    documentSegment: Partial<DocumentSegment>
    labelSegments: LabelSegment[]
  }
}

interface LabelGroup {
  documentSegment: Partial<DocumentSegment>
  labelSegments: LabelSegment[]
}

const DocumentViewerIcons: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)
  const navigation = useNavigate()
  const { data: labels } = useGetCustomLabelsQuery(
    currentProject?.uuid ? { projectUUID: currentProject?.uuid } : skipToken
  )
  const { getTopOffset } = useCoords()
  const dispatch = useDispatch()
  const currentDocument = useSelector(selectCurrentDocument)
  const groupCustomLabelsByDocSegment = useCallback(
    (customLabels: CustomLabel[]) => {
      const customLabelGroup: CustomLabelGroup = {}
      for (const customLabel of customLabels) {
        for (const segment of customLabel.segments.filter(
          (s) => s.document_uuid === currentDocument?.uuid
        ) ?? []) {
          if (!segment.document_segment || !segment.document_segment.id) {
            continue
          }
          if (!customLabelGroup[segment.document_segment.id]) {
            customLabelGroup[segment.document_segment.id] = {} as {
              documentSegment: Partial<DocumentSegment>
              labelSegments: LabelSegment[]
            }
            customLabelGroup[segment.document_segment.id].documentSegment =
              segment.document_segment
            customLabelGroup[segment.document_segment.id].labelSegments = []
          }
          customLabelGroup[segment.document_segment.id]?.labelSegments?.push({
            ...segment,
            parentLabel: customLabel,
          })
        }
      }

      return customLabelGroup
    },
    [currentDocument?.uuid]
  )
  const renderCustomLabels = useCallback((labelGroup: LabelGroup) => {
    const customLabelIcons: JSX.Element[] = []
    for (const segment of labelGroup.labelSegments) {
      customLabelIcons.push(
        <CustomLabelIcon
          key={`custom_label_icon_${segment.id ?? 0}`}
          labelSegment={segment}
        />
      )
    }
    return customLabelIcons
  }, [])
  const onSelectCustomLabel = useCallback(
    (labelSegment?: LabelSegment) => {
      if (!labelSegment || !labelSegment.id) {
        return
      }
      const parentLabel = labels?.find((l) => l.id === labelSegment.label)
      navigation(
        `/${currentProject?.uuid}/clause-filters/${currentDocument?.uuid}?label=${parentLabel?.name}&page=${labelSegment.page}`
      )
      dispatch(setSelectedCustomLabel(labelSegment.id))
      dispatch(setIconMenuOpen(null))
    },
    [currentDocument?.uuid, currentProject?.uuid, dispatch, labels, navigation]
  )
  const renderIcons = useCallback(
    (customLabels: CustomLabel[]) => {
      const customLabelGroup = groupCustomLabelsByDocSegment(customLabels)
      const labelElements: JSX.Element[] = []
      for (const [, labelGroup] of Object.entries(customLabelGroup)) {
        const segment = labelGroup.labelSegments[0]
        const quads = labelGroup?.documentSegment?.quads?.[0]
        if (!quads) {
          continue
        }
        const coords = getTopOffset(quads, segment.page, 'document-viewer')
        const customLabelIcons = renderCustomLabels(labelGroup)
        const labelSegment = labelGroup?.labelSegments?.[0]
        const iconLength = labelGroup?.labelSegments?.length
        const iconGroup = (
          <IconGroup
            onSelectCustomLabel={onSelectCustomLabel}
            labelSegment={labelSegment}
            key={`icon_group_${segment.id ?? 0}`}
            iconLength={iconLength}
            coords={coords}
            quads={quads}
            page={segment.page}
            documentSegment={labelGroup.documentSegment}
            icons={[]}
          />
        )
        for (const customLabelIcon of customLabelIcons) {
          iconGroup.props.icons.push(customLabelIcon)
        }
        labelElements.push(iconGroup)
      }
      return labelElements
    },
    [
      getTopOffset,
      groupCustomLabelsByDocSegment,
      onSelectCustomLabel,
      renderCustomLabels,
    ]
  )
  return <div>{labels && renderIcons(labels)}</div>
}
export default DocumentViewerIcons
