import React, { useCallback } from 'react'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'
import {
  setHighlightedDocumentChange,
  setSelectedDocumentChange,
} from '../../redux/viewer-slice'
import { useDispatch } from 'react-redux'

interface PageOverlayHighlightProps {
  coords: {
    width: number
    height: number
    topOffset: number
    leftOffset: number
  }
  amendment: DocumentChange
}

const PageOverlayAmendmentHighlight: React.FC<PageOverlayHighlightProps> = ({
  coords,
  amendment,
}) => {
  const dispatch = useDispatch()
  const onHighlightedChangeClick = useCallback(() => {
    dispatch(setSelectedDocumentChange(amendment))
    dispatch(setHighlightedDocumentChange(amendment.id))
  }, [dispatch, amendment])
  return (
    <button
      onClick={onHighlightedChangeClick}
      key={`change_highlight_${amendment.id}`}
      className={
        'absolute z-50 cursor-pointer border border-yellow-400 p-0.5 opacity-100'
      }
      style={{
        width: `${coords.width}%`,
        height: `${coords.height}%`,
        top: `${coords.topOffset}%`,
        left: `${coords.leftOffset}px`,
      }}
    />
  )
}

export default PageOverlayAmendmentHighlight
