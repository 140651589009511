import React, {
  useState,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { selectModal, setModal } from '../../redux/application-slice'
import { useUpdateRiskPipelineDefinitionMutation } from '../../redux/api/project-risk-api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'

interface EditCustomRiskModalProps {
  open: boolean
}

const EditCustomRiskModal: React.FC<EditCustomRiskModalProps> = ({ open }) => {
  const [updateRisk] = useUpdateRiskPipelineDefinitionMutation()
  const dispatch = useDispatch()
  const modalOpen = useSelector(selectModal)
  const [newName, setNewName] = useState(modalOpen?.props?.riskName ?? '')
  const [newQueryText, setNewQueryText] = useState(
    modalOpen?.props?.queryText ?? ''
  )
  const [newRiskDescriptionValue, setNewRiskDescriptionValue] = useState(
    modalOpen?.props?.risk_description ?? ''
  )
  const posthog = usePostHog()

  const isButtonDisabled = useMemo(() => {
    const isNameEmpty = !newName.trim()
    const isQueryTextEmpty = !newQueryText.trim()
    const isNameUnchanged = newName === modalOpen?.props?.riskName
    const isQueryTextUnchanged = newQueryText === modalOpen?.props?.queryText
    const isRiskDescriptionUnchanged =
      newRiskDescriptionValue === modalOpen?.props?.risk_description

    return (
      isNameEmpty ||
      isQueryTextEmpty ||
      (isNameUnchanged && isQueryTextUnchanged && isRiskDescriptionUnchanged)
    )
  }, [
    modalOpen?.props?.queryText,
    modalOpen?.props?.riskName,
    modalOpen?.props?.risk_description,
    newName,
    newQueryText,
    newRiskDescriptionValue,
  ])

  useEffect(() => {
    if (modalOpen?.props) {
      setNewName(modalOpen.props.riskName ?? '')
      setNewQueryText(modalOpen.props.queryText ?? '')
      setNewRiskDescriptionValue(modalOpen.props.risk_description ?? '')
    }
  }, [modalOpen?.props])

  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])

  const handleSubmit = useCallback(async () => {
    const hasNameChanged = newName !== modalOpen?.props?.riskName
    const hasQueryTextChanged = newQueryText !== modalOpen?.props?.queryText
    const hasRiskDescriptionChanged =
      newRiskDescriptionValue !== modalOpen?.props?.risk_description

    try {
      const updateData: any = {
        id: modalOpen?.props?.id,
        risk_list_template_id: modalOpen?.props?.risk_list_template_id,
      }
      if (hasNameChanged) {
        updateData.risk_name = newName
      }
      if (hasQueryTextChanged) {
        updateData.query_text = newQueryText
      }
      if (hasRiskDescriptionChanged) {
        updateData.risk_description = newRiskDescriptionValue
      }
      await updateRisk(updateData).unwrap()
      posthog?.capture(POSTHOG.custom_riskpipelinedefinition_edited, {
        risk_id: modalOpen?.props?.id,
        risk_list_template_id: modalOpen?.props?.risk_list_template_id,
      })
      onClose()
      toast.success('Risk updated successfully')
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }, [
    updateRisk,
    modalOpen?.props,
    newName,
    newQueryText,
    newRiskDescriptionValue,
    onClose,
    posthog,
  ])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="border-text-400 relative transform overflow-hidden rounded-lg border bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Update Name, Question, and Definition
              </Dialog.Title>
              <div className="mt-4 space-y-4">
                <div>
                  <label
                    htmlFor="riskName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Risk Title
                  </label>
                  <input
                    type="text"
                    id="riskName"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="queryText"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Risk Question
                  </label>
                  <textarea
                    id="queryText"
                    value={newQueryText}
                    onChange={(e) => setNewQueryText(e.target.value)}
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="riskDescription"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Risk Description (optional)
                  </label>
                  <textarea
                    id="riskDescription"
                    value={newRiskDescriptionValue}
                    onChange={(e) => setNewRiskDescriptionValue(e.target.value)}
                    rows={1}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                  />
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={clsx(
                    'inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
                    isButtonDisabled
                      ? 'cursor-not-allowed border-gray-300 bg-gray-100 text-gray-400'
                      : 'border-transparent bg-blue-600 text-white hover:bg-blue-700'
                  )}
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default EditCustomRiskModal
