import { Dialog, Transition } from '@headlessui/react'
import React, {
  Fragment,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '../../redux/api-slice'

interface UserFirstNameModalProps {
  open: boolean
}

const UserFirstNameModal: React.FC<UserFirstNameModalProps> = ({ open }) => {
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const { data: profile } = useGetUserProfileQuery(undefined)

  useEffect(() => {
    if (!profile) {
      return
    }
    if (profile.first_name) {
      setFirstName(profile.first_name)
    }
    if (profile.last_name) {
      setLastName(profile.last_name)
    }
  }, [profile])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const onSetFirstName = useCallback((e) => {
    setFirstName(e.target.value)
  }, [])

  const onSetLastName = useCallback((e) => {
    setLastName(e.target.value)
  }, [])

  const onAccept = useCallback(() => {
    updateUserProfile({
      agreed_tandcs: true,
      first_name: firstName,
      last_name: lastName,
    })
    setIsOpen(false)
  }, [firstName, lastName, updateUserProfile])

  const onKeyDownName = useCallback(
    (e) => {
      if (e.key === 'Enter' && firstName && lastName) {
        onAccept()
      }
    },
    [firstName, lastName, onAccept]
  )

  const provisionImage = useMemo(() => {
    return (
      <div className="flex items-center justify-center text-lg text-white">
        <img
          className="flex-1 rounded-lg text-center"
          src="/favicons/android-chrome-192x192.png"
          alt="Provision Logo"
        />
      </div>
    )
  }, [])

  const firstNameContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white p-2 text-left shadow-xl transition-all">
        <div className="flex h-full items-center space-x-4">
          {provisionImage}
          <div className="flex h-48 flex-col justify-between space-y-2 text-sm">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col">
                <div className="text-md font-bold">Welcome to Provision!</div>
                <div>
                  To personalize your experience, please provide us with your
                  name.
                </div>
              </div>
              <input
                type="text"
                name="first_name"
                id="first_name"
                onKeyDown={onKeyDownName}
                onChange={onSetFirstName}
                value={firstName}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Your first name here"
              />
              <input
                type="text"
                name="last_name"
                id="last_name"
                onKeyDown={onKeyDownName}
                onChange={onSetLastName}
                value={lastName}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Your last name here"
              />
            </div>
            <div className={'flex w-full justify-end p-2'}>
              <button
                type="button"
                disabled={!firstName || !lastName}
                className={`inline-flex w-28 justify-center self-end rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:col-start-2 ${
                  !firstName || !lastName
                    ? 'bg-indigo-300 focus-visible:outline-indigo-300'
                    : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                }}`}
                onClick={onAccept}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [
    firstName,
    lastName,
    onAccept,
    onKeyDownName,
    onSetFirstName,
    onSetLastName,
    provisionImage,
  ])

  const onClose = useCallback(() => {
    //intentionally left blank
  }, [])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} as="div" className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {firstNameContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UserFirstNameModal
