import React from 'react'
import { useDroppable } from '@dnd-kit/core'

interface DroppableDocumentProps {
  id: string
  children: React.ReactNode
}

const DroppableDocument: React.FC<DroppableDocumentProps> = ({
  id,
  children,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: id,
  })

  return (
    <div
      className={`grow overflow-hidden ${isOver && 'bg-indigo-100'}`}
      ref={setNodeRef}
    >
      {children}
    </div>
  )
}

export default DroppableDocument
