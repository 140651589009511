import React, { useCallback } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { ChatHistory } from '../../../shared/interfaces/chat/chat-history.interface'
import { useDeleteChatHistoryMutation } from '../../../redux/api/chat-api-slice'
import {
  selectCurrentProject,
  setModal,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES } from '../../modals/modal-controller'

interface AIChatPopoverProps {
  chatHistory: ChatHistory
}

const AIChatPopover: React.FC<AIChatPopoverProps> = ({ chatHistory }) => {
  const currentProject = useSelector(selectCurrentProject)
  const [buttonRef, setButtonRef] = React.useState<HTMLButtonElement | null>(
    null
  )
  const [panelRef, setPanelRef] = React.useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const [deleteChatHistory] = useDeleteChatHistoryMutation()
  const dispatch = useDispatch()

  const onDelete = useCallback(async () => {
    if (!chatHistory || !chatHistory.uuid || !currentProject?.uuid) {
      return
    }
    await deleteChatHistory({
      uuid: chatHistory.uuid,
      project_uuid: currentProject?.uuid,
    })
  }, [chatHistory, currentProject, deleteChatHistory])

  const onRename = useCallback(() => {
    dispatch(setModal({ chat: chatHistory, modal: MODAL_TYPES.RENAME_CHAT }))
  }, [dispatch, chatHistory])

  return (
    <Popover>
      <Popover.Button
        aria-label={`Chat menu ${chatHistory?.session_name}`}
        className={'rounded p-2 hover:bg-gray-200'}
        tabIndex={-1}
        ref={setButtonRef}
      >
        <EllipsisVerticalIcon width={18} />
      </Popover.Button>

      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="absolute z-50 flex flex-col rounded border bg-white text-sm text-gray-900 shadow"
      >
        <button
          className={'px-4 py-2 text-left hover:bg-gray-100'}
          onClick={onRename}
        >
          Rename
        </button>
        <button
          className={'px-4 py-2 text-left hover:bg-gray-100'}
          onClick={onDelete}
        >
          Delete
        </button>
      </Popover.Panel>
    </Popover>
  )
}

export default AIChatPopover
