import React, {
  useContext,
  useEffect,
  useState,
  createRef,
  useCallback,
} from 'react'
import {
  selectCurrentPage,
  setTotalPages,
} from '../redux/secondary-viewer-slice'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentDocument } from '../redux/application-slice'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import { DocumentViewerCommentContext } from '../contexts/document-viewer-comment-instance-context'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'

interface PageCountSecondaryProps {
  document: ProjectDocumentMetadata | null
  totalPages: number
  originalRevisionPage: number
  revision: Revision
}

const PageCountSecondary: React.FC<PageCountSecondaryProps> = ({
  document,
  totalPages,
  originalRevisionPage,
  revision,
}) => {
  const documentViewerContext = useContext(DocumentViewerCommentContext)
  const { documentViewer } = documentViewerContext
  const currentDocument = useSelector(selectCurrentDocument)
  const currentPage = useSelector(selectCurrentPage)
  const dispatch = useDispatch()

  const [page, setPage] = useState(currentPage)
  const ref = createRef<HTMLInputElement>()

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    dispatch(setTotalPages(1))
  }, [currentDocument, dispatch])

  const onPageChange = (event) => {
    setPage(event.target.value)
  }

  const onPressEnter = (event) => {
    if (event.key === 'Enter') {
      documentViewer?.setCurrentPage(parseInt(page), false)
      ref?.current?.blur()
    }
  }

  const onNavigationClick = useCallback(() => {
    documentViewer?.setCurrentPage(originalRevisionPage, false)
  }, [documentViewer, originalRevisionPage])

  return (
    <div
      className={
        'relative flex items-center gap-2 rounded bg-gray-100 px-2 py-1 shadow-sm'
      }
    >
      <div>
        <input
          disabled={!document?.id}
          ref={ref}
          max={totalPages}
          min={1}
          onChange={onPageChange}
          onKeyUp={onPressEnter}
          className={
            'w-10 rounded border-0 bg-gray-200 p-0 text-center opacity-75 focus:bg-white'
          }
          type="text"
          value={page}
        />
      </div>
      <div>/ {totalPages}</div>
      {currentPage !== originalRevisionPage &&
      currentPage !== revision?.page ? (
        <button
          className={
            'flex cursor-pointer gap-2 rounded bg-gray-100 p-1 text-sm text-gray-800 hover:bg-gray-200'
          }
          onClick={onNavigationClick}
        >
          <ArrowUturnLeftIcon width={18} />
          Page{' '}
          {revision?.v2_text === null ? originalRevisionPage : revision?.page}
        </button>
      ) : null}
    </div>
  )
}

export default PageCountSecondary
