import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDocumentViewerSidebarOpen,
  setDocumentViewerSidebarOpen,
} from '../../../redux/viewer-slice'
import { useParams } from 'react-router-dom'
import DocumentViewerPopover from '../document-viewer-popover'
import {
  selectApplicationSidebarOpen,
  selectCurrentDocument,
  selectPreviousTabWidth,
  setApplicationSidebarOpen,
} from '../../../redux/application-slice'
import { useTabWidth } from '../../../hooks/use-tab-width'
import useWindowDimensions from '../../../hooks/use-window-dimensions'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  BookOpenIcon,
  MagnifyingGlassIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import DocumentViewerViewOptions from './document-viewer-view-options'
import clsx from 'clsx'
import { Tooltip } from '@mantine/core'

const DocumentViewerHeaderMisc: React.FC = () => {
  const dispatch = useDispatch()
  const previousTabWidth = useSelector(selectPreviousTabWidth)
  const currentDocument = useSelector(selectCurrentDocument)
  const applicationSidebarOpen = useSelector(selectApplicationSidebarOpen)
  const { tabWidth, setSidebarTabWidth } = useTabWidth()
  const { width } = useWindowDimensions()
  const { documentId } = useParams<{
    documentId: string
    projectId: string
  }>()
  const documentViewerSidebarOpen = useSelector(selectDocumentViewerSidebarOpen)
  const onClickSearch = useCallback(() => {
    dispatch(
      setDocumentViewerSidebarOpen(
        documentViewerSidebarOpen === 'search' ? null : 'search'
      )
    )
  }, [dispatch, documentViewerSidebarOpen])

  const onClickToc = useCallback(() => {
    dispatch(
      setDocumentViewerSidebarOpen(
        documentViewerSidebarOpen === 'toc' ? null : 'toc'
      )
    )
  }, [dispatch, documentViewerSidebarOpen])

  const onClickDefinitions = useCallback(() => {
    dispatch(
      setDocumentViewerSidebarOpen(
        documentViewerSidebarOpen === 'definitions' ? null : 'definitions'
      )
    )
  }, [dispatch, documentViewerSidebarOpen])

  const onRevertSidebarWidth = useCallback(() => {
    dispatch(setApplicationSidebarOpen(null))
    setSidebarTabWidth(previousTabWidth)
  }, [dispatch, previousTabWidth, setSidebarTabWidth])

  const onCtrlF = useCallback(
    (e) => {
      e.preventDefault()
      onClickSearch()
    },
    [onClickSearch]
  )

  useHotkeys('ctrl+f', onCtrlF)
  useHotkeys('meta+f', onCtrlF)

  return (
    <div className="mr-4 flex items-center justify-end space-x-1 text-sm">
      <DocumentViewerViewOptions />

      <button
        disabled={!documentId}
        onClick={onClickToc}
        className={`group w-40 rounded-lg border-2 border-[#ffffff00] px-1 py-1.5 transition-colors ${
          documentId
            ? 'hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]'
            : ''
        } ${documentViewerSidebarOpen === 'toc' ? 'bg-gray-200' : ''}`}
      >
        <div className="flex items-center justify-center text-gray-500 group-hover:text-black">
          <TableCellsIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
          Table of Contents
        </div>
      </button>
      <button
        disabled={!documentId}
        onClick={onClickDefinitions}
        className={clsx(
          'group w-28 rounded-lg border-2 border-[#ffffff00] px-1 py-1.5 transition-colors',
          documentId &&
            'hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]',
          documentViewerSidebarOpen === 'definitions' && 'bg-gray-200'
        )}
      >
        <div className="flex items-center justify-center text-gray-500 group-hover:text-black">
          <BookOpenIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
          Definitions
        </div>
      </button>

      <Tooltip label="Search within the Document" position="bottom">
        <button
          disabled={!documentId}
          onClick={onClickSearch}
          className={`group w-24 cursor-pointer rounded-lg border-2 border-[#ffffff00] px-1 py-1.5 transition-colors  ${
            documentId
              ? 'hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]'
              : ''
          } ${documentViewerSidebarOpen === 'search' ? 'bg-gray-200' : ''}`}
        >
          <div className="flex items-center justify-center text-gray-500 group-hover:text-black">
            <MagnifyingGlassIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
            Search
          </div>
        </button>
      </Tooltip>

      {currentDocument?.v1_document ? (
        <DocumentViewerPopover document={currentDocument} />
      ) : null}
      {(tabWidth >= width - 20 || applicationSidebarOpen === 'left') && (
        <button
          onClick={onRevertSidebarWidth}
          className="flex h-7 items-center justify-center space-x-0.5 rounded p-1 hover:bg-gray-300"
        >
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="7"
              height="12"
              rx="0.5"
              strokeWidth="1.3"
              className="fill-gray-50 hover:fill-gray-300"
              stroke="black"
            />
          </svg>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'rotate-180 transform'}
          >
            <path
              d="M4.46875 2.4375L8.53125 6.5L4.46875 10.5625"
              stroke="black"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </div>
  )
}
export default DocumentViewerHeaderMisc
