import React, { useCallback, useState } from 'react'
import SupplementaryConditionsQAPage from '../../supplementary-conditions-qa/supplementary-conditions-qa-page'
import RiskReviewQA from './risk-review-q-a'
import AddendaQAPage from '../../addenda-qa/addenda-qa-page'

interface QualityAssuranceWorkflowProps {}

const QualityAssuranceWorkflow: React.FC<
  QualityAssuranceWorkflowProps
> = () => {
  const [currentTab, setCurrentTab] = useState<string>('')

  const onSCReviewTab = useCallback(() => {
    setCurrentTab('sc-review')
  }, [])

  const onAddendaTab = useCallback(() => {
    setCurrentTab('addenda')
  }, [])

  const onRiskReviewTab = useCallback(() => {
    setCurrentTab('risk-review')
  }, [])

  return (
    <div className={'flex flex-col overflow-hidden'}>
      <div className={'flex border-b'}>
        <div
          className={`cursor-pointer rounded-t border  px-3 py-2 hover:bg-gray-200 ${
            currentTab === 'sc-review' && 'bg-gray-200'
          }`}
          onClick={onSCReviewTab}
        >
          SC Review
        </div>
        <div
          className={`cursor-pointer rounded-t border  px-3 py-2 hover:bg-gray-200 ${
            currentTab === 'addenda' && 'bg-gray-200'
          }`}
          onClick={onAddendaTab}
        >
          Addenda
        </div>
        <div
          className={`cursor-pointer rounded-t border px-3 py-2 hover:bg-gray-200 ${
            currentTab === 'risk-review' && 'bg-gray-200'
          }`}
          onClick={onRiskReviewTab}
        >
          Risk Review
        </div>
      </div>
      <div className={'flex overflow-hidden'}>
        {currentTab === 'sc-review' && <SupplementaryConditionsQAPage />}
        {currentTab === 'addenda' && <AddendaQAPage />}
        {currentTab === 'risk-review' && <RiskReviewQA />}
      </div>
    </div>
  )
}

export default QualityAssuranceWorkflow
