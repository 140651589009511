import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useGetDocumentStatusByProjectQuery } from '../../redux/api-slice'
import {
  selectCurrentCommentDocuments,
  selectCurrentProject,
} from '../../redux/application-slice'
import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'
import LoadingCircle from '../loading/loading-circle'
import { useNavigate } from 'react-router-dom'
import {
  GetRevision,
  useGetRevisionsQuery,
} from '../../redux/api/api-revisions-slice'

interface DuplicatorStatusProps {
  revisionParams: GetRevision | SkipToken
}

const DuplicatorStatus: React.FC<DuplicatorStatusProps> = ({
  revisionParams,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const { data: revisionsData } = useGetRevisionsQuery(
    (revisionParams as GetRevision)?.documentIds?.length ||
      (revisionParams as GetRevision)?.projectId
      ? revisionParams
      : skipToken
  )
  const navigate = useNavigate()
  const currentCommentDocuments = useSelector(selectCurrentCommentDocuments)
  const { currentData: documentStatuses } = useGetDocumentStatusByProjectQuery(
    currentProject?.id ? currentProject?.id : skipToken,
    {
      pollingInterval: 5000,
    }
  )
  const documentStatusDetails = useMemo(() => {
    return documentStatuses?.map((ds) => ds.document_statuses) ?? []
  }, [documentStatuses])
  const numDuplicatorProcessing = useMemo(() => {
    if (!documentStatuses) {
      return 0
    }
    return documentStatusDetails.filter(
      (dj) => dj?.is_duplicator_complete === false
    ).length
  }, [documentStatusDetails, documentStatuses])

  const requiresReconciliation = useMemo(() => {
    if (!revisionsData) {
      return false
    }
    return (
      revisionsData.some(
        (r) => r.v1_revision && r.revision_status === 'NEEDS_RECON'
      ) || numDuplicatorProcessing
    )
  }, [revisionsData, numDuplicatorProcessing])

  const onClickReconcileComments = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    navigate(
      `/${currentProject?.uuid}/commentviewer${
        currentCommentDocuments?.length === 1
          ? `?documentId=${currentCommentDocuments[0]?.uuid}`
          : ''
      }`
    )
  }, [currentProject?.uuid, navigate, currentCommentDocuments])

  return (
    <>
      {requiresReconciliation ? (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-2">
          <div className="ml-3 flex-1 md:flex md:items-center md:justify-between">
            <div className="text-sm text-yellow-700">
              {!numDuplicatorProcessing ? (
                'Some comments require reconciliation. Please review them now.'
              ) : (
                <div className="flex items-center space-x-2">
                  <LoadingCircle
                    isSpinning
                    className="h-4 w-4 animate-spin fill-blue-600"
                  />
                  <div>
                    Comments are being processed. {numDuplicatorProcessing} /{' '}
                    {documentStatuses?.length ?? 0} documents remaining.
                  </div>
                </div>
              )}
            </div>
            {!numDuplicatorProcessing ? (
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <button
                  onClick={onClickReconcileComments}
                  className="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600"
                >
                  Reconcile comments
                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}
export default DuplicatorStatus
