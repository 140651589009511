import { Popover } from '@headlessui/react'
import React, { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskOpen, setTaskOpen } from '../../redux/viewer-slice'
import { DocumentSegment } from '../../shared/interfaces/project/document/segments/document-segment.interface'
import TextareaAutosize from 'react-textarea-autosize'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  GetDocumentSegments,
  useCreateTaskFromSegmentsMutation,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { selectCurrentProject } from '../../redux/application-slice'
import { Task } from '../../shared/interfaces/task/task.interface'
import {
  selectAssignedUsers,
  selectFilterLabelNames,
  selectSearchQuery,
} from '../../redux/search-slice'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { CustomLabel } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { ProjectPermissionUser } from '../../shared/interfaces/project/permissions/project-permissions.interface'
import AssignedUserCombobox from '../tasks/assigned-user-combobox'

interface TaskPopoverProps {
  referenceElement: HTMLElement | null
  documentSegment?: DocumentSegment
  page: number
  selectedDocuments?: ProjectDocumentMetadata[] | null
}

const TaskPopover: React.FC<TaskPopoverProps> = ({
  referenceElement,
  documentSegment,
  page,
  selectedDocuments,
}) => {
  const filterLabelNames = useSelector(selectFilterLabelNames) as CustomLabel[]
  const searchQuery = useSelector(selectSearchQuery)
  const currentProject = useSelector(selectCurrentProject)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, panelRef)
  const taskOpen = useSelector(selectTaskOpen)
  const [taskText, setTaskText] = useState<string>('')
  const dispatch = useDispatch()
  const [assignedUsers, setAssignedUsers] = useState<ProjectPermissionUser[]>(
    []
  )
  const [createTaskFromMutation] = useCreateTaskFromSegmentsMutation()
  const { data: user } = useGetUserProfileQuery(undefined)
  const filteredUsers = useSelector(selectAssignedUsers)

  const onClose = useCallback(() => {
    dispatch(setTaskOpen(null))
  }, [dispatch])

  const onSaveTask = useCallback(async () => {
    const documentQueryParams: GetDocumentSegments = {
      projectId: selectedDocuments?.length ? undefined : currentProject?.uuid,
      documentIds: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      query: searchQuery,
      labels: filterLabelNames?.map((f) => f.id),
      page: filteredUsers?.[0] || filterLabelNames?.length > 0 ? 1 : page,
      assigned_user: filteredUsers?.[0] ? filteredUsers[0].id : undefined,
    }
    const task: Task = {
      description: taskText,
      assigned_users: assignedUsers,
      segment: documentSegment?.id,
      getDocumentSegments: documentQueryParams,
      project: currentProject?.id,
      status: 'NOT_STARTED',
      user_created: user,
    }
    createTaskFromMutation(task)
    onClose()
  }, [
    selectedDocuments,
    currentProject?.uuid,
    currentProject?.id,
    searchQuery,
    filterLabelNames,
    page,
    filteredUsers,
    taskText,
    assignedUsers,
    documentSegment?.id,
    user,
    createTaskFromMutation,
    onClose,
  ])

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Escape') {
        onClose()
      } else if (e.key === 'Enter' && !e.shiftKey) {
        onSaveTask()
      }
    },
    [onClose, onSaveTask]
  )

  const onChangeTaskText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTaskText(e.currentTarget.value)
    },
    []
  )

  useHotkeys('esc', onClose, [onClose])

  return (
    <Popover id="task-popover" className="relative">
      {taskOpen && (
        <Popover.Panel
          static
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 rounded border bg-white text-gray-700 shadow"
        >
          <div
            style={{
              minWidth: '20rem',
            }}
            className={'h-full w-full min-w-fit'}
          >
            <div className="w-full p-3">
              <TextareaAutosize
                className={
                  'sm:leading-6W block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                }
                name="task_description"
                minRows={4}
                autoFocus
                placeholder="Enter task description"
                value={taskText}
                onChange={onChangeTaskText}
                onKeyDown={onKeyDown}
              />
              <AssignedUserCombobox
                onChangeUser={(user) => setAssignedUsers([user])}
              />
              <div className="mt-1 flex justify-end space-x-1">
                <button
                  onClick={onClose}
                  className={`inline-flex items-center rounded-md border bg-white px-3 py-1 text-xs font-medium text-gray-600 hover:bg-gray-100`}
                >
                  Cancel
                </button>
                <button
                  onClick={onSaveTask}
                  disabled={!taskText || !assignedUsers.length}
                  className={`inline-flex items-center rounded-md border px-3 py-1 text-xs font-medium text-gray-600
                  ${
                    !taskText || !assignedUsers.length
                      ? 'bg-gray-200'
                      : 'bg-white hover:bg-gray-100'
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Popover.Panel>
      )}
    </Popover>
  )
}
export default TaskPopover
