import React, { useMemo } from 'react'
import { selectTextSelected } from '../../../redux/viewer-slice'
import { useSelector } from 'react-redux'
import EditorContextMenu from '../../qa-editor/editor-context-menu'
import { useGetUserProfileQuery } from '../../../redux/api-slice'
import { useLocation } from 'react-router-dom'
import { useCoords } from '../../../hooks/use-coords'
import ContextMenu from '../../overlay/context-menu'
const DocumentViewerContextMenu: React.FC = () => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const { getCoordsFromPageSection, getTextSelectedTopOffset } = useCoords()
  const { pathname } = useLocation()
  const editorMode = useMemo(() => {
    return (
      pathname.includes('quality-assurance') && user?.feature_flags?.editor_mode
    )
  }, [user, pathname])
  const textSelected = useSelector(selectTextSelected)

  return (
    textSelected &&
    textSelected?.quads?.[textSelected.pageNumber] &&
    (editorMode ? (
      <EditorContextMenu
        positionY={`${getTextSelectedTopOffset(textSelected, 'document-viewer')}px`}
        positionX={`${
          getCoordsFromPageSection(
            textSelected.quads[textSelected.pageNumber][
              textSelected?.quads[textSelected.pageNumber].length - 1
            ],
            textSelected.pageNumber,
            'document-viewer',
            false
          ).leftOffset
        }px`}
      />
    ) : (
      <ContextMenu
        positionY={`${getTextSelectedTopOffset(textSelected, 'document-viewer')}px`}
        positionX={`${
          getCoordsFromPageSection(
            textSelected?.quads[textSelected.pageNumber][
              textSelected?.quads[textSelected.pageNumber].length - 1
            ],
            textSelected.pageNumber,
            'document-viewer',
            false
          ).leftOffset
        }px`}
      />
    ))
  )
}
export default DocumentViewerContextMenu
