import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Workflow } from '../components/workflows/workflow-popover/workflow-popover'
import { useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../redux/application-slice'

export const useNavigateWorkflow = () => {
  const navigate = useNavigate()
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const navigateWorkflow = useCallback(
    (workflow: Workflow) => {
      navigate(
        `/${currentProject?.uuid}/${workflow.route}${
          currentDocument ? `/${currentDocument.uuid}` : ''
        }`
      )
    },
    [currentProject, currentDocument, navigate]
  )
  return { navigateWorkflow }
}
