import React, { useCallback, useMemo } from 'react'
import { Popover } from '@headlessui/react'
import { BoltIcon } from '@heroicons/react/24/outline'
import { BoltIcon as BoltIconFilled } from '@heroicons/react/24/solid'
import {
  useGetUserPermissionsQuery,
  useGetUserProfileQuery,
} from '../../../redux/api-slice'
import { useGetRiskListsQuery } from '../../../redux/api/project-risk-api-slice'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
  setSharedFromChatRiskQueryText,
} from '../../../redux/application-slice'
import { ChatSessionMessage } from '../../../shared/interfaces/chat/chat-history.interface'
import { Tooltip } from '@mantine/core'

interface AiChatQuestionPopoverProps {
  source: string
  chatHistoryMessage: ChatSessionMessage
}

const AiChatQuestionPopover: React.FC<AiChatQuestionPopoverProps> = ({
  source,
  chatHistoryMessage,
}) => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const custom_risk_lists_flag = user?.feature_flags?.custom_risk_lists
  const { data: userPermissions } = useGetUserPermissionsQuery()
  const { data: riskLists } = useGetRiskListsQuery()
  const filteredRiskLists = riskLists?.filter(
    (riskList) =>
      riskList.user_permissions.can_view && riskList.user_permissions.can_change
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentDocument = useSelector(selectCurrentDocument)
  const currentProject = useSelector(selectCurrentProject)
  const hasCustomRiskListPermission = useMemo(
    () =>
      userPermissions?.includes('api.create_customrisklisttemplate') ?? false,
    [userPermissions]
  )

  const onRiskListClick = useCallback(() => {
    const sharedFromChatRiskQueryText = chatHistoryMessage.message
    dispatch(setSharedFromChatRiskQueryText(sharedFromChatRiskQueryText))
  }, [dispatch, chatHistoryMessage.message])

  const handleRiskListSelect = (riskListId: number) => {
    const newPath = `/${currentProject?.uuid}/riskreview/${currentDocument?.uuid}/customrisklists/${riskListId}`
    onRiskListClick()
    navigate(newPath)
  }

  const isCustomRiskListEnabled =
    hasCustomRiskListPermission && (filteredRiskLists?.length ?? 0) > 0

  if (source !== 'user' || !custom_risk_lists_flag) {
    return null
  }

  return (
    <div className="absolute right-0 top-0 p-1.5">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className="absolute -right-8 top-0 z-10"
              aria-label="Chat options"
            >
              <Tooltip position="left" label="Add to Custom Risk List">
                <BoltIconFilled className="h-5 w-5 text-yellow-500 opacity-80 hover:text-amber-500 hover:opacity-100" />
              </Tooltip>
            </Popover.Button>

            <Popover.Panel className="absolute right-0 z-20 mt-2 w-64 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <Popover.Group>
                  <Popover>
                    <Popover.Button
                      className={`w-full px-4 py-2 text-left text-xs ${
                        isCustomRiskListEnabled
                          ? 'hover:bg-gray-100'
                          : 'cursor-not-allowed text-gray-400'
                      }`}
                      disabled={!isCustomRiskListEnabled}
                    >
                      <div className="text-md flex items-center">
                        <BoltIcon className="mr-2 h-4 w-4" />
                        Click to show existing lists
                      </div>
                    </Popover.Button>
                    <Popover.Panel className="pl-4">
                      {filteredRiskLists?.map((riskList) => (
                        <button
                          key={riskList.id}
                          onClick={() => handleRiskListSelect(riskList.id)}
                          className="block w-full px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          {riskList.name}
                        </button>
                      ))}
                    </Popover.Panel>
                  </Popover>
                </Popover.Group>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

export default AiChatQuestionPopover
