import React from 'react'
import PageOverlaySimple from './page-overlay-simple'

const PageOverlaySimpleController = (props) => {
  return props.pages.map((page) => (
    <PageOverlaySimple
      key={page}
      revisionSelected={props.revisionSelected}
      documentChangeSelected={props.documentChangeSelected}
      page={page}
      isSecond={props.isSecond}
    />
  ))
}

export default PageOverlaySimpleController
