import React from 'react'
import DiffMatchPatch from 'diff-match-patch'
import parse from 'html-react-parser'

interface DiffProps {
  inputA: string
  inputB: string
}

const DIFF_DELETE = -1
const DIFF_INSERT = 1
const DIFF_EQUAL = 0

function formatDiffHtml(diffs) {
  const html: string[] = []
  const pattern_amp = /&/g
  const pattern_lt = /</g
  const pattern_gt = />/g
  const pattern_para = /\n/g
  for (const [op, data] of diffs) {
    const text = data
      .replace(pattern_amp, '&amp;')
      .replace(pattern_lt, '&lt;')
      .replace(pattern_gt, '&gt;')
      .replace(pattern_para, '&para;<br>')
    switch (op) {
      case DIFF_INSERT:
        html.push(
          '<span class="border-b-[3px] border-blue-400 border-double text-blue-400">' +
            text +
            '</span>'
        )
        break
      case DIFF_DELETE:
        html.push('<span class="text-red-500 line-through">' + text + '</span>')
        break
      case DIFF_EQUAL:
        html.push('<span>' + text + '</span>')
        break
    }
  }
  return html.join('')
}

const Diff: React.FC<DiffProps> = ({ inputA, inputB }) => {
  const getDiff = (original, revision) => {
    const dmp = new DiffMatchPatch()
    const diff = dmp.diff_main(original, revision)
    dmp.diff_cleanupSemantic(diff)
    return formatDiffHtml(diff)
  }

  return (
    <div className="revisiondiff z-50 mb-1">
      {parse(getDiff(inputA, inputB))}
    </div>
  )
}

export default Diff
