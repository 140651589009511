import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectModal, setModal } from '../../redux/application-slice'
import { Project } from '../../shared/interfaces/project/project.interface'
import CommandBarCombobox from './command-bar-combobox'
import { useHotkeys } from 'react-hotkeys-hook'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { MODAL_TYPES } from '../modals/modal-controller'

interface CommandBarPopupProps {
  open: boolean
}

const CommandBarPopup: React.FC<CommandBarPopupProps> = ({ open }) => {
  const [rawQuery, setRawQuery] = useState('')
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const dispatch = useDispatch()
  const onAfterLeave = useCallback(() => {
    setRawQuery('')
  }, [])
  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setTimeout(() => {
      setRawQuery('')
      setSelectedProject(null)
    }, 300)
  }, [dispatch])
  const posthog = usePostHog()
  const modal = useSelector(selectModal)

  useHotkeys(
    'ctrl+k',
    () => {
      posthog?.capture(POSTHOG.command_bar_opened)
      if (modal?.modal !== MODAL_TYPES.COMMAND_BAR) {
        dispatch(setModal({ modal: MODAL_TYPES.COMMAND_BAR }))
      } else {
        dispatch(setModal(null))
      }
    },
    {
      preventDefault: true,
    }
  )

  useHotkeys(
    'meta+k',
    () => {
      posthog?.capture(POSTHOG.command_bar_opened)
      if (modal?.modal !== MODAL_TYPES.COMMAND_BAR) {
        dispatch(setModal({ modal: MODAL_TYPES.COMMAND_BAR }))
      } else {
        dispatch(setModal(null))
      }
    },
    {
      preventDefault: true,
    }
  )

  const dialogPanel = useMemo(() => {
    return (
      <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
        <CommandBarCombobox
          open={open}
          rawQuery={rawQuery}
          setRawQuery={setRawQuery}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      </Dialog.Panel>
    )
  }, [rawQuery, selectedProject, open])

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={onAfterLeave} appear>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {dialogPanel}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CommandBarPopup
