import React, { useRef, useState } from 'react'
import PageOverlayController from '../page-overlay-controller'
import 'react-tooltip/dist/react-tooltip.css'
import { useSelector } from 'react-redux'
import { selectCurrentDocument } from '../../redux/application-slice'
import {
  selectCurrentPage,
  selectDocumentLoaded,
  selectNewRevision,
  selectShowCustomLabels,
} from '../../redux/viewer-slice'
import ViewerShortcuts from '../viewer/viewer-shortcuts'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import ReactDOM from 'react-dom'
import DocumentViewerSidelingContainer from './document-viewer-sideling-container'
import BlacklinePopover from '../overlay/blackline-popover'
import OverlayContextMenu from '../overlay/overlay-context-menu'
import { OverlayRefClicked } from '../page-overlay/page-overlay'
import DocumentViewerOptionsHeader from './components/document-viewer-header-options'
import DocumentViewerHeaderMisc from './components/document-viewer-header-misc'
import DocumentViewerIcons from './components/document-viewer-icons'
import DocumentViewerContextMenu from './components/document-viewer-context-menu'
import { useDocumentViewer } from '../../hooks/use-document-viewer'

// TODO nikhi broke the Skeleton Loading state view
// import DocumentViewerSkeleton from './document-viewer-skeleton'

function DocumentViewer() {
  const scrollView = useRef<HTMLDivElement | null>(null)
  const isDocumentLoaded = useSelector(selectDocumentLoaded)
  const viewer = useRef<HTMLDivElement | null>(null)
  const {
    sidelingLeftOffset,
    onSelectBlackline,
    selectedBlacklineElement,
    renderedPages,
    lastClickedRef,
  } = useDocumentViewer(viewer, scrollView)
  const [overlaysClicked, setOverlaysClicked] =
    useState<OverlayRefClicked | null>(null)

  const currentPage = useSelector(selectCurrentPage)
  const currentDocument = useSelector(selectCurrentDocument)
  const showCustomLabels = useSelector(selectShowCustomLabels)
  const newRevision = useSelector(selectNewRevision) as Revision | null

  const webviewer = window?.document?.getElementById('virtualListContainer')

  return (
    <div
      className="flex h-full items-stretch overflow-hidden"
      style={{ maxWidth: '100vw' }}
    >
      <div className={'bg-white-100 flex min-w-0 flex-1 flex-grow flex-col'}>
        <div
          className={
            'flex h-12 flex-shrink-0 items-center justify-between border-b border-gray-300 bg-[#edf0f5] px-4 pr-1'
          }
        >
          <DocumentViewerHeaderMisc />
          <DocumentViewerOptionsHeader />
        </div>
        <div
          id="document-viewer"
          className={'flex h-full overflow-auto bg-gray-200'}
          ref={scrollView}
        >
          <div
            className={`webviewer z-10 mx-auto ${!isDocumentLoaded ? 'hidden' : ''}`}
            id="webviewer-wrapper"
            ref={viewer}
          />
        </div>
      </div>
      {/* VIEW/EDIT REVISION SIDELING */}
      {webviewer &&
        ReactDOM.createPortal(
          <>
            <div
              id={'revisions_container'}
              className={'absolute z-[31] h-96'}
              style={{
                top: 0,
                left: sidelingLeftOffset,
              }}
            >
              <DocumentViewerSidelingContainer
                currentPage={currentPage}
                newRevision={newRevision}
                scrollView={scrollView}
              />
            </div>
            {showCustomLabels && <DocumentViewerIcons />}
            {/* CONTEXT MENU FOR SELECTED TEXT */}
            <DocumentViewerContextMenu />
          </>,
          webviewer
        )}
      <PageOverlayController
        onSelectBlackline={onSelectBlackline}
        pages={renderedPages}
        setOverlaysClicked={setOverlaysClicked}
        overlaysClicked={overlaysClicked}
      />
      <ViewerShortcuts />
      <BlacklinePopover
        selectedDocument={currentDocument}
        referenceElement={selectedBlacklineElement}
      />
      {overlaysClicked ? (
        <OverlayContextMenu
          overlays={overlaysClicked.overlayRefs}
          referenceElement={lastClickedRef.current}
          setOverlaysClicked={setOverlaysClicked}
        />
      ) : null}
    </div>
  )
}

export default DocumentViewer
