import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
  SelectedSource,
  setSelectedSources,
} from '../../../../redux/viewer-slice'
import { InTextCitation } from '../../../../shared/interfaces/project/document/in-text-citation/in-text-citation.interface'
import { selectCurrentProject } from '../../../../redux/application-slice'

interface RiskReviewAnswerTextCitationProps {
  citation: InTextCitation
  index: number
  citations: InTextCitation[]
}

const RiskReviewAnswerTextCitation: React.FC<
  RiskReviewAnswerTextCitationProps
> = ({ citation, index, citations }) => {
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const onClickCitation = useCallback(
    (e: React.MouseEvent) => {
      if (!citation.document_segment.document) {
        return
      }

      e.stopPropagation()
      const pathToDocumentSegment = `/${currentProject?.uuid}/riskreview/${citation.document_segment.document}`
      navigate(pathToDocumentSegment, { replace: true })
      const page = citation.document_segment.page.toString()
      searchParams.set('page', page)
      setSearchParams(searchParams)
      const selectedSources: SelectedSource[] = []
      const distinctCitations = citations.reduce<InTextCitation[]>(
        (acc, citation) => {
          const document_segment_id = citation.document_segment.id
          if (!acc.find((c) => c.document_segment.id === document_segment_id)) {
            acc.push(citation)
          }
          return acc
        },
        []
      )
      for (const citationSource of distinctCitations) {
        const highlightID = uuidv4()
        const quads = citationSource.document_segment.quads ?? []
        selectedSources.push({
          id: highlightID,
          page: citationSource.document_segment.page,
          quads,
          isPrimary: citation.id === citationSource.id,
          documentUUID: citation.document_segment.document as string,
        })
      }
      dispatch(setSelectedSources(selectedSources))
    },
    [
      navigate,
      currentProject?.uuid,
      citation,
      searchParams,
      setSearchParams,
      dispatch,
      citations,
    ]
  )
  return (
    <button className="relative bottom-0.5 ml-1" onClick={onClickCitation}>
      <div className="flex h-4 w-4 items-center justify-center rounded-full bg-gray-200 px-1 text-center text-[0.6rem] text-black hover:bg-gray-500 hover:text-white">
        {index + 1}
      </div>
    </button>
  )
}

export default RiskReviewAnswerTextCitation
