import React, { useCallback } from 'react'
import { useGetCCDCVersionsQuery } from '../../redux/api/document-change-template-api-slice'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCCDCVersion, setCCDCVersion } from '../../redux/editor-slice'

const CCDCVersionDropdown: React.FC = () => {
  const { data: versions } = useGetCCDCVersionsQuery()
  const { data: user } = useGetUserProfileQuery(undefined)
  const ccdcVersion = useSelector(selectCCDCVersion)

  const dispatch = useDispatch()

  const onChangeVersion = useCallback(
    (e) => {
      dispatch(setCCDCVersion(e.target.value))
    },
    [dispatch]
  )

  return user?.feature_flags?.editor_mode === true ? (
    <select
      onChange={onChangeVersion}
      value={ccdcVersion ?? 'Select Version'}
      name="ccdc_versions"
      className="block rounded border-0 py-0.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      id="ccdc_versions"
    >
      <option>Select Version</option>
      {versions?.map((version) => (
        <option key={version} value={version}>
          {version}
        </option>
      ))}
    </select>
  ) : null
}

export default CCDCVersionDropdown
