import React, { Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface ModalProps {
  open: boolean
  onClose: () => void
  title: string
  body: ReactNode
  icon?: ReactNode
  confirmText: string
  cancelText: string
  onConfirm: () => void
}

const DeleteModal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  body,
  icon,
  confirmText,
  cancelText,
  onConfirm,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <ModalBackdrop />
        <ModalWrapper>
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <ModalBody title={title} body={body} icon={icon} />
            <ModalFooter
              onClose={onClose}
              onConfirm={onConfirm}
              confirmText={confirmText}
              cancelText={cancelText}
            />
          </Dialog.Panel>
        </ModalWrapper>
      </Dialog>
    </Transition.Root>
  )
}

const ModalBackdrop = () => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  </Transition.Child>
)

const ModalWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        {children}
      </Transition.Child>
    </div>
  </div>
)

const ModalBody: React.FC<{
  title: string
  body: ReactNode
  icon?: ReactNode
}> = ({ title, body, icon }) => (
  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
    <div className="sm:flex sm:items-start">
      {icon && (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          {icon}
        </div>
      )}
      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-900"
        >
          {title}
        </Dialog.Title>
        <div className="mt-2">{body}</div>
      </div>
    </div>
  </div>
)

const ModalFooter: React.FC<{
  onClose: () => void
  onConfirm: () => void
  confirmText: string
  cancelText: string
}> = ({ onClose, onConfirm, confirmText, cancelText }) => (
  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
    <button
      type="button"
      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
      onClick={onConfirm}
    >
      {confirmText}
    </button>
    <button
      type="button"
      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      onClick={onClose}
    >
      {cancelText}
    </button>
  </div>
)

export default DeleteModal
