import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'

const ErrorMessage = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center border p-3">
      <div>
        <ExclamationCircleIcon className="h-10 w-10 text-red-600" />
      </div>
      <p className="font-bold">Something went wrong</p>
      <p>Our support team has been notified</p>
    </div>
  )
}

export default ErrorMessage
