import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import PageCount from './page-count'
import 'react-tooltip/dist/react-tooltip.css'
import { useGetDocumentByUuidQuery } from '../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../redux/application-slice'
import {
  selectCursorTool,
  selectCustomLabelOpen,
  selectIconMenuOpen,
  selectNewRevision,
  selectOpenRevision,
  selectTextSelected,
  setCurrentPage,
  setIconMenuOpen,
  setNewRevision,
  setOpenRevision,
  setTextSelected,
  setTemporaryHighlight,
  setTotalPages,
  setZoomLevel,
  TemporaryHighlight,
} from '../redux/viewer-slice'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import debounce from 'lodash/debounce'
import { skipToken } from '@reduxjs/toolkit/query'
import NavigationHistoryPane from './viewer/navigation-history-pane'
import { POSTHOG } from '../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import PageOverlaySimpleController from './page-overlay-simple-controller'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'
import { useHotkeys } from 'react-hotkeys-hook'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'
import { Quads } from '../shared/interfaces/quads.interface'
import { isRatio } from '../utils/convert-quads'
import useWindowDimensions from '../hooks/use-window-dimensions'

interface DocumentViewerProps {
  selectedDocument: ProjectDocumentMetadata | null
  selectedSource: {
    source?: number
    source_document_uuid?: string
  } | null
  revisionSelected?: Revision | null
  documentChangeSelected?: DocumentChange | null
  overlay?: boolean
  tabWidth?: number
}

const DocumentViewerSimple: React.FC<DocumentViewerProps> = ({
  selectedDocument,
  selectedSource,
  revisionSelected,
  documentChangeSelected,
  overlay = true,
  tabWidth,
}) => {
  const [renderedPages, setRenderedPages] = useState([])

  const cursorTool = useSelector(selectCursorTool)

  const scrollView = useRef<HTMLDivElement | null>(null)
  const viewer = useRef<HTMLDivElement | null>(null)
  const renderedPagesRef = useRef<number[]>(renderedPages)
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const customLabelOpen = useSelector(selectCustomLabelOpen)
  const iconMenuOpen = useSelector(selectIconMenuOpen)
  const newRevision = useSelector(selectNewRevision)
  const openRevision = useSelector(selectOpenRevision)
  const customLabelRef = useRef({})
  const iconMenuOpenRef = useRef({})
  const openRevisionRef = useRef({})
  const textSelected = useSelector(selectTextSelected)
  const selectedSourceRef = useRef<{
    source?: number
    source_document_uuid?: string
    quads?: Quads[]
    answer_index?: number
  } | null>(null)
  const revisionSelectedRef = useRef<Revision | null>(null)

  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext

  const [searchParams] = useSearchParams()

  const { currentData: currentDocumentData } = useGetDocumentByUuidQuery(
    selectedDocument?.uuid ?? skipToken
  )

  useEffect(() => {
    const newLevel = documentViewer?.getZoomLevel() ?? 0
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer, width])

  const highlightSources = useCallback(
    (pageNumber?: number) => {
      setTimeout(() => {
        if (selectedSourceRef.current) {
          documentViewer?.setCurrentPage(
            selectedSourceRef.current?.source ??
              revisionSelectedRef.current?.page ??
              1,
            false
          )
          if (
            (!revisionSelectedRef.current ||
              pageNumber !== revisionSelectedRef.current?.page) &&
            selectedSourceRef.current &&
            pageNumber !== selectedSourceRef.current?.source
          ) {
            return
          }
          const highlights: TemporaryHighlight[] = []
          for (const segment of revisionSelectedRef.current?.segments ?? []) {
            highlights.push({
              page: segment.page,
              quads: segment.quads ?? [],
              id: `highlight_${revisionSelectedRef.current?.id}_${segment.id}`,
              pdfCoords: isRatio(segment.quads ?? []),
            })
          }
          dispatch(setTemporaryHighlight(highlights))
          setTimeout(() => {
            const el = window?.document?.getElementById('webviewer-wrapper')
              ?.parentNode as HTMLDivElement
            if (!el) {
              return
            }
            const highlightEl = document.getElementById(
              highlights?.[0]?.id ?? ''
            )
            if (!highlightEl) {
              return
            }
            highlightEl.scrollIntoView({
              block: 'start',
            })
            el.scrollTop -= 200
          }, 200)
        }
      }, 200)
    },
    [dispatch, documentViewer]
  )

  useEffect(() => {
    highlightSources(selectedSource?.source)
  }, [selectedDocument, selectedSource, highlightSources])

  useEffect(() => {
    if (documentChangeSelected) {
      documentViewer?.setCurrentPage(documentChangeSelected.source.page, false)
    }
  }, [documentChangeSelected, documentViewer])

  useEffect(() => {
    setTimeout(() => {
      if (openRevision || newRevision) {
        openRevisionRef.current = true
      } else {
        openRevisionRef.current = false
      }
    }, 50)
  }, [openRevision, newRevision])

  useEffect(() => {
    customLabelRef.current = customLabelOpen
  }, [customLabelOpen])

  useEffect(() => {
    revisionSelectedRef.current = revisionSelected ?? null
  }, [revisionSelected])

  useEffect(() => {
    selectedSourceRef.current = selectedSource
  }, [selectedSource])

  useEffect(() => {
    //very small timeout to support order of operations between click callback and risk menu flag being updated
    setTimeout(() => {
      iconMenuOpenRef.current = iconMenuOpen
    }, 50)
  }, [iconMenuOpen])

  const setRenderedPagesRef = (data) => {
    renderedPagesRef.current = data
    setRenderedPages(data)
  }

  useHotkeys('ctrl+c', async () => {
    if (!textSelected) {
      return
    }
    await navigator.clipboard.writeText(textSelected.text ?? '')
  })

  useHotkeys('meta+c', async () => {
    if (!textSelected) {
      return
    }
    await navigator.clipboard.writeText(textSelected.text ?? '')
  })

  const documentViewerHeader = useMemo(() => {
    return (
      <div className={'flex w-full items-center justify-center space-x-2'}>
        <PageCount document={selectedDocument ? selectedDocument : undefined} />
      </div>
    )
  }, [selectedDocument])

  useEffect(() => {
    const pageCompleteCallback = (pageNumber: number) => {
      if (!renderedPagesRef.current.includes(pageNumber)) {
        setRenderedPagesRef([...renderedPagesRef.current, pageNumber])
      }
    }
    const textSelectedCallback = debounce((quads, text, pageNumber) => {
      if (customLabelRef.current) {
        return
      }
      if (quads && quads.length > 0) {
        const textSelected = documentViewer?.getSelectedText()
        const quadsSelected = documentViewer?.getSelectedTextQuads()
        dispatch(
          setTextSelected({
            quads: quadsSelected,
            text: textSelected,
            pageNumber,
          })
        )
        posthog?.capture(POSTHOG.text_selected, {
          document_uuid: selectedDocument?.uuid,
        })
      } else {
        dispatch(setTextSelected(null))
      }
    }, 50)
    const pageNumberUpdatedCallback = (pageNumber) => {
      dispatch(setCurrentPage(pageNumber))
      debounce(() => {
        posthog?.capture(POSTHOG.page_changed, {
          page: pageNumber,
          document_uuid: selectedDocument?.uuid,
          project_uuid: currentProject?.uuid,
        })
      }, 1000)()
    }
    const documentClickCallback = (e: React.MouseEvent<HTMLElement>) => {
      if (openRevisionRef.current) {
        let trueCount = 0
        const revisionSidelings =
          document.querySelectorAll('.revision-sideling')
        for (const revisionSideling of revisionSidelings) {
          if (revisionSideling.contains(e.target as HTMLElement)) {
            trueCount += 1
            break
          }
        }
        if (trueCount === 0 && !openRevisionRef.current) {
          dispatch(setOpenRevision(null))
          dispatch(setNewRevision(null))
        }
      }
      if (iconMenuOpenRef.current !== null) {
        dispatch(setIconMenuOpen(null))
      }
    }

    const documentLoadedCallback = () => {
      if (!documentViewer) {
        return
      }
      const fitMode = 'FitMode'
      documentViewer?.setFitMode(documentViewer[fitMode].FitWidth)
      setRenderedPagesRef([])
      highlightSources(revisionSelectedRef.current?.page)
    }

    if (documentViewer) {
      if (!scrollView.current || !viewer.current) {
        return
      }
      // Attach viewer elements
      documentViewer?.setScrollViewElement(scrollView.current)
      documentViewer?.setViewerElement(viewer.current)

      dispatch(setZoomLevel(documentViewer?.getZoomLevel()))
      documentViewer?.enableAnnotations()

      // Set default tool
      documentViewer?.setToolMode(documentViewer?.getTool('TextSelect'))

      // Hook up event listeners
      documentViewer?.addEventListener('pageComplete', pageCompleteCallback)
      documentViewer?.addEventListener('textSelected', textSelectedCallback)
      documentViewer?.addEventListener(
        'pageNumberUpdated',
        pageNumberUpdatedCallback
      )
      documentViewer?.addEventListener('documentLoaded', documentLoadedCallback)
      documentViewer?.addEventListener('click', documentClickCallback)
    }
    return () => {
      if (documentViewer) {
        documentViewer?.removeEventListener(
          'textSelected',
          textSelectedCallback
        )
        documentViewer?.removeEventListener(
          'pageNumberUpdated',
          pageNumberUpdatedCallback
        )
        documentViewer?.removeEventListener(
          'pageComplete',
          pageCompleteCallback
        )
        documentViewer?.removeEventListener(
          'documentLoaded',
          documentLoadedCallback
        )
        documentViewer?.removeEventListener('click', documentClickCallback)
      }
    }
    // eslint-disable-next-line
  }, [documentViewer, setZoomLevel])

  useEffect(() => {
    if (currentDocumentData && documentViewer) {
      const fileUrl =
        currentDocumentData.optimized_file ?? currentDocumentData.file
      documentViewer
        .loadDocument(fileUrl, {
          licenseKey: atob(
            'UHJvdmlzaW9uIFNvZnR3YXJlIENvcnBvcmF0aW9uIChnZXRwcm92aXNpb24uY28pOk9FTTpQcm92aXNpb246OkIrOkFNUygyMDIzMDcxNyk6RkFBNTQ5MkQwNDg3ODgwQUYzNjBCMTNBQzlBMjczNzg2MDYxMkY4NUY3MTgxQUEwREQwNDdCOEFBRDRDNkU5MDNBOTQzMUY1Qzc='
          ),
        })
        .then(() => {
          const page = searchParams.get('page')
          if (page) {
            documentViewer?.setCurrentPage(parseInt(page), false)
          }
          dispatch(setZoomLevel(documentViewer?.getZoomLevel()))
          dispatch(setTotalPages(documentViewer?.getPageCount()))
        })
    }
    // eslint-disable-next-line
  }, [currentDocumentData, documentViewer])

  useEffect(() => {
    const page = searchParams.get('page')
    documentViewer?.setCurrentPage(parseInt(page ?? '1'), false)
  }, [searchParams, documentViewer])

  useEffect(() => {
    if (selectedDocument?.uuid) {
      posthog?.capture(POSTHOG.document_opened, {
        document_uuid: selectedDocument?.uuid,
      })
    }
    //   eslint-disable-next-line
  }, [selectedDocument?.uuid])

  useEffect(() => {
    if (cursorTool && documentViewer) {
      documentViewer?.setToolMode(documentViewer?.getTool(cursorTool))
    }
  }, [cursorTool, documentViewer])

  const onZoomIncrease = useCallback(() => {
    const newLevel = Math.min((documentViewer?.getZoomLevel() ?? 0) + 0.15, 2)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  const onZoomDecrease = useCallback(() => {
    const newLevel = Math.max((documentViewer?.getZoomLevel() ?? 0) - 0.15, 0.3)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  return (
    <div
      style={{
        width: tabWidth,
      }}
      className="flex h-full w-full items-stretch overflow-hidden"
    >
      <div className={'flex min-w-0 flex-1 flex-grow flex-col bg-gray-100'}>
        {overlay ? (
          <div
            className={
              'flex h-10 flex-shrink-0 items-center justify-center border-b border-gray-300  bg-gray-50 px-2'
            }
          >
            <div className={'flex items-center space-x-2'}>
              <div>
                <NavigationHistoryPane />
              </div>
              <button
                className={
                  'cursor-pointer rounded bg-gray-100 p-1.5 shadow-sm hover:bg-white'
                }
                onClick={onZoomIncrease}
              >
                <PlusIcon width={20} />
              </button>
              <button
                className={
                  'cursor-pointer rounded bg-gray-100 p-1.5 shadow-sm hover:bg-white'
                }
                onClick={onZoomDecrease}
              >
                <MinusIcon width={20} />
              </button>
              <PageCount />
            </div>
          </div>
        ) : (
          documentViewerHeader
        )}
        <div
          id="document-viewer"
          className={'flex-1 overflow-auto bg-gray-200'}
          ref={scrollView}
        >
          <div
            className="webviewer z-10 mx-auto"
            id="webviewer-wrapper"
            ref={viewer}
          />
        </div>
      </div>
      <PageOverlaySimpleController
        pages={renderedPages}
        revisionSelected={overlay ? revisionSelected : null}
        documentChangeSelected={documentChangeSelected}
      />
    </div>
  )
}

export default DocumentViewerSimple
