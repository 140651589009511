import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import { FilePond } from 'react-filepond'
import Uploader from './uploader'
import Dropzone from 'react-dropzone'
import { FilePondFile } from 'filepond'
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { DocumentArrowUpIcon, FolderIcon } from '@heroicons/react/24/solid'
import { useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { usePrompt } from '../../hooks/use-prompt'
import DuplicatorStatus from '../duplicator/duplicator-status'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject, setModal } from '../../redux/application-slice'
import DocumentManagerDragDrop from '../document-manager/document-manager-drag-drop'
import {
  useGetDocumentsByProjectQuery,
  useMoveItemsIntoFolderMutation,
  useUpdateDocumentSortMutation,
} from '../../redux/api-slice'
import { toast } from 'react-toastify'
import FolderHeader from './folder-header'
import { MODAL_TYPES } from '../modals/modal-controller'
import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import ProjectUploadStatus from './project-upload-status'
import DocumentSearchInput from './document-search-input'
import clsx from 'clsx'

interface DocumentListProps {}

const DocumentList: React.FC<DocumentListProps> = () => {
  const dispatch = useDispatch()
  const dragUploaderRef = useRef<FilePond | null>(null)
  const [files, setFiles] = useState<FilePondFile[]>([])
  const [uploaderVisible, setUploaderVisible] = useState(true)
  const { projectId } = useParams<{ projectId: string }>()
  const currentProject = useSelector(selectCurrentProject)
  const [updateDocumentSort] = useUpdateDocumentSortMutation()
  const [moveItemsIntoFolder] = useMoveItemsIntoFolderMutation()
  const [searchParams] = useSearchParams()
  const [fileProgressRef, setFileProgressRef] = useState<{
    [fileName: string]: {
      lengthComputable: boolean
      loaded: number
      total: number
    }
  }>({})

  const folderId = useMemo(() => {
    return searchParams.get('folderId')
  }, [searchParams])

  const {
    currentData: documentResponse,
    isLoading: documentsAndFoldersLoading,
  } = useGetDocumentsByProjectQuery(
    currentProject?.id
      ? { projectId: currentProject?.id, folderId: folderId ?? null }
      : skipToken,
    {
      pollingInterval: 10000,
    }
  )

  const allFilesUploaded = useMemo(() => {
    if (!files.length) {
      return true
    }
    return Object.values(fileProgressRef).every((progress) => {
      return progress.loaded === progress.total
    })
  }, [fileProgressRef, files])

  //unstable but fine for our purposes https://reactrouter.com/en/main/hooks/use-prompt
  usePrompt({ when: !allFilesUploaded })

  useEffect(() => {
    setFiles([])
  }, [projectId])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const filesToUpload = acceptedFiles.filter(
      (f) =>
        f.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        f.type === 'application/pdf'
    )
    if (!dragUploaderRef) {
      return
    }
    dragUploaderRef?.current?.addFiles(filesToUpload)
  }, [])

  const onDocumentUploaded = useCallback(() => {
    //overriding onDocumentUploaded
  }, [])

  const calculateProgressWidth = (file: FilePondFile) => {
    const loaded = fileProgressRef?.[file.filename]?.loaded ?? 0
    const total = fileProgressRef?.[file.filename]?.total ?? 0
    if (!total) {
      return '0%'
    }
    return `${(loaded / total) * 100}%`
  }

  const onClickUpload = useCallback(() => {
    dragUploaderRef?.current?.browse()
  }, [dragUploaderRef])

  const onClickOpenFolder = useCallback(() => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.CREATE_FOLDER,
        folder: documentResponse?.folder?.id,
      })
    )
  }, [dispatch, documentResponse?.folder?.id])

  const onCloseUploader = useCallback(() => {
    setUploaderVisible(false)
    setFiles([])
  }, [])

  const onProgressUpdate = useCallback(
    (fileName, progress) => {
      if (!fileProgressRef[fileName]) {
        setUploaderVisible(true)
      }
      setFileProgressRef((prev) => ({
        ...prev,
        [fileName]: {
          lengthComputable: progress.lengthComputable,
          loaded: progress.loaded,
          total: progress.total,
        },
      }))
    },
    [fileProgressRef]
  )

  const _sortDocuments = useCallback(
    async (
      foundActiveDocumentIndex: number | undefined,
      foundOverDocumentIndex: number | undefined
    ) => {
      if (
        foundActiveDocumentIndex === undefined ||
        foundOverDocumentIndex === undefined ||
        !currentProject?.id ||
        !documentResponse?.documents
      ) {
        return
      }
      let reorderedDocuments: ProjectDocument[] = [
        ...(documentResponse?.documents ?? []),
      ]
      reorderedDocuments = arrayMove(
        reorderedDocuments,
        foundActiveDocumentIndex,
        foundOverDocumentIndex
      )
      const documentIndexes = reorderedDocuments.reduce((acc, item, index) => {
        acc[item.id] = index
        return acc
      }, {})

      await updateDocumentSort({
        projectId: currentProject?.id,
        documentIndexes: documentIndexes,
        folderId: documentResponse?.folder?.id,
      })
    },
    [
      currentProject?.id,
      documentResponse?.documents,
      documentResponse?.folder?.id,
      updateDocumentSort,
    ]
  )

  const documentSearchQuery = useMemo(() => {
    return searchParams.get('search')
  }, [searchParams])

  const handleDrop = useCallback(
    // eslint-disable-next-line complexity
    async (dropResult: DragEndEvent) => {
      const { active, over } = dropResult
      if (!active || !over) {
        return
      }
      const foundActiveDocumentIndex = documentResponse?.documents?.findIndex(
        (d) => d.id === active.id
      )
      const foundOverDocumentIndex = documentResponse?.documents?.findIndex(
        (d) => d.id === over.id
      )
      const foundOverFolderIndex = documentResponse?.folders?.findIndex(
        (f) => f.id === over.id
      )
      const foundActiveFolderIndex = documentResponse?.folders?.findIndex(
        (f) => f.id === active.id
      )
      //Case when dragging folder where target is folder up one level
      if (
        over.id === documentResponse?.folder?.id &&
        foundActiveFolderIndex !== -1
      ) {
        if (foundActiveFolderIndex === undefined || !currentProject?.id) {
          return
        }
        const foundFolder = documentResponse?.folders[foundActiveFolderIndex]
        await moveItemsIntoFolder({
          destination_folder_id:
            documentResponse?.folder.folder?.id ?? undefined,
          document_ids: [],
          folder_ids: [foundFolder.id],
          projectId: currentProject?.id,
          folderId: documentResponse?.folder?.id,
          searchQuery: documentSearchQuery ?? undefined,
        }).unwrap()
        toast.success('Folder moved into parent folder')
        return
      }
      if (
        over.id === documentResponse?.folder?.id &&
        foundActiveDocumentIndex !== -1
      ) {
        if (
          foundActiveDocumentIndex === undefined ||
          foundOverFolderIndex === undefined ||
          !currentProject?.id ||
          !documentResponse?.documents
        ) {
          return
        }
        const foundDocument =
          documentResponse?.documents[foundActiveDocumentIndex]
        await moveItemsIntoFolder({
          destination_folder_id:
            documentResponse?.folder.folder?.id ?? undefined,
          document_ids: [foundDocument.id],
          folder_ids: [],
          projectId: currentProject?.id,
          folderId: documentResponse?.folder?.id,
        }).unwrap()
        toast.success('Document moved into folder')
        return
      }
      //Case when both target and origin are documents, sort
      if (foundActiveDocumentIndex !== -1 && foundOverDocumentIndex !== -1) {
        _sortDocuments(foundActiveDocumentIndex, foundOverDocumentIndex)
      }
      //Case when dragging a document into a folder
      else if (foundActiveDocumentIndex !== -1 && foundOverFolderIndex !== -1) {
        if (
          foundActiveDocumentIndex === undefined ||
          foundOverFolderIndex === undefined ||
          !currentProject?.id ||
          !documentResponse?.documents
        ) {
          return
        }
        const foundDocument =
          documentResponse?.documents[foundActiveDocumentIndex]
        await moveItemsIntoFolder({
          destination_folder_id: over.id.toString(),
          document_ids: [foundDocument.id],
          folder_ids: [],
          projectId: currentProject?.id,
          folderId: documentResponse?.folder?.id,
        }).unwrap()
        toast.success('Document moved into folder')
      }
      //Case when dragging a folder into a folder
      else if (
        foundActiveFolderIndex !== -1 &&
        foundOverFolderIndex !== -1 &&
        foundActiveFolderIndex !== foundOverFolderIndex
      ) {
        if (
          foundActiveDocumentIndex === undefined ||
          foundOverFolderIndex === undefined ||
          !currentProject?.id ||
          !documentResponse?.documents
        ) {
          return
        }
        await moveItemsIntoFolder({
          destination_folder_id: over.id.toString(),
          document_ids: [],
          folder_ids: [active.id.toString()],
          projectId: currentProject?.id,
          folderId: documentResponse?.folder?.id,
        }).unwrap()
        toast.success('Folder moved successfully')
      }
    },
    [
      documentResponse,
      currentProject?.id,
      moveItemsIntoFolder,
      documentSearchQuery,
      _sortDocuments,
    ]
  )

  const renderDocumentListHeader = useMemo(() => {
    if (documentResponse?.folder?.name) {
      return <FolderHeader folder={documentResponse?.folder} />
    } else {
      return <div>{currentProject?.title}</div>
    }
  }, [currentProject?.title, documentResponse?.folder])

  return (
    <div className="flex grow flex-col overflow-hidden">
      <ProjectUploadStatus />
      <DuplicatorStatus
        revisionParams={
          currentProject?.id ? { projectId: currentProject?.id } : skipToken
        }
      />
      <div className="flex w-full justify-between p-2 pr-4">
        <DocumentSearchInput />
        <div className="flex gap-2">
          <button
            disabled={Boolean(documentSearchQuery)}
            onClick={onClickOpenFolder}
            className={clsx(
              'ml-2 max-h-[36px] rounded px-2 py-1 font-medium ring-1 ring-inset ring-gray-300 transition-colors',
              documentSearchQuery
                ? 'bg-gray-100 text-gray-500'
                : 'bg-white text-gray-900 hover:bg-gray-50'
            )}
          >
            <div className="flex items-center justify-center whitespace-nowrap">
              <FolderIcon className="mr-2 h-5 w-5 text-gray-700" />
              Create Folder
            </div>
          </button>
          <button
            id="upload-files-button"
            onClick={onClickUpload}
            className="max-h-[36px] rounded border bg-blue-600 px-2 py-0 font-medium text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <div className="flex items-center justify-center whitespace-nowrap">
              <DocumentArrowUpIcon className="mr-2 h-5 w-5" />
              Upload Files
            </div>
          </button>
        </div>
      </div>
      <div className="flex shrink-0 items-center justify-between gap-2 overflow-hidden border-y p-2 py-1 text-sm">
        <div className="shrink-0">{renderDocumentListHeader}</div>
      </div>
      <Dropzone noClick onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            data-playwright="document-list-dropzone"
            className="mt-0 grow overflow-auto outline-none focus:ring-0"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <DocumentManagerDragDrop
              dragUploaderRef={dragUploaderRef}
              handleDrop={handleDrop}
            />
            {documentsAndFoldersLoading && (
              <>
                <div className="mb-4 h-6 w-full animate-pulse rounded border-b bg-gray-200" />
                <div className="mb-4 h-6 w-full animate-pulse rounded border-b bg-gray-200" />
                <div className="mb-4 h-6 w-full animate-pulse rounded border-b bg-gray-200" />
                <div className="mb-4 h-6 w-full animate-pulse rounded border-b bg-gray-200" />
                <div className="mb-4 h-6 w-full animate-pulse rounded border-b bg-gray-200" />
              </>
            )}
            {documentResponse?.documents?.length === 0 && (
              <div className="flex h-full items-center justify-center bg-[#e5e7eb]">
                <button
                  onClick={onClickUpload}
                  className="flex h-full w-full cursor-pointer items-center justify-center rounded-full border bg-gray-200 hover:text-gray-500"
                >
                  <div>
                    <div className="flex flex-col items-center">
                      <ArrowUpTrayIcon className="mb-8 h-16 w-16 text-blue-500" />
                      <div className="text-xl">
                        Click Here to Upload Documents
                      </div>
                      <div className="my-2 text-sm">or</div>
                      <div>Drag-and-drop files into this panel</div>
                    </div>
                  </div>
                </button>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      {files.length > 0 && uploaderVisible && (
        <div className="fixed bottom-0 right-5 min-w-[20rem] items-center justify-center rounded border bg-white p-2 pb-12 shadow">
          <div className="flex justify-between p-1">
            <div className="text-sm">Uploading</div>
            <button onClick={onCloseUploader}>
              <XMarkIcon className="h-5 w-5 cursor-pointer hover:text-gray-500" />
            </button>
          </div>
          <div className="max-h-96 overflow-auto bg-white">
            {files.map((file) => (
              <div
                key={`file_uploading_${file.id}`}
                className="m-0.5 rounded bg-gray-100 p-3"
              >
                <div
                  className={`w-0 rounded-full bg-blue-600 p-0.5 text-center text-xs font-medium leading-none text-blue-100 transition-all ${
                    !fileProgressRef?.[file.filename]?.loaded
                      ? 'animate-pulse'
                      : ''
                  }`}
                  style={{
                    width: calculateProgressWidth(file),
                  }}
                />
                <div
                  className="w-64 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                  data-tooltip-id="document-title-tooltip"
                  data-tooltip-content={file.filename}
                >
                  {file.filename}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="hidden">
        <Uploader
          setFiles={setFiles}
          files={files}
          ref={dragUploaderRef}
          onDocumentUploaded={onDocumentUploaded}
          onProgressUpdate={onProgressUpdate}
        />
      </div>
      <Tooltip id={'document-title-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default DocumentList
