import React, { useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  selectCurrentPage,
  selectCursorTool,
  selectTextSelected,
  selectTotalPages,
  setCursorTool,
  setCustomLabelOpen,
  setNewRevision,
  setSelectedRevision,
  setSidebarTab,
  setTextSelected,
  setZoomLevel,
} from '../../redux/viewer-slice'
import { POSTHOG } from '../../utils/posthog-constants'
import { useDispatch, useSelector } from 'react-redux'
import { useCreateHighlightMutation } from '../../redux/api-slice'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { usePostHog } from 'posthog-js/react'
import { selectCurrentDocument } from '../../redux/application-slice'
import { setSearchQuery } from '../../redux/search-slice'
import { Quads } from '../../shared/interfaces/quads.interface'
import { RevisionSegment } from '../../shared/interfaces/project/document/revision/revision.interface'

function ViewerShortcuts() {
  const currentDocument = useSelector(selectCurrentDocument)
  const totalPages = useSelector(selectTotalPages)
  const currentPage = useSelector(selectCurrentPage)
  const textSelected = useSelector(selectTextSelected)
  const cursorTool = useSelector(selectCursorTool)
  const [createHighlight] = useCreateHighlightMutation()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const posthog = usePostHog()

  const onSetCursorTool = (value) => {
    dispatch(setCursorTool(value))
    documentViewer?.setToolMode(documentViewer?.getTool(value))
  }

  const onZoomIncrease = () => {
    const newLevel = Math.min((documentViewer?.getZoomLevel() ?? 0) + 0.15, 2)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }

  const onZoomDecrease = () => {
    const newLevel = Math.max((documentViewer?.getZoomLevel() ?? 0) - 0.15, 0.3)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }

  useHotkeys('right', () => {
    documentViewer?.setCurrentPage(
      currentPage < totalPages ? currentPage + 1 : 1,
      false
    )
  })
  useHotkeys('left', () => {
    documentViewer?.setCurrentPage(currentPage > 1 ? currentPage - 1 : 1, false)
  })
  useHotkeys('equal', () => {
    onZoomIncrease()
  })
  useHotkeys('minus', () => {
    onZoomDecrease()
  })
  useHotkeys('c', () => {
    if (!currentDocument?.id) {
      return
    }
    if (textSelected) {
      const text = textSelected?.text?.replace(/(\r\n|\n|\r)/gm, ' ') ?? ''
      const height = documentViewer?.getPageHeight(textSelected.pageNumber)
      const width = documentViewer?.getPageWidth(textSelected.pageNumber)
      if (!height || !width) {
        return
      }
      const revisionSegments: RevisionSegment[] = []
      for (const quadPage in textSelected.quads) {
        const quads: Quads[] = textSelected.quads[quadPage].map(
          (quad: Quads) => {
            return {
              x1: quad.x1 / width,
              x2: quad.x2 / width,
              x3: quad.x3 / width,
              x4: quad.x4 / width,
              y1: quad.y1 / height,
              y2: quad.y2 / height,
              y3: quad.y3 / height,
              y4: quad.y4 / height,
              page: parseInt(quadPage),
            }
          }
        )
        revisionSegments.push({
          page: parseInt(quadPage),
          quads,
          document: currentDocument?.id,
          text: textSelected.text ?? '',
        })
      }
      dispatch(
        setNewRevision({
          page: textSelected.pageNumber,
          quads: revisionSegments?.[0]?.quads ?? [],
          original_text: text,
          revised_text: text,
          segments: revisionSegments,
        })
      )
    }
  })
  //Custom labels
  useHotkeys('d', () => {
    if (textSelected) {
      dispatch(setCustomLabelOpen(true))
    }
  })
  useHotkeys('s', () => {
    if (textSelected) {
      dispatch(setSidebarTab('search'))
      dispatch(setSearchQuery(textSelected.text))
    }
  })

  useHotkeys('h', () => {
    if (!currentDocument?.id || currentDocument?.uuid) {
      return
    }
    if (textSelected && !textSelected.highlighted) {
      dispatch(setTextSelected({ ...textSelected, highlighted: true }))
      const quads = textSelected.quads[textSelected.pageNumber]
      createHighlight({
        document: currentDocument?.id,
        page: textSelected.pageNumber,
        quads,
        text: textSelected.text,
      })
      posthog?.capture(POSTHOG.highlight_created, {
        document_uuid: currentDocument.uuid,
      })
    }
  })

  useHotkeys('ctrl+c', async () => {
    if (textSelected) {
      await navigator.clipboard.writeText(textSelected?.text ?? '')
    }
  })
  useHotkeys('meta+c', async () => {
    if (textSelected) {
      await navigator.clipboard.writeText(textSelected.text ?? '')
    }
  })
  useHotkeys('esc', async () => {
    dispatch(setTextSelected(null))
    dispatch(setSelectedRevision(null))
    documentViewer?.clearSelection()
  })

  useHotkeys('a', () => {
    if (cursorTool === 'TextSelect') {
      onSetCursorTool('Pan')
    } else {
      onSetCursorTool('TextSelect')
    }
  })

  return <div />
}

export default ViewerShortcuts
