import React, { useCallback, useMemo } from 'react'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSearchParams } from 'react-router-dom'

export interface WorkflowFilter {
  status?:
    | 'IN_REVIEW'
    | 'APPROVED'
    | 'NOT_APPROVED'
    | 'NEEDS_RECON'
    | 'NO_STATUS'
  comment_author?: ProvisionUser
  comment_uuid?: string
  documentIds?: number[]
  projectId?: number
}

interface FilterDisplayProps {
  filter: WorkflowFilter
  setFilter: React.Dispatch<React.SetStateAction<WorkflowFilter>>
}

const FilterDisplay: React.FC<FilterDisplayProps> = ({ filter, setFilter }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const onAuthorClear = useCallback(() => {
    setFilter((f) => {
      return { ...f, comment_author: undefined }
    })
  }, [setFilter])

  const onStatusClear = useCallback(() => {
    setFilter((f) => {
      return { ...f, status: undefined }
    })
  }, [setFilter])
  const onCommentClear = useCallback(() => {
    setFilter((f) => {
      return { ...f, comment_uuid: undefined }
    })
    searchParams.delete('comment')
    setSearchParams(searchParams)
  }, [searchParams, setFilter, setSearchParams])

  const getStatusDisplayValue = useMemo(() => {
    switch (filter?.status) {
      case 'APPROVED':
        return 'Closed'
      case 'NOT_APPROVED':
        return 'Carried Over'
      case 'IN_REVIEW':
        return 'In Review'
      case 'NEEDS_RECON':
        return 'Requires reconciliation'
      case 'NO_STATUS':
        return 'Open'
    }
  }, [filter?.status])

  return (
    <div>
      {filter.comment_author && (
        <div
          key={`comment_author_filter_${filter?.comment_author}`}
          className="flex items-center"
        >
          <span className="border-x border-gray-700 px-1">Author</span>
          <span className="px-1">{filter?.comment_author?.email}</span>
          <button onClick={onAuthorClear}>
            <XMarkIcon className="h-3 w-3 cursor-pointer" />
          </button>
        </div>
      )}
      {filter.status && (
        <div
          key={`comment_status_filter_${filter?.status}`}
          className="flex items-center"
        >
          <span className="border-x border-gray-700 px-1">Status</span>
          <span className="px-1">{getStatusDisplayValue}</span>
          <button onClick={onStatusClear}>
            <XMarkIcon className="h-3 w-3 cursor-pointer" />
          </button>
        </div>
      )}
      {filter.comment_uuid && (
        <div
          key={`comment_status_filter_${filter?.comment_uuid}`}
          className="flex items-center"
        >
          <span className="border-x border-gray-700 px-1">Comment</span>
          <button onClick={onCommentClear}>
            <XMarkIcon className="h-3 w-3 cursor-pointer" />
          </button>
        </div>
      )}
    </div>
  )
}

export default FilterDisplay
