import React, { useCallback } from 'react'

import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'

import CreateRiskListForm from './create-risk-list-form'

const CustomRiskListsPage = () => {
  const navigate = useNavigate()

  const handleCloseCreateRiskListForm = useCallback(() => {
    navigate('..')
  }, [navigate])

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="w-full flex-auto space-y-2 overflow-auto p-2 pr-3">
        <CreateRiskListForm onClose={handleCloseCreateRiskListForm} />
      </div>

      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default CustomRiskListsPage
