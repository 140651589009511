import { Listbox } from '@headlessui/react'
import React, { useCallback, useMemo } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { CustomLabel } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'

interface DocumentListboxProps {
  labels?: CustomLabel[]
  selectedLabel: CustomLabel | null
  setSelectedLabel: (d: CustomLabel) => void
  onSelectNewLabel: (b: boolean) => void
  sorted?: boolean
}

const LabelListbox: React.FC<DocumentListboxProps> = ({
  labels,
  selectedLabel,
  setSelectedLabel,
  onSelectNewLabel,
  sorted,
}) => {
  const labelsToDisplay = useMemo(() => {
    if (sorted) {
      return labels?.slice().sort((a, b) => a.name.localeCompare(b.name))
    } else {
      return labels
    }
  }, [labels, sorted])

  const onSelectNewLabelClick = useCallback(() => {
    onSelectNewLabel(true)
  }, [onSelectNewLabel])

  const onDeselectNewLabelClick = useCallback(() => {
    onSelectNewLabel(false)
  }, [onSelectNewLabel])
  return (
    <Listbox value={selectedLabel} onChange={setSelectedLabel}>
      <div className={'relative my-2 flex h-8 min-w-48 self-stretch'}>
        <Listbox.Button className="w-full flex-shrink-0 truncate rounded-md border bg-white px-2.5 py-1.5 text-left text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selectedLabel?.name ?? 'New Label'}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute top-full z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <Listbox.Option
            className={
              'flex cursor-pointer justify-between truncate px-2 py-2 font-bold hover:bg-gray-200'
            }
            onClick={onSelectNewLabelClick}
            value={null}
            key={'null'}
          >
            New Label
          </Listbox.Option>
          {labelsToDisplay?.map((label) => (
            <Listbox.Option
              onClick={onDeselectNewLabelClick}
              key={`custom_label_option_${label.id}`}
              value={label}
              className={
                'flex cursor-pointer justify-between truncate px-2 py-2 hover:bg-gray-200'
              }
            >
              {label.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default LabelListbox
