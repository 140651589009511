import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectModal,
  setModal,
} from '../../redux/application-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { toast } from 'react-toastify'
import { Project } from '../../shared/interfaces/project/project.interface'
import { useCreateFolderMutation } from '../../redux/api-slice'

interface CreateFolderModalProps {
  open: boolean
}

const CreateFolderModal: React.FC<CreateFolderModalProps> = ({ open }) => {
  const [submitting, setSubmitting] = React.useState(false)
  const { handleSubmit, reset } = useForm()
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const [createFolder] = useCreateFolderMutation()
  const modal = useSelector(selectModal)
  const currentProject = useSelector(selectCurrentProject) as Project
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null)
  const [folderName, setFolderName] = useState<string>('')

  useEffect(() => {
    if (modal?.folder) {
      setCurrentFolderId(modal?.folder)
    }
  }, [modal?.folder])

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setSubmitting(false)
    setCurrentFolderId(null)
    reset()
  }, [reset, dispatch])

  const handleFocus = useCallback((event) => event.target.select(), [])

  const onSubmit = useCallback(async () => {
    if (!currentProject?.id) {
      return
    }
    if (submitting) return

    setSubmitting(true)
    try {
      await createFolder({
        folder: currentFolderId ?? undefined,
        project: currentProject?.id,
        name: folderName,
      }).unwrap()
      posthog?.capture(POSTHOG.folder_created, {
        project_id: currentProject?.id,
        folder_id: currentFolderId,
      })
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
      setSubmitting(false)
    }
    onClose()
  }, [
    createFolder,
    currentFolderId,
    currentProject?.id,
    folderName,
    onClose,
    posthog,
    submitting,
  ])

  const onSetFolderName = useCallback((e) => {
    setFolderName(e?.target?.value)
  }, [])

  const dialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Choose a Folder Name
              </label>
              <input
                required
                tabIndex={0}
                autoFocus
                type="text"
                onFocus={handleFocus}
                onChange={onSetFolderName}
                placeholder="Enter a folder name..."
                value={folderName}
                name="folder_title"
                id="folder_title"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </Dialog.Panel>
    )
  }, [handleSubmit, onSubmit, handleFocus, onSetFolderName, folderName])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CreateFolderModal
