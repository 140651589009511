import { ProvisionMention } from '../shared/interfaces/mention/mention.interface'

export function getMentions(comment: string) {
  const mentionPattern = /@\[(.*?)\]\([0-9]+\)/g
  const matches = comment.matchAll(mentionPattern)
  const mentions: ProvisionMention[] = []
  for (const match of matches) {
    if (match.index === undefined) {
      continue
    }
    const mention: ProvisionMention = {
      user_targeted_id: parseInt(match[0].split('(')[1].split(')')[0]),
      user_targeted: {
        email: match[0].split('[')[1].split(']')[0],
        id: parseInt(match[0].split('(')[1].split(')')[0]),
      },
      start_index: match.index ?? 0,
      end_index: match.index + match[0].length,
    }
    mentions.push(mention)
  }
  return mentions
}
