import React, { useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { useGetProjectPermissionsByIdQuery } from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../redux/application-slice'
import { ProvisionUser } from '../../shared/interfaces/user/user.inteface'
import { ProjectPermissionUser } from '../../shared/interfaces/project/permissions/project-permissions.interface'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface AssignedUserComboboxProps {
  onChangeUser?: (user: ProjectPermissionUser) => void
}

export default function AssignedUserCombobox({
  onChangeUser,
}: AssignedUserComboboxProps) {
  const [query, setQuery] = useState('')
  const [selectedPerson, setSelectedPerson] = useState(null)
  const currentProject = useSelector(selectCurrentProject)

  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    currentProject?.id ?? skipToken
  )

  const userData = useMemo(() => {
    return permissions?.users ?? []
  }, [permissions?.users])

  const filteredPeople =
    query === ''
      ? userData
      : userData.filter((person) => {
          return person.email.toLowerCase().includes(query.toLowerCase())
        })

  const onComboBoxChange = (user) => {
    if (onChangeUser) {
      onChangeUser(user)
    }
    setSelectedPerson(user)
  }

  return (
    <Combobox as="div" value={selectedPerson} onChange={onComboBoxChange}>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person: ProvisionUser) => person?.email ?? ''}
          placeholder="Assigned to"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          'truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {person.email}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
