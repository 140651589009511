import React from 'react'

interface DocumentReferenceProps {
  documentTitle: string
  pageNumber: number | null
  onClick?: () => void
}

const DocumentReference: React.FC<DocumentReferenceProps> = ({
  documentTitle,
  pageNumber,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={!pageNumber}
      className={`flex min-w-0 cursor-default justify-end space-x-2 ${
        onClick && pageNumber ? 'cursor-pointer hover:underline' : ''
      }`}
    >
      <div className={'overflow-hidden text-ellipsis whitespace-nowrap'}>
        {documentTitle}
      </div>
      {!pageNumber ? (
        <div className="italic">Original Reference Not Found</div>
      ) : (
        <div className={'flex-shrink-0'}>Pg. {pageNumber}</div>
      )}
    </button>
  )
}

export default DocumentReference
