import React, { useRef, useEffect, useCallback } from 'react'
import { Menu } from '@mantine/core'
import { ReactElement } from 'react'

type PanelPopoverProps = {
  width: number
  topLabel?: string
  menuTarget: ReactElement
  menuPanel: ReactElement
}

export const PanelPopover = ({
  width,
  menuTarget,
  topLabel,
  menuPanel,
}: PanelPopoverProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [isOpen])

  const handleMenuTargetClick = useCallback(() => {
    setIsOpen(true)
  }, [])

  return (
    <Menu
      transitionProps={{ transition: 'scale', duration: 200 }}
      shadow="md"
      radius={'md'}
      width={width}
      withArrow
      arrowSize={12}
      opened={isOpen}
      onChange={setIsOpen}
    >
      <Menu.Target>
        <div className="!cursor-pointer" onClick={handleMenuTargetClick}>
          {menuTarget}
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {topLabel ? <Menu.Label>{topLabel}</Menu.Label> : null}
        {menuPanel}
      </Menu.Dropdown>
    </Menu>
  )
}
