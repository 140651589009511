import React, { useState } from 'react'
import { OverlayAction, OverlayRefClicked } from '../page-overlay/page-overlay'
import OverlayContextMenuButton from './overlay-context-menu-button'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'

interface ContextMenuProps {
  overlays: OverlayAction[]
  setOverlaysClicked: React.Dispatch<
    React.SetStateAction<OverlayRefClicked | null>
  >
  referenceElement: HTMLElement | null
}

const OverlayContextMenu: React.FC<ContextMenuProps> = ({
  overlays,
  setOverlaysClicked,
  referenceElement,
}) => {
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, panelRef)
  return (
    <Popover id="blackline-popover" className="relative">
      <Popover.Panel
        static
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-[60] rounded border bg-white text-gray-700 shadow"
      >
        <div
          style={{
            minWidth: '20rem',
          }}
          className={'flex h-full w-64 min-w-fit flex-col space-y-2 p-1'}
        >
          {overlays?.map((overlay) => (
            <OverlayContextMenuButton
              key={overlay.id}
              setOverlaysClicked={setOverlaysClicked}
              overlay={overlay}
            />
          ))}
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default OverlayContextMenu
