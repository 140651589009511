import React, { forwardRef } from 'react'
import { Highlight } from '../../shared/interfaces/project/document/highlight/highlight.interface'

interface PageOverlayHighlightProps {
  highlight: Highlight
  coords: {
    width: number
    height: number
    topOffset: number
    leftOffset: number
  }
  selectedHighlight: Highlight | null
  shouldElevate: boolean
}
const PageOverlayHighlight = forwardRef<
  HTMLButtonElement,
  PageOverlayHighlightProps
>(({ highlight, coords, selectedHighlight, shouldElevate }, ref) => {
  return (
    <button
      ref={ref}
      className={`absolute bg-yellow-200 ${
        shouldElevate ? 'z-50' : 'z-30'
      } ${highlight.id ? 'cursor-pointer' : ''} ${
        highlight.id !== undefined && selectedHighlight?.id === highlight.id
          ? 'ring-2 ring-blue-500'
          : ''
      }`}
      style={{
        width: `${coords.width}%`,
        height: `${coords.height}%`,
        top: `${coords.topOffset}%`,
        left: `${coords.leftOffset}px`,
      }}
    />
  )
})

PageOverlayHighlight.displayName = 'PageOverlayHighlight'

export default PageOverlayHighlight
