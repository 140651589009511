import React, { useCallback, useEffect } from 'react'
import { Listbox } from '@headlessui/react'
import { DocumentNode } from './document-listbox'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import DocumentListboxOption from './document-listbox-option'

interface ListboxOptionNodeProps {
  root: DocumentNode
  isExpanded?: boolean
  shouldShowVersion?: boolean
}

const ListboxOptionNode: React.FC<ListboxOptionNodeProps> = ({
  root,
  isExpanded,
  shouldShowVersion,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const [childrenExpanded, setChildrenExpanded] = React.useState(false)

  useEffect(() => {
    setExpanded(isExpanded ? true : false)
  }, [isExpanded])

  const onClickPlusIcon = useCallback(() => {
    setChildrenExpanded((e) => !e)
  }, [])

  return expanded ? (
    <div className="flex flex-col" key={root.document.uuid}>
      <div className="flex items-center space-x-1 px-2 py-2 hover:bg-gray-200">
        {root?.children?.length ? (
          <button>
            {childrenExpanded ? (
              <ChevronUpIcon className="h-4 w-4" onClick={onClickPlusIcon} />
            ) : (
              <ChevronDownIcon onClick={onClickPlusIcon} className="h-4 w-4" />
            )}
          </button>
        ) : null}
        <Listbox.Option
          aria-label={`Select ${root.document.title}`}
          key={`listbox_document_option_${root.document.id}`}
          value={root.document}
          className={
            'flex w-64 cursor-pointer justify-between space-x-1 truncate'
          }
        >
          <div
            className="overflow-hidden text-ellipsis whitespace-nowrap"
            data-tooltip-id="document-title-single-tooltip"
            data-tooltip-content={root.document.title}
          >
            {root.document.title}
          </div>
          {root.version > 1 || shouldShowVersion ? (
            <div className="inline-flex items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
              Version {root.version}
            </div>
          ) : null}
        </Listbox.Option>
      </div>
      <div className="ml-5 ">
        {root.children && (
          <DocumentListboxOption
            isExpanded={childrenExpanded}
            documentNodes={root.children}
          />
        )}
      </div>
    </div>
  ) : null
}
export default ListboxOptionNode
