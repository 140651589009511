import React, { useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import {
  TemporaryHighlight,
  selectTemporaryHighlight,
} from '../redux/viewer-slice'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'
import Diff from './workflows/comment-table/diff'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'
import { isRatio } from '../utils/convert-quads'
import { useCoords } from '../hooks/use-coords'
import TemporaryHighlightContainer from './page-overlay/temporary-highlight'

interface PageOverlaySimpleProps {
  revisionSelected?: Revision
  documentChangeSelected?: DocumentChange
  page: number
  isSecond?: boolean
}

const PageOverlaySimple = (props: PageOverlaySimpleProps) => {
  const { getCoords } = useCoords()
  const temporaryHighlight = useSelector(selectTemporaryHighlight)

  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const pageWidgetContainer = window?.document
    ?.getElementById('webviewer-wrapper')
    ?.querySelector(`#pageWidgetContainer${props.page}`) as HTMLDivElement
  const pageContainer = window?.document
    ?.getElementById('webviewer-wrapper')
    ?.querySelector(`#pageContainer${props.page}`) as HTMLDivElement

  // TODO This doesnt check if a page exists, which shouldnt happen but it does occasionally
  const pageInfo = useMemo(() => {
    if (documentViewer && documentViewer?.getDocument()) {
      return documentViewer?.getDocument()?.getPageInfo(props.page)
    } else {
      return { width: 0, height: 0 }
    }
  }, [props.page, documentViewer])

  const renderDocumentChange = () => {
    if (!props.documentChangeSelected) {
      return null
    }
    const documentChange: DocumentChange = props.documentChangeSelected
    let coords: {
      topOffset?: number
      leftOffset?: number
      width?: number
      height?: number
    } = {}
    const quads = documentChange.source.quads
    coords = getCoords(quads[0], props.page, 'document-viewer', isRatio(quads))
    return (
      <div
        key={'temp_document_change'}
        className="absolute z-50 border bg-white p-2 text-xs"
        style={{
          top: `calc(${coords.topOffset}% + ${coords.height}%)`,
          left: `${coords.leftOffset}px`,
        }}
      >
        <div>
          <div className="mt-1">
            <Diff
              inputA={documentChange.original_clause ?? ''}
              inputB={documentChange.reconciled_clause ?? ''}
            />
          </div>
        </div>
      </div>
    )
  }

  if (pageWidgetContainer && pageContainer) {
    return ReactDOM.createPortal(
      <React.Fragment key={props.page}>
        {/* TEMPORARY HIGHLIGHT DATA */}
        {pageInfo &&
          temporaryHighlight &&
          temporaryHighlight
            .slice()
            .filter((h) => h.page === props.page)
            .map((highlight: TemporaryHighlight) => (
              <TemporaryHighlightContainer
                key={highlight.id}
                highlight={highlight}
                page={props.page}
                documentViewerID="document-viewer"
              />
            ))}
        {props.documentChangeSelected &&
          props.page === props.documentChangeSelected.source.page &&
          renderDocumentChange()}
      </React.Fragment>,
      pageWidgetContainer
    )
  } else {
    return null
  }
}

export default PageOverlaySimple
