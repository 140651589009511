import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RiskListState {
  modalStates: {
    [id: number]: { isDeleteModalOpen: boolean; isEditModalOpen: boolean }
  }
  refreshTrigger: number
}

const initialState: RiskListState = {
  modalStates: {},
  refreshTrigger: 0,
}

const riskListSlice = createSlice({
  name: 'riskList',
  initialState,
  reducers: {
    setDeleteModalOpen: (
      state,
      action: PayloadAction<{ id: number; isOpen: boolean }>
    ) => {
      const { id, isOpen } = action.payload
      if (!state.modalStates[id]) {
        state.modalStates[id] = {
          isDeleteModalOpen: false,
          isEditModalOpen: false,
        }
      }
      state.modalStates[id].isDeleteModalOpen = isOpen
    },
    setEditModalOpen: (
      state,
      action: PayloadAction<{ id: number; isOpen: boolean }>
    ) => {
      const { id, isOpen } = action.payload
      if (!state.modalStates[id]) {
        state.modalStates[id] = {
          isDeleteModalOpen: false,
          isEditModalOpen: false,
        }
      }
      state.modalStates[id].isEditModalOpen = isOpen
    },
    triggerRefresh: (state) => {
      state.refreshTrigger += 1
    },
  },
})

export const { setDeleteModalOpen, setEditModalOpen, triggerRefresh } =
  riskListSlice.actions

export default riskListSlice.reducer
