import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 3000)
  })
  return (
    <div className="flex h-full flex-col items-center justify-center border p-3">
      <div>
        <ExclamationCircleIcon className="h-10 w-10 text-red-600" />
      </div>
      <p className="font-bold">404 - Page Not Found</p>
      <p>Redirecting to home page...</p>
    </div>
  )
}
export default NotFoundPage
