import { useEffect } from 'react'
import { unstable_usePrompt } from 'react-router-dom'

export const usePrompt = ({ when }) => {
  useEffect(() => {
    window.onbeforeunload = when ? () => true : null
    return () => {
      window.onbeforeunload = null
    }
  }, [when])

  unstable_usePrompt({
    message: 'Some files are still uploading. Are you sure you want to leave?',
    when: ({ currentLocation, nextLocation }) => {
      const currentLocationProject = currentLocation.pathname.split('/')[1]
      const nextLocationProject = nextLocation.pathname.split('/')[1]
      const currentLocationRoute = currentLocation.pathname.split('/')[2]
      const nextLocationRoute = nextLocation.pathname.split('/')[2]
      if (
        currentLocationProject === nextLocationProject &&
        currentLocationRoute === nextLocationRoute
      ) {
        return false
      }
      return when
    },
  })
}
