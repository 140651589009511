import React, { useCallback, useMemo } from 'react'
import { RevisionStatus } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { WorkflowFilter } from '../workflow-components/filter-display'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetRevisionStatsQuery } from '../../../redux/api/api-revisions-slice'

interface CommentTableHeaderStatusFilterProps {
  filter: WorkflowFilter
  setFilter: React.Dispatch<React.SetStateAction<WorkflowFilter>>
}

const CommentTableHeaderStatusFilter: React.FC<
  CommentTableHeaderStatusFilterProps
> = ({ filter, setFilter }) => {
  const revisionParams = useMemo(() => {
    return filter.documentIds?.length
      ? { documentIds: filter.documentIds }
      : filter.projectId
        ? {
            projectId: filter.projectId,
          }
        : skipToken
  }, [filter])
  const { data: revisionStats } = useGetRevisionStatsQuery(revisionParams)
  const revisionStatsMapped = useMemo(() => {
    const revisionStatsMapped: {
      // eslint-disable-next-line no-unused-vars
      [revisionStats in RevisionStatus]: number
    } = {
      APPROVED: 0,
      NEEDS_RECON: 0,
      NOT_APPROVED: 0,
      NO_STATUS: 0,
      IN_REVIEW: 0,
    }
    for (const stats of revisionStats ?? []) {
      if (!revisionStatsMapped[stats.revision_status]) {
        revisionStatsMapped[stats.revision_status] = 0
      }
      revisionStatsMapped[stats.revision_status] += stats.count
    }
    return revisionStatsMapped
  }, [revisionStats])
  const onSelectAddressedFilter = useCallback(() => {
    setFilter((filter) => {
      if (filter.status === 'APPROVED') {
        return {
          ...filter,
          status: undefined,
        }
      }
      return {
        ...filter,
        status: 'APPROVED',
      }
    })
  }, [setFilter])

  const onSelectNotAddressedFilter = useCallback(() => {
    setFilter((filter) => {
      if (filter.status === 'NOT_APPROVED') {
        return {
          ...filter,
          status: undefined,
        }
      }
      return {
        ...filter,
        status: 'NOT_APPROVED',
      }
    })
  }, [setFilter])

  const onSelectRequiresReconFilter = useCallback(() => {
    setFilter((filter) => {
      if (filter.status === 'NEEDS_RECON') {
        return {
          ...filter,
          status: undefined,
        }
      }
      return {
        ...filter,
        status: 'NEEDS_RECON',
      }
    })
  }, [setFilter])
  return (
    <div className="flex space-x-2 text-xs">
      <button
        onClick={onSelectNotAddressedFilter}
        className={`cursor-pointer space-x-1 rounded border px-3 py-1 ${
          filter.status === 'NOT_APPROVED'
            ? 'bg-gray-100 hover:bg-white'
            : 'bg-white hover:bg-gray-100'
        }`}
      >
        <span>Open</span>
        <span className="inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
          {revisionStatsMapped.NOT_APPROVED}
        </span>
      </button>
      <button
        onClick={onSelectAddressedFilter}
        className={`cursor-pointer space-x-1 rounded border px-3 py-1 ${
          filter.status === 'APPROVED'
            ? 'bg-gray-100 hover:bg-white'
            : 'bg-white hover:bg-gray-100'
        }`}
      >
        <span>Closed</span>
        <span className="inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
          {revisionStatsMapped.APPROVED}
        </span>
      </button>
      <button
        onClick={onSelectRequiresReconFilter}
        className={`cursor-pointer space-x-1 rounded border px-3 py-1 ${
          filter.status === 'NEEDS_RECON'
            ? 'bg-gray-100 hover:bg-white'
            : 'bg-white hover:bg-gray-100'
        }`}
      >
        <span>Requires reconciliation</span>
        <span className="inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
          {revisionStatsMapped.NEEDS_RECON}
        </span>
      </button>
    </div>
  )
}

export default CommentTableHeaderStatusFilter
