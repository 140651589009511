import React from 'react'
import { Hit } from '../../shared/interfaces/search-results.interface'

interface PageOverlayHighlightProps {
  coords: {
    width: number
    height: number
    topOffset: number
    leftOffset: number
  }
  searchResult: Hit
}

const PageOverlaySearchHighlight: React.FC<PageOverlayHighlightProps> = ({
  coords,
  searchResult,
}) => {
  return (
    <div
      key={`search_highlight_${searchResult?.document?.id}`}
      className={
        'absolute z-50 cursor-pointer border-2 border-yellow-400 p-0.5 opacity-100'
      }
      style={{
        width: `${coords.width + 1}%`,
        height: `${coords.height + 1}%`,
        top: `${coords.topOffset - 0.5}%`,
        left: `${coords.leftOffset - 3}px`,
      }}
    />
  )
}

export default PageOverlaySearchHighlight
