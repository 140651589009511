import React, { Fragment, useCallback, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectModal,
  setCurrentProject,
  setModal,
} from '../../redux/application-slice'
import { useDeleteProjectMutation } from '../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useLocation, useNavigate } from 'react-router-dom'

interface DeleteProjectModalProps {
  open: boolean
}

function DeleteProjectModal({ open }: DeleteProjectModalProps) {
  const posthog = usePostHog()
  const dispatch = useDispatch()
  const modal = useSelector(selectModal)
  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])
  const [deleteProject] = useDeleteProjectMutation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onProjectDelete = useCallback(() => {
    posthog?.capture(POSTHOG.project_deleted, {
      project_uuid: modal.project.uuid,
    })
    deleteProject(modal.project.id)
      .unwrap()
      .then(() => {
        if (pathname.includes(modal.project.uuid)) {
          navigate('')
        }
        dispatch(setCurrentProject(null))
      })
  }, [posthog, deleteProject, modal, dispatch, pathname, navigate])

  const onProjectDeleteClick = useCallback(() => {
    onProjectDelete()
    onClose()
  }, [onProjectDelete, onClose])

  const dialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                Delete project
              </Dialog.Title>
              <p className="mt-2 text-sm text-gray-500">
                Are you sure you want to delete this project? This will remove
                all documents and attached data.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={onProjectDeleteClick}
          >
            Delete
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    )
  }, [onClose, onProjectDeleteClick])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DeleteProjectModal
