import React, { useCallback, useMemo } from 'react'
import {
  useGetDocumentByUuidQuery,
  useGetDocumentChangesByProjectQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import { useMarkAddendaQACompleteMutation } from '../../redux/api/document-change-template-api-slice'
import { selectCurrentProject } from '../../redux/application-slice'
import { Tooltip } from 'react-tooltip'
import { skipToken } from '@reduxjs/toolkit/query'

const MarkAddendaCompleteButton: React.FC = () => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const currentProject = useSelector(selectCurrentProject)
  const { currentData: documentChanges } = useGetDocumentChangesByProjectQuery(
    currentProject?.uuid ?? skipToken
  )
  const firstDocumentChange = useMemo(() => {
    return documentChanges?.[0]
  }, [documentChanges])
  const { currentData: documentChangeDocument } = useGetDocumentByUuidQuery(
    firstDocumentChange?.source?.document_uuid ?? skipToken
  )
  const [markAddendaQAComplete] = useMarkAddendaQACompleteMutation()

  const onMarkAddendaComplete = useCallback(
    (e) => {
      if (!currentProject?.id) {
        return
      }
      markAddendaQAComplete({
        project: currentProject?.id,
        uuid: documentChangeDocument?.uuid,
      })
    },
    [currentProject?.id, documentChangeDocument?.uuid, markAddendaQAComplete]
  )

  return user?.feature_flags?.editor_mode === true ? (
    <>
      <button
        className={`rounded px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-300  ${documentChangeDocument?.review?.review_type === 'ADDENDA' ? 'bg-white text-gray-900 hover:bg-gray-50' : 'bg-gray-100 text-gray-400'}`}
        onClick={onMarkAddendaComplete}
        disabled={documentChangeDocument?.review?.review_type !== 'ADDENDA'}
        data-tooltip-id={
          documentChangeDocument?.review?.review_type === 'ADDENDA'
            ? 'mark-addenda-complete-button'
            : ''
        }
        data-tooltip-content={`A notification will be sent to the document's uploader`}
      >
        Mark addenda as complete
      </button>
      <Tooltip id={'mark-addenda-complete-button'} />
    </>
  ) : null
}

export default MarkAddendaCompleteButton
