import { skipToken } from '@reduxjs/toolkit/query'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DocumentViewerContext } from '../../../contexts/document-viewer-instance-context'
import {
  useCreateEvaluationProjectRiskMutation,
  useGetEvaluationProjectRiskQuery,
  useGetRiskPipelineDefinitionsQuery,
  useUpdateEvaluationProjectRiskMutation,
} from '../../../redux/api/qa-api-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { selectTextSelected } from '../../../redux/viewer-slice'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import {
  EvaluationProjectRisk,
  RiskPipelineDefinition,
} from '../../../shared/interfaces/qa.interface'
import { useQuads } from '../../../hooks/use-quads'

const RiskReviewQA = () => {
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const { data: riskPipelineDefinitionsData } =
    useGetRiskPipelineDefinitionsQuery()
  const textSelected = useSelector(selectTextSelected)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { mappedQuads } = useQuads()

  const { documentViewer } = documentViewerContext

  const [createEvaluationProjectRisk] = useCreateEvaluationProjectRiskMutation()
  const [updateEvaluationProjectRisk] = useUpdateEvaluationProjectRiskMutation()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [currentRiskPipelineDefinition, setCurrentRiskPipelineDefinition] =
    useState<RiskPipelineDefinition | null>(null)

  const { data: evaluationProjectRisksData } = useGetEvaluationProjectRiskQuery(
    currentProject?.uuid && currentRiskPipelineDefinition?.id
      ? {
          projectUuid: currentProject.uuid,
          riskPipelineDefinitionUuid:
            currentRiskPipelineDefinition.id.toString(),
        }
      : skipToken
  )

  const [comment, setComment] = useState<string>('')
  const [sources, setSources] = useState<DocumentSegment[]>([])
  const [currentEvaulationProjectRisk, setCurrentEvaulationProjectRisk] =
    useState<EvaluationProjectRisk | null>(null)

  useEffect(() => {
    if (evaluationProjectRisksData && evaluationProjectRisksData.length > 0) {
      setComment(evaluationProjectRisksData[0].comment)
      setSources(evaluationProjectRisksData[0].sources)
      setCurrentEvaulationProjectRisk(evaluationProjectRisksData[0])
    } else {
      setComment('')
      setSources([])
      setCurrentEvaulationProjectRisk({
        project: currentProject?.id,
        risk_pipeline_definition: currentRiskPipelineDefinition?.id,
        comment: '',
        sources: [],
      } as EvaluationProjectRisk)
    }
  }, [
    evaluationProjectRisksData,
    currentProject,
    currentRiskPipelineDefinition,
  ])

  const onSelectRiskPipelineDefinition = useCallback(
    (riskPipelineDefinition: RiskPipelineDefinition) => {
      setComment('')
      setSources([])
      setCurrentRiskPipelineDefinition(riskPipelineDefinition)
    },
    []
  )

  const onAddSource = useCallback(() => {
    if (!documentViewer) return
    if (!textSelected) return
    if (!currentDocument) return

    setSources((state) => [
      ...state,
      {
        text: textSelected?.text ?? '',
        page: textSelected?.pageNumber ?? 0,
        quads: mappedQuads ?? [],
        document: currentDocument.id,
      },
    ])
  }, [documentViewer, textSelected, currentDocument, mappedQuads])

  const onCreateCustomRisk = useCallback(async () => {
    if (!currentProject) return
    if (!currentProject.id) return
    if (!currentRiskPipelineDefinition) return
    if (!currentRiskPipelineDefinition.id) return
    if (!currentEvaulationProjectRisk) return
    if (isSubmitting) return
    if (currentEvaulationProjectRisk.id) {
      setIsSubmitting(true)
      const result = await updateEvaluationProjectRisk({
        id: currentEvaulationProjectRisk.id,
        comment: comment ?? '',
        sources: sources,
        project: currentProject.id,
        risk_pipeline_definition: currentRiskPipelineDefinition.id,
      }).unwrap()
      setIsSubmitting(false)
      if (result) {
        toast.success('Custom risk updated')
        setCurrentEvaulationProjectRisk(result)
      }
      return
    } else {
      setIsSubmitting(true)
      const result = await createEvaluationProjectRisk({
        risk_pipeline_definition: currentRiskPipelineDefinition.id,
        project: currentProject.id,
        comment: comment ?? '',
        sources: sources,
      }).unwrap()
      setIsSubmitting(false)
      if (result) {
        toast.success('Custom risk saved')
        setCurrentEvaulationProjectRisk(result)
      }
    }
  }, [
    currentProject,
    currentRiskPipelineDefinition,
    comment,
    sources,
    createEvaluationProjectRisk,
    updateEvaluationProjectRisk,
    currentEvaulationProjectRisk,
    isSubmitting,
  ])

  const onChangeCurrentRiskComment = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(e.target.value)
    },
    []
  )

  const onRemoveSource = useCallback((index: number) => {
    setSources((state) => {
      const newState = [...state]
      newState.splice(index, 1)
      return newState
    })
  }, [])

  return (
    <div className={'flex overflow-hidden'}>
      <div
        className={'w-56 flex-shrink-0 overflow-auto border-r border-gray-200'}
      >
        {riskPipelineDefinitionsData &&
          riskPipelineDefinitionsData.map((riskPipelineDefinition) => (
            <button
              key={riskPipelineDefinition.id}
              onClick={() =>
                onSelectRiskPipelineDefinition(riskPipelineDefinition)
              }
              className={`w-full p-2 text-left hover:bg-gray-200 ${
                currentRiskPipelineDefinition &&
                currentRiskPipelineDefinition.id ===
                  riskPipelineDefinition.id &&
                'bg-gray-200'
              }`}
            >
              {riskPipelineDefinition.risk_name}
            </button>
          ))}
      </div>
      <div className={'overflow-y-scroll'}>
        {currentRiskPipelineDefinition && (
          <>
            <div className={'border-b p-2'}>
              <div className={'font-semibold'}>
                {currentRiskPipelineDefinition.risk_name}
              </div>
              <div>{currentRiskPipelineDefinition.risk_description}</div>
            </div>
            <div className={'border-b p-2'}>
              <div className={'font-semibold'}>Question</div>
              <div>{currentRiskPipelineDefinition.query_text}</div>
            </div>
            <div>
              <div className={'p-2'}>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Answer
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={onChangeCurrentRiskComment}
                    name="comment"
                    id="comment"
                    value={comment}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className={'p-2'}>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Sources
                </label>
                <div className={'space-y-1'}>
                  {sources &&
                    sources.map((source, index) => (
                      <div key={index} className={'relative border p-1'}>
                        {source.text}
                        <button
                          className="block rounded border p-1 text-sm hover:bg-gray-100"
                          onClick={() => onRemoveSource(index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                </div>
                <div className={'flex justify-end gap-1 pt-2'}>
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${isSubmitting ? 'bg-indigo-200' : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'}`}
                    onClick={onAddSource}
                  >
                    Add source
                  </button>
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className={`shadow-smfocus-visible:outline  rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline-2 focus-visible:outline-offset-2 ${isSubmitting ? 'bg-indigo-200' : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'}`}
                    onClick={onCreateCustomRisk}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default RiskReviewQA
