import React from 'react'

const CommentLoadingCard: React.FC = () => {
  return (
    <div className="my-2 flex flex-col space-y-2 rounded bg-white p-2 shadow">
      <div className="h-4 w-1/2 animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-full animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-full animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-11/12 animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-1/3 animate-pulse self-end rounded border-l bg-gray-200 text-xs text-gray-500" />
    </div>
  )
}

export default CommentLoadingCard
