import React from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'

interface DraggableDocumentProps {
  children: React.ReactNode
  id: string
}

const DraggableDocument: React.FC<DraggableDocumentProps> = ({
  id,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  })
  const style = {
    transform: CSS.Translate.toString(transform),
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex overflow-hidden hover:bg-gray-50"
    >
      <svg
        {...listeners}
        {...attributes}
        className="w-4 shrink-0 cursor-move fill-current pr-0.5 text-gray-400"
        version="1.1"
        id="XMLID_308_"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <g id="drag">
          <path d="M10,6H6V2h4V6z M18,2h-4v4h4V2z M10,10H6v4h4V10z M18,10h-4v4h4V10z M10,18H6v4h4V18z M18,18h-4v4h4V18z" />
        </g>
      </svg>
      {children}
    </div>
  )
}
export default DraggableDocument
