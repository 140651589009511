import { useCallback, useContext, useEffect } from 'react'
import useWindowDimensions from './use-window-dimensions'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import { useDispatch, useSelector } from 'react-redux'
import { selectZoomLevel } from '../redux/viewer-slice'
import {
  selectCommentViewerTabWidth,
  setCommentViewerTabWidth,
} from '../redux/application-slice'

export const useCommentViewerTabWidth = () => {
  const { width } = useWindowDimensions()
  const zoomLevel = useSelector(selectZoomLevel)
  const tabWidth = useSelector(selectCommentViewerTabWidth)
  const dispatch = useDispatch()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const setSidebarTabWidth = useCallback(
    (width: number) => {
      dispatch(setCommentViewerTabWidth(width))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(setCommentViewerTabWidth(width / 3))
  }, [dispatch, width])

  const setDefaultTabWidth = useCallback(() => {
    setSidebarTabWidth(width / 2)
  }, [width, setSidebarTabWidth])

  const setHalfWidth = useCallback(() => {
    setSidebarTabWidth(width / 2)
  }, [setSidebarTabWidth, width])

  const setDocumentSidebarBig = useCallback(() => {
    setSidebarTabWidth(Math.round(width / 1.5))
  }, [setSidebarTabWidth, width])

  useEffect(() => {
    setTimeout(() => {
      documentViewer?.zoomTo(zoomLevel)
    }, 1)
  }, [documentViewer, tabWidth, zoomLevel])

  const handler = (mouseDownEvent) => {
    const onHandleDrag = (mouseMoveEvent) => {
      const startSize = tabWidth ?? width / 3
      const MAX_WIDTH = 5000
      const MIN_WIDTH = 0
      const newWidth = startSize + (mouseMoveEvent.pageX - mouseDownEvent.pageX)

      if (newWidth < MIN_WIDTH) {
        setSidebarTabWidth(MIN_WIDTH)
      } else if (newWidth > MAX_WIDTH) {
        setSidebarTabWidth(MAX_WIDTH)
      } else {
        setSidebarTabWidth(newWidth)
      }
    }

    const onMouseUp = () => {
      documentViewer?.zoomTo(zoomLevel)
      document.body.removeEventListener('mousemove', onHandleDrag)
    }

    document.body.addEventListener('mousemove', onHandleDrag)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  return {
    handler,
    tabWidth,
    setSidebarTabWidth,
    setDefaultTabWidth,
    setDocumentSidebarBig,
    setHalfWidth,
  }
}
