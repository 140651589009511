import React, { useCallback, useContext, useEffect } from 'react'
import NavigationHistoryPane from '../../viewer/navigation-history-pane'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCursorTool,
  setCursorTool,
  setZoomLevel,
} from '../../../redux/viewer-slice'
import { DocumentViewerContext } from '../../../contexts/document-viewer-instance-context'
import PageCount from '../../page-count'
import { Tooltip as MantineTooltip } from '@mantine/core'
import { useParams } from 'react-router-dom'

import DocumentViewerCursorMode from '../document-viewer-cursor-mode'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

const DocumentViewerOptionsHeader: React.FC = () => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()

  const { documentId } = useParams<{
    documentId: string
    projectId: string
  }>()

  const cursorTool = useSelector(selectCursorTool)

  useEffect(() => {
    if (cursorTool && documentViewer) {
      documentViewer?.setToolMode(documentViewer?.getTool(cursorTool))
    }
  }, [cursorTool, documentViewer])
  const onClickTextSelect = useCallback(() => {
    dispatch(setCursorTool('TextSelect'))
  }, [dispatch])

  const onClickPan = useCallback(() => {
    dispatch(setCursorTool('Pan'))
  }, [dispatch])

  const onZoomIncrease = useCallback(() => {
    const newLevel = Math.min((documentViewer?.getZoomLevel() ?? 0) + 0.15, 2)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  const onZoomDecrease = useCallback(() => {
    const newLevel = Math.max((documentViewer?.getZoomLevel() ?? 0) - 0.15, 0.3)
    documentViewer?.zoomTo(newLevel)
    dispatch(setZoomLevel(newLevel))
  }, [dispatch, documentViewer])

  return (
    <div className={'flex items-center space-x-2'}>
      <div className="z-50 flex items-center space-x-2">
        <NavigationHistoryPane />
        <DocumentViewerCursorMode
          documentId={documentId ?? ''}
          cursorTool={cursorTool}
          onClickPan={onClickPan}
          onClickTextSelect={onClickTextSelect}
        />
      </div>
      <div className="z-50 min-w-[76px] rounded-md transition-colors">
        <MantineTooltip
          label={
            <div className="flex flex-col justify-center p-1 text-center">
              <div className="flex items-center justify-center text-lg font-semibold">
                <PlusIcon width={20} className="mr-1" /> Zoom In
              </div>
            </div>
          }
          position="bottom"
          w="135px"
          multiline
          withArrow
        >
          <button
            disabled={!documentId}
            className={`rounded-l-lg bg-gray-100 p-2 shadow-sm ${
              documentId ? 'hover:bg-white' : ''
            }`}
            onClick={onZoomIncrease}
          >
            <PlusIcon width={20} />
          </button>
        </MantineTooltip>

        <MantineTooltip
          label={
            <div className="flex flex-col justify-center p-1 text-center">
              <div className="flex items-center justify-center text-lg font-semibold">
                <MinusIcon width={20} className="mr-1" /> Zoom Out
              </div>
            </div>
          }
          position="bottom"
          w="140px"
          multiline
          withArrow
        >
          <button
            disabled={!documentId}
            className={`border-top-left-0 !ml-0 rounded-r-lg bg-gray-100 p-2 shadow-sm ${
              documentId ? 'hover:bg-white' : ''
            }`}
            onClick={onZoomDecrease}
          >
            <MinusIcon width={20} />
          </button>
        </MantineTooltip>
      </div>
      <PageCount />
    </div>
  )
}
export default DocumentViewerOptionsHeader
