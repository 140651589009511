import React, { useEffect, useMemo } from 'react'
import { useGetDocumentChangesByProjectQuery } from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import Diff from '../workflows/comment-table/diff'
import {
  selectDocumentChange,
  setSelectedDocumentChange,
} from '../../redux/viewer-slice'
import { useDispatch } from 'react-redux'
import AddendaQACard from './addenda-qa-card'
import AddendaQADestinationCard from './addenda-qa-destination-card'
import MarkAddendaCompleteButton from '../qa-editor/mark-addenda-complete-button'

const AddendaQAPage = () => {
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const { currentData: documentChanges } = useGetDocumentChangesByProjectQuery(
    currentProject?.uuid ?? skipToken
  )

  const selectedDocumentChange = useSelector(selectDocumentChange)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedDocumentChange) {
      return
    }
    const matchingDocumentChange = documentChanges?.find(
      (change) => change.id === selectedDocumentChange.id
    )
    if (!matchingDocumentChange) {
      return
    }
    dispatch(setSelectedDocumentChange(matchingDocumentChange))
  }, [dispatch, documentChanges, selectedDocumentChange])

  const documentChangesToDisplay = useMemo(() => {
    return [...(documentChanges ?? [])].slice().sort((a, b) => {
      const quadsA = a?.source?.quads
      const quadsB = b?.source?.quads
      return (
        a?.source?.page - b?.source?.page || quadsA?.[0]?.y1 - quadsB?.[0]?.y1
      )
    })
  }, [documentChanges])

  const prettyStatus = useMemo(() => {
    return currentDocument?.job_status
      ?.toLocaleLowerCase()
      ?.replaceAll('_', ' ')
  }, [currentDocument?.job_status])

  const destinationsToDisplay = useMemo(() => {
    if (!selectedDocumentChange?.destinations) {
      return []
    }
    const sortedDestinations = [...selectedDocumentChange.destinations].sort(
      (a, b) => {
        return a.page - b.page || a.quads?.[0]?.y1 - b.quads?.[0]?.y1
      }
    )
    return sortedDestinations
  }, [selectedDocumentChange])

  return (
    <div className="flex h-full flex-grow flex-col space-y-3 overflow-hidden p-3">
      <div>
        <div className="text-sm capitalize">
          Document status: {prettyStatus}
        </div>
        <div className="flex space-x-2">
          <MarkAddendaCompleteButton />
        </div>
      </div>
      <div className={'flex h-full flex-1 overflow-hidden'}>
        <div
          className={
            'h-full flex-shrink-0 space-y-1 overflow-auto pb-5 text-sm'
          }
        >
          {documentChangesToDisplay?.map((documentChange) => {
            return (
              <AddendaQACard
                documentChange={documentChange}
                key={`document_change_card_${documentChange.id}`}
              />
            )
          })}
        </div>
        <div className={'flex-grow space-y-2 px-1'}>
          {selectedDocumentChange ? (
            <>
              <div className={'rounded border p-2'}>
                <div>{selectedDocumentChange.original_clause_reference}</div>
                <div className="h-full w-full overflow-auto text-xs">
                  <Diff
                    inputA={selectedDocumentChange.original_clause ?? ''}
                    inputB={selectedDocumentChange.reconciled_clause ?? ''}
                  />
                </div>
                <div className="overflow-auto p-2">
                  <div className="text-xs">
                    {selectedDocumentChange?.explanation}
                  </div>
                </div>
              </div>
              Sources:
              {destinationsToDisplay?.map((destination) => (
                <AddendaQADestinationCard
                  key={`document_change_destination_${destination.id}`}
                  destination={destination}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AddendaQAPage
