import React, { useCallback } from 'react'
import { OverlayAction, OverlayRefClicked } from '../page-overlay/page-overlay'

interface ContextMenuButtonProps {
  overlay: OverlayAction
  setOverlaysClicked: React.Dispatch<
    React.SetStateAction<OverlayRefClicked | null>
  >
}

const OverlayContextMenuButton: React.FC<ContextMenuButtonProps> = ({
  overlay,
  setOverlaysClicked,
}) => {
  const onClickAction = useCallback(
    (e) => {
      e.stopPropagation()
      setOverlaysClicked(null)
      overlay.action()
    },
    [overlay, setOverlaysClicked]
  )

  return (
    <button
      key={overlay.type}
      type={'button'}
      className={
        'z-50 flex h-8 cursor-pointer items-center rounded p-1 text-left text-xs hover:bg-gray-100'
      }
      onMouseDown={onClickAction}
    >
      {overlay.label}
    </button>
  )
}

export default OverlayContextMenuButton
