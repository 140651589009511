import React from 'react'

import { Paper, Text } from '@mantine/core'
import { ProjectRisk } from '../../../../shared/interfaces/project/risk/risk-inteface'

export const NumberOfRisksPopover = ({
  risksToDisplay,
  projectRisks,
}: {
  risksToDisplay: ProjectRisk[]
  projectRisks: ProjectRisk[]
}) => (
  <Paper
    className="group ml-2 !cursor-default whitespace-nowrap !rounded-lg border p-2.5"
    styles={() => ({
      root: {
        backgroundColor: '#e5e7eb',
        cursor: 'pointer',
      },
    })}
  >
    <div className="flex items-center justify-center">
      <div className="flex flex-col">
        <div className="flex text-gray-600">
          <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
            {risksToDisplay.length === 0
              ? 'No risks found'
              : risksToDisplay.length === projectRisks?.length
                ? `Viewing ${risksToDisplay.length} Risks`
                : `Viewing ${risksToDisplay.length} of ${projectRisks?.length} Risks`}
          </Text>
        </div>
      </div>
    </div>
  </Paper>
)
