import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface SortableDocumentProps {
  id: string | number
  children: React.ReactNode
  isSelected?: boolean
}

const SortableDocument: React.FC<SortableDocumentProps> = ({
  id,
  children,
  isSelected = false,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="relative flex items-center hover:bg-gray-50"
    >
      <svg
        {...listeners}
        {...attributes}
        className="z-20 w-4 shrink-0 cursor-move fill-current pr-0.5 text-gray-400"
        version="1.1"
        id="XMLID_308_"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        data-playwright={`drag_handle_${id}`}
      >
        <g id="drag">
          <path d="M10,6H6V2h4V6z M18,2h-4v4h4V2z M10,10H6v4h4V10z M18,10h-4v4h4V10z M10,18H6v4h4V18z M18,18h-4v4h4V18z" />
        </g>
      </svg>
      {isSelected ? (
        <div className="absolute left-1 z-10 text-indigo-500">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 18l6-6-6-6v12z" fill="currentColor" />
          </svg>
        </div>
      ) : null}
      {children}
    </div>
  )
}
export default SortableDocument
