import React, { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import { Popover } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useGetTaskStatsQuery } from '../../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { ProjectPermissionUser } from '../../../shared/interfaces/project/permissions/project-permissions.interface'

interface FilteringPageTaskFilterProps {
  selectUserFilter: (user: ProjectPermissionUser) => void
}

const FilteringPageTaskFilter: React.FC<FilteringPageTaskFilterProps> = ({
  selectUserFilter,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const { data: taskStats } = useGetTaskStatsQuery(
    currentProject?.id ? { projectId: currentProject.id } : skipToken
  )

  const onSelectUser = useCallback(
    (user: ProjectPermissionUser) => {
      selectUserFilter(user)
      buttonRef?.click()
    },
    [buttonRef, selectUserFilter]
  )

  return (
    <Popover className="relative">
      <Popover.Button
        className={'h-full rounded border px-3 py-1.5 hover:bg-gray-100'}
        tabIndex={-1}
        ref={setButtonRef}
      >
        Tasks assigned to
      </Popover.Button>

      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="absolute z-50 flex flex-col rounded border bg-white text-xs shadow"
      >
        {taskStats?.map((taskStat) => (
          <button
            key={`task_filter_${taskStat.assigned_users?.id}`}
            onClick={() => onSelectUser(taskStat.assigned_users)}
            className={
              'flex cursor-pointer items-center justify-between space-x-1 truncate px-4 py-2 text-left hover:bg-gray-100'
            }
          >
            <div>{taskStat.assigned_users.email}</div>
            <div className="relative top-[0.07rem] text-[0.6rem] font-bold text-gray-700">
              {taskStat.count}
            </div>
          </button>
        ))}
      </Popover.Panel>
    </Popover>
  )
}

export default FilteringPageTaskFilter
