import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  FunnelIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline'
import { Workflow } from './workflow-popover'
import { createElement } from 'react'

// Document Icon From Phosphor Icons
const DocumentIcon = () => {
  return createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '22',
      height: '22',
      fill: 'currentColor',
      viewBox: '0 0 256 256',
      className: 'mr-1',
    },
    createElement('path', {
      d: 'M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z',
    })
  )
}

export const workflows: Workflow[] = [
  {
    title: 'Documents',
    Icon: DocumentIcon,
    route: 'documents',
  },
  {
    title: 'Risk Review',
    Icon: ClipboardDocumentCheckIcon,
    route: 'riskreview',
  },
  {
    title: 'Chat',
    Icon: ChatBubbleBottomCenterIcon,
    route: 'chat',
  },
  {
    title: 'Comments',
    Icon: ChatBubbleLeftRightIcon,
    route: 'comments',
  },
  {
    title: 'Clause Filtering',
    Icon: FunnelIcon,
    route: 'clause-filters',
  },

  {
    title: 'Supplementary Conditions',
    Icon: Square2StackIcon,
    route: 'supplementary-conditions',
  },
  {
    title: 'Quality Assurance',
    Icon: ClipboardDocumentCheckIcon,
    route: 'quality-assurance',
    featureFlag: true,
  },
]
