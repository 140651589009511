import React, { useCallback, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../redux/application-slice'
import {
  ChatObject,
  ChatSessionMessageSource,
  Explanation,
} from '../shared/interfaces/chat/chat-history.interface'
import { auth0TokenHelper } from '../utils/auth0-token-helper'

interface ChatContext {
  messages: ChatObject
  explanation?: Explanation
  setExplanation: (explanation: Explanation) => void
  thinking: boolean
  setMessages: (messages: ChatObject) => void
  writing: boolean
  onMessage: (message: string, documentIDs?: number[]) => void
  connected: boolean
  setSessionUuid: (uuid: string | null) => void
  sessionUuid?: string | null
  onCancel: () => void
  sendMessage: (message: string) => void
  onCheckConflicts: (message: string, chat_session_message_id: string) => void
  sendExplainRequest: (
    clause: string,
    projectUUID: string,
    documentUUID: string
  ) => void
  lastMessage?: { data } | null
  conflicts: ChatObject
  conflictsThinking: {
    [chat_session_message_id: string]: boolean
  }
  conflictsWriting: {
    [chat_session_message_id: string]: boolean
  }
}

export const WebsocketContext = React.createContext<ChatContext>({
  messages: {},
  explanation: undefined,
  thinking: false,
  writing: false,
  connected: false,
  setMessages: () => {
    //default to empty
  },
  setExplanation: () => {
    //default to empty
  },
  setSessionUuid: () => {
    //default to empty
  },
  onMessage: () => {
    //default to empty
  },
  onCancel: () => {
    //default to empty
  },
  sendMessage: () => {
    //default to empty
  },
  sendExplainRequest: () => {
    //default to empty
  },
  conflicts: {},
  conflictsThinking: {},
  conflictsWriting: {},
  onCheckConflicts: () => {
    //default to empty
  },
})

interface WebsocketProviderProps {
  children: React.ReactNode
}

export function WebsocketProvider(props: WebsocketProviderProps) {
  const [connected, setConnected] = useState(false)
  const [sessionUuid, setSessionUuid] = useState<string | null>(null)
  const [messages, setMessages] = useState<ChatObject>({})
  const [conflicts, setConflicts] = useState<ChatObject>({})
  const [explanation, setExplanation] = useState<Explanation>()
  const [conflictsThinking, setConflictsThinking] = useState<{
    [chat_session_message_id: string]: boolean
  }>({})
  const [conflictsWriting, setConflictsWriting] = useState<{
    [chat_session_message_id: string]: boolean
  }>({})
  const [thinking, setThinking] = useState(false)
  const [writing, setWriting] = useState(false)
  const currentProject = useSelector(selectCurrentProject)

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve: (value: string) => void) => {
      auth0TokenHelper
        .getAccessTokenSilently()()
        .then((access_token) => {
          resolve(
            `${process.env.REACT_APP_PROVISION_API_BASE_URL?.replace(
              'http',
              'ws'
            )}ws/chat/?token=${access_token}`
          )
        })
    })
  }, [])

  const { sendMessage, lastMessage, readyState } = useWebSocket(getSocketUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  })

  useEffect(() => {
    const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]
    if (connectionStatus === 'Open') {
      setConnected(true)
    }
    if (connectionStatus === 'Closed') {
      setConnected(false)
    }
  }, [readyState])

  const onCancel = useCallback(() => {
    sendMessage(
      JSON.stringify({
        action: 'chat.cancel',
        content: '',
        session_uuid: sessionUuid,
        index: currentProject?.uuid,
      })
    )
    setThinking(false)
    setWriting(false)
    setExplanation(undefined)
  }, [currentProject?.uuid, sessionUuid, sendMessage])

  const sendExplainRequest = useCallback(
    (message: string, projectUUID: string, documentUUID: string) => {
      setMessages((state) => {
        const newData = { ...state }
        newData[uuidv4()] = {
          content: message,
          source: 'user',
        }
        return newData
      })
      sendMessage(
        JSON.stringify({
          action: 'document.explain',
          content: message,
          project_uuid: projectUUID,
          document_uuid: documentUUID,
        })
      )
      setThinking(true)
      setWriting(true)
    },
    [sendMessage]
  )

  const onMessage = (message, documentIDs?: number[]) => {
    setMessages((state) => {
      const newData = { ...state }
      newData[uuidv4()] = {
        content: message,
        source: 'user',
      }
      return newData
    })
    sendMessage(
      JSON.stringify({
        action: 'chat.message',
        content: message,
        session_uuid: sessionUuid,
        index: currentProject?.uuid,
        document_ids: documentIDs,
      })
    )
    setThinking(true)
    setWriting(true)
  }

  const onCheckConflicts = (
    message: string,
    chat_session_message_id: string
  ) => {
    setConflicts((state) => {
      const newData = { ...state }
      newData[uuidv4()] = {
        content: message,
        source: 'user',
      }
      return newData
    })
    sendMessage(
      JSON.stringify({
        action: 'chat.conflicts',
        content: message,
        session_uuid: sessionUuid,
        index: currentProject?.uuid,
        chat_session_message_id,
      })
    )
    setConflictsThinking((c) => {
      return {
        ...c,
        [chat_session_message_id]: true,
      }
    })
    setConflictsWriting((c) => {
      return {
        ...c,
        [chat_session_message_id]: true,
      }
    })
  }

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data)

      if (
        data.action === 'chat.message_stream_end' ||
        data.action === 'document.explain_stream_end'
      ) {
        setWriting(false)
        setMessages((state) => {
          const newData = { ...state }
          newData[data.message_id] = {
            ...state[data.message_id],
            chat_session_message_id: data.chat_session_message_id,
          }
          return newData
        })
      }

      if (data.action === 'chat.conflict_stream_end') {
        setConflictsWriting((c) => {
          return {
            ...c,
            [data.message_id]: false,
          }
        })
      }

      setSessionUuid(data.session_uuid)

      if (data.action === 'chat.message_sources_ready') {
        setMessages((state) => {
          const newData = { ...state }
          const sources: ChatSessionMessageSource[] = JSON.parse(data.content)

          newData[data.message_id] = {
            content: state[data.message_id].content,
            session_uuid: data.session_uuid,
            source: 'system',
            sources,
            source_documents: data.source_documents ?? [],
          }

          return newData
        })
      }

      if (data.action === 'chat.message') {
        setThinking(false)
        setMessages((state) => {
          const newData = { ...state }

          const newAnswer = `${
            state[data.message_id] ? state[data.message_id].content : ''
          }${data.content}`

          newData[data.message_id] = {
            content: newAnswer,
            session_uuid: data.session_uuid,
            source: 'system',
            sources: data.sources !== undefined ? data.sources : [],
            source_documents: data.source_documents ?? [],
            chat_session_message_id: data.chat_session_message_id,
          }

          return newData
        })
      }

      if (data.action === 'chat.conflict.message') {
        setConflictsThinking((c) => {
          return {
            ...c,
            [data.message_id]: false,
          }
        })
        setConflicts((state) => {
          const newData = { ...state }

          const newAnswer = `${
            state[data.message_id] ? state[data.message_id].content : ''
          }${data.content}`

          newData[data.message_id] = {
            content: newAnswer,
            session_uuid: data.session_uuid,
            source: 'system',
            sources: data.sources !== undefined ? data.sources : [],
            source_documents: data.source_documents ?? [],
          }

          return newData
        })
      }

      if (data.action === 'document.explain') {
        setThinking(false)
        setExplanation((explanation) => {
          let content = explanation?.content ?? ''
          content += data.content
          return {
            action: data.action ?? 'document.explain',
            content,
          }
        })
      }
    }
  }, [lastMessage])

  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
        sendExplainRequest,
        explanation,
        setExplanation,
        lastMessage,
        connected,
        onMessage,
        messages,
        thinking,
        setMessages,
        setSessionUuid,
        sessionUuid,
        onCancel,
        writing,
        onCheckConflicts,
        conflicts,
        conflictsThinking,
        conflictsWriting,
      }}
    >
      {props.children}
    </WebsocketContext.Provider>
  )
}
