import React, { useCallback, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { Popover } from '@headlessui/react'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'

interface CommentFilterPopoverProps {
  onSelectUser: (user: ProvisionUser) => void
  commentAuthors: ProvisionUser[]
}

const CommentFilterPopover: React.FC<CommentFilterPopoverProps> = ({
  onSelectUser,
  commentAuthors,
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)

  const groupedCommentAuthors: ProvisionUser[] = useMemo(() => {
    if (!commentAuthors) {
      return []
    }
    const userDict: { [id: string]: ProvisionUser } = {}
    for (const author of commentAuthors) {
      if (!author?.id) {
        continue
      }
      userDict[author?.id] = author
    }
    const users: ProvisionUser[] = []
    for (const key of Object.keys(userDict)) {
      const user = userDict[key]
      users.push(user)
    }
    return users
  }, [commentAuthors])

  const onSelectAuthor = useCallback(
    (author) => {
      onSelectUser(author)
      buttonRef?.click()
    },
    [buttonRef, onSelectUser]
  )

  return (
    <Popover className="relative">
      <Popover.Button
        className={'h-full rounded border px-3 py-1 hover:bg-gray-100'}
        tabIndex={-1}
        ref={setButtonRef}
      >
        Created by
      </Popover.Button>

      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="absolute z-50 flex flex-col rounded border bg-white text-xs shadow"
      >
        {groupedCommentAuthors.length === 0 && (
          <div className={'w-32 px-3 py-2'}>No comments to filter</div>
        )}
        {groupedCommentAuthors.map((author) => {
          return (
            <button
              key={`comment_author_filter_${author?.id}`}
              onClick={() => onSelectAuthor(author)}
              className={
                'cursor-pointer truncate px-4 py-2 text-left hover:bg-gray-100'
              }
            >
              {author.email}
            </button>
          )
        })}
      </Popover.Panel>
    </Popover>
  )
}

export default CommentFilterPopover
