import React from 'react'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import {
  ProjectRisk,
  RiskCategory,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { RiskWorkflowFilterState } from '../../../../redux/workflow-slice'
import { Divider, Paper } from '@mantine/core'
import { ExclamationCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { RiskListHeader } from './risk-list-header'
import { RiskList } from '../../../../shared/interfaces/project/risk/risk-list-interface'

export const MemoizedNoProjectRisks = React.memo(
  ({
    selectedDocuments,
    riskListSearchQuery,
    currentSort,
    selectedRiskList,
    riskWorkflowFilterState,
    selectedCategory,
    categoryName,
    onExcelExport,
    projectRisks,
    risksToDisplay,
    onExcelPDF,
    riskCategories,
    handleResetAllFilters,
  }: {
    selectedDocuments: ProjectDocumentMetadata[]
    riskListSearchQuery: string
    currentSort: string
    selectedRiskList: RiskList | null
    riskWorkflowFilterState: RiskWorkflowFilterState
    selectedCategory: string | null
    categoryName: string
    onExcelExport: () => void
    projectRisks: ProjectRisk[]
    risksToDisplay: ProjectRisk[]
    onExcelPDF: () => void
    riskCategories: RiskCategory[]
    handleResetAllFilters: () => void
  }) => (
    <div className="h-[calc(100%-104px)] p-4 pr-5 pt-3">
      <RiskListHeader
        selectedDocuments={selectedDocuments}
        riskListSearchQuery={riskListSearchQuery}
        currentSort={currentSort}
        selectedRiskList={selectedRiskList}
        riskWorkflowFilterState={riskWorkflowFilterState}
        riskCategories={riskCategories}
        onExcelExport={onExcelExport}
        projectRisks={projectRisks}
        risksToDisplay={risksToDisplay}
        onExcelPDF={onExcelPDF}
        selectedCategory={selectedCategory}
        categoryName={categoryName}
      />
      <Divider color="#d1d1d1" mt="12px" mb="16px" size="xs" w="100%" />
      <div className="flex h-full flex-col items-center justify-center px-16 text-center text-xl text-gray-700">
        <div className="flex items-center justify-center text-xl">
          <ExclamationCircleIcon className="mr-2 h-8 w-8" />
          <div>No risks found</div>
        </div>
        <Divider color="#d1d1d1" my="16px" size="xs" w="45%" />
        <Paper
          onClick={handleResetAllFilters}
          style={{ width: '180px' }}
          className="group cursor-pointer !rounded-lg border !border-red-400 px-2 py-1.5 transition-all duration-300 ease-in-out hover:!bg-red-100"
        >
          <div className="flex items-center justify-center">
            <div className="relative flex h-5 w-5 flex-shrink-0 items-center">
              <TrashIcon className="text-red-500" />
            </div>
            <div className="ml-1 flex items-center justify-center font-[600]">
              <span className="text-[16px] font-normal text-red-500">
                Clear all Filters
              </span>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  )
)

MemoizedNoProjectRisks.displayName = 'MemoizedNoProjectRisks'
