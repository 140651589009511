import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import Diff from './diff'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentCommentDocuments,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
  selectSelectedRevision,
  setTemporaryHighlight,
  TemporaryHighlight,
} from '../../../redux/viewer-slice'
import TextareaAutosize from 'react-textarea-autosize'
import { isRatio } from '../../../utils/convert-quads'
import DocumentReference from '../../document-reference'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { DocumentViewerContext } from '../../../contexts/document-viewer-instance-context'
import RevisionCommentComponent from '../../overlay/revision-comment-component'
import RevisionCreateComment from '../../overlay/revision-create-comment'
import CommentCardPopover from './comment-card-popover'
import RevisionHistoryPopover from '../../revisions/revision-history-popover'
import RevisionStatusBadge from '../../revisions/revision-status-badge'
import RevisionCustomLabel from '../../revisions/revision-custom-label'
import { useBackButton } from '../../../hooks/use-back-button'
import { useUpdateRevisionMutation } from '../../../redux/api/api-revisions-slice'

interface CommentCardProps {
  revision: Revision
  hasDuplicatedComments: boolean
  revisionVersion: number
}

// eslint-disable-next-line complexity
const CommentTableCard: React.FC<CommentCardProps> = ({
  revision,
  hasDuplicatedComments,
  revisionVersion,
}) => {
  const currentCommentDocuments = useSelector(selectCurrentCommentDocuments)
  const [updateRevision] = useUpdateRevisionMutation()
  const { documentViewer } = useContext(DocumentViewerContext)
  const ref = useRef<HTMLDivElement>(null)

  const currentProject = useSelector(selectCurrentProject)
  const selectedRevision = useSelector(selectSelectedRevision)

  const isSelectedRevision = useMemo(() => {
    return selectedRevision?.id === revision.id
  }, [selectedRevision, revision])

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [editText, setEditText] = useState<string>(revision.revised_text ?? '')
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false)
  const [sectionReferenceEdit, setSectionReferenceEdit] = useState<string>(
    revision.section_reference ?? ''
  )
  const { addToNavigationHistoryAndNavigate } = useBackButton()

  const dispatch = useDispatch()

  useEffect(() => {
    setEditText(revision.revised_text ?? '')
  }, [revision.revised_text])

  const reset = useCallback(() => {
    setIsEditMode(false)
    setEditText('')
  }, [])

  useEffect(() => {
    if (selectedRevision?.id === revision.id) {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [revision.id, selectedRevision])

  const onUpdateRevision = useCallback(() => {
    updateRevision({
      id: revision?.id,
      content: {
        ...revision,
        documents: currentCommentDocuments?.length
          ? currentCommentDocuments.map((d) => d.id)
          : undefined,
        project: !currentCommentDocuments?.length
          ? currentProject?.id
          : undefined,
        parent: revision,
        revised_text: editText,
        section_reference: sectionReferenceEdit,
      },
    })
    reset()
  }, [
    updateRevision,
    revision,
    currentCommentDocuments,
    currentProject?.id,
    editText,
    sectionReferenceEdit,
    reset,
  ])

  const setHighlight = useCallback(
    (
      revision: Revision,
      source: { source_document_uuid: string | undefined; source: number }
    ) => {
      navigate(
        `/${currentProject?.uuid}/comments/${source.source_document_uuid}`
      )
      const page = source.source.toString()
      searchParams.set('page', page)
      setSearchParams(searchParams)
      const highlightIDs: string[] = []
      const temporaryHighlights: TemporaryHighlight[] = []

      for (const segment of revision?.segments ?? []) {
        highlightIDs.push(uuidv4())
        temporaryHighlights.push({
          page: segment.page,
          quads: segment?.quads ?? [],
          id: uuidv4(),
          pdfCoords: isRatio(segment?.quads ?? []),
        })
      }
      dispatch(setTemporaryHighlight(temporaryHighlights))
      setTimeout(() => {
        const el = document?.getElementById(highlightIDs?.[0] ?? '')
        if (!el) {
          return
        }
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 500)
    },
    [currentProject?.uuid, dispatch, navigate, searchParams, setSearchParams]
  )

  const onPressEnter = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onUpdateRevision()
      } else if (e.key === 'Enter' && e.shiftKey) {
        setEditText(`${editText}\n`)
      } else if (e.key === 'Escape') {
        reset()
      }
    },
    [editText, onUpdateRevision, reset]
  )

  const onChange = useCallback((e) => {
    setEditText(e.target.value)
  }, [])

  const onClickDocumentReference = useCallback(() => {
    const page = documentViewer?.getCurrentPage()
    if (!page || !revision?.document_uuid) {
      return
    }
    addToNavigationHistoryAndNavigate(
      'documents',
      revision?.document_uuid,
      revision?.page
    )
    setHighlight(revision, {
      source: revision.page ?? 1,
      source_document_uuid: revision.document_uuid,
    })
  }, [
    documentViewer,
    revision,
    addToNavigationHistoryAndNavigate,
    setHighlight,
  ])

  const onCommentsClick = useCallback(() => {
    setCommentsOpen(!commentsOpen)
  }, [commentsOpen])

  const handleSectionReferenceTextChange = useCallback((e) => {
    setSectionReferenceEdit(e.target.value)
  }, [])

  const renderSectionReference = useMemo(() => {
    if (isEditMode) {
      return (
        <input
          type="text"
          onChange={handleSectionReferenceTextChange}
          value={sectionReferenceEdit}
          placeholder={'Enter section reference'}
          className="custom-scroll m-0 block w-full resize-none whitespace-pre rounded-sm border border-gray-100 p-0 text-xs text-gray-500 focus:border focus:border-indigo-500 focus:ring-indigo-500"
        />
      )
    } else {
      return (
        <div
          className={
            'overflow-hidden text-ellipsis whitespace-nowrap border border-transparent text-xs text-gray-500'
          }
        >
          {revision?.section_reference ?? 'No section reference'}
        </div>
      )
    }
  }, [
    isEditMode,
    handleSectionReferenceTextChange,
    sectionReferenceEdit,
    revision?.section_reference,
  ])
  const remainingCommentCount = useMemo(() => {
    if (!revision.comments?.length) {
      return 0
    }
    if (revision?.comments?.length >= 0 && revision?.comments?.length <= 2) {
      return 0
    }
    return revision?.comments?.length - 2
  }, [revision])

  return (
    <div
      ref={ref}
      className={`flex flex-col items-stretch rounded border ${isSelectedRevision && 'border-l-2 border-l-gray-500'} bg-white`}
    >
      <div className="flex items-center justify-between border-b p-2">
        <RevisionCustomLabel revision={revision} />
        <div className={'flex items-center gap-1'}>
          {!revision.page ? (
            <div className="inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              Comment Could Not Be Matched
            </div>
          ) : (
            <RevisionStatusBadge revision={revision} />
          )}
          <CommentCardPopover
            source={'comment_table'}
            setEditRevision={setIsEditMode}
            revision={revision}
          />
        </div>
      </div>
      <div className="border-b px-2">{renderSectionReference}</div>
      <div className={'w-full p-2'}>
        {isEditMode ? (
          <>
            <TextareaAutosize
              data-cy="revision-edit-textarea"
              className={
                'w-full flex-grow resize-none rounded-sm border-0 border-white  p-1 text-sm text-gray-800 placeholder-gray-400 focus:border focus:border-none focus:border-gray-300 focus:text-black focus:outline-none focus:ring-0'
              }
              autoFocus
              onChange={onChange}
              value={editText}
              onKeyDown={onPressEnter}
            />
            <div className={'mr-2 flex justify-end gap-2 pt-1'}>
              <button
                type="button"
                className="self-start rounded border bg-white px-4 py-1 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={reset}
              >
                Cancel
              </button>
              <button
                type="button"
                className="self-start rounded border border-indigo-600 bg-indigo-600 px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onUpdateRevision}
              >
                Save
              </button>
            </div>
          </>
        ) : (
          <div className="text-left text-sm text-gray-900">
            {revision.revised_text === null ? (
              <div>{revision?.original_text}</div>
            ) : (
              <Diff
                inputA={revision?.original_text ?? ''}
                inputB={
                  revision.v1_revision
                    ? revision.v2_text ?? ''
                    : revision?.revised_text ?? ''
                }
              />
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between gap-2 p-2 text-xs text-gray-400">
        <RevisionHistoryPopover revision={revision} />
        <DocumentReference
          onClick={onClickDocumentReference}
          documentTitle={revision.document_title ?? ''}
          pageNumber={revision.page ?? null}
        />
      </div>

      <div className="space-y-2 border-t py-1 text-sm">
        {revision?.comments?.[0] ? (
          <RevisionCommentComponent
            comment={revision?.comments?.[0]}
            source={'document'}
            revision={revision}
            isTableView={true}
          />
        ) : null}
        {remainingCommentCount > 0 ? (
          <button
            data-cy="revision-comments-expand-button"
            onClick={onCommentsClick}
            className={
              'flex w-full items-center justify-start gap-1 border-t p-1 px-2 text-xs text-gray-500 hover:bg-gray-100'
            }
          >
            <span>
              {commentsOpen ? 'Hide' : 'Show'} ({remainingCommentCount})
              Comments
            </span>
            {commentsOpen ? (
              <ChevronUpIcon width={18} />
            ) : (
              <ChevronDownIcon width={18} />
            )}
          </button>
        ) : null}
        {commentsOpen &&
          revision?.comments?.map((comment, index) => {
            if (
              index === 0 ||
              index === (revision?.comments?.length ?? 0) - 1
            ) {
              return null
            }
            return (
              <RevisionCommentComponent
                key={comment.id}
                comment={comment}
                source={
                  currentCommentDocuments?.length ? 'document' : 'project'
                }
                revision={revision}
                isTableView={true}
              />
            )
          })}
        {revision?.comments?.[revision?.comments?.length - 1] &&
        revision?.comments?.length >= 2 ? (
          <RevisionCommentComponent
            comment={revision?.comments?.[revision?.comments?.length - 1]}
            source={'document'}
            revision={revision}
            isTableView={true}
          />
        ) : null}
        {revision && revision.id && (
          <RevisionCreateComment revision={revision} />
        )}
      </div>
    </div>
  )
}

export default CommentTableCard
