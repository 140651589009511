import React, { useCallback } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useDeleteRiskPipelineDefinitionMutation } from '../../redux/api/project-risk-api-slice'
import { toast } from 'react-toastify'
import { selectModal, setModal } from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModal from './delete-modal'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'

interface DeleteRiskModalProps {
  open: boolean
}

const DeleteCustomRiskModal: React.FC<DeleteRiskModalProps> = ({ open }) => {
  const [deleteRisk] = useDeleteRiskPipelineDefinitionMutation()
  const dispatch = useDispatch()
  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])
  const modal = useSelector(selectModal)
  const posthog = usePostHog()
  const handleDelete = useCallback(async () => {
    try {
      await deleteRisk({
        id: modal?.props?.id,
        risk_list_template_id: modal?.props?.risk_list_template_id,
      }).unwrap()
      posthog?.capture(POSTHOG.custom_riskpipelinedefinition_deleted, {
        risk_id: modal?.props?.id,
        risk_list_template_id: modal?.props?.risk_list_template_id,
      })
      onClose()
      toast.success('Risk deleted successfully')
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }, [
    deleteRisk,
    modal?.props?.id,
    modal?.props?.risk_list_template_id,
    onClose,
    posthog,
  ])

  return (
    <DeleteModal
      open={open}
      onClose={onClose}
      title="Delete Risk"
      body={
        <p className="text-sm text-gray-500">
          Are you sure you want to delete &quot;{modal?.props?.risk_name}&quot;?{' '}
          <br />
          This action cannot be undone.
        </p>
      }
      icon={
        <ExclamationTriangleIcon
          className="h-6 w-6 text-red-600"
          aria-hidden="true"
        />
      }
      confirmText="Delete"
      cancelText="Cancel"
      onConfirm={handleDelete}
    />
  )
}

export default DeleteCustomRiskModal
