import React, { forwardRef } from 'react'
import { LabelSegment } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { useSelector } from 'react-redux'
import { selectSelectedCustomLabel } from '../../redux/viewer-slice'

interface PageOverlayLabelProps {
  labelSegment: LabelSegment
  coords: {
    width: number
    height: number
    topOffset: number
    leftOffset: number
  }
  shouldElevate: boolean
}

const PageOverlayLabel = forwardRef<HTMLButtonElement, PageOverlayLabelProps>(
  ({ labelSegment, coords, shouldElevate }, ref) => {
    const selectedLabelID = useSelector(selectSelectedCustomLabel)

    return (
      <button
        ref={ref}
        className={`absolute ${selectedLabelID === labelSegment?.id ? 'bg-violet-200' : 'bg-violet-100'} opacity-20 ${
          shouldElevate ? 'z-50' : 'z-30'
        }`}
        style={{
          width: `${coords.width}%`,
          height: `${coords.height}%`,
          top: `${coords.topOffset}%`,
          left: `${coords.leftOffset}px`,
        }}
      />
    )
  }
)

PageOverlayLabel.displayName = 'PageOverlayLabel'

export default PageOverlayLabel
