// secondaryViewer slice

import { createSlice } from '@reduxjs/toolkit'
import { Quads } from '../shared/interfaces/quads.interface'
import { Highlight } from '../shared/interfaces/project/document/highlight/highlight.interface'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'
import { TextSelected } from './viewer-slice'

export interface SelectedRisk {
  riskID: number
  labelID: number
}

export type SidebarTabType =
  | 'chat'
  | 'bookmarks'
  | 'contents'
  | 'search'
  | 'risks'

export interface TemporaryHighlight {
  pdfCoords?: boolean
  quads: Quads[]
  page: number
  id?: string
}

interface ViewerState {
  totalPages: number
  currentPage: number
  currentSidebarWidth: string
  zoomLevel: number
  textSelected: TextSelected | null
  leftPaneOpen: boolean
  newRevision: Revision | null
  openRevision: Revision | null
  showHighlights: boolean
  documentLoaded: boolean
  highlightSelected: Highlight | null
  navigationHistory: string[]
  temporaryHighlight: TemporaryHighlight[] | null
  cursorTool: string
  sidebarTab: SidebarTabType
  selectedDocumentChange?: string
  highlightedDocumentChange?: string
  selectedRisk?: SelectedRisk | null
  customLabelOpen: boolean
  iconMenuOpen: number | null
  bookmarkTab: 'bookmarks' | 'revisions' | 'labels'
  selectedCustomLabel: number | null
  currentDocumentViewerWidth: string
  editorMode: boolean
}

const initialState: ViewerState = {
  totalPages: 0,
  currentPage: 1,
  currentSidebarWidth: '400px',
  currentDocumentViewerWidth: '400px',
  zoomLevel: 1,
  textSelected: null,
  leftPaneOpen: true,
  newRevision: null,
  openRevision: null,
  showHighlights: true,
  documentLoaded: true,
  highlightSelected: null,
  navigationHistory: [],
  temporaryHighlight: null,
  cursorTool: 'TextSelect',
  sidebarTab: 'chat',
  selectedRisk: null,
  customLabelOpen: false,
  iconMenuOpen: null,
  bookmarkTab: 'bookmarks',
  selectedCustomLabel: null,
  editorMode: false,
}

export const secondaryViewerSlice = createSlice({
  name: 'secondaryViewer',
  initialState,
  reducers: {
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setCurrentSidebarWidth: (state, action) => {
      state.currentSidebarWidth = action.payload
    },
    setDocumentViewerWidth: (state, action) => {
      state.currentDocumentViewerWidth = action.payload
    },
    setZoomLevel: (state, action) => {
      state.zoomLevel = action.payload
    },
    setTextSelected: (
      state,
      action: {
        payload: TextSelected | null
        type: string
      }
    ) => {
      state.textSelected = action.payload
    },
    setLeftPaneOpen: (state, action) => {
      state.leftPaneOpen = action.payload
    },
    setNewRevision: (state, action) => {
      state.newRevision = action.payload
    },
    setOpenRevision: (state, action) => {
      state.openRevision = action.payload
    },
    setShowHighlights: (state, action) => {
      state.showHighlights = action.payload
    },
    setDocumentLoaded: (state, action) => {
      state.documentLoaded = action.payload
    },
    setHighlightSelected: (state, action) => {
      state.highlightSelected = action.payload
    },
    setNavigationHistory: (state, action) => {
      state.navigationHistory = action.payload
    },
    addNavigationHistory: (state, action) => {
      const currentState = state.navigationHistory
      state.navigationHistory = [...currentState, action.payload]
    },
    setTemporaryHighlight: (
      state,
      action: {
        payload: TemporaryHighlight[] | null
        type: string
      }
    ) => {
      state.temporaryHighlight = action.payload
    },
    setCursorTool: (state, action) => {
      state.cursorTool = action.payload
    },
    setSelectedDocumentChange: (state, action) => {
      state.selectedDocumentChange = action.payload
    },
    setHighlightedDocumentChange: (state, action) => {
      state.highlightedDocumentChange = action.payload
    },
    setSidebarTab: (
      state,
      action: {
        payload: SidebarTabType
        type: string
      }
    ) => {
      state.sidebarTab = action.payload
    },
    setSelectedRisk: (
      state,
      action: {
        payload: SelectedRisk | null
        type: string
      }
    ) => {
      state.selectedRisk = action.payload
    },
    setCustomLabelOpen: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.customLabelOpen = action.payload
    },
    setIconMenuOpen: (
      state,
      action: {
        payload: number | null
        type: string
      }
    ) => {
      state.iconMenuOpen = action.payload
    },
    setBookmarkTab: (
      state,
      action: {
        payload: 'bookmarks' | 'revisions' | 'labels'
        type: string
      }
    ) => {
      state.bookmarkTab = action.payload
    },
    setSelectedCustomLabel: (
      state,
      action: {
        payload: number | null
        type: string
      }
    ) => {
      state.selectedCustomLabel = action.payload
    },
    setEditorMode: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.editorMode = action.payload
    },
  },
})

export const {
  setTotalPages,
  setCurrentPage,
  setCurrentSidebarWidth,
  setDocumentViewerWidth,
  setZoomLevel,
  setTextSelected,
  setLeftPaneOpen,
  setNewRevision,
  setShowHighlights,
  setDocumentLoaded,
  setOpenRevision,
  setHighlightSelected,
  setNavigationHistory,
  addNavigationHistory,
  setTemporaryHighlight,
  setCursorTool,
  setSidebarTab,
  setSelectedDocumentChange,
  setHighlightedDocumentChange,
  setSelectedRisk,
  setCustomLabelOpen,
  setIconMenuOpen,
  setBookmarkTab,
  setSelectedCustomLabel,
  setEditorMode,
} = secondaryViewerSlice.actions

export const selectCurrentPage = (state) => state.secondaryViewer.currentPage
export const selectCurrentSidebarWidth = (state) =>
  state.secondaryViewer.currentSidebarWidth
export const selectDocumentViewerWidth = (state) =>
  state.secondaryViewer.currentDocumentViewerWidth
export const selectZoomLevel = (state) => state.secondaryViewer.zoomLevel
export const selectTextSelected = (state: {
  secondaryViewer: {
    textSelected: TextSelected | null
  }
}) => {
  return state.secondaryViewer.textSelected
}
export const selectLeftPaneOpen = (state) => state.secondaryViewer.leftPaneOpen
export const selectTotalPages = (state) => state.secondaryViewer.totalPages
export const selectNewRevision = (state) => state.secondaryViewer.newRevision
export const selectShowHighlights = (state) =>
  state.secondaryViewer.showHighlights
export const selectDocumentLoaded = (state) =>
  state.secondaryViewer.documentLoaded
export const selectOpenRevision = (state) => state.secondaryViewer.openRevision
export const selectDocumentChange = (state) =>
  state.secondaryViewer.selectedDocumentChange
export const selectCursorTool = (state) => state.secondaryViewer.cursorTool
export const selectSidebarTab = (state: {
  secondaryViewer: { sidebarTab: SidebarTabType }
}) => state.secondaryViewer.sidebarTab
export const selectHighlightSelected = (state) =>
  state.secondaryViewer.highlightSelected
export const selectNavigationHistory = (state) =>
  state.secondaryViewer.navigationHistory
export const selectTemporaryHighlight = (state: {
  secondaryViewer: { temporaryHighlight: TemporaryHighlight[] | null }
}) => state.secondaryViewer.temporaryHighlight
export const selectHighlightedDocumentChange = (state) =>
  state.secondaryViewer.highlightedDocumentChange
export const selectSelectedRisk = (state) => state.secondaryViewer.selectedRisk
export const selectCustomLabelOpen = (state) =>
  state.secondaryViewer.customLabelOpen
export const selectIconMenuOpen = (state) => state.secondaryViewer.iconMenuOpen
export const selectSelectedCustomLabel = (state: {
  secondaryViewer: { selectedCustomLabel: number | null }
}) => state.secondaryViewer.selectedCustomLabel
export const selectBookmarkTab = (state: {
  secondaryViewer: { bookmarkTab: 'bookmarks' | 'revisions' | 'labels' }
}) => state.secondaryViewer.bookmarkTab
export const selectEditorMode = (state: {
  secondaryViewer: { editorMode: boolean }
}) => state.secondaryViewer.editorMode

export const secondaryViewerReducer = secondaryViewerSlice.reducer
