import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { selectModal, setModal } from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import {
  useGetOrganizationUsersQuery,
  useGetProjectPermissionsByIdQuery,
  useInviteOrgToProjectMutation,
  useInviteUserToProjectMutation,
} from '../../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { toast } from 'react-toastify'
import LoadingCircle from '../../loading/loading-circle'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'
import SharingModalOrgInviteButton from './sharing-modal-org-invite-button'
import { Project } from '../../../shared/interfaces/project/project.interface'

interface SharingModalProps {
  open: boolean
}

const SharingModal: React.FC<SharingModalProps> = ({ open }) => {
  const dispatch = useDispatch()
  const modal = useSelector(selectModal)
  const currentProject: Project = modal?.project
  const [emails, setEmails] = useState<string[]>([])
  const [userEmail, setUserEmail] = useState<string>('')
  const [inviteUsers] = useInviteUserToProjectMutation()
  const [inviteOrg] = useInviteOrgToProjectMutation()
  const posthog = usePostHog()
  const [orgSubmitting, setOrgSubmitting] = useState(false)

  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    currentProject ? currentProject?.id ?? skipToken : skipToken
  )
  const { data: organizationUsers } = useGetOrganizationUsersQuery(undefined)

  const reset = useCallback(() => {
    setEmails([])
    setUserEmail('')
  }, [setEmails, setUserEmail])

  const onClose = useCallback(() => {
    reset()
    setOrgSubmitting(false)
    dispatch(setModal(null))
  }, [reset, dispatch, setOrgSubmitting])

  const isValidEmail = useCallback((email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }, [])

  const isDisabled = useMemo(() => {
    return (
      (!userEmail && emails?.length === 0) ||
      (Boolean(userEmail) && !isValidEmail(userEmail))
    )
  }, [emails?.length, isValidEmail, userEmail])

  const organizationUsersToInvite = useMemo(() => {
    if (!organizationUsers || !permissions) {
      return []
    }
    const orgUsers = organizationUsers.filter(
      (user) =>
        permissions.users?.find(
          (permission) => permission.email === user.email
        ) === undefined &&
        user.email?.toLowerCase().includes(userEmail.toLowerCase())
    )
    return orgUsers
  }, [organizationUsers, permissions, userEmail])

  const onInviteEntireOrganisationClick = useCallback(async () => {
    if (!currentProject?.id) {
      return
    }
    setOrgSubmitting(true)
    await inviteOrg(currentProject?.id)
    setOrgSubmitting(false)
  }, [currentProject?.id, inviteOrg])

  const onChangeUserEmail = useCallback((e) => {
    setUserEmail(e?.target?.value)
  }, [])

  const onInviteNewEmail = useCallback(async () => {
    if (userEmail && isValidEmail(userEmail)) {
      if (!currentProject?.id) {
        return
      }
      await inviteUsers({
        projectID: currentProject?.id,
        emails: [{ email: userEmail }],
      }).unwrap()
      posthog?.capture(POSTHOG.invite_users, {
        project_uuid: currentProject?.id,
      })
      toast.success(`Project ${currentProject?.title} was successfully shared`)
      reset()
    }
  }, [
    currentProject?.id,
    currentProject?.title,
    inviteUsers,
    isValidEmail,
    posthog,
    reset,
    userEmail,
  ])

  const sharingContentInput = useMemo(() => {
    return (
      <div className="mt-2 space-y-4">
        <div className="flex flex-col space-y-2">
          <input
            tabIndex={0}
            placeholder={'Add people by email'}
            type="email"
            name="sharing_name"
            id="sharing_name"
            value={userEmail}
            onChange={onChangeUserEmail}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {!isDisabled && organizationUsersToInvite.length === 0 && (
            <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex w-0 flex-1 justify-between">
                    <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                      {userEmail}
                    </p>
                    <button
                      type="button"
                      className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onInviteNewEmail}
                    >
                      Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="text-sm">
          <hr className="py-2" />
          <div className="mb-2 text-lg">People with access</div>
          <div className="flex max-h-48 flex-col space-y-2 overflow-auto">
            {permissions &&
              permissions?.users?.map((permission) => (
                <div
                  key={`permission_${permission.id}`}
                  className={'flex items-center space-x-2'}
                >
                  <span className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-gray-500">
                    <span className="text-md font-medium uppercase leading-none text-white">
                      {permission?.email[0]}
                    </span>
                  </span>
                  <div>
                    <div className={'text-sm'}>{permission.email}</div>
                  </div>
                </div>
              ))}
            {permissions &&
              permissions?.invited_users?.length > 0 &&
              permissions?.invited_users.map((permission) => (
                <div
                  key={`permission_${permission.email}`}
                  className={'my-1 flex items-center space-x-2'}
                >
                  <span className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-gray-500">
                    <span className="text-md font-medium leading-none text-white">
                      {permission?.email[0]?.toLocaleUpperCase()}
                    </span>
                  </span>
                  <div className="flex flex-1 justify-between">
                    <div className={'text-sm text-gray-400'}>
                      {permission.email}
                    </div>
                    <div>Invited</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="text-sm">
          <hr className="py-2" />
          <div className="mb-2 flex justify-between">
            <div className="text-lg">People without access</div>
            <button
              onClick={onInviteEntireOrganisationClick}
              className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {orgSubmitting ? (
                <LoadingCircle
                  className="mt-0.5 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                  isSpinning={orgSubmitting}
                />
              ) : (
                <>Add All</>
              )}
            </button>
          </div>
          <div className="flex h-72 flex-col space-y-3 overflow-y-scroll">
            {organizationUsersToInvite.map((user: ProvisionUser) => (
              <div
                key={`org_users_${user.id}`}
                className={'flex items-center justify-between'}
              >
                <div className="flex items-center space-x-2">
                  <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-300">
                    <span className="text-md font-medium uppercase leading-none text-white">
                      {user?.email ? user?.email[0] : 'U'}
                    </span>
                  </span>
                  <div className="flex flex-col overflow-hidden">
                    {user?.first_name && user?.last_name ? (
                      <>
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {user?.first_name &&
                            user?.last_name &&
                            `${user?.first_name} ${user?.last_name}`}
                        </p>
                        <p className="truncate text-xs leading-5 text-gray-500">
                          {user.email}
                        </p>
                      </>
                    ) : (
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {user.email}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <SharingModalOrgInviteButton
                    user={user}
                    project={currentProject}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }, [
    userEmail,
    onChangeUserEmail,
    isDisabled,
    organizationUsersToInvite,
    onInviteNewEmail,
    permissions,
    onInviteEntireOrganisationClick,
    orgSubmitting,
    currentProject,
  ])

  const sharingContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative w-[600px] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="w-full sm:items-start">
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h2"
                className="mb-4 text-xl font-semibold leading-6 text-gray-900"
              >
                Share &quot;{currentProject?.title}&quot;
              </Dialog.Title>
              {sharingContentInput}
            </div>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [currentProject?.title, sharingContentInput])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {sharingContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SharingModal
