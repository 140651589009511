import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import routesConfig from './routes-config'

function Router() {
  const router = createBrowserRouter(routesConfig)
  return <RouterProvider router={router} />
}

export default Router
