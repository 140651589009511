import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectNavigationHistory,
  setNavigationHistory,
} from '../../redux/viewer-slice'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { useNavigate } from 'react-router-dom'
import { useBackButton } from '../../hooks/use-back-button'
import { Tooltip } from '@mantine/core'

function NavigationHistoryPane() {
  const navigationHistory = useSelector(selectNavigationHistory)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useBackButton()

  const onNavigationClick = useCallback(() => {
    const page = navigationHistory[navigationHistory.length - 1].page
    const route = navigationHistory[navigationHistory.length - 1].route
    dispatch(
      setNavigationHistory(
        navigationHistory.slice(0, navigationHistory.length - 1)
      )
    )
    if (route) {
      navigate(route)
    } else {
      documentViewer?.setCurrentPage(page, false)
    }
  }, [dispatch, navigationHistory, documentViewer, navigate])

  return navigationHistory && navigationHistory.length > 0 ? (
    <Tooltip
      label={`Return to previous page`}
      position="bottom"
      withArrow
      openDelay={400}
    >
      <button
        className={
          'flex cursor-pointer gap-2 whitespace-nowrap rounded-lg border-2 border-[#ffffff00] bg-white px-2 py-1.5 text-sm text-blue-500 hover:border-blue-600 hover:bg-sky-50 hover:text-blue-600'
        }
        onClick={onNavigationClick}
      >
        <ArrowUturnLeftIcon width={18} />
        Page {navigationHistory[navigationHistory.length - 1].page}
      </button>
    </Tooltip>
  ) : null
}

export default NavigationHistoryPane
