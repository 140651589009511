import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useGetDocumentsListByProjectQuery,
  useGetProjectsQuery,
} from '../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  setCurrentProject,
} from '../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import DocumentViewerSimple from '../document-viewer-simple'
import CommentViewerCardComponent from './comment-viewer-card-component'
import {
  Revision,
  RevisionSegment,
} from '../../shared/interfaces/project/document/revision/revision.interface'
import SecondaryDocumentViewerSimple from '../secondary-document-viewer-simple'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { useHotkeys } from 'react-hotkeys-hook'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { Quads } from '../../shared/interfaces/quads.interface'
import { selectTextSelected } from '../../redux/secondary-viewer-slice'
import DocumentListboxMulti from '../document-listbox/document-listbox-multi'
import CommentTableHeaderStatusFilter from '../workflows/comment-table/comment-table-header-status-filter'
import { WorkflowFilter } from '../workflows/workflow-components/filter-display'
import CommentViewerCardPopover from './comment-viewer-card-popover'
import { useCommentViewerTabWidth } from '../../hooks/use-comment-viewer-tab-width'
import { useSecondaryCommentViewerTabWidth } from '../../hooks/use-secondary-comment-viewer-tab-width'
import useWindowDimensions from '../../hooks/use-window-dimensions'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import {
  GetRevision,
  useGetRevisionsQuery,
} from '../../redux/api/api-revisions-slice'

const CommentViewer: React.FC = () => {
  const { width } = useWindowDimensions()
  const { tabWidth, handler } = useCommentViewerTabWidth()
  const {
    tabWidth: secondaryTabWidth,
    handler: secondaryHandler,
    commentViewerCenterWidth,
  } = useSecondaryCommentViewerTabWidth()
  const { projectId } = useParams()
  const textSelected = useSelector(selectTextSelected)
  const { data: projects } = useGetProjectsQuery(undefined)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const [selectedDocumentForSource, setSelectedDocumentForSource] =
    useState<ProjectDocumentMetadata | null>(null)
  const [selectedSecondDocumentForSource, setSelectedSecondDocumentForSource] =
    useState<ProjectDocumentMetadata | null>(null)
  const [selectedSource, setSelectedSource] = useState<{
    source: number
    source_document_uuid?: string
  } | null>(null)
  const [selectedSecondSource, setSelectedSecondSource] = useState<{
    source: number
    source_document_uuid?: string
    shouldScroll: boolean
  } | null>(null)
  const [selectedRevision, setSelectedRevision] = useState<Revision | null>(
    null
  )
  const [localRevision, setLocalRevision] = useState<Revision | null>(null)
  const [isChanging, setIsChanging] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[]
  >([])
  const [filter, setFilter] = useState<WorkflowFilter>({})

  const [searchParams, setSearchParams] = useSearchParams()
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const mappedQuads: Quads[] | null = useMemo(() => {
    if (!textSelected || !textSelected?.pageNumber) {
      return null
    }
    const height = documentViewer?.getPageHeight(textSelected?.pageNumber)
    const width = documentViewer?.getPageWidth(textSelected?.pageNumber)
    if (!height || !width) {
      return null
    }
    return textSelected.quads[textSelected?.pageNumber].map((quad: Quads) => {
      return {
        x1: quad.x1 / width,
        x2: quad.x2 / width,
        x3: quad.x3 / width,
        x4: quad.x4 / width,
        y1: quad.y1 / height,
        y2: quad.y2 / height,
        y3: quad.y3 / height,
        y4: quad.y4 / height,
        page: textSelected.pageNumber,
      }
    })
  }, [documentViewer, textSelected])
  const getPreviousDocuments = useCallback(
    (document: ProjectDocumentMetadata) => {
      if (!document.v1_document) {
        return null
      }
      let documentPointer = document.v1_document
      const previousDocuments: ProjectDocumentMetadata[] = []

      while (documentPointer) {
        const currentDocumentID = documentPointer
        const foundDocument = documents?.find(
          (r: ProjectDocumentMetadata) =>
            r?.id?.toString() === currentDocumentID?.toString()
        )
        if (!foundDocument) {
          break
        }
        previousDocuments.push(foundDocument)
        if (!foundDocument.v1_document) {
          break
        }
        documentPointer = foundDocument.v1_document
      }
      return previousDocuments
    },
    [documents]
  )
  const revisionDocumentIds = useMemo(() => {
    if (!selectedDocuments.length) {
      return []
    }
    const previousDocuments: ProjectDocumentMetadata[] = []
    for (const document of selectedDocuments) {
      const foundDocuments = getPreviousDocuments(document)
      if (foundDocuments) {
        previousDocuments.push(...foundDocuments)
      }
    }
    return [
      ...selectedDocuments.map((d) => d.id),
      ...previousDocuments.map((d) => d.id),
    ]
  }, [selectedDocuments, getPreviousDocuments])

  const posthog = usePostHog()

  useEffect(() => {
    posthog.capture(POSTHOG.duplicator_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [posthog, currentProject])

  useEffect(() => {
    if (!searchParams.get('documentId')) {
      return
    }
    const documentId = searchParams.get('documentId')
    const foundDocument = documents?.find(
      (d) => d.uuid.toString() === documentId
    )
    if (!foundDocument) {
      searchParams.delete('documentId')
      setSearchParams(searchParams)
      return
    }
    setSelectedDocuments([foundDocument])
  }, [searchParams, documents, setSearchParams])

  useEffect(() => {
    setIsChanging(true)
  }, [filter])

  const revisionParams = useMemo(() => {
    const filterParams = {
      status: filter?.status ? [filter.status] : undefined,
    }
    return selectedDocuments?.length
      ? { ...filterParams, documentIds: revisionDocumentIds }
      : currentProject
        ? {
            ...filterParams,
            projectId: currentProject?.id,
          }
        : skipToken
  }, [filter, selectedDocuments, revisionDocumentIds, currentProject])

  const {
    data: revisionsData,
    isLoading: revisionsLoading,
    isFetching: revisionsFetching,
  } = useGetRevisionsQuery(
    (revisionParams as GetRevision)?.documentIds?.length ||
      (revisionParams as GetRevision)?.projectId
      ? revisionParams
      : skipToken
  )

  const toggleLoading = useCallback(() => {
    setIsChanging(true)
    setTimeout(() => {
      setIsChanging(false)
    }, 300)
  }, [])

  useEffect(() => {
    if (!revisionsFetching) {
      toggleLoading()
    }
  }, [selectedRevision, toggleLoading, revisionsFetching])

  useEffect(() => {
    setLocalRevision(selectedRevision)
  }, [selectedRevision])

  useEffect(() => {
    const sourceSecondDocument = documents?.find(
      (d) => d.id === localRevision?.document
    )
    const secondSource = {
      source: localRevision?.page ?? 1,
      source_document_uuid: sourceSecondDocument?.uuid,
      shouldScroll: false,
    }
    setSelectedSecondSource(secondSource)
  }, [documents, localRevision])

  const revisionsToDisplay = useMemo(() => {
    if (!revisionsData) {
      return null
    }
    return revisionsData.filter((r) => r.v1_revision)
  }, [revisionsData])

  const onRevisionSelected = useCallback(
    (revision: Revision) => {
      toggleLoading()
      const v1Revision = revision.previous_revision
      if (revision) {
        const sourceSecondDocument = documents?.find(
          (d) => d.id === revision?.document
        )
        const secondSource = {
          source: revision?.page ?? 1,
          source_document_uuid: sourceSecondDocument?.uuid,
          shouldScroll: true,
        }
        setSelectedSecondDocumentForSource(sourceSecondDocument ?? null)
        setSelectedSecondSource(secondSource)
      }
      if (v1Revision) {
        const sourceDocument = documents?.find(
          (d) => d.id === v1Revision.document
        )
        const source = {
          source: v1Revision.page ?? 1,
          source_document_uuid: sourceDocument?.uuid,
        }
        setSelectedDocumentForSource(sourceDocument ?? null)
        setSelectedSource(source)
      }
      setSelectedRevision(revision)
    },
    [documents, toggleLoading]
  )

  useEffect(() => {
    if (projects && projectId) {
      const projectMatch = projects.find((p) => p.uuid === projectId)
      if (projectMatch) {
        dispatch(setCurrentProject(projectMatch))
      }
    }
  }, [projects, dispatch, projectId])

  useEffect(() => {
    if (!revisionsToDisplay?.length) {
      return
    }
    onRevisionSelected(revisionsToDisplay[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRevisionSelected, revisionsData])

  useEffect(() => {
    if (
      !(
        revisionsToDisplay &&
        !selectedRevision &&
        revisionsToDisplay.length > 0
      )
    ) {
      return
    }
    for (const revision of revisionsToDisplay) {
      if (
        revision.v1_revision &&
        revision.revision_status !== 'APPROVED' &&
        revision.revision_status !== 'NOT_APPROVED'
      ) {
        onRevisionSelected(revision)
        return
      }
    }
  }, [revisionsData, revisionsToDisplay, selectedRevision, onRevisionSelected])

  useEffect(() => {
    if (!revisionsData?.length) {
      setSelectedRevision(null)
      return
    }
    const foundRevision = revisionsData?.find(
      (r) => r.id === selectedRevision?.id
    )
    if (!foundRevision) {
      return
    }
    setSelectedRevision(foundRevision)
  }, [revisionsData, selectedRevision])

  const moveUpRevision = useCallback(
    (isComplete?: boolean) => {
      const revisionIndex = revisionsToDisplay?.findIndex(
        (r) => r.id === selectedRevision?.id
      )
      if (
        !revisionsToDisplay ||
        revisionIndex === undefined ||
        revisionIndex === -1 ||
        revisionIndex === (revisionsToDisplay?.length ?? 0) - 1
      ) {
        return
      }
      for (let i = revisionIndex + 1; i < revisionsToDisplay.length; i++) {
        if (!isComplete) {
          setSelectedRevision(revisionsToDisplay[i])
          onRevisionSelected(revisionsToDisplay[i])
          break
        }
        if (
          revisionsToDisplay[i].revision_status !== 'APPROVED' &&
          revisionsToDisplay[i].revision_status !== 'NOT_APPROVED'
        ) {
          setSelectedRevision(revisionsToDisplay[i])
          onRevisionSelected(revisionsToDisplay[i])
          break
        }
      }
    },
    [onRevisionSelected, revisionsToDisplay, selectedRevision]
  )

  const moveDownRevision = useCallback(
    (isComplete?: boolean) => {
      const revisionIndex = revisionsToDisplay?.findIndex(
        (r) => r.id === selectedRevision?.id
      )
      if (
        !revisionsToDisplay ||
        !revisionIndex ||
        revisionIndex === -1 ||
        revisionIndex === 0
      ) {
        return
      }
      for (let i = revisionIndex - 1; i >= 0; i--) {
        if (!isComplete) {
          setSelectedRevision(revisionsToDisplay[i])
          onRevisionSelected(revisionsToDisplay[i])
          break
        }
        if (
          revisionsToDisplay[i].revision_status !== 'APPROVED' &&
          revisionsToDisplay[i].revision_status !== 'NOT_APPROVED'
        ) {
          setSelectedRevision(revisionsToDisplay[i])
          onRevisionSelected(revisionsToDisplay[i])
          break
        }
      }
    },
    [onRevisionSelected, revisionsToDisplay, selectedRevision]
  )

  const onSelectAddContext = useCallback(() => {
    if (!mappedQuads || !textSelected) {
      return
    }
    const document = selectedSecondDocumentForSource
      ? selectedSecondDocumentForSource
      : documents
        ? documents?.[0] ?? null
        : null
    if (!document) {
      return
    }
    const height = documentViewer?.getPageHeight(textSelected.pageNumber)
    const width = documentViewer?.getPageWidth(textSelected.pageNumber)
    if (!height || !width) {
      return
    }
    const revisionSegments: RevisionSegment[] = []
    for (const quadPage in textSelected.quads) {
      const quads: Quads[] = textSelected.quads[quadPage].map((quad: Quads) => {
        return {
          x1: quad.x1 / width,
          x2: quad.x2 / width,
          x3: quad.x3 / width,
          x4: quad.x4 / width,
          y1: quad.y1 / height,
          y2: quad.y2 / height,
          y3: quad.y3 / height,
          y4: quad.y4 / height,
          page: textSelected.pageNumber,
        }
      })
      revisionSegments.push({
        page: parseInt(quadPage),
        quads,
        document: document.id,
        text: textSelected.text ?? '',
      })
    }
    const segments = [...(localRevision?.segments ?? []), ...revisionSegments]
    setLocalRevision((prev) => {
      return {
        ...prev,
        v2_text: (prev?.v2_text ?? '') + textSelected.text,
        quads: [...(prev?.quads ?? []), ...mappedQuads],
        segments,
      }
    })
  }, [
    mappedQuads,
    textSelected,
    selectedSecondDocumentForSource,
    documents,
    documentViewer,
    localRevision?.segments,
  ])

  const onSelectReplaceText = useCallback(() => {
    if (!mappedQuads || !textSelected || !selectedDocumentForSource) {
      return
    }
    const document = selectedSecondDocumentForSource
      ? selectedSecondDocumentForSource
      : documents
        ? documents?.[0] ?? null
        : null
    if (!document) {
      return
    }
    const height = documentViewer?.getPageHeight(textSelected.pageNumber)
    const width = documentViewer?.getPageWidth(textSelected.pageNumber)
    if (!height || !width) {
      return
    }
    const revisionSegments: RevisionSegment[] = []
    for (const quadPage in textSelected.quads) {
      const quads: Quads[] = textSelected.quads[quadPage].map((quad: Quads) => {
        return {
          x1: quad.x1 / width,
          x2: quad.x2 / width,
          x3: quad.x3 / width,
          x4: quad.x4 / width,
          y1: quad.y1 / height,
          y2: quad.y2 / height,
          y3: quad.y3 / height,
          y4: quad.y4 / height,
          page: parseInt(quadPage),
        }
      })
      revisionSegments.push({
        page: parseInt(quadPage),
        quads,
        document: document.id,
        text: textSelected.text ?? '',
      })
    }
    setLocalRevision((prev) => {
      return {
        ...prev,
        v2_text: textSelected.text,
        quads: mappedQuads,
        segments: revisionSegments,
      }
    })
  }, [
    documentViewer,
    documents,
    mappedQuads,
    selectedDocumentForSource,
    selectedSecondDocumentForSource,
    textSelected,
  ])

  useHotkeys('ArrowUp', () => moveDownRevision(), [() => moveDownRevision()])

  useHotkeys('ArrowDown', () => moveUpRevision(), [() => moveUpRevision()])

  const onStatusUpdate = useCallback(
    (isDown: boolean, isComplete: boolean) => {
      if (isDown) {
        moveDownRevision(isComplete)
        return
      }
      moveUpRevision(isComplete)
    },
    [moveDownRevision, moveUpRevision]
  )

  const originalRevision = useMemo(() => {
    return selectedRevision?.previous_revision
  }, [selectedRevision])

  const documentsToDisplay = useMemo(() => {
    if (!documents) {
      return []
    }
    const documentsToDisplay: ProjectDocumentMetadata[] = []
    const v1Dict = {}
    for (const document of documents ?? []) {
      v1Dict[document?.v1_document?.toString() ?? ''] = document.v1_document
    }
    for (const document of documents ?? []) {
      if (v1Dict[document?.id.toString()]) {
        continue
      }
      documentsToDisplay.push(document)
    }
    return documentsToDisplay
  }, [documents])

  const selectedRevisionIndex = useMemo(() => {
    const index = revisionsToDisplay?.findIndex(
      (r) => r.id === selectedRevision?.id
    )
    return index === undefined ? 0 : index + 1
  }, [revisionsToDisplay, selectedRevision?.id])

  return (
    <div className="flex">
      <div
        style={{
          maxWidth: '100vw',
          height: 'calc(100vh - 50px)',
        }}
        className="flex w-full justify-between overflow-hidden"
      >
        <div className="flex flex-1 flex-grow">
          {documents && (
            <DocumentViewerSimple
              revisionSelected={originalRevision}
              selectedSource={selectedSource}
              tabWidth={tabWidth ?? width / 3}
              overlay={true}
              selectedDocument={
                selectedDocumentForSource
                  ? selectedDocumentForSource
                  : documents
                    ? documents?.[0] ?? null
                    : null
              }
            />
          )}
          <div className="relative flex">
            <button
              className={
                'text-md flex cursor-col-resize select-none items-center border border-t-[0px] border-[#dbdee3]  bg-[#f2f5fb]  px-0.5 text-xs transition-colors hover:border-blue-500 hover:bg-blue-300 hover:text-blue-500'
              }
              onMouseDown={handler}
            >
              ≡
            </button>
          </div>
        </div>
        <div
          style={{
            width: commentViewerCenterWidth ?? width / 3,
          }}
          className="flex h-full w-3/12 flex-col overflow-auto border-x bg-white"
        >
          <div className="flex w-full items-center justify-center space-x-2 bg-gray-100">
            <div className={'relative flex items-center gap-2 px-2 py-1'}>
              {selectedRevisionIndex > 0 &&
              (revisionsToDisplay?.length ?? 0) > 0 ? (
                <div>
                  {selectedRevisionIndex} / {revisionsToDisplay?.length ?? 0}
                </div>
              ) : (
                <div className="my-1 h-4 w-80 animate-pulse rounded border-l bg-gray-300" />
              )}
            </div>
          </div>
          <div className={'space-y-2 p-2'}>
            <DocumentListboxMulti
              selectedDocuments={selectedDocuments}
              documents={documentsToDisplay}
              setSelectedDocuments={setSelectedDocuments}
            />
            <div className={'flex justify-between space-x-2'}>
              <CommentTableHeaderStatusFilter
                filter={{
                  ...filter,
                  documentIds: revisionDocumentIds,
                  projectId: currentProject?.id,
                }}
                setFilter={setFilter}
              />
              <CommentViewerCardPopover />
            </div>
          </div>
          {revisionsToDisplay && selectedRevision && localRevision && (
            <CommentViewerCardComponent
              selectedDocuments={selectedDocuments}
              originalRevision={selectedRevision?.previous_revision}
              onStatusUpdate={onStatusUpdate}
              revision={selectedRevision}
              setLocalRevision={setLocalRevision}
              localRevision={localRevision}
              isChanging={isChanging}
              filter={filter}
            />
          )}
          {revisionsToDisplay && !selectedRevision && (
            <div className="my-2 flex h-10 w-96 max-w-sm items-center justify-center rounded bg-white text-center text-sm text-gray-700 shadow-sm">
              No Comments to Resolve
            </div>
          )}
          {revisionsLoading && (
            <div className="my-2 flex h-48 items-center justify-center rounded bg-white text-center text-sm text-gray-700 shadow-sm">
              <div className="mx-1 w-full">
                <div className="my-3 h-3 w-full animate-pulse rounded border-l bg-gray-200" />
                <div className="my-3 mt-10 h-3 w-full animate-pulse rounded border-l bg-gray-200" />
                <div className="my-3 h-3 w-full animate-pulse rounded border-l bg-gray-200" />
                <div className="my-3 mt-10 h-3 w-full animate-pulse rounded border-l bg-gray-200" />
                <div className="my-3 h-3 w-full animate-pulse rounded border-l bg-gray-200" />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-grow">
          <div className="relative flex">
            <button
              className={
                'text-md flex cursor-col-resize select-none items-center border border-t-[0px] border-[#dbdee3]  bg-[#f2f5fb]  px-0.5 text-xs transition-colors hover:border-blue-500 hover:bg-blue-300 hover:text-blue-500'
              }
              onMouseDown={secondaryHandler}
            >
              ≡
            </button>
          </div>
          {documents && (
            <SecondaryDocumentViewerSimple
              isChanging={isChanging}
              tabWidth={secondaryTabWidth ?? width / 3}
              onSelectReplaceText={onSelectReplaceText}
              onSelectAddContext={onSelectAddContext}
              originalRevision={originalRevision}
              revisionSelected={localRevision}
              selectedSource={selectedSecondSource}
              overlay={true}
              selectedDocument={
                selectedSecondDocumentForSource
                  ? selectedSecondDocumentForSource
                  : documents
                    ? documents?.[0] ?? null
                    : null
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentViewer
