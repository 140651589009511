// Function to convert a list of x1, y1, x2, y2 into an object with four coordinates, x1, y1, x2, y2, x3, y3, x4, y4

import { Quads } from '../shared/interfaces/quads.interface'

export function convertQuads(quads, page: number): Quads {
  return {
    x1: quads[0],
    y1: quads[1],
    x2: quads[2],
    y2: quads[1],
    x3: quads[2],
    y3: quads[3],
    x4: quads[0],
    y4: quads[3],
    page,
  }
}

export function isRatio(quads: Quads[]) {
  if (!quads?.[0]?.y3) {
    return false
  }
  return quads?.[0]?.y3 > quads?.[0]?.y2
}
