import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '../../redux/api-slice'
import { Dialog, Transition } from '@headlessui/react'

export enum SignUpStep {
  // eslint-disable-next-line no-unused-vars
  Toc = 1,
}

function TermsAndConditions() {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)

  const { data: profile } = useGetUserProfileQuery(undefined)
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false)
  const [isLegalChecked, setIsLegalChecked] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')

  useEffect(() => {
    if (profile && !profile.agreed_tandcs) {
      setOpen(true)
    }
  }, [profile])

  const onAccept = useCallback(() => {
    updateUserProfile({
      agreed_tandcs: true,
      first_name: firstName,
      last_name: lastName,
    })
    setOpen(false)
  }, [firstName, lastName, updateUserProfile])

  const onCloseOverride = useCallback(() => {
    // Overriding default close
  }, [])

  const onSetFirstName = useCallback((e) => {
    setFirstName(e.target.value)
  }, [])

  const onSetLastName = useCallback((e) => {
    setLastName(e.target.value)
  }, [])

  const onAgreeChange = useCallback(() => {
    setIsAgreeChecked((a) => !a)
  }, [])

  const onLegalChange = useCallback(() => {
    setIsLegalChecked((l) => !l)
  }, [])

  const termsAndConditionsContent = useMemo(() => {
    return (
      <div>
        <h3 className=" text-gray-900">Terms and Conditions</h3>
        <p className="text-sm text-gray-500">
          You must agree to the terms and conditions before you can use this
          application. Read the full{' '}
          <a
            className={'text-blue-400 underline'}
            href="https://drive.google.com/file/d/1ec7VNtdk0_T9kLp7ncEGIAuwK_2stGOs/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          ,{' '}
          <a
            className={'text-blue-400 underline'}
            href="https://drive.google.com/file/d/1Delth1ApAOLYUsFxZwkq29GNQQW2nLuj/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          , and{' '}
          <a
            className={'text-blue-400 underline'}
            href="https://drive.google.com/file/d/13V8zo-wOg0OE1OaosAKjwgwYUMZhS4UN/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            Master Services Agreement
          </a>
          .
        </p>
        <br />
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="agree-checkbox"
              aria-describedby="agreement-description"
              name="agree"
              type="checkbox"
              checked={isAgreeChecked}
              onChange={onAgreeChange}
              className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="agree-checkbox"
              className="cursor-pointer font-medium text-gray-900"
            >
              I (herein after referred to as <q>User</q>) hereby confirm that I
              have read and agree to the Terms of Service and the Privacy Policy
            </label>
          </div>
        </div>
        <div className="relative mt-5 flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="not-legal-checkbox"
              aria-describedby="not-legal-description"
              name="not-legal"
              type="checkbox"
              checked={isLegalChecked}
              onChange={onLegalChange}
              className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="not-legal-checkbox"
              className="cursor-pointer font-medium text-gray-900"
            >
              I hereby acknowledge and agree that Provision Software Corporation
              (<q>Company</q>) is not providing legal services and that this
              Agreement does not create any solicitor-client relationship
              between the Company and the User of any kind.
            </label>
          </div>
        </div>
      </div>
    )
  }, [isAgreeChecked, isLegalChecked, onAgreeChange, onLegalChange])

  const renderStep = useCallback(() => {
    return (
      <div className="flex flex-grow flex-col items-stretch p-4 sm:p-16">
        <div className="m-auto w-96 space-y-5">
          <div>
            <div className="block text-lg font-medium leading-6 text-gray-900">
              Sign up
            </div>
          </div>
          <div className={'space-y-2'}>
            <label htmlFor="first_name" className=" text-gray-900">
              First name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              onChange={onSetFirstName}
              value={firstName}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Your first name here"
            />
          </div>
          <div className={'space-y-2'}>
            <label htmlFor="last_name" className=" text-gray-900">
              Last name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              onChange={onSetLastName}
              value={lastName}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Your last name here"
            />
          </div>
          {termsAndConditionsContent}
          <div className={'flex w-full justify-end'}>
            <button
              type="button"
              disabled={
                !isAgreeChecked || !isLegalChecked || !firstName || !lastName
              }
              className={`inline-flex w-28 justify-center self-end rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:col-start-2 ${
                !isAgreeChecked || !isLegalChecked || !firstName || !lastName
                  ? 'bg-indigo-300 focus-visible:outline-indigo-300'
                  : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
              }}`}
              onClick={onAccept}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    )
  }, [
    onSetFirstName,
    firstName,
    onSetLastName,
    lastName,
    termsAndConditionsContent,
    isAgreeChecked,
    isLegalChecked,
    onAccept,
  ])

  const welcomeContent = useMemo(() => {
    return (
      <Dialog.Panel className="transition-al relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl">
        <div className="flex h-full">
          <div className="hidden items-center justify-center overflow-hidden bg-gray-800 lg:flex">
            <div className="px-8">
              <div className="flex items-center justify-center p-6 text-lg text-white">
                <img
                  width={'50px'}
                  className="rounded-lg text-center"
                  src="/favicons/android-chrome-192x192.png"
                  alt="Provision Logo"
                />
                <span className="px-4 text-4xl font-bold text-white">
                  Provision
                </span>
              </div>
              <div className="mt-10 text-center text-[1.5rem] text-white">
                Welcome to Provision
              </div>
            </div>
          </div>
          {renderStep()}
        </div>
      </Dialog.Panel>
    )
  }, [renderStep])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onCloseOverride}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {welcomeContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TermsAndConditions
