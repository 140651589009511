import React from 'react'

const CommentViewerCardLoading: React.FC = () => {
  return (
    <div className="my-2 flex flex-col space-y-2 rounded bg-white p-2 shadow">
      <div className="mb-10 flex space-x-2">
        <div className="h-8 w-1/2 animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
        <div className="h-8 w-1/2 animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      </div>
      <div className="h-4 w-full animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-full animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-11/12 animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="mb-10 h-4 w-1/3 animate-pulse rounded border border-b-2 border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-full animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
      <div className="h-4 w-full animate-pulse rounded border-l bg-gray-200 text-xs text-gray-500" />
    </div>
  )
}

export default CommentViewerCardLoading
