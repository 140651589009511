import { Popover } from '@headlessui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSelectedBlacklineSegments,
  setSelectedBlacklineSegments,
} from '../../redux/viewer-slice'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Diff from '../workflows/comment-table/diff'

interface BlacklinePopoverProps {
  referenceElement: HTMLElement | null
  selectedDocument?: ProjectDocument | null
}

const BlacklinePopover: React.FC<BlacklinePopoverProps> = ({
  referenceElement,
}) => {
  // const currentProject = useSelector(selectCurrentProject);
  // const currentDocument = useSelector(selectCurrentDocument);
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, panelRef)
  const dispatch = useDispatch()
  const blacklineSegments = useSelector(selectSelectedBlacklineSegments)

  const onClose = useCallback(() => {
    dispatch(setSelectedBlacklineSegments(null))
  }, [dispatch])

  const originalSegment = useMemo(() => {
    const segments = blacklineSegments?.filter(
      (segment) => segment.document_blackline.blackline_type === 0
    )
    if (!segments) {
      return null
    }
    const segmentsText = segments?.map((segment) => segment.text).join(' ')
    return {
      ...segments?.[0],
      text: segmentsText,
    }
  }, [blacklineSegments])

  const revisedSegment = useMemo(() => {
    const segments = blacklineSegments?.filter(
      (segment) => segment.document_blackline.blackline_type === 1
    )
    if (!segments) {
      return null
    }
    const segmentsText = segments?.map((segment) => segment.text).join(' ')
    return {
      ...segments?.[0],
      text: segmentsText,
    }
  }, [blacklineSegments])

  const matchType = useMemo(() => {
    switch (revisedSegment?.match_type) {
      case 0:
        return 'Insert'
      case 1:
        return 'Edit'
      case 2:
        return 'Delete'
      default:
        return 'Unknown'
    }
  }, [revisedSegment])

  const panelContent = useMemo(() => {
    return (
      <div className="flex w-full flex-col text-sm">
        <div className="mb-2 flex w-full items-center justify-between">
          <div className="font-bold underline">{matchType}</div>
          <button onClick={onClose}>
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
        <div className="max-h-48 flex-1 overflow-auto">
          <Diff
            inputA={originalSegment?.text ?? ''}
            inputB={revisedSegment?.text ?? ''}
          />
        </div>
      </div>
    )
  }, [matchType, onClose, originalSegment?.text, revisedSegment?.text])

  return (
    <Popover id="blackline-popover" className="relative">
      {Boolean(blacklineSegments) && (
        <Popover.Panel
          static
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-[60] rounded border bg-white text-gray-700 shadow"
        >
          <div
            style={{
              minWidth: '20rem',
            }}
            className={'flex h-full w-96 min-w-fit flex-col space-y-2 p-1'}
          >
            {panelContent}
          </div>
        </Popover.Panel>
      )}
    </Popover>
  )
}
export default BlacklinePopover
