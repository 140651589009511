import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { selectCurrentProject } from '../../../redux/application-slice'
import {
  selectCustomLabelOpen,
  selectTaskOpen,
  setCustomLabelOpen,
  setSelectedSources,
  setTaskOpen,
} from '../../../redux/viewer-slice'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { SegmentContext } from './filtering'
import DocumentReference from '../../document-reference'
import CustomLabelPopover from '../../overlay/custom-label-popover'
import TaskPopover from '../../overlay/task-popover'
import FilteringCardTask from './filtering-card-task'
import DocumentLabelSegment from './document-label-segment'

interface FilteringCardProps {
  documentSegment: DocumentSegment
  isSelectedDocumentSegment: boolean
  setSelectedDocumentSegment: React.Dispatch<
    React.SetStateAction<DocumentSegment | null>
  >
  documentTitle: string
  index: number
  page: number
}

const FilteringCard: React.FC<FilteringCardProps> = ({
  documentSegment,
  isSelectedDocumentSegment,
  setSelectedDocumentSegment,
  documentTitle,
  index,
  page,
}) => {
  const { setSize, windowDimensions, labelFilters, selectedDocuments } =
    useContext(SegmentContext)
  const currentProject = useSelector(selectCurrentProject)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  const root = useRef<HTMLDivElement | null>(null)
  const addLabelRef = useRef<HTMLButtonElement | null>(null)
  const addTaskRef = useRef<HTMLButtonElement | null>(null)
  const customLabelOpen = useSelector(selectCustomLabelOpen)
  const taskOpen = useSelector(selectTaskOpen)

  useEffect(() => {
    if (!root || !root.current || !setSize) {
      return
    }
    setSize(index, root.current.getBoundingClientRect().height)
  }, [setSize, windowDimensions?.width, index])

  const setHighlight = useCallback(
    (source: { source_document_uuid: string | undefined; source: number }) => {
      if (!source.source_document_uuid) {
        return
      }
      navigate(
        `/${currentProject?.uuid}/clause-filters/${source.source_document_uuid}`
      )
      const page = source.source.toString()
      searchParams.set('page', page)
      setSearchParams(searchParams)
      const highlightID = uuidv4()
      const quads = documentSegment?.quads
      dispatch(
        setSelectedSources([
          {
            id: highlightID,
            page: parseInt(page),
            quads: quads ?? [],
            isPrimary: true,
            documentUUID: source.source_document_uuid,
          },
        ])
      )
    },
    [
      currentProject?.uuid,
      dispatch,
      documentSegment?.quads,
      navigate,
      searchParams,
      setSearchParams,
    ]
  )

  const onClickDocumentSegment = useCallback(() => {
    setSelectedDocumentSegment(documentSegment)
    setHighlight({
      source_document_uuid: documentSegment.document_uuid,
      source: documentSegment.page,
    })
  }, [setSelectedDocumentSegment, documentSegment, setHighlight])

  const onClickAddLabel = useCallback(() => {
    if (!documentSegment.id) {
      return
    }
    dispatch(setCustomLabelOpen(documentSegment.id))
  }, [dispatch, documentSegment?.id])

  const onClickAddTask = useCallback(() => {
    if (!documentSegment.id) {
      return
    }
    dispatch(setTaskOpen(documentSegment.id))
  }, [dispatch, documentSegment?.id])

  const renderLabel = useCallback((labelSegment) => {
    return (
      <DocumentLabelSegment
        key={labelSegment.id}
        documentLabelSegment={labelSegment}
      />
    )
  }, [])

  const renderLabelSection = useMemo(() => {
    return (
      <div>
        <div className="flex flex-col space-y-0.5">
          <div className={'mb-0.5 text-gray-400'}>Labels</div>
          <div className={'space-x-1'}>
            {documentSegment.labels?.map((label) => renderLabel(label))}
            <button
              ref={addLabelRef}
              onClick={onClickAddLabel}
              className={
                'inline-flex items-center rounded-full border px-1.5 py-0.5 text-xs font-medium text-gray-600 hover:bg-gray-100'
              }
            >
              Add label +
            </button>
          </div>
          <div className="flex flex-col space-y-0.5">
            <div className={'mb-0.5 text-gray-400'}>Tasks</div>
            <div className="flex flex-col space-y-1">
              {documentSegment?.tasks?.map((task) => (
                <FilteringCardTask
                  selectedDocuments={selectedDocuments}
                  page={page}
                  documentSegment={documentSegment}
                  key={task.id}
                  task={task}
                />
              ))}
            </div>
            <button
              ref={addTaskRef}
              onClick={onClickAddTask}
              className={
                'inline-flex w-fit items-center rounded-full border px-1.5 py-0.5 text-xs font-medium text-gray-600 hover:bg-gray-100'
              }
            >
              Create Task +
            </button>
          </div>
          {taskOpen === documentSegment?.id && (
            <TaskPopover
              page={page}
              documentSegment={documentSegment}
              referenceElement={addTaskRef.current}
              selectedDocuments={selectedDocuments}
            />
          )}
          {customLabelOpen === documentSegment?.id && (
            <CustomLabelPopover
              labelFilters={labelFilters}
              documentSegment={documentSegment}
              referenceElement={addLabelRef.current}
            />
          )}
        </div>
      </div>
    )
  }, [
    documentSegment,
    onClickAddLabel,
    onClickAddTask,
    taskOpen,
    page,
    selectedDocuments,
    customLabelOpen,
    labelFilters,
    renderLabel,
  ])

  return (
    <div
      ref={root}
      className={`w-full rounded border text-left ${
        isSelectedDocumentSegment ? 'bg-gray-50' : 'bg-white'
      }`}
    >
      <div className={'flex'}>
        <div className="flex justify-start p-2 w-2/3">
          <div className="text-gray-900 text-xs">
            <div>{documentSegment.text}</div>
          </div>
        </div>
        <div className="flex flex-col justify-start space-y-2 p-2 border-l w-1/3 text-xs">
          {renderLabelSection}
          <div>
            <div className={'text-gray-400'}>Document</div>
            <DocumentReference
              onClick={onClickDocumentSegment}
              documentTitle={documentTitle}
              pageNumber={documentSegment.page}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilteringCard
