import React, { useMemo } from 'react'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import DocumentViewerSideling from './document-viewer-sideling'
import { useSideling } from '../../hooks/use-sideling'

interface DocumentViewerSidelingContainerProps {
  currentPage: number
  newRevision: Revision | null
  scrollView: React.RefObject<HTMLDivElement>
}

const DocumentViewerSidelingContainer: React.FC<
  DocumentViewerSidelingContainerProps
> = ({ currentPage, newRevision, scrollView }) => {
  const { changeSideling, setSidelingsRef, sidelingTop, sortedSidelings } =
    useSideling(scrollView)

  const pageRange = useMemo(() => {
    return [
      currentPage - 10,
      currentPage - 9,
      currentPage - 8,
      currentPage - 7,
      currentPage - 6,
      currentPage - 5,
      currentPage - 4,
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
      currentPage + 4,
      currentPage + 5,
      currentPage + 6,
      currentPage + 7,
      currentPage + 8,
      currentPage + 9,
      currentPage + 10,
    ]
  }, [currentPage])

  return (
    <>
      {sortedSidelings
        ?.filter((s) => pageRange.includes(currentPage))
        .map((sortedSideling) => (
          <DocumentViewerSideling
            key={`sideling_${sortedSideling.id}`}
            sidelingData={sortedSideling}
            isNewRevision={sortedSideling?.id === 'new_revision'}
            changeSideling={changeSideling}
            sidelingTop={sidelingTop}
            setSidelingsRef={setSidelingsRef}
          />
        ))}
    </>
  )
}

export default DocumentViewerSidelingContainer
