import React, { useCallback, useMemo } from 'react'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query'
import DocumentRow from '../file-manager/document-row'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import { FilePond } from 'react-filepond'
import { useGetDocumentsByProjectQuery } from '../../redux/api-slice'
import FolderRow from '../file-manager/folder-row'
import { useSearchParams } from 'react-router-dom'
import {
  DndContext,
  DragEndEvent,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
} from '@dnd-kit/core'
import DroppableDocument from './droppable-document'
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import SortableDocument from './sortable-document'
import DraggableDocument from './draggable-document'

interface DocumentManagerDragDropProps {
  handleDrop: (event: DragEndEvent) => void
  dragUploaderRef: React.RefObject<FilePond | null>
  sortEnabled?: boolean
}

const DocumentManagerDragDrop: React.FC<DocumentManagerDragDropProps> = ({
  handleDrop,
  dragUploaderRef,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 0, tolerance: 100 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const currentProject = useSelector(selectCurrentProject)
  const [searchParams] = useSearchParams()
  const folderId = useMemo(() => {
    return searchParams.get('folderId')
  }, [searchParams])
  const searchQuery = useMemo(() => {
    return searchParams.get('search')
  }, [searchParams])
  const { currentData: documentResponse } = useGetDocumentsByProjectQuery(
    currentProject?.id
      ? {
          projectId: currentProject?.id,
          folderId: folderId ?? null,
          search_query: searchQuery ?? undefined,
        }
      : skipToken
  )
  const selectedDocument = useSelector(selectCurrentDocument)

  const getDocumentVersion = useCallback(
    (document: ProjectDocument) => {
      if (!document.v1_document) {
        return 1
      }
      let version = 2
      let documentPointer = document.v1_document

      while (documentPointer) {
        let currentDocumentID = documentPointer
        const foundDocument = documentResponse?.documents?.find(
          (r: ProjectDocument) =>
            r?.id?.toString() === currentDocumentID?.toString()
        )
        if (!foundDocument || !foundDocument.v1_document) {
          break
        }
        documentPointer = foundDocument.v1_document
        version++
      }
      return version
    },
    [documentResponse?.documents]
  )

  const getDocumentsWithNoParent = useMemo(() => {
    return (
      documentResponse?.documents?.filter(
        (d) =>
          !documentResponse?.documents?.find(
            (r) => r.v1_document?.toString() === d.id.toString()
          )
      ) ?? []
    )
  }, [documentResponse?.documents])

  const getPreviousDocuments = useCallback(
    (document: ProjectDocument) => {
      if (!document.v1_document) {
        return null
      }
      let documentPointer = document.v1_document
      const previousDocuments: ProjectDocument[] = []

      while (documentPointer) {
        let currentDocumentID = documentPointer
        const foundDocument = documentResponse?.documents?.find(
          (r: ProjectDocument) =>
            r?.id?.toString() === currentDocumentID?.toString()
        )
        if (!foundDocument) {
          break
        }
        previousDocuments.push({
          ...foundDocument,
          document_version: getDocumentVersion(foundDocument),
        })
        if (!foundDocument.v1_document) {
          break
        }
        documentPointer = foundDocument.v1_document
      }
      return previousDocuments
    },
    [documentResponse?.documents, getDocumentVersion]
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDrop}
    >
      <SortableContext
        items={[
          ...(documentResponse?.folder?.id
            ? [documentResponse?.folder.id]
            : ([] as string[])),
          ...(getDocumentsWithNoParent?.map((d) => d.id) ?? []),
          ...(documentResponse?.folders?.map((f) => f.id) ?? []),
        ]}
        strategy={verticalListSortingStrategy}
      >
        {documentResponse?.folder?.id && !searchQuery ? (
          <div className="flex pl-4 hover:bg-gray-50">
            <DroppableDocument
              key={`folder_draggable_${documentResponse?.folder.id}`}
              id={documentResponse?.folder.id}
            >
              <FolderRow
                folder={documentResponse?.folder.folder}
                key={documentResponse?.folder.id}
                isAboveFolder={true}
              />
            </DroppableDocument>
          </div>
        ) : null}
        {documentResponse?.folders?.map((folder, index) => {
          const uniqueFolderName = folder?.name ?? 'root'
          return (
            <DraggableDocument
              id={folder.id}
              key={`folder_sortable_${uniqueFolderName}`}
            >
              <DroppableDocument
                key={`folder_draggable_${uniqueFolderName}`}
                id={folder.id}
              >
                <FolderRow key={folder.id} folder={folder} />
              </DroppableDocument>
            </DraggableDocument>
          )
        })}
        {getDocumentsWithNoParent?.map((document, index) => {
          const uniqueDocumentName = document?.title
            ?.toLocaleLowerCase()
            ?.replaceAll('.pdf', '')
            ?.replaceAll('.docx', '')
          return (
            <SortableDocument
              id={document.id}
              key={uniqueDocumentName}
              isSelected={selectedDocument?.id === document?.id}
            >
              <DocumentRow
                documentVersion={getDocumentVersion(document)}
                previousDocumentVersions={getPreviousDocuments(document)}
                key={document.uuid}
                documentData={document}
                uploaderRef={dragUploaderRef}
                showSortHandle={true}
                index={
                  documentResponse?.folder?.id
                    ? index +
                      (documentResponse?.folder?.folders?.length ?? 0) +
                      1
                    : index + (documentResponse?.folder?.folders?.length ?? 0)
                }
              />
            </SortableDocument>
          )
        })}
      </SortableContext>
    </DndContext>
  )
}
export default DocumentManagerDragDrop
