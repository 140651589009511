import React from 'react'
import SecondaryPageOverlaySimple from './secondary-page-overlay-simple'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'

interface SecondaryPageOverlaySimpleControllerProps {
  pages: number[]
  revisionSelected?: Revision | null
  documentChangeSelected?: DocumentChange | null
  onSelectReplaceText: () => void
  onSelectAddContext: () => void
}

const SecondaryPageOverlaySimpleController: React.FC<
  SecondaryPageOverlaySimpleControllerProps
> = (props) => {
  return (
    <>
      {props.pages.map((page) => (
        <SecondaryPageOverlaySimple
          key={page}
          documentChangeSelected={props.documentChangeSelected ?? undefined}
          page={page}
          onSelectReplaceText={props.onSelectReplaceText}
        />
      ))}
    </>
  )
}

export default SecondaryPageOverlaySimpleController
