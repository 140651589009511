import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Revision,
  RevisionComment,
} from '../../shared/interfaces/project/document/revision/revision.interface'
import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { usePopper } from 'react-popper'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import { selectCurrentDocument } from '../../redux/application-slice'
import TextareaAutosize from 'react-textarea-autosize'
import { selectCommentWorkflowFilterStateQueryParams } from '../../redux/workflow-slice'
import { selectSelectedRevision } from '../../redux/viewer-slice'
import {
  useDeleteRevisionCommentMutation,
  useUpdateRevisionCommentMutation,
} from '../../redux/api/api-revisions-slice'

interface RevisionCommentProps {
  comment: RevisionComment
  source: 'document' | 'project'
  revision: Revision
  isTableView?: boolean
}

const RevisionCommentComponent = ({
  comment,
  revision,
  isTableView = false,
}: RevisionCommentProps) => {
  const selectedRevision = useSelector(selectSelectedRevision)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const revisionQueryParams = useSelector(
    selectCommentWorkflowFilterStateQueryParams
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const currentDocument = useSelector(selectCurrentDocument)
  const [isEdit, setEdit] = useState(false)
  const [commentEditText, setCommentEditText] = useState(comment.comment)
  const { data: user } = useGetUserProfileQuery(undefined)
  const [updateRevisionComment] = useUpdateRevisionCommentMutation()

  const [deleteRevisionComment] = useDeleteRevisionCommentMutation()

  useEffect(() => {
    setCommentEditText(comment.comment)
  }, [comment.comment])

  const reset = useCallback(
    (e) => {
      e?.stopPropagation()
      setEdit(false)
      setCommentEditText(comment.comment)
    },
    [comment.comment]
  )

  const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentEditText(e.target.value)
  }, [])

  const onUpdateComment = useCallback(
    (e) => {
      e?.stopPropagation()
      updateRevisionComment({
        ...comment,
        revisionQueryParams,
        parent: revision,
        comment: commentEditText,
      })
      setEdit(false)
    },
    [
      updateRevisionComment,
      comment,
      revision,
      commentEditText,
      revisionQueryParams,
    ]
  )

  const onPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onUpdateComment(null)
      } else if (e.key === 'Escape') {
        reset(null)
      }
    },
    [onUpdateComment, reset]
  )

  const editTextArea = useMemo(() => {
    return (
      <>
        <TextareaAutosize
          data-cy={`revision-comment-edit-textarea-${comment.id}`}
          className={
            'w-full flex-grow resize-none rounded-sm border-0 border-white  p-1 text-sm text-gray-800 placeholder-gray-400 focus:border focus:border-none focus:border-gray-300 focus:text-black focus:outline-none focus:ring-0'
          }
          autoFocus
          onChange={onChange}
          value={commentEditText}
          onKeyDown={onPressEnter}
        />
        <div className={'mr-2 flex justify-end gap-2 pt-1'}>
          <button
            type="button"
            className="bg-white hover:bg-gray-100 shadow-sm px-4 py-1 border rounded font-semibold text-sm self-start focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={reset}
          >
            Cancel
          </button>
          <button
            data-cy={`revision-comment-save-button-${comment.id}`}
            type="button"
            className="border-indigo-600 bg-indigo-600 hover:bg-indigo-500 shadow-sm px-4 py-1 border rounded font-semibold text-sm text-white self-start focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onUpdateComment}
          >
            Save
          </button>
        </div>
      </>
    )
  }, [
    comment.id,
    onChange,
    commentEditText,
    onPressEnter,
    reset,
    onUpdateComment,
  ])

  const renderComment = useCallback(
    (comment: RevisionComment) => {
      const mentions = comment.mentions ?? []
      const elements: JSX.Element[] = []
      let index = 0
      if (isEdit) {
        return editTextArea
      }
      if (!mentions || mentions.length === 0) {
        return <span>{comment.comment}</span>
      }
      for (const mention of mentions) {
        if (
          mention.start_index === undefined ||
          mention.end_index === undefined
        ) {
          continue
        }
        elements.push(
          <span key={`mention_${mention.user_targeted_id}_${index}`}>
            {comment.comment?.slice(index, mention.start_index)}
          </span>
        )
        elements.push(
          <span
            key={`mention_${mention.user_targeted_id}_${index}_mention`}
            className="text-indigo-500"
          >
            {mention.user_targeted?.email}{' '}
          </span>
        )
        index = mention.end_index
      }
      elements.push(
        <span key={`mention_${mentions.length}_${index}`}>
          {comment.comment?.slice(index)}
        </span>
      )
      return elements
    },
    [editTextArea, isEdit]
  )

  const onDeleteRevisionComment = useCallback(() => {
    deleteRevisionComment({
      revisionComment: comment,
      queryParams: revisionQueryParams,
      currentDocumentId: currentDocument?.id,
    })
  }, [deleteRevisionComment, comment, revisionQueryParams, currentDocument?.id])

  const onEditRevisionComment = useCallback((e) => {
    e.stopPropagation()
    setEdit(true)
  }, [])

  return (
    <div
      className="px-2 py-1 max-h-96 overflow-auto"
      key={`revision_comment_${comment?.id}`}
    >
      <div className="flex justify-between items-center text-sm">
        <div className={'space-x-1'}>
          <div className="inline flex-auto font-semibold text-sm truncate leading-6">
            {comment?.user?.first_name} {comment?.user?.last_name}
          </div>
          <div className="inline text-gray-400 text-xs">
            {`${new Date(
              comment?.date_created || new Date().toISOString()
            ).toDateString()}`}
          </div>
        </div>
        {user?.id === comment?.user_created ? (
          <Popover>
            <Popover.Button
              className={
                'flex items-center justify-center rounded text-gray-600 hover:bg-gray-100'
              }
              tabIndex={-1}
              data-playwright={`comment_card_comment_options_${comment?.id}`}
              ref={setReferenceElement}
            >
              <EllipsisVerticalIcon className="w-5 h-5" />
            </Popover.Button>
            <Popover.Panel
              ref={setPanelRef}
              style={styles.popper}
              {...attributes.popper}
              className="z-50 flex flex-col bg-white shadow border rounded"
            >
              <>
                <button
                  className="hover:bg-gray-100 px-4 py-2 text-left text-xs cursor-pointer"
                  onClick={onEditRevisionComment}
                >
                  Edit
                </button>
                <button
                  className="hover:bg-gray-100 px-4 py-2 text-left text-xs cursor-pointer"
                  onClick={onDeleteRevisionComment}
                >
                  Delete
                </button>
              </>
            </Popover.Panel>
          </Popover>
        ) : null}
      </div>
      <div
        className={`whitespace-pre-wrap ${selectedRevision?.id === revision?.id || isTableView ? '' : 'max-h-16 overflow-auto'}`}
        key={`comment_${comment.id}`}
      >
        {renderComment(comment)}
      </div>
    </div>
  )
}

export default RevisionCommentComponent
