import React, { useCallback } from 'react'
import {
  setIconMenuOpen,
  setSelectedCustomLabel,
  setTemporaryHighlight,
} from '../../redux/viewer-slice'
import { useDispatch, useSelector } from 'react-redux'
import { TagIcon } from '@heroicons/react/24/outline'
import { LabelSegment } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { isRatio } from '../../utils/convert-quads'
import { useNavigate } from 'react-router-dom'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { useGetCustomLabelsQuery } from '../../redux/api/custom-label-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'

interface CustomLabelIconProps {
  labelSegment: LabelSegment
}

const CustomLabelIcon: React.FC<CustomLabelIconProps> = ({ labelSegment }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const { data: labels } = useGetCustomLabelsQuery(
    currentProject?.uuid
      ? { projectUUID: currentProject?.uuid, segmentsIncluded: false }
      : skipToken
  )
  const onSelectCustomLabel = useCallback(
    (labelID: number) => {
      const parentLabel = labels?.find((l) => l.id === labelSegment.label)
      navigate(
        `/${currentProject?.uuid}/clause-filters/${currentDocument?.uuid}?label=${parentLabel?.name}&page=${labelSegment.page}`
      )
      dispatch(setSelectedCustomLabel(labelID))
    },
    [
      currentDocument?.uuid,
      currentProject?.uuid,
      dispatch,
      labelSegment.label,
      labelSegment.page,
      labels,
      navigate,
    ]
  )

  const onCustomLabelClick = useCallback(() => {
    onSelectCustomLabel(labelSegment?.parentLabel?.id ?? 0)
    dispatch(setIconMenuOpen(null))
  }, [dispatch, labelSegment?.parentLabel?.id, onSelectCustomLabel])

  const onMouseEnter = useCallback(() => {
    dispatch(
      setTemporaryHighlight([
        {
          page: labelSegment.page,
          quads: labelSegment.quads,
          pdfCoords: isRatio(labelSegment.quads ?? []),
        },
      ])
    )
  }, [dispatch, labelSegment?.page, labelSegment?.quads])

  const onMouseLeave = useCallback(() => {
    dispatch(setTemporaryHighlight(null))
  }, [dispatch])

  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onCustomLabelClick}
      tabIndex={0}
      className="z-5 flex h-8 cursor-pointer items-center truncate rounded border bg-white text-left text-xs shadow hover:bg-gray-200"
    >
      <TagIcon className="ml-0.5 mr-2 h-4 w-5" />
      <div className="w-36 truncate">{labelSegment?.parentLabel?.name}</div>
    </button>
  )
}

export default CustomLabelIcon
