import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React, { useId } from 'react'
import { Tooltip } from 'react-tooltip'

interface HelpIconWithTextProps {
  text: string
}

const InformationIconWithText: React.FC<HelpIconWithTextProps> = ({ text }) => {
  const uniqueId = useId()

  return (
    <>
      <InformationCircleIcon
        className="h-4 w-4 text-gray-600"
        data-tooltip-id={`help-icon-${uniqueId}`}
        data-tooltip-html={text}
      />
      <Tooltip id={`help-icon-${uniqueId}`} style={{ maxWidth: '50vw' }} />
    </>
  )
}

export default InformationIconWithText
