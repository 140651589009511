import React, { useCallback } from 'react'
import { ProjectRiskList } from '../../../shared/interfaces/project/risk/risk-inteface'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { setSelectedRiskList } from '../../../redux/risks-search-slice'

interface RiskReviewListButtonProps {
  projectRiskList: ProjectRiskList | null
}

const RiskReviewListButton: React.FC<RiskReviewListButtonProps> = ({
  projectRiskList,
}) => {
  const selectedRiskList = useSelector(
    (state: any) => state.riskListState.selectedRiskList
  )

  const dispatch = useDispatch()
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)

  const updateSelectedRiskList = useCallback(
    (newList: any) => {
      dispatch(setSelectedRiskList(newList))
    },
    [dispatch]
  )

  const onClickProjectRiskListButton = useCallback(() => {
    updateSelectedRiskList(projectRiskList)
    posthog?.capture(POSTHOG.risk_review_list_opened, {
      project_uuid: currentProject?.uuid,
      risk_list_name: projectRiskList?.name,
    })
  }, [projectRiskList, posthog, currentProject, updateSelectedRiskList])

  return (
    <button
      data-testid={
        projectRiskList
          ? `risk-review-list-filter-${projectRiskList?.id}`
          : `risk-review-list-all-lists`
      }
      onClick={onClickProjectRiskListButton}
      className={`flex w-full justify-start rounded px-3 py-2 text-left hover:bg-gray-100 ${
        selectedRiskList?.id === projectRiskList?.id ? 'bg-gray-100' : ''
      }`}
    >
      {projectRiskList?.name ?? 'All Risks'}
    </button>
  )
}

export default RiskReviewListButton
