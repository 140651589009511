import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'

interface RiskListState {
  riskListSearchQuery: string
  selectedRiskList: null | any
  selectedDocuments: null | ProjectDocumentMetadata[]
  selectedCategoryFilter: null | string
}

const initialState: RiskListState = {
  riskListSearchQuery: '',
  selectedRiskList: null,
  selectedDocuments: null,
  selectedCategoryFilter: null,
}

const searchSlice = createSlice({
  name: 'riskListState',
  initialState,
  reducers: {
    setRiskListSearchQuery(state, action: PayloadAction<string>) {
      state.riskListSearchQuery = action.payload
    },
    setSelectedRiskList(state, action: PayloadAction<null | any>) {
      state.selectedRiskList = action.payload
    },
    setSelectedDocuments(
      state,
      action: PayloadAction<null | ProjectDocumentMetadata[]>
    ) {
      state.selectedDocuments = action.payload
    },
    setSelectedCategoryFilter: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedCategoryFilter = action.payload
    },
  },
})

export const {
  setRiskListSearchQuery,
  setSelectedRiskList,
  setSelectedDocuments,
  setSelectedCategoryFilter,
} = searchSlice.actions

export const selectRiskListSearchQuery = (state: {
  riskListState: RiskListState
}) => state.riskListState.riskListSearchQuery
export const selectSelectedDocuments = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedDocuments
export const selectSelectedCategoryForRiskList = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedCategoryFilter

export const riskListSearchSliceReducer = searchSlice.reducer
