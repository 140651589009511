import React, { useEffect, useMemo } from 'react'
import { useGetDocumentChangesByProjectQuery } from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import SupplementaryConditionsQACard from './supplementary-conditions-qa-card'
import CCDCVersionDropdown from '../qa-editor/ccdc-version-dropdown'
import { DocumentChange } from '../../shared/interfaces/project/document/changes/document-change.interface'
import { selectCCDCVersion, selectNewAmendment } from '../../redux/editor-slice'
import SupplementaryConditionsQAEditor from './supplementary-conditions-qa-editor'
import Diff from '../workflows/comment-table/diff'
import {
  selectDocumentChange,
  setSelectedDocumentChange,
} from '../../redux/viewer-slice'
import { useDispatch } from 'react-redux'
import MarkSCCompleteButton from '../qa-editor/mark-sc-complete-button'

const SupplementaryConditionsQAPage = () => {
  const currentProject = useSelector(selectCurrentProject)
  const { currentData: documentChanges } = useGetDocumentChangesByProjectQuery(
    currentProject?.uuid ?? skipToken
  )
  const currentDocument = useSelector(selectCurrentDocument)
  const selectedDocumentChange = useSelector(selectDocumentChange)
  const newAmendment = useSelector(selectNewAmendment) as DocumentChange
  const ccdcVersion = useSelector(selectCCDCVersion)
  const dispatch = useDispatch()

  const documentChangesToDisplay = useMemo(() => {
    if (!newAmendment) {
      return documentChanges ?? []
    }
    return [
      { ...newAmendment, document: currentDocument?.id },
      ...(documentChanges ?? []),
    ]
      .slice()
      .sort((a, b) => {
        const quadsA = a?.source?.quads
        const quadsB = b?.source?.quads
        return (
          a?.source?.page - b?.source?.page || quadsA?.[0]?.y1 - quadsB?.[0]?.y1
        )
      })
  }, [documentChanges, newAmendment, currentDocument?.id])

  useEffect(() => {
    dispatch(setSelectedDocumentChange(newAmendment))
  }, [newAmendment, dispatch])

  const prettyStatus = useMemo(() => {
    return currentDocument?.job_status
      ?.toLocaleLowerCase()
      ?.replaceAll('_', ' ')
  }, [currentDocument?.job_status])

  return (
    <div className="flex h-full flex-grow flex-col space-y-3 overflow-hidden p-3">
      <div>
        <div className="text-sm capitalize">
          Document status: {prettyStatus}
        </div>
        <div className="flex space-x-2">
          <CCDCVersionDropdown />
          <MarkSCCompleteButton />
        </div>
      </div>
      <div className={'flex h-full flex-1 overflow-hidden'}>
        <div
          className={
            'h-full flex-shrink-0 space-y-1 overflow-auto pb-5 text-sm'
          }
        >
          {ccdcVersion &&
            documentChangesToDisplay?.map((documentChange) => {
              return (
                <SupplementaryConditionsQACard
                  documentChange={documentChange}
                  key={`document_change_card_${documentChange.id}`}
                />
              )
            })}
        </div>
        <div className={'flex-grow space-y-2 px-1'}>
          {ccdcVersion && (
            <>
              <SupplementaryConditionsQAEditor />
              {selectedDocumentChange && (
                <div className={'rounded border p-2'}>
                  <div>{selectedDocumentChange.original_clause_reference}</div>
                  <div className="h-full w-full overflow-auto text-xs">
                    <Diff
                      inputA={selectedDocumentChange.original_clause ?? ''}
                      inputB={selectedDocumentChange.reconciled_clause ?? ''}
                    />
                  </div>
                  <div className="overflow-auto p-2">
                    <div className="text-xs">
                      {selectedDocumentChange?.explanation}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SupplementaryConditionsQAPage
