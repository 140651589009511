import React, { forwardRef } from 'react'
import { InTextLink } from '../../shared/interfaces/project/document/in-text-link/in-text-link.interface'
import { convertQuads } from '../../utils/convert-quads'
import { useCoords } from '../../hooks/use-coords'
import { Quads } from '../../shared/interfaces/quads.interface'

interface PageOverlayDefinitionProps {
  link: InTextLink
  page: number
  shouldElevate: boolean
}

const PageOverlayDefinition = forwardRef<
  HTMLButtonElement,
  PageOverlayDefinitionProps
>(({ link, page, shouldElevate }, ref) => {
  const { getCoordsFromPageSection } = useCoords()

  const coords = () => {
    const quads = (link?.quads as Quads[])?.[0].x1
      ? (link?.quads[0] as Quads)
      : convertQuads(link?.quads, page)
    return getCoordsFromPageSection(
      quads,
      page,
      'document-viewer',
      quads.y3 > quads.y2
    )
  }

  return (
    <button
      ref={ref}
      key={link.id}
      className={`absolute bg-blue-800
       ${shouldElevate ? 'z-50' : 'z-30'} cursor-pointer mix-blend-lighten`}
      style={{
        width: `${coords().width}%`,
        height: `${coords().height}%`,
        left: `${coords().leftOffset}px`,
        top: `${coords().topOffset}%`,
      }}
    />
  )
})
PageOverlayDefinition.displayName = 'PageOverlayDefinition'

export default PageOverlayDefinition
