import { Dialog, Transition } from '@headlessui/react'
import React, {
  Fragment,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import {
  selectCurrentProject,
  selectModal,
  setModal,
} from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import InformationIconWithText from '../common/help-icon-with-text'
import {
  GetRevision,
  useCreateRevisionExportMutation,
} from '../../redux/api/api-revisions-slice'

interface ExportOptionsModalProps {
  open: boolean
}

const ExportOptionsModal: React.FC<ExportOptionsModalProps> = ({ open }) => {
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const dispatch = useDispatch()
  const [isExcludeOptionsChecked, setIsExcludeOptionsChecked] = useState(false)
  const [isDuplicatorChecked, setIsDuplicatorChecked] = useState(false)
  const [exportType, setExportType] = useState<'excel' | 'pdf'>('excel')
  const [createRevisionExport] = useCreateRevisionExportMutation()
  const currentProject = useSelector(selectCurrentProject)
  const modal = useSelector(selectModal)

  const revisionParams: GetRevision = useMemo(() => {
    return modal?.revisionParams
  }, [modal])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const onExport = useCallback(async () => {
    const exportFile = await createRevisionExport({
      ...revisionParams,
      projectId: currentProject?.id,
      simple_comments_format: isExcludeOptionsChecked,
      show_diff: isDuplicatorChecked ? isDuplicatorChecked : false,
      timezone: Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.replaceAll('+', '%2B'),
    }).unwrap()
    window.open(exportFile.export_url, '_blank')
    setIsOpen(false)
    dispatch(setModal(null))
  }, [
    createRevisionExport,
    currentProject?.id,
    dispatch,
    isExcludeOptionsChecked,
    revisionParams,
    isDuplicatorChecked,
  ])

  const onExcludeOptionsChange = useCallback(() => {
    setIsExcludeOptionsChecked((prev) => !prev)
  }, [])

  const onIncludeDuplicatorChanges = useCallback(() => {
    setIsDuplicatorChecked((prev) => !prev)
  }, [])

  const onSetExportType = useCallback((e) => {
    setExportType(e.target.value)
  }, [])

  const nameContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">
        <div className="flex h-full w-full items-center space-x-4">
          <div className="flex h-48 w-full flex-col justify-between space-y-2 text-sm">
            <span className="font-semibold">Export comment table</span>
            <div className="flex w-full flex-col space-y-4">
              <select
                name="export-type"
                id="export-type"
                value={exportType}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={onSetExportType}
              >
                <option value="excel">Excel</option>
              </select>
              <div className="flex h-6 items-center">
                <input
                  id="export-exclude-options"
                  aria-describedby="export-exclude-options"
                  name="export-exclude-options"
                  type="checkbox"
                  checked={isExcludeOptionsChecked}
                  onChange={onExcludeOptionsChange}
                  className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="export-exclude-options"
                    className="cursor-pointer font-medium text-gray-900"
                  >
                    Exclude comment author names and dates
                  </label>
                </div>
              </div>
              <div className="flex h-6 items-center">
                <input
                  id="export-include-duplicator"
                  aria-describedby="export-include-duplicator"
                  name="export-include-duplicator"
                  type="checkbox"
                  checked={isDuplicatorChecked}
                  onChange={onIncludeDuplicatorChanges}
                  className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <div className="ml-3 flex items-center gap-1 text-sm leading-6">
                  <label
                    htmlFor="export-include-duplicator"
                    className="cursor-pointer font-medium text-gray-900"
                  >
                    Show revision redlines with statuses
                  </label>
                  <InformationIconWithText text="Selecting this option will export the comments with redlines between the latest revision of your documents and the previous revisions, along with their statuses" />
                </div>
              </div>
            </div>
            <div className={'flex w-full justify-end p-2'}>
              <button
                type="button"
                className={`$bg-indigo-600 inline-flex w-28 justify-center self-end rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold  text-white shadow-sm hover:bg-indigo-700  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2`}
                onClick={onExport}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [
    exportType,
    isDuplicatorChecked,
    isExcludeOptionsChecked,
    onExcludeOptionsChange,
    onExport,
    onIncludeDuplicatorChanges,
    onSetExportType,
  ])

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setIsOpen(false)
    setExportType('excel')
    setIsExcludeOptionsChecked(false)
    setIsDuplicatorChecked(false)
  }, [dispatch])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} as="div" className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {nameContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ExportOptionsModal
