import { RefObject, useEffect, useRef, useState } from 'react'

export const useElementWidth = (): [RefObject<HTMLDivElement>, number] => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const resizeObserver = new ResizeObserver(([entry]) => {
      if (entry) {
        setWidth(entry.contentRect.width)
      }
    })

    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return [ref, width]
}
