import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Project } from '../../../shared/interfaces/project/project.interface'
import {
  DeleteCustomSegment,
  useDeleteCustomLabelSegmentMutation,
} from '../../../redux/api/custom-label-api-slice'
import { XCircleIcon } from '@heroicons/react/24/outline'
import {
  selectCurrentProject,
  selectDocumentSegmentsParams,
} from '../../../redux/application-slice'
import { DocumentSegmentSearchParams } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { LabelSegment } from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'

interface LabelProps {
  documentLabelSegment: LabelSegment
}

const DocumentLabelSegment: React.FC<LabelProps> = ({
  documentLabelSegment,
}) => {
  const currentProject = useSelector(selectCurrentProject) as Project
  const documentSegmentsParams = useSelector(selectDocumentSegmentsParams)
  const [deleteCustomLabelSegmentMutation] =
    useDeleteCustomLabelSegmentMutation()

  const handleRemoveLabelClick = useCallback(async () => {
    if (
      !documentLabelSegment?.label ||
      !documentLabelSegment?.document_segment?.id ||
      !documentLabelSegment?.id
    ) {
      return
    }
    const params: DocumentSegmentSearchParams =
      documentSegmentsParams as DocumentSegmentSearchParams
    const deleteCustomSegment: DeleteCustomSegment = {
      segmentID: documentLabelSegment.id,
      labelID: documentLabelSegment.label,
      projectUUID: currentProject.uuid ?? '',
      documentSegmentID: documentLabelSegment.document_segment?.id,
      documentSegmentsParams: params,
    }
    await deleteCustomLabelSegmentMutation(deleteCustomSegment)
  }, [
    currentProject.uuid,
    deleteCustomLabelSegmentMutation,
    documentSegmentsParams,
    documentLabelSegment,
  ])

  return (
    <div
      data-playwright={`${documentLabelSegment.id}_clause_label`}
      key={`${documentLabelSegment.id}_clause_label`}
      className={
        'group relative inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium uppercase text-gray-600'
      }
    >
      {documentLabelSegment?.label_name}
      <button
        data-playwright={`label-tag-x_${documentLabelSegment.id}`}
        className="group-hover:block right-[0.5px] absolute hidden bg-gray-100 px-1.5 border border-black rounded-full text-[8px] text-gray-600 transform cursor-pointer scale-75"
        onClick={handleRemoveLabelClick}
      >
        <XCircleIcon className="w-5 h-5 text-gray-500" />
      </button>
    </div>
  )
}

export default DocumentLabelSegment
