import { useCallback, useContext } from 'react'
import useWindowDimensions from './use-window-dimensions'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import { useDispatch, useSelector } from 'react-redux'
import { selectZoomLevel } from '../redux/viewer-slice'
import { selectTabWidth, setTabWidth } from '../redux/application-slice'

export const useTabWidth = () => {
  const { width } = useWindowDimensions()
  const zoomLevel = useSelector(selectZoomLevel)
  const tabWidth = useSelector(selectTabWidth)
  const dispatch = useDispatch()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const setSidebarTabWidth = useCallback(
    (width: number) => {
      dispatch(setTabWidth(width))
      localStorage.setItem('sidebarTabWidth', width.toString())
    },
    [dispatch]
  )
  const MAX_WIDTH = 5000
  const MIN_WIDTH = 500

  const setDefaultTabWidth = useCallback(() => {
    const localStorageTabWidth = localStorage.getItem('sidebarTabWidth')
    if (localStorageTabWidth) {
      setSidebarTabWidth(parseFloat(localStorageTabWidth))
      return
    }
    setSidebarTabWidth(width / 2)
  }, [width, setSidebarTabWidth])

  const setHalfWidth = useCallback(() => {
    setSidebarTabWidth(width / 2)
  }, [setSidebarTabWidth, width])

  const setDocumentSidebarBig = useCallback(() => {
    setSidebarTabWidth(Math.round(width / 3))
  }, [setSidebarTabWidth, width])

  const handler = (mouseDownEvent) => {
    const onHandleDrag = (mouseMoveEvent) => {
      const startSize = tabWidth

      const newWidth = startSize - (mouseMoveEvent.pageX - mouseDownEvent.pageX)
      if (newWidth < MIN_WIDTH) {
        if (newWidth < MIN_WIDTH / 2) {
          setSidebarTabWidth(0)
        }
        setSidebarTabWidth(MIN_WIDTH)
      } else if (newWidth > MAX_WIDTH) {
        setSidebarTabWidth(MAX_WIDTH)
      } else {
        setSidebarTabWidth(newWidth)
      }
    }

    const onMouseUp = () => {
      documentViewer?.zoomTo(zoomLevel)
      document.body.removeEventListener('mousemove', onHandleDrag)
    }

    document.body.addEventListener('mousemove', onHandleDrag)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  return {
    handler,
    tabWidth,
    setSidebarTabWidth,
    setDefaultTabWidth,
    setDocumentSidebarBig,
    setHalfWidth,
  }
}
