import React from 'react'
import { DocumentNode } from './document-listbox'
import ListboxOptionNode from './document-listbox-option-node'

interface DocumentListboxOptionProps {
  documentNodes: DocumentNode[]
  isExpanded?: boolean
}

const DocumentListboxOption: React.FC<DocumentListboxOptionProps> = ({
  documentNodes,
  isExpanded = false,
}) => {
  return (
    <>
      {documentNodes?.map((root) => (
        <ListboxOptionNode
          shouldShowVersion={root.shouldShowVersion}
          key={root.document.uuid}
          root={root}
          isExpanded={isExpanded}
        />
      ))}
    </>
  )
}
export default DocumentListboxOption
