import React, { useCallback, useState } from 'react'
import {
  selectIconMenuOpen,
  selectNewRevision,
  selectOpenRevision,
  setIconMenuOpen,
  setTemporaryHighlight,
} from '../../redux/viewer-slice'
import { useDispatch, useSelector } from 'react-redux'
import { TagIcon } from '@heroicons/react/24/outline'
import { LabelSegment } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { isRatio } from '../../utils/convert-quads'
import { DocumentSegment } from '../../shared/interfaces/project/document/segments/document-segment.interface'
import { Quads } from '../../shared/interfaces/quads.interface'

interface CustomLabelIconProps {
  icons: React.ReactNode
  iconLength: number
  coords: {
    topOffset: number
  }
  page: number
  quads: Quads
  documentSegment: Partial<DocumentSegment>
  onSelectCustomLabel: (labelSegment?: LabelSegment) => void
  labelSegment?: LabelSegment
}

const IconGroup: React.FC<CustomLabelIconProps> = ({
  icons,
  iconLength,
  coords,
  page,
  quads,
  documentSegment,
  onSelectCustomLabel,
  labelSegment,
}) => {
  const dispatch = useDispatch()
  const newRevision = useSelector(selectNewRevision)
  const openRevision = useSelector(selectOpenRevision)
  const iconMenuOpen = useSelector(selectIconMenuOpen)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)

  const onIconClick = useCallback(() => {
    if (iconLength > 1) {
      dispatch(setIconMenuOpen(documentSegment?.id ?? null))
      dispatch(setTemporaryHighlight(null))
    } else {
      onSelectCustomLabel(labelSegment)
    }
  }, [
    dispatch,
    iconLength,
    documentSegment?.id,
    labelSegment,
    onSelectCustomLabel,
  ])

  const onMouseEnter = useCallback(() => {
    if (iconMenuOpen) {
      return
    }
    dispatch(
      setTemporaryHighlight([
        {
          page,
          quads: [quads],
          pdfCoords: isRatio([quads]),
        },
      ])
    )
  }, [dispatch, iconMenuOpen, page, quads])

  const onMouseLeave = useCallback(() => {
    dispatch(setTemporaryHighlight(null))
  }, [dispatch])

  return coords.topOffset ? (
    <button
      ref={setButtonRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onIconClick}
      tabIndex={0}
      className={`border-full absolute flex h-8 w-8 cursor-pointer justify-center rounded-full border-gray-400 bg-gray-50 p-1 shadow-md hover:bg-gray-200 ${
        openRevision ||
        newRevision ||
        (iconMenuOpen && iconMenuOpen !== documentSegment.id)
          ? 'z-30'
          : 'z-50'
      }`}
      style={{
        top: `${coords.topOffset}px`,
        right: '100%',
      }}
    >
      <TagIcon className="ml-0.5 h-4 w-5" />
      <div
        className="absolute top-1/2 text-center"
        style={{
          fontSize: 10,
        }}
      >
        {iconLength}
      </div>
      <Popover>
        {iconMenuOpen === documentSegment.id && (
          <Popover.Panel
            static
            ref={setPanelRef}
            style={{ ...styles.popper, position: 'fixed' }}
            {...attributes.popper}
            className="z-50 cursor-pointer rounded border bg-white text-left text-xs shadow"
          >
            {icons}
          </Popover.Panel>
        )}
      </Popover>
    </button>
  ) : null
}

export default IconGroup
