import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '../../redux/api-slice'

type MyProfileProps = {}

const MyProfile: React.FC<MyProfileProps> = (props) => {
  const { data: userProfile } = useGetUserProfileQuery(undefined)
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)

  useEffect(() => {
    if (!userProfile) {
      return
    }
    if (userProfile.first_name) {
      setFirstName(userProfile.first_name)
    }
    if (userProfile.last_name) {
      setLastName(userProfile.last_name)
    }
  }, [userProfile])

  const onSaveProfileChanges = useCallback(() => {
    if (!firstName || !lastName) {
      return
    }
    updateUserProfile({
      first_name: firstName,
      last_name: lastName,
    })
  }, [firstName, lastName, updateUserProfile])

  const onChangeFirstName = useCallback((e) => {
    setFirstName(e.target.value)
  }, [])

  const onChangeLastName = useCallback((e) => {
    setLastName(e.target.value)
  }, [])

  const inputsAreDifferentFromProfile = useMemo(() => {
    if (!userProfile) {
      return false
    }
    return (
      userProfile.first_name !== firstName || userProfile.last_name !== lastName
    )
  }, [userProfile, firstName, lastName])

  return (
    <div>
      <h2 className="py-4 text-base font-semibold leading-7 text-gray-900">
        My Profile
      </h2>
      <hr />
      <div className="pt-4">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email
        </label>
        <div className="mt-2">
          <input
            type="email"
            name="email"
            id="email"
            defaultValue={userProfile?.email}
            disabled
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
            placeholder={userProfile?.email}
          />
        </div>
      </div>
      <div className="flex justify-normal gap-4 pt-4">
        <div className="flex-1">
          <label
            htmlFor="first_name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            First name
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={firstName ?? ''}
              disabled={!userProfile}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="John"
              onChange={onChangeFirstName}
            />
          </div>
        </div>
        <div className="flex-1">
          <label
            htmlFor="last_name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Last name
          </label>
          <div className="mt-2">
            <input
              type="last_name"
              name="last_name"
              id="last_name"
              value={lastName ?? ''}
              disabled={!userProfile}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Snow"
              onChange={onChangeLastName}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end py-4">
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-200"
          onClick={onSaveProfileChanges}
          disabled={!inputsAreDifferentFromProfile}
        >
          Save changes
        </button>
      </div>
    </div>
  )
}

export default MyProfile
