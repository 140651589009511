import React, { useCallback } from 'react'
import {
  LinkIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { Project } from '../../../shared/interfaces/project/project.interface'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES } from '../../modals/modal-controller'
import { setModal } from '../../../redux/application-slice'
import { Tooltip } from '@mantine/core'

interface ProjectOptionsMenuProps {
  project: Project
  onDelete: () => void
}

const ProjectOptionsMenu: React.FC<ProjectOptionsMenuProps> = ({
  project,
  onDelete,
}) => {
  const [buttonRef, setButtonRef] = React.useState<HTMLButtonElement | null>(
    null
  )
  const [panelRef, setPanelRef] = React.useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const dispatch = useDispatch()

  const onEditClick = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.RENAME_PROJECT, project }))
  }, [dispatch, project])

  const onDeleteClick = useCallback(() => {
    if (!project?.uuid) {
      return
    }
    onDelete()
  }, [onDelete, project?.uuid])

  const onClickShareModal = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.SHARE_PROJECT, project }))
  }, [dispatch, project])

  return (
    <Popover className="relative">
      <Popover.Button
        aria-label={`Project ${project.title} Options Menu`}
        className={'group rounded-lg p-2 hover:bg-blue-200'}
        tabIndex={-1}
        ref={setButtonRef}
      >
        <Tooltip label="Edit, Share, or Delete">
          <div className="flex items-center justify-center">
            <PencilSquareIcon
              width={20}
              className="group-hover:text-blue-500"
            />
            <LinkIcon width={20} className="mx-2 group-hover:text-blue-500" />
            <TrashIcon width={20} className="group-hover:text-blue-500" />
          </div>
        </Tooltip>
      </Popover.Button>

      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="absolute z-50 rounded border bg-white text-sm text-gray-900 shadow"
      >
        <button
          aria-label={`Rename ${project.title}`}
          className={`w-full px-4 py-2 text-left ${
            !project.uuid ? 'cursor-default bg-gray-100' : 'hover:bg-gray-100'
          }`}
          onClick={onEditClick}
        >
          Edit
        </button>
        <button
          aria-label={`Share ${project.title}`}
          className={`w-full px-4 py-2 text-left ${
            !project.uuid ? 'cursor-default bg-gray-100' : 'hover:bg-gray-100'
          }`}
          onClick={onClickShareModal}
        >
          Share
        </button>
        <button
          aria-label={`Delete ${project.title}`}
          className={`w-full px-4 py-2 text-left ${
            !project.uuid ? 'cursor-default bg-gray-100' : 'hover:bg-gray-100'
          }`}
          onClick={onDeleteClick}
        >
          Delete
        </button>
      </Popover.Panel>
    </Popover>
  )
}

export default ProjectOptionsMenu
