import React, { useCallback, useMemo } from 'react'
import { Task } from '../../../shared/interfaces/task/task.interface'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Listbox } from '@headlessui/react'
import {
  GetDocumentSegments,
  useUpdateTaskStatusMutation,
} from '../../../redux/api-slice'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import {
  selectAssignedUsers,
  selectFilterLabelNames,
  selectSearchQuery,
} from '../../../redux/search-slice'
import { CustomLabel } from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'

interface FilteringCardTaskStatusProps {
  task: Task
  page: number
  documentSegment: DocumentSegment
  selectedDocuments?: ProjectDocumentMetadata[] | null
  canEditStatus: boolean
}

const FilteringCardTaskStatus: React.FC<FilteringCardTaskStatusProps> = ({
  task,
  documentSegment,
  page,
  selectedDocuments,
  canEditStatus,
}) => {
  const filterLabelNames = useSelector(selectFilterLabelNames) as CustomLabel[]
  const searchQuery = useSelector(selectSearchQuery)
  const currentProject = useSelector(selectCurrentProject)
  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const assignedUsers = useSelector(selectAssignedUsers)
  const statusName = useMemo(() => {
    if (!task.status) {
      return 'No Status'
    }
    switch (task.status) {
      case 'IN_PROGRESS':
        return 'In Progress'
      case 'NOT_STARTED':
        return 'Not Started'
      case 'COMPLETE':
        return 'Completed'
      default:
        return 'No Status'
    }
  }, [task.status])

  const onChangeTaskStatus = useCallback(
    (taskStatus: 'IN_PROGRESS' | 'NOT_STARTED' | 'COMPLETE') => {
      const documentQueryParams: GetDocumentSegments = {
        projectId: selectedDocuments?.length ? undefined : currentProject?.uuid,
        documentIds: selectedDocuments?.length
          ? selectedDocuments.map((d) => d.uuid)
          : undefined,
        query: searchQuery,
        labels: filterLabelNames?.map((f) => f.id),
        page: filterLabelNames?.length > 0 ? 1 : page,
        assigned_user: assignedUsers?.[0] ? assignedUsers[0].id : undefined,
      }
      updateTaskStatus({
        ...task,
        segment: documentSegment?.id,
        status: taskStatus,
        getDocumentSegments: documentQueryParams,
      })
    },
    [
      assignedUsers,
      currentProject?.uuid,
      documentSegment?.id,
      filterLabelNames,
      page,
      searchQuery,
      selectedDocuments,
      task,
      updateTaskStatus,
    ]
  )

  const taskStatuses = useMemo(() => {
    return [
      {
        name: 'Not Started',
        value: 'NOT_STARTED',
      },
      {
        name: 'In Progress',
        value: 'IN_PROGRESS',
      },
      {
        name: 'Completed',
        value: 'COMPLETE',
      },
    ]
  }, [])

  const taskStatusColor = useMemo(() => {
    if (!task.status) {
      return 'bg-gray-200'
    }
    switch (task.status) {
      case 'IN_PROGRESS':
        return 'bg-blue-200'
      case 'NOT_STARTED':
        return 'bg-gray-200'
      case 'COMPLETE':
        return 'bg-green-200'
      default:
        return 'bg-gray-200'
    }
  }, [task.status])

  const taskStatusTextColor = useMemo(() => {
    if (!task.status) {
      return 'text-gray-800'
    }
    switch (task.status) {
      case 'IN_PROGRESS':
        return 'text-blue-800'
      case 'NOT_STARTED':
        return 'text-gray-800'
      case 'COMPLETE':
        return 'text-green-800'
      default:
        return 'text-gray-800'
    }
  }, [task.status])
  return (
    <Listbox
      disabled={!canEditStatus}
      value={task.status}
      onChange={onChangeTaskStatus}
    >
      <div className={'relative'}>
        <Listbox.Button
          className={`flex justify-between text-ellipsis whitespace-nowrap rounded-full ${taskStatusColor} p-2 px-2.5 py-0.5`}
        >
          <div
            color="info"
            className={`flex items-center space-x-1 text-xs font-medium ${taskStatusTextColor}`}
          >
            <div>{statusName}</div>
            {canEditStatus ? <ChevronDownIcon className="h-3 w-3" /> : null}
          </div>
        </Listbox.Button>
        <Listbox.Options className="absolute top-full z-40 mt-1 max-h-96 w-full min-w-fit overflow-auto rounded-md bg-white text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
          {taskStatuses?.map((taskStatus) => (
            <Listbox.Option
              aria-label={`Select ${taskStatus.name}`}
              key={`listbox_task_status_option_${taskStatus.value}`}
              value={taskStatus.value}
              className={
                'flex cursor-pointer justify-between truncate px-2 py-2 hover:bg-gray-200'
              }
            >
              {taskStatus.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default FilteringCardTaskStatus
