import React, { useCallback, useMemo, useState } from 'react'
import { Task } from '../../../shared/interfaces/task/task.interface'
import FilteringCardUserBadge from './filtering-card-user-badge'
import FilteringCardTaskStatus from './filtering-card-task-status'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import FilteringCardTaskPopover from './filtering-card-task-popover'
import {
  GetDocumentSegments,
  useGetUserProfileQuery,
  useUpdateTaskDescriptionMutation,
} from '../../../redux/api-slice'
import TextareaAutosize from 'react-textarea-autosize'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useSelector } from 'react-redux'
import {
  selectAssignedUsers,
  selectFilterLabelNames,
  selectSearchQuery,
} from '../../../redux/search-slice'
import { CustomLabel } from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'

interface FilteringCardTaskProps {
  task: Task
  page: number
  documentSegment: DocumentSegment
  selectedDocuments?: ProjectDocumentMetadata[] | null
}

const FilteringCardTask: React.FC<FilteringCardTaskProps> = ({
  task,
  documentSegment,
  page,
  selectedDocuments,
}) => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const currentProject = useSelector(selectCurrentProject)
  const filterLabelNames = useSelector(selectFilterLabelNames) as CustomLabel[]
  const searchQuery = useSelector(selectSearchQuery)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editText, setEditText] = useState<string>(task.description ?? '')
  const [updateTaskDescription] = useUpdateTaskDescriptionMutation()
  const assignedUsers = useSelector(selectAssignedUsers)
  const canEditStatus = useMemo(() => {
    if (!user) {
      return false
    }
    return (
      task.assigned_users?.some((u) => u.id === user.id) ||
      task.user_created?.id === user?.id
    )
  }, [task.assigned_users, task.user_created?.id, user])

  const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditText(e.target.value)
  }, [])

  const onSaveTaskDescription = useCallback(() => {
    setEditMode(false)
    const documentQueryParams: GetDocumentSegments = {
      projectId: selectedDocuments?.length ? undefined : currentProject?.uuid,
      documentIds: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      query: searchQuery,
      labels: filterLabelNames?.map((f) => f.id),
      page: assignedUsers?.[0] || filterLabelNames?.length > 0 ? 1 : page,
      assigned_user: assignedUsers?.[0] ? assignedUsers[0].id : undefined,
    }
    updateTaskDescription({
      ...task,
      segment: documentSegment?.id,
      description: editText,
      getDocumentSegments: documentQueryParams,
    })
  }, [
    assignedUsers,
    currentProject?.uuid,
    documentSegment?.id,
    editText,
    filterLabelNames,
    page,
    searchQuery,
    selectedDocuments,
    task,
    updateTaskDescription,
  ])

  const onPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onSaveTaskDescription()
      } else if (e.key === 'Enter' && e.shiftKey) {
        setEditText((prev) => prev + '\n')
      }
    },
    [onSaveTaskDescription]
  )

  const onCancelEdit = useCallback(() => {
    setEditMode(false)
    setEditText(task.description ?? '')
  }, [task.description])

  return (
    <div
      className={
        'w-full flex-grow resize-none rounded border p-1 text-xs text-gray-800 placeholder-gray-400 focus:border focus:border-none focus:border-gray-300 focus:text-black focus:outline-none focus:ring-0'
      }
    >
      <div className="flex items-center justify-between gap-1">
        {task.assigned_users?.map((user) => (
          <FilteringCardUserBadge key={`task_popover_${user.id}`} user={user} />
        ))}
        <div className={'flex items-center'}>
          <FilteringCardTaskStatus
            selectedDocuments={selectedDocuments}
            documentSegment={documentSegment}
            page={page}
            task={task}
            canEditStatus={canEditStatus}
          />
          {task?.user_created?.id === user?.id ? (
            <FilteringCardTaskPopover
              selectedDocuments={selectedDocuments}
              documentSegment={documentSegment}
              page={page}
              task={task}
              setEditMode={setEditMode}
            />
          ) : null}
        </div>
      </div>
      <div className="flex w-full justify-between">
        {editMode ? (
          <div>
            <TextareaAutosize
              className={
                'w-full flex-grow resize-none rounded-sm border-0 border-white p-1 text-xs placeholder-gray-400 focus:border focus:border-none focus:border-gray-300  focus:outline-none focus:ring-0'
              }
              autoFocus
              onChange={onChange}
              value={editText}
              onKeyDown={onPressEnter}
            />
            <div className="mt-1 flex space-x-1">
              <button
                onClick={onCancelEdit}
                className={`inline-flex items-center rounded-md border bg-white px-3 py-1 text-xs font-medium text-gray-600 hover:bg-gray-100`}
              >
                Cancel
              </button>
              <button
                onClick={onSaveTaskDescription}
                disabled={!editText}
                className={`inline-flex items-center rounded-md border px-3 py-1 text-xs font-medium text-gray-600
                  ${!editText ? 'bg-gray-200' : 'bg-white hover:bg-gray-100'}`}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div className={'flex-grow whitespace-pre-wrap break-all p-1'}>
            {task.description}
          </div>
        )}
      </div>
    </div>
  )
}
export default FilteringCardTask
