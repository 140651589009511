import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import React, { useState, useCallback } from 'react'
import { usePopper } from 'react-popper'
import { useExportDocumentDiffMutation } from '../../redux/api-slice'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import { Tooltip } from 'react-tooltip'

interface DocumentViewerPopoverProps {
  document: ProjectDocument
}

const DocumentViewerPopover: React.FC<DocumentViewerPopoverProps> = ({
  document,
}) => {
  //   const currentProject = useSelector(selectCurrentProject) as Project;
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  const [exportDocumentDiff] = useExportDocumentDiffMutation()

  const onExportDiff = useCallback(async () => {
    referenceElement?.click()
    if (!document?.id) {
      return
    }
    const response = await exportDocumentDiff(document?.id).unwrap()
    window.open(response.export_url, '_blank')
  }, [document?.id, exportDocumentDiff, referenceElement])

  return (
    <>
      <Popover>
        <Popover.Button
          className={'px-2 py-2 hover:bg-gray-200'}
          tabIndex={-1}
          ref={setReferenceElement}
        >
          <EllipsisVerticalIcon className="h-5 w-5" />
        </Popover.Button>
        <Popover.Panel
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 flex flex-col rounded border bg-white shadow"
        >
          <button
            disabled={!document?.has_blackline}
            className={`w-48 px-4 py-2 text-left ${
              document?.has_blackline ? 'hover:bg-gray-100' : 'bg-gray-200'
            }`}
            onClick={onExportDiff}
          >
            <span
              data-tooltip-id={`${
                document?.has_blackline === false ? 'export-diff-tooltip' : ''
              }`}
              data-tooltip-content="Please wait a moment, Provision is preparing the document comparison."
            >
              Export Document Comparison
            </span>
          </button>
        </Popover.Panel>
      </Popover>
      <Tooltip id={'export-diff-tooltip'} style={{ zIndex: 100 }} />
    </>
  )
}

export default DocumentViewerPopover
