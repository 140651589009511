import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Revision,
  RevisionStatus,
} from '../../shared/interfaces/project/document/revision/revision.interface'
import Diff from '../workflows/comment-table/diff'
import { useDispatch, useSelector } from 'react-redux'
import LoadingCircle from '../loading/loading-circle'
import { useHotkeys } from 'react-hotkeys-hook'
import RevisionCommentComponent from '../overlay/revision-comment-component'
import RevisionCreateComment from '../overlay/revision-create-comment'
import {
  selectEditorMode,
  setEditorMode,
} from '../../redux/secondary-viewer-slice'
import CommentViewerCardLoading from './comment-viewer-card-loading'
import { selectCurrentProject } from '../../redux/application-slice'
import { WorkflowFilter } from '../workflows/workflow-components/filter-display'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import {
  useUpdateRevisionStatusMutation,
  useUpdateRevisionV2TextMutation,
} from '../../redux/api/api-revisions-slice'

interface RevisionCardProps {
  revision: Revision
  setLocalRevision: React.Dispatch<React.SetStateAction<Revision | null>>
  localRevision: Revision
  originalRevision?: Revision
  onStatusUpdate: (isDown: boolean, isComplete: boolean) => void
  isChanging: boolean
  filter: WorkflowFilter
  selectedDocuments: ProjectDocumentMetadata[] | null
}

const pattern = /(\r\n|\n|\r|\([a-z]\)|[a-z]\)|\([iv]+\))/gm

const CommentViewerCardComponent: React.FC<RevisionCardProps> = ({
  revision,
  setLocalRevision,
  localRevision,
  originalRevision,
  onStatusUpdate,
  isChanging,
  filter,
  selectedDocuments,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const [updateRevisionV2Text] = useUpdateRevisionV2TextMutation()
  const [updateRevisionStatus] = useUpdateRevisionStatusMutation()
  const [keepSubmitting, setKeepSubmitting] = useState<boolean>(false)
  const [removeSubmitting, setRemoveSubmitting] = useState<boolean>(false)
  const editorMode = useSelector(selectEditorMode)
  const posthog = usePostHog()

  useEffect(() => {
    setLocalRevision(revision)
  }, [revision, setLocalRevision])

  const onDisableEditorMode = useCallback(() => {
    dispatch(setEditorMode(false))
  }, [dispatch])

  const selectRevisionStatus = useCallback(
    async (status: RevisionStatus) => {
      if (status === 'APPROVED') {
        setKeepSubmitting(true)
      } else if (status === 'NOT_APPROVED') {
        setRemoveSubmitting(true)
      }
      const data = {
        id: localRevision?.id,
        content: {
          revision_status:
            localRevision?.revision_status === status ? 'NO_STATUS' : status,
          project: currentProject?.id,
          previous_status: localRevision?.revision_status,
          status_filter: filter?.status,
          documents: selectedDocuments?.length
            ? selectedDocuments.map((d) => d.id)
            : undefined,
        },
      }
      setKeepSubmitting(false)
      setRemoveSubmitting(false)
      onStatusUpdate(false, true)
      await updateRevisionStatus(data)
    },
    [
      localRevision?.id,
      localRevision?.revision_status,
      currentProject?.id,
      filter?.status,
      selectedDocuments,
      onStatusUpdate,
      updateRevisionStatus,
    ]
  )

  const isRevisionAccepted = useMemo(() => {
    const revisedTextClean = originalRevision?.revised_text
      ?.replace(pattern, '')
      ?.replace(/[^a-zA-Z0-9 ]/g, '')
      ?.replace(/\s/g, '')
    const v2TextClean = localRevision?.v2_text
      ?.replace(pattern, '')
      ?.replace(/[^a-zA-Z0-9 ]/g, '')
      ?.replace(/\s/g, '')
    return revisedTextClean === v2TextClean
  }, [localRevision, originalRevision])

  const isRevisionRejected = useMemo(() => {
    const originalTextClean = originalRevision?.original_text
      ?.replace(pattern, '')
      ?.replace(/[^a-zA-Z0-9 ]/g, '')
      ?.replace(/\s/g, '')
    const originalRevisedClean = originalRevision?.revised_text
      ?.replace(pattern, '')
      ?.replace(/[^a-zA-Z0-9 ]/g, '')
      ?.replace(/\s/g, '')
    const revisedClean = localRevision?.v2_text
      ?.replace(pattern, '')
      ?.replace(/[^a-zA-Z0-9 ]/g, '')
      ?.replace(/\s/g, '')
    return (
      originalTextClean === revisedClean &&
      originalRevisedClean !== revisedClean
    )
  }, [localRevision, originalRevision])

  const onSelectRevisionApproved = useCallback(() => {
    selectRevisionStatus('APPROVED')
    posthog.capture(POSTHOG.revision_status_changed, {
      project_uuid: currentProject?.uuid,
      document_uuid: revision?.document_uuid,
      revision_id: revision?.id,
      revision_status: 'APPROVED',
      workflow: 'duplicator',
    })
  }, [
    selectRevisionStatus,
    currentProject?.uuid,
    revision?.document_uuid,
    revision?.id,
    posthog,
  ])

  const onSelectRevisionNotApproved = useCallback(() => {
    selectRevisionStatus('NOT_APPROVED')
    posthog.capture(POSTHOG.revision_status_changed, {
      project_uuid: currentProject?.uuid,
      document_uuid: revision?.document_uuid,
      revision_id: revision?.id,
      revision_status: 'NOT_APPROVED',
      workflow: 'duplicator',
    })
  }, [
    selectRevisionStatus,
    currentProject?.uuid,
    revision?.document_uuid,
    revision?.id,
    posthog,
  ])

  const onSelectRevisionInReview = useCallback(() => {
    selectRevisionStatus('IN_REVIEW')
    posthog.capture(POSTHOG.revision_status_changed, {
      project_uuid: currentProject?.uuid,
      document_uuid: revision?.document_uuid,
      revision_id: revision?.id,
      revision_status: 'IN_REVIEW',
      workflow: 'duplicator',
    })
  }, [
    selectRevisionStatus,
    currentProject?.uuid,
    revision?.document_uuid,
    revision?.id,
    posthog,
  ])

  useHotkeys('d', onSelectRevisionApproved, [onSelectRevisionApproved])
  useHotkeys('a', onSelectRevisionInReview, [onSelectRevisionInReview])
  useHotkeys('s', onSelectRevisionNotApproved, [onSelectRevisionNotApproved])

  const revisionDiff = useMemo(() => {
    if (isRevisionRejected && localRevision?.revised_text !== null) {
      return (
        <div className="text-sm">
          <span className="font-bold">Your proposed changes</span>
          <div className="mt-0.5 text-gray-900">
            <Diff
              inputA={
                originalRevision?.segments
                  ? originalRevision?.segments
                      .map((segment) => segment.text)
                      .join('\n')
                      .replace(pattern, '')
                  : ''
              }
              inputB={
                originalRevision?.revised_text
                  ? originalRevision?.revised_text.replace(pattern, '')
                  : ''
              }
            />
          </div>
        </div>
      )
    }
    return localRevision?.revised_text !== null ? (
      <div className="space-y-3 text-sm">
        <div>
          <span className="font-bold">Your proposed changes</span>
          <div className="mt-0.5 text-gray-900">
            <Diff
              inputA={
                localRevision.original_text ? localRevision.original_text : ''
              }
              inputB={
                localRevision.revised_text ? localRevision.revised_text : ''
              }
            />
          </div>
        </div>
        <div>
          <span className="font-bold">Redline between revisions</span>
          <div className="mt-0.5 text-gray-900">
            <Diff
              inputA={
                originalRevision?.segments
                  ? originalRevision?.segments
                      .map((segment) => segment.text)
                      .join('\n')
                      .replace(pattern, '')
                  : ''
              }
              inputB={
                localRevision?.v2_text
                  ? localRevision?.v2_text.replace(pattern, '')
                  : ''
              }
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="text-sm">
        <span className="font-bold">Text Comparison</span>
        <div className="mt-0.5 text-gray-900">
          <Diff
            inputA={
              originalRevision?.segments
                ? originalRevision?.segments
                    .map((segment) => segment.text)
                    .join('\n')
                    .replace(pattern, '')
                : ''
            }
            inputB={
              localRevision?.v2_text
                ? localRevision?.v2_text.replace(pattern, '')
                : ''
            }
          />
        </div>
      </div>
    )
  }, [
    isRevisionRejected,
    localRevision.revised_text,
    localRevision.original_text,
    localRevision?.v2_text,
    originalRevision?.segments,
    originalRevision?.revised_text,
  ])

  const onStatusUpdateDown = useCallback(() => {
    onStatusUpdate(true, false)
  }, [onStatusUpdate])

  const onStatusUpdateUp = useCallback(() => {
    onStatusUpdate(false, false)
  }, [onStatusUpdate])

  const onSelectCancel = useCallback(() => {
    setLocalRevision(revision)
    onDisableEditorMode()
  }, [revision, setLocalRevision, onDisableEditorMode])

  const onSelectAccept = useCallback(() => {
    const data = {
      id: localRevision?.id,
      content: {
        segments: localRevision?.segments,
        v2_text: localRevision?.v2_text?.replace(/(\r\n|\n|\r)/g, ' '),
        project: currentProject?.id,
        documents: selectedDocuments
          ? selectedDocuments.map((d) => d.id)
          : undefined,
      },
    }
    updateRevisionV2Text(data)
    onDisableEditorMode()
  }, [
    localRevision?.id,
    localRevision?.segments,
    localRevision?.v2_text,
    currentProject?.id,
    selectedDocuments,
    updateRevisionV2Text,
    onDisableEditorMode,
  ])

  const renderNavigationButtons = useCallback(
    (isNavOnly: boolean = false) => {
      return (
        <div
          className={`flex items-center gap-2 p-2 text-sm ${
            isNavOnly ? 'justify-between' : ''
          }`}
        >
          <button
            type="button"
            className="basis-1/6 rounded border py-2"
            onClick={onStatusUpdateDown}
          >
            Back
          </button>
          {!isNavOnly ? (
            <>
              <button
                type="button"
                className={`flex-1 rounded border border-green-300 py-2 text-center font-semibold text-green-700 hover:bg-gray-100 ${
                  localRevision.revision_status === 'APPROVED'
                    ? 'bg-green-300 text-white hover:text-black'
                    : 'bg-white'
                }`}
                onClick={onSelectRevisionApproved}
              >
                Close
                <LoadingCircle
                  className="ml-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                  isSpinning={keepSubmitting}
                />
              </button>
              <button
                type="button"
                className={`flex-1 rounded border border-red-300 py-2 text-center font-semibold text-red-500 hover:bg-gray-100 sm:mt-0 ${
                  localRevision.revision_status === 'NOT_APPROVED'
                    ? 'bg-red-300 text-white hover:text-black'
                    : 'bg-white'
                }`}
                onClick={onSelectRevisionNotApproved}
              >
                Carry Over
                <LoadingCircle
                  className="ml-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                  isSpinning={removeSubmitting}
                />
              </button>
            </>
          ) : null}
          <button
            type="button"
            className="basis-1/6 rounded border py-2"
            onClick={onStatusUpdateUp}
          >
            Skip
          </button>
        </div>
      )
    },
    [
      keepSubmitting,
      localRevision.revision_status,
      onSelectRevisionApproved,
      onSelectRevisionNotApproved,
      onStatusUpdateDown,
      onStatusUpdateUp,
      removeSubmitting,
    ]
  )

  return (
    <div
      id={`revision_card_${localRevision.id}`}
      className={`rounded bg-white`}
    >
      <div className="border-b"></div>
      {editorMode ? (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-2">
          <div className="ml-3 flex-1 md:flex md:items-center md:justify-between">
            <p className="text-sm text-yellow-700">
              Editor Mode Enabled. Select Text on Revision Document to Begin.
              <span aria-hidden="true"> &rarr;</span>
            </p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <button
                onClick={onDisableEditorMode}
                className="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600"
              >
                Disable Editor Mode
              </button>
            </p>
          </div>
        </div>
      ) : null}
      {isChanging ? (
        <CommentViewerCardLoading />
      ) : (
        <>
          {renderNavigationButtons()}
          {revision?.revised_text !== null ? (
            <>
              {isRevisionAccepted && (
                <div className="flex items-center justify-center bg-green-100 py-2 text-sm">
                  Revision changes accepted
                </div>
              )}
              {isRevisionRejected && (
                <div className="flex items-center justify-center gap-2 bg-red-100 py-2 text-sm">
                  <div>Revision changes not accepted</div>
                </div>
              )}
              {!isRevisionAccepted && !isRevisionRejected && (
                <div className="flex items-center justify-center gap-2 bg-yellow-100 py-2 text-sm">
                  <div>Partial changes made</div>
                </div>
              )}
            </>
          ) : null}
          <div className={'p-2'}>{revisionDiff}</div>
          {revision?.v2_text !== localRevision?.v2_text ? (
            <div className="flex items-center gap-2 p-2 text-sm">
              <button
                type="button"
                className="flex-1 rounded border border-red-300 py-2 text-center font-semibold text-red-500 hover:bg-gray-100 sm:mt-0"
                onClick={onSelectCancel}
              >
                Cancel
                <LoadingCircle
                  className="ml-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                  isSpinning={keepSubmitting}
                />
              </button>
              <button
                type="button"
                className={`flex-1 rounded border border-green-300 py-2 text-center font-semibold text-green-700 hover:bg-gray-100 ${
                  localRevision.revision_status === 'APPROVED'
                    ? 'bg-green-300 text-white hover:text-black'
                    : 'bg-white'
                }`}
                onClick={onSelectAccept}
              >
                Accept
                <LoadingCircle
                  className="ml-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                  isSpinning={removeSubmitting}
                />
              </button>
            </div>
          ) : null}
          <div className={'border-y px-2 py-1 text-sm'}>Comments</div>
          <div className="space-y-2 p-2 text-xs">
            {localRevision?.comments?.map((comment) => (
              <RevisionCommentComponent
                key={comment.id}
                comment={comment}
                source={'project'}
                revision={revision}
              />
            ))}
            <RevisionCreateComment revision={localRevision} />
          </div>
        </>
      )}
    </div>
  )
}
export default CommentViewerCardComponent
