import { Popover } from '@headlessui/react'
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  FolderIcon,
} from '@heroicons/react/24/outline'
import { usePostHog } from 'posthog-js/react'
import React, { useCallback, useMemo } from 'react'
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useDeleteFolderMutation } from '../../redux/api-slice'
import {
  selectDocumentSearchQuery,
  setDocumentSearchQuery,
  setModal,
} from '../../redux/application-slice'
import { Folder } from '../../shared/interfaces/project/folder/folder.interface'
import { POSTHOG } from '../../utils/posthog-constants'
import { MODAL_TYPES } from '../modals/modal-controller'

interface FolderRowProps {
  folder?: Folder
  isAboveFolder?: boolean
}

const FolderRow: React.FC<FolderRowProps> = ({ folder, isAboveFolder }) => {
  const posthog = usePostHog()
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteFolder] = useDeleteFolderMutation()
  const [buttonRef, setButtonRef] = React.useState<HTMLButtonElement | null>(
    null
  )
  const [panelRef, setPanelRef] = React.useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const documentSearchQuery = useSelector(selectDocumentSearchQuery)

  const dispatch = useDispatch()

  const onDelete = useCallback(async () => {
    if (!folder) {
      return
    }
    posthog?.capture(POSTHOG.folder_deleted, {
      folder_id: folder?.id,
    })
    await deleteFolder({
      id: folder?.id,
      projectId: folder?.project,
      folderId: folder?.folder?.id,
      searchQuery: searchParams.get('search') ?? undefined,
    })
  }, [folder, posthog, deleteFolder, searchParams])

  const onRenameFolder = useCallback(() => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.RENAME_FOLDER,
        folder: folder,
      })
    )
  }, [dispatch, folder])

  const onNavigateFolder = useCallback(() => {
    searchParams.delete('search')
    if (!folder?.id) {
      searchParams.delete('folderId')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('folderId', folder?.id)
    setSearchParams(searchParams)
  }, [folder, searchParams, setSearchParams])

  const folderOptions = useMemo(() => {
    return (
      <Popover className="shrink-0 rounded text-xs text-gray-900">
        <Popover.Button
          data-cy="document-options"
          className={'h-full rounded p-1 hover:bg-gray-200'}
          tabIndex={-1}
          ref={setButtonRef}
        >
          <EllipsisVerticalIcon className="h-5" />
        </Popover.Button>
        <Popover.Panel
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 flex flex-col rounded border bg-white shadow"
        >
          <button
            className={'px-2 py-2 text-left hover:bg-gray-100'}
            onClick={onRenameFolder}
          >
            Rename
          </button>
          <button
            className={'px-2 py-2 text-left hover:bg-gray-100'}
            onClick={onDelete}
          >
            Delete
          </button>
        </Popover.Panel>
      </Popover>
    )
  }, [attributes.popper, onDelete, onRenameFolder, styles.popper])

  const onClickNavigateParentFolder = useCallback(() => {
    dispatch(setDocumentSearchQuery(null))
    searchParams.delete('search')
    if (!folder?.folder?.id) {
      searchParams.delete('folderId')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('folderId', folder?.folder?.id)
    setSearchParams(searchParams)
  }, [dispatch, folder?.folder?.id, searchParams, setSearchParams])

  return (
    <div className={`flex items-center justify-between overflow-hidden`}>
      <button
        data-tooltip-id="folder-name-id"
        data-tooltip-content={folder?.name ?? '..'}
        title={folder?.name}
        onClick={onNavigateFolder}
        className="flex grow items-center space-x-1 overflow-hidden text-sm text-gray-900"
      >
        <FolderIcon className="h-5 w-5 shrink-0 text-gray-500" />
        <div className="shrink overflow-hidden text-ellipsis whitespace-nowrap">
          {isAboveFolder ? '..' : folder?.name}
        </div>
      </button>
      <button
        onClick={onNavigateFolder}
        className="whitespace-nowrap px-3 text-sm text-gray-500"
      />
      <div className="whitespace-nowrap px-3 text-sm text-gray-500">
        {documentSearchQuery ? (
          <button
            onClick={onClickNavigateParentFolder}
            className="flex items-center space-x-1 hover:text-gray-900"
          >
            {folder?.folder?.name ? (
              <ChevronRightIcon className="h-4 w-4" />
            ) : null}
            <div className="w-36 justify-between overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
              {folder?.folder?.name}
            </div>
          </button>
        ) : null}
      </div>
      {!isAboveFolder ? folderOptions : <div className="h-7" />}
    </div>
  )
}

export default FolderRow
