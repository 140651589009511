import { useSelector } from 'react-redux'
import { selectTextSelected } from '../redux/viewer-slice'
import { Quads } from '../shared/interfaces/quads.interface'
import { useContext, useMemo } from 'react'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'

export const useQuads = () => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const textSelected = useSelector(selectTextSelected)
  const mappedQuads: Quads[] | null = useMemo(() => {
    if (!textSelected || !textSelected?.pageNumber) {
      return null
    }
    const height = documentViewer?.getPageHeight(textSelected?.pageNumber)
    const width = documentViewer?.getPageWidth(textSelected?.pageNumber)
    if (!height || !width) {
      return null
    }
    return textSelected.quads[textSelected?.pageNumber].map((quad: Quads) => {
      return {
        x1: quad.x1 / width,
        x2: quad.x2 / width,
        x3: quad.x3 / width,
        x4: quad.x4 / width,
        y1: quad.y1 / height,
        y2: quad.y2 / height,
        y3: quad.y3 / height,
        y4: quad.y4 / height,
        page: textSelected?.pageNumber,
      }
    })
  }, [textSelected, documentViewer])
  return {
    mappedQuads,
  }
}
