import React, { useState, useCallback } from 'react'
import {
  useCreateRiskListMutation,
  useGetRiskListsQuery,
} from '../../../../redux/api/project-risk-api-slice'
import RiskList from '../risk-list'
import { toast } from 'react-toastify'
import {
  CreateRiskListFormProps,
  RiskListItem,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../../redux/application-slice'
import {
  ClipboardDocumentListIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'

const CreateRiskListForm: React.FC<CreateRiskListFormProps> = ({ onClose }) => {
  const [riskListName, setRiskListName] = useState('')
  const [createRiskList] = useCreateRiskListMutation()
  const currentProject = useSelector(selectCurrentProject)

  const {
    data: riskLists,
    isLoading: isLoadingRiskLists,
    refetch,
  } = useGetRiskListsQuery()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await createRiskList({ name: riskListName }).unwrap()
      setRiskListName('')
      await refetch()
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }

  const handleRiskListNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRiskListName(e.target.value)
    },
    []
  )

  return (
    <div className="rounded-lg p-2">
      <div className="mb-4 rounded-md border border-[#ffffff00] bg-white p-4 shadow hover:border-yellow-400">
        <h2 className="mb-3 flex items-center text-lg font-semibold">
          <PlusIcon className="mr-1.5 h-6 w-6" />
          Create a New Risk List
        </h2>
        <form onSubmit={handleSubmit} className="flex items-center space-x-4">
          <div className="flex-grow">
            <label htmlFor="riskListName" className="sr-only">
              Risk List Name
            </label>
            <input
              type="text"
              id="riskListName"
              value={riskListName}
              onChange={handleRiskListNameChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Risk List Name"
              required
            />
          </div>
          <button
            type="submit"
            className="rounded-md border border-transparent bg-blue-600 px-4 py-[10px] text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Create
          </button>
        </form>
      </div>

      <div className="rounded-md border border-[#ffffff00] bg-gray-50 p-4 shadow-sm transition-colors hover:border-yellow-400 hover:bg-white">
        <h3 className="mb-6 text-lg font-semibold">
          <div className="flex items-center ">
            <ClipboardDocumentListIcon className="text=gray-900 mr-2 h-6 w-6" />{' '}
            Custom Risk Lists
          </div>
        </h3>
        {isLoadingRiskLists ? (
          <p>Loading risk lists...</p>
        ) : riskLists?.length ? (
          riskLists.map((riskList: RiskListItem) => (
            <div key={riskList.id}>
              <RiskList
                id={riskList.id}
                name={riskList.name}
                projectUuid={currentProject?.uuid}
                userPermissions={riskList.user_permissions}
                isLoadingRiskLists={isLoadingRiskLists}
              />
            </div>
          ))
        ) : (
          <p>No risk lists found.</p>
        )}
      </div>
    </div>
  )
}

export default CreateRiskListForm
