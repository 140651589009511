import React from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import ErrorMessage from './workflows/workflow-components/error-message'
import { useWorkflow } from '../hooks/use-workflow'
import { RiskReviewHeader } from './workflow-sidebar/risk-review-header'
import { CustomRiskListsHeader } from './workflow-sidebar/custom-risk-lists-header'
import clsx from 'clsx'

interface WorkflowSidebarProps {
  width: number | string
}

// Reuse spacing, bottom border, and shadow to ensure consistency across pages.
export const HeaderContainer: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="flex h-12 items-center justify-between border-b-[1px] border-[#D5D8DE] bg-[#edf0f5] p-3 px-1.5 shadow-sm">
      {children}
    </div>
  )
}

// The HeaderContainer will be duplcated until every page has been migrated to this new arrangement,
// because some of the pages have the header built into them.
const WorkflowHeader: React.FC<{
  workflowTitle: string
}> = ({ workflowTitle }) => {
  switch (workflowTitle) {
    case 'Risk Review':
      return (
        <HeaderContainer>
          <RiskReviewHeader />
        </HeaderContainer>
      )
    case 'Custom Risk Lists':
      return (
        <HeaderContainer>
          <CustomRiskListsHeader />
        </HeaderContainer>
      )
    // TODO slowly move header knobs from other workflows to this switch
    case 'Documents':
      return null
    case 'Chat':
      return null
    case 'Clause Filtering':
      return null
    case 'Comments':
      return null
    case 'Supplementary Conditions':
      return null
    default:
      return null
  }
}

// TODO handle small screen sizes
const WorkflowSidebar: React.FC<WorkflowSidebarProps> = ({ width }) => {
  const { workflowMatch } = useWorkflow()
  const { title } = workflowMatch

  const backgroundClasses = {
    'Risk Review': 'bg-[#e5e7eb]',
    Documents: 'bg-[#e5e7eb]',
    Chat: 'bg-[#e5e7eb]',
    Comments: 'bg-white',
    'Clause Filtering': 'bg-white',
    'Supplementary Conditions': 'bg-white',
    'Quality Assurance': 'bg-white',
    'Custom Risk Lists': 'bg-[#e5e7eb]',
  }

  const getBackgroundClass = (title: string): string => {
    return backgroundClasses[title as keyof typeof backgroundClasses] || ''
  }

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col items-stretch bg-[#e5e7eb]',
        getBackgroundClass(title)
      )}
      style={{
        width,
      }}
    >
      <WorkflowHeader workflowTitle={title} />
      <ErrorBoundary fallback={ErrorMessage}>
        <Outlet />
      </ErrorBoundary>
    </div>
  )
}

export default WorkflowSidebar
