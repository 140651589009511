import { useCallback, useContext } from 'react'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import { TextSelected, selectZoomLevel } from '../redux/viewer-slice'
import { Quads } from '../shared/interfaces/quads.interface'
import { useSelector } from 'react-redux'

export const useCoords = () => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const currentZoom = useSelector(selectZoomLevel)

  const getPageInfo = useCallback(
    (page: number) => {
      if (documentViewer && documentViewer?.getDocument()) {
        return (
          documentViewer?.getDocument().getPageInfo(page) ?? {
            width: 0,
            height: 0,
          }
        )
      } else {
        return { width: 0, height: 0 }
      }
    },
    [documentViewer]
  )

  const getTopOffset = useCallback(
    (coords: Quads, page: number, documentViewerID: string) => {
      const documentViewerEl = document.getElementById(documentViewerID)
      const parentEl = documentViewerEl?.querySelector('#virtualListContainer')
      const pageSelectedEl = documentViewerEl?.querySelector(
        `#pageSection${page}`
      )
      if (!pageSelectedEl || !parentEl) {
        return { topOffset: 0 }
      }
      const childOffset = pageSelectedEl.getBoundingClientRect().top
      const parentOffset = parentEl?.getBoundingClientRect().top
      const pageOffset = Math.abs(childOffset - parentOffset)
      const pageHeight = getPageInfo(page).height
      const zoomLevel = documentViewer?.getZoomLevel() ?? 1
      const topOffset = pageOffset + coords.y2 * pageHeight * zoomLevel
      return {
        topOffset,
      }
    },
    [documentViewer, getPageInfo]
  )
  const getCoordsWithHeightAndWidth = useCallback(
    (
      coords: Quads,
      page: number,
      pdfCoords: boolean,
      pageHeight: number,
      pageWidth: number
    ) => {
      const pageSection = window?.document
        ?.getElementById('webviewer-wrapper')
        ?.querySelector(`#pageSection${page}`) as HTMLDivElement
      const pageContainer = window?.document
        ?.getElementById('webviewer-wrapper')
        ?.querySelector(`#pageContainer${page}`) as HTMLDivElement
      const isRatio: boolean =
        parseFloat(coords?.y3?.toString()) <= 1 &&
        parseFloat(coords?.y3?.toString()) >= 0
      return {
        // Don't hate me future self, but the check for between 0 and 1 is to check if the coords are the new percentage formay
        topOffset: isRatio
          ? (pdfCoords ? coords.y2 : coords.y3) * pageHeight
          : ((pdfCoords ? pageHeight - coords.y3 : coords.y3) / pageHeight) *
            100,
        leftOffset: isRatio
          ? coords.x1 * pageWidth
          : (((coords.x1 / pageWidth) * pageContainer?.offsetWidth +
              (pageSection?.offsetWidth - pageContainer?.offsetWidth) / 2) /
              pageSection?.offsetWidth) *
            100,
        width: isRatio
          ? (coords.x2 - coords.x1) * pageWidth
          : (100 * (coords.x2 - coords.x1) * pageContainer?.offsetWidth) /
            (pageWidth * pageSection?.offsetWidth),
        height: isRatio
          ? (pdfCoords ? coords.y3 - coords.y2 : coords.y2 - coords.y3) *
            pageHeight
          : ((pdfCoords ? coords.y3 - coords.y2 : coords.y2 - coords.y3) /
              pageHeight) *
            100,
      }
    },
    []
  )

  const getCoords = useCallback(
    (
      coords: Quads,
      page: number,
      documentViewerID: string,
      pdfCoords = false
    ) => {
      const documentViewerEl = document.getElementById(documentViewerID)
      const pageSection = documentViewerEl?.querySelector(
        `#pageSection${page}`
      ) as HTMLDivElement
      const pageContainer = documentViewerEl?.querySelector(
        `#pageContainer${page}`
      ) as HTMLDivElement

      const zoomLevel = documentViewer?.getZoomLevel()

      const isRatio: boolean =
        parseFloat(coords?.y3?.toString()) <= 1 &&
        parseFloat(coords?.y3?.toString()) >= 0

      const quadsBottomLeftCoordinateLeftOffset =
        (!isRatio ? coords.x1 : coords.x1 * getPageInfo(page).width) *
        (zoomLevel ? zoomLevel : currentZoom)

      return {
        // Don't hate me future self, but the check for between 0 and 1 is to check if the coords are the new percentage formay
        topOffset: isRatio
          ? (pdfCoords ? coords.y2 : coords.y3) * 100
          : ((pdfCoords ? getPageInfo(page).height - coords.y3 : coords.y3) /
              getPageInfo(page).height) *
            100,
        leftOffset: quadsBottomLeftCoordinateLeftOffset,
        width: isRatio
          ? (coords.x2 - coords.x1) * 100
          : (100 * (coords.x2 - coords.x1) * pageContainer?.offsetWidth) /
            (getPageInfo(page).width * pageSection?.offsetWidth),
        height: isRatio
          ? (pdfCoords ? coords.y3 - coords.y2 : coords.y2 - coords.y3) * 100
          : ((pdfCoords ? coords.y3 - coords.y2 : coords.y2 - coords.y3) /
              getPageInfo(page).height) *
            100,
      }
    },
    [documentViewer, currentZoom, getPageInfo]
  )

  const getCoordsFromPageSection = useCallback(
    (
      coords: Quads,
      page: number,
      documentViewerID: string,
      pdfCoords = false
    ) => {
      const documentViewerEl = document.getElementById(documentViewerID)
      const pageSection = documentViewerEl?.querySelector(
        `#pageSection${page}`
      ) as HTMLDivElement
      const pageContainer = documentViewerEl?.querySelector(
        `#pageContainer${page}`
      ) as HTMLDivElement

      const zoomLevel = documentViewer?.getZoomLevel()

      const isRatio: boolean =
        parseFloat(coords?.y3?.toString()) <= 1 &&
        parseFloat(coords?.y3?.toString()) >= 0

      const quadsBottomLeftCoordinateLeftOffset =
        (!isRatio ? coords.x1 : coords.x1 * getPageInfo(page).width) *
        (zoomLevel ? zoomLevel : currentZoom)

      return {
        // Don't hate me future self, but the check for between 0 and 1 is to check if the coords are the new percentage formay
        topOffset: isRatio
          ? (pdfCoords ? coords.y2 : coords.y3) * 100
          : ((pdfCoords ? getPageInfo(page).height - coords.y3 : coords.y3) /
              getPageInfo(page).height) *
            100,
        leftOffset:
          pageSection?.offsetWidth / 2 -
          pageContainer?.offsetWidth / 2 +
          quadsBottomLeftCoordinateLeftOffset,
        width: isRatio
          ? (coords.x2 - coords.x1) * 100
          : (100 * (coords.x2 - coords.x1) * pageContainer?.offsetWidth) /
            (getPageInfo(page).width * pageSection?.offsetWidth),
        height: isRatio
          ? (pdfCoords ? coords.y3 - coords.y2 : coords.y2 - coords.y3) * 100
          : ((pdfCoords ? coords.y3 - coords.y2 : coords.y2 - coords.y3) /
              getPageInfo(page).height) *
            100,
      }
    },
    [documentViewer, currentZoom, getPageInfo]
  )

  const getTextSelectedTopOffset = useCallback(
    (textSelected: TextSelected, documentViewerID: string) => {
      if (!textSelected) {
        return 0
      }
      const documentViewerEl = document.getElementById(documentViewerID)
      const parentEl = documentViewerEl?.querySelector('#virtualListContainer')
      const textSelectedEl = documentViewerEl?.querySelector(
        `#pageSection${textSelected.pageNumber}`
      )
      if (!textSelectedEl || !parentEl) {
        return 0
      }
      const childOffset = textSelectedEl.getBoundingClientRect().top
      const parentOffset = parentEl?.getBoundingClientRect().top
      const pageOffset = Math.abs(childOffset - parentOffset)
      const pageQuads = textSelected.quads[textSelected.pageNumber]
      if (pageQuads?.length === 0) {
        return 0
      }
      const bottomQuad = pageQuads[pageQuads.length - 1]
      const zoomLevel = documentViewer?.getZoomLevel() ?? 1
      const textSelectedOffset = pageOffset + bottomQuad.y3 * zoomLevel
      return textSelectedOffset
    },
    [documentViewer]
  )

  return {
    getCoords,
    getTopOffset,
    getCoordsWithHeightAndWidth,
    getTextSelectedTopOffset,
    getCoordsFromPageSection,
  }
}
