export const UserRoles = {
  ORGANISATION_ADMIN_ROLE: 'organisation_admin',
  ORGANISATION_MEMBER_ROLE: 'organisation_member',
  PROJECT_ADMIN_ROLE: 'project_admin',
  PROJECT_MEMBER_ROLE: 'project_member',
}

export interface OrganisationUser {
  id: number
  first_name: string
  last_name: string
  email: string
  organisation_roles: string[]
  project_roles: { [key: string]: string[] }
}
