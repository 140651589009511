import React, { useCallback, useContext, useState } from 'react'
import { AiOutlineHighlight } from 'react-icons/ai'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCustomLabelOpen,
  selectTextSelected,
  setCustomLabelOpen,
  setExplainQuery,
  setSelectedRevision,
  setSelectedRevisionEdit,
  setTextSelected,
} from '../../redux/viewer-slice'
import { useCreateHighlightMutation } from '../../redux/api-slice'
import {
  ArrowPathIcon,
  ChatBubbleLeftIcon,
  PencilIcon,
  SparklesIcon,
  TagIcon,
} from '@heroicons/react/24/outline'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { Tooltip } from 'react-tooltip'
import CustomLabelPopover from './custom-label-popover'
import { Quads } from '../../shared/interfaces/quads.interface'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import UseUrlNavigate from '../../hooks/use-url-navigate'
import { RevisionSegment } from '../../shared/interfaces/project/document/revision/revision.interface'
import { useSearchParams } from 'react-router-dom'
import { WebsocketContext } from '../../contexts/websocket-context'
import { useQuads } from '../../hooks/use-quads'
import { useCreateRevisionMutation } from '../../redux/api/api-revisions-slice'

interface ContextMenuProps {
  positionX: string
  positionY: string
}

const ContextMenu: React.FC<ContextMenuProps> = ({ positionX, positionY }) => {
  const urlNavigate = UseUrlNavigate()
  const currentDocument = useSelector(selectCurrentDocument)
  const currentProject = useSelector(selectCurrentProject)
  const textSelected = useSelector(selectTextSelected)
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const websocketContext = useContext(WebsocketContext)
  const [commentSaving, setCommentSaving] = useState(false)
  const [revisionSaving, setRevisionSaving] = useState(false)
  const { setSessionUuid } = websocketContext
  const [createRevision] = useCreateRevisionMutation()
  const [createHighlight] = useCreateHighlightMutation()
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const customLabelOpen = useSelector(selectCustomLabelOpen)
  const [searchParams, setSearchParams] = useSearchParams()
  const { mappedQuads } = useQuads()

  const onCreateCustomLabel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(setCustomLabelOpen(!customLabelOpen))
    },
    [dispatch, customLabelOpen]
  )

  const onCreateCommentClicked = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      setCommentSaving(true)
      if (!currentDocument?.id) {
        return
      }
      const text = textSelected?.text?.replace(/(\r\n|\n|\r)/gm, ' ') ?? ''
      posthog?.capture(POSTHOG.comment_created_opened, {
        document_uuid: currentDocument?.uuid,
      })
      if (!textSelected || !textSelected.pageNumber) {
        return
      }
      const height = documentViewer?.getPageHeight(textSelected.pageNumber)
      const width = documentViewer?.getPageWidth(textSelected.pageNumber)
      if (!height || !width) {
        return
      }
      const revisionSegments: RevisionSegment[] = []
      for (const quadPage in textSelected.quads) {
        const quads: Quads[] = textSelected.quads[quadPage].map(
          (quad: Quads) => {
            return {
              x1: quad.x1 / width,
              x2: quad.x2 / width,
              x3: quad.x3 / width,
              x4: quad.x4 / width,
              y1: quad.y1 / height,
              y2: quad.y2 / height,
              y3: quad.y3 / height,
              y4: quad.y4 / height,
              page: parseInt(quadPage),
            }
          }
        )
        revisionSegments.push({
          page: parseInt(quadPage),
          quads,
          document: currentDocument?.id,
          text: textSelected.text ?? '',
        })
      }
      const response = await createRevision({
        page: textSelected.pageNumber,
        quads: revisionSegments?.[0]?.quads ?? [],
        original_text: text,
        revised_text: null,
        document: currentDocument?.id,
        segments: revisionSegments,
        // Add the section reference in here
        // section_reference: localRevision?.section_reference,
      }).unwrap()
      dispatch(setTextSelected(null))
      dispatch(setSelectedRevision(response))
      dispatch(setSelectedRevisionEdit(true))
    },
    [
      currentDocument?.id,
      currentDocument?.uuid,
      textSelected,
      posthog,
      documentViewer,
      createRevision,
      dispatch,
    ]
  )

  const onCreateRevisionClicked = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      setRevisionSaving(true)
      if (
        !textSelected ||
        !textSelected?.quads ||
        !textSelected?.text ||
        !currentDocument?.uuid ||
        !currentDocument?.id ||
        !documentViewer
      ) {
        return
      }
      const text = textSelected.text.replace(/(\r\n|\n|\r)/gm, ' ')
      posthog?.capture(POSTHOG.revision_created_opened, {
        document_uuid: currentDocument?.uuid,
      })
      const height = documentViewer?.getPageHeight(textSelected.pageNumber)
      const width = documentViewer?.getPageWidth(textSelected.pageNumber)
      if (!height || !width) {
        return
      }
      const revisionSegments: RevisionSegment[] = []
      for (const quadPage in textSelected.quads) {
        const quads: Quads[] = textSelected.quads[quadPage].map(
          (quad: Quads) => {
            return {
              x1: quad.x1 / width,
              x2: quad.x2 / width,
              x3: quad.x3 / width,
              x4: quad.x4 / width,
              y1: quad.y1 / height,
              y2: quad.y2 / height,
              y3: quad.y3 / height,
              y4: quad.y4 / height,
              page: parseInt(quadPage),
            }
          }
        )
        revisionSegments.push({
          page: parseInt(quadPage),
          quads,
          document: currentDocument?.id,
          text: textSelected.text ?? '',
        })
      }
      const response = await createRevision({
        page: textSelected.pageNumber,
        quads: revisionSegments?.[0]?.quads ?? [],
        original_text: text,
        revised_text: text,
        document: currentDocument?.id,
        segments: revisionSegments,
        // Add the section reference in here
        // section_reference: localRevision?.section_reference,
      }).unwrap()

      dispatch(setSelectedRevision(response))
      dispatch(setTextSelected(null))
      dispatch(setSelectedRevisionEdit(true))
    },
    [
      textSelected,
      currentDocument?.uuid,
      currentDocument?.id,
      documentViewer,
      posthog,
      createRevision,
      dispatch,
    ]
  )

  const onMouseDownHighlight = useCallback(
    (e) => {
      if (!textSelected) {
        return
      }
      if (!textSelected.highlighted) {
        dispatch(setTextSelected({ ...textSelected, highlighted: true }))
        const quads = mappedQuads
        if (!quads) {
          return
        }
        createHighlight({
          document: currentDocument?.id,
          page: textSelected.pageNumber,
          quads,
          text: textSelected.text,
        })
        posthog?.capture(POSTHOG.highlight_created, {
          document_uuid: currentDocument?.uuid,
        })
      }
      dispatch(setTextSelected(null))
      e.stopPropagation()
      e.preventDefault()
    },
    [
      textSelected,
      dispatch,
      mappedQuads,
      createHighlight,
      currentDocument?.id,
      currentDocument?.uuid,
      posthog,
    ]
  )

  const onMouseDownExplain = useCallback(
    (e) => {
      e.stopPropagation()
      if (!textSelected?.text) {
        return
      }
      setSessionUuid(null)
      searchParams.delete('chat_history_id')
      setSearchParams(searchParams)
      urlNavigate.navigateChatUrl(
        currentProject?.uuid ?? '',
        currentDocument?.uuid ?? ''
      )
      dispatch(setExplainQuery(textSelected.text))
      dispatch(setCustomLabelOpen(false))
      posthog?.capture(POSTHOG.explain_this_clause_clicked, {
        document_uuid: currentDocument?.uuid,
      })
    },
    [
      currentProject?.uuid,
      dispatch,
      currentDocument?.uuid,
      posthog,
      searchParams,
      setSearchParams,
      textSelected?.text,
      urlNavigate,
      setSessionUuid,
    ]
  )

  return (
    <div
      className={'absolute z-50 flex rounded border bg-white shadow-lg'}
      style={{
        top: positionY,
        left: positionX,
      }}
    >
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center  rounded hover:bg-gray-100'
        }
        onMouseDown={onCreateRevisionClicked}
        data-tooltip-id="revision-id"
        data-tooltip-content={revisionSaving ? 'Saving...' : 'Create Revision'}
        disabled={revisionSaving}
        aria-label="Create Revision"
      >
        {revisionSaving ? (
          <ArrowPathIcon width={20} className="animate-spin" />
        ) : (
          <PencilIcon width={20} />
        )}
        <Tooltip id={'revision-id'} style={{ zIndex: 100 }} />
      </button>
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center  rounded hover:bg-gray-100'
        }
        onMouseDown={onCreateCommentClicked}
        data-tooltip-id="comment-id"
        data-tooltip-content={commentSaving ? 'Saving...' : 'Create Comment'}
        disabled={commentSaving}
        aria-label="Create Comment"
      >
        {commentSaving ? (
          <ArrowPathIcon width={20} className="animate-spin" />
        ) : (
          <ChatBubbleLeftIcon width={20} />
        )}
        <Tooltip id={'comment-id'} style={{ zIndex: 100 }} />
      </button>
      <button
        ref={setReferenceElement}
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center  rounded hover:bg-gray-100'
        }
        onMouseDown={onCreateCustomLabel}
        data-tooltip-id="comment-id"
        data-tooltip-content="Create Custom Label"
        aria-label="Create Custom Label"
      >
        <TagIcon width={20} />
        <Tooltip id={'comment-id'} style={{ zIndex: 100 }} />
      </button>
      <CustomLabelPopover referenceElement={referenceElement} />
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center rounded hover:bg-gray-100'
        }
        onMouseDown={onMouseDownHighlight}
        data-tooltip-id="highlight-id"
        data-tooltip-content="Highlight"
        aria-label="Highlight"
      >
        <AiOutlineHighlight size={20} />
        <Tooltip id={'highlight-id'} style={{ zIndex: 100 }} />
      </button>
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center rounded p-1 hover:bg-gray-100'
        }
        onMouseDown={onMouseDownExplain}
        data-tooltip-id="explain-id"
        data-tooltip-content="Explain this clause"
        aria-label="Explain this clause"
      >
        <SparklesIcon width={20} />
        <Tooltip id={'explain-id'} style={{ zIndex: 100 }} />
      </button>
    </div>
  )
}

export default ContextMenu
