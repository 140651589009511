import React, { useCallback, useMemo } from 'react'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectedSource,
  setSelectedSources,
} from '../../../../redux/viewer-slice'
import { v4 as uuidv4 } from 'uuid'
import { selectCurrentProject } from '../../../../redux/application-slice'
import { InTextCitation } from '../../../../shared/interfaces/project/document/in-text-citation/in-text-citation.interface'
import clsx from 'clsx'
import { Quads } from '../../../../shared/interfaces/quads.interface'
import { truncateText } from '../../../../utils/truncate-text'
import { Tooltip } from '@mantine/core'

interface RiskReviewSourceProps {
  citation: InTextCitation
  documents: ProjectDocumentMetadata[]
  sourceIndex: number
  isExpanded: boolean
  citations: InTextCitation[]
}

type SourcesCitation = {
  id: number
  page: number
  document: number | string
  quads: Quads[]
  text: string
}

interface RiskReviewSectionSourceProps {
  citation: SourcesCitation
  documents: ProjectDocumentMetadata[]
  sourceIndex: number
  isExpanded: boolean
  citations: SourcesCitation[]
}
const RiskReviewSource: React.FC<RiskReviewSourceProps> = ({
  citation,
  documents,
  sourceIndex,
  isExpanded = false,
  citations,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const navigate = useNavigate()
  const sourceDocument = useMemo(() => {
    return documents?.find((d) => d.uuid === citation.document_segment.document)
  }, [citation, documents])

  const onSelectCitation = useCallback(() => {
    if (!sourceDocument?.uuid) {
      return
    }
    navigate(`/${currentProject?.uuid}/riskreview/${sourceDocument?.uuid}`)
    const page = citation.document_segment.page.toString()
    searchParams.set('page', page)
    setSearchParams(searchParams)
    const selectedSources: SelectedSource[] = []
    const distinctCitations = citations.reduce<InTextCitation[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment.id
        if (!acc.find((c) => c.document_segment.id === document_segment_id)) {
          acc.push(citation)
        }
        return acc
      },
      []
    )
    for (const citationSource of distinctCitations) {
      const highlightID = uuidv4()
      const quads = citationSource.document_segment.quads ?? []
      selectedSources.push({
        id: highlightID,
        page: citationSource.document_segment.page,
        quads,
        isPrimary: citation.id === citationSource.id,
        documentUUID: sourceDocument?.uuid,
      })
    }
    dispatch(setSelectedSources(selectedSources))
  }, [
    citation,
    citations,
    currentProject?.uuid,
    dispatch,
    navigate,
    searchParams,
    setSearchParams,
    sourceDocument?.uuid,
  ])

  return (
    <button
      onClick={onSelectCitation}
      className={clsx(
        'flex flex w-full items-center items-center justify-center justify-between gap-1 rounded p-1 py-2 pl-2 text-left text-sm text-black hover:bg-yellow-100'
      )}
    >
      <div className="relative mr-1 h-5 w-5 flex-shrink-0 cursor-pointer rounded-full bg-gray-200 px-1 text-center text-[0.6rem] text-xs text-black">
        <div className="relative top-0.5">{sourceIndex + 1}</div>
      </div>
      <div
        className={'flex-grow overflow-hidden text-ellipsis whitespace-nowrap'}
      >
        {citation.document_segment.text}
      </div>
      <div className="flex flex-shrink-0 items-center text-gray-400">
        <Tooltip
          label={
            <>
              <span className="font-bold">Document Title:</span>
              <br />
              {sourceDocument?.title || ''}
            </>
          }
          multiline
          w={180}
          maw={400}
          h={'fit-content'}
          className="overflow-hidden truncate text-ellipsis"
          position="top"
        >
          <div className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
            {truncateText(150, sourceDocument?.title || '')}
          </div>
        </Tooltip>
        <span className="ml-1">- pg. {citation.document_segment.page}</span>
      </div>
    </button>
  )
}

const RiskReviewSectionSource: React.FC<RiskReviewSectionSourceProps> = ({
  citation,
  documents,
  sourceIndex,
  isExpanded,
  citations,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const navigate = useNavigate()
  const sourceDocument = useMemo(() => {
    const found = documents?.find((d) => d.id === citation.document)
    return found
  }, [citation, documents])
  const onSelectSourceCitation = useCallback(() => {
    // Missing required data
    if (!sourceDocument?.id || !currentProject?.uuid) {
      return
    }
    const targetUrl = `/${currentProject.uuid}/riskreview/${sourceDocument.uuid}`
    navigate(targetUrl)
    const page = citation.page.toString()
    searchParams.set('page', page)
    setSearchParams(searchParams)

    const highlightID = uuidv4()
    const selectedSources: SelectedSource[] = [
      {
        id: highlightID,
        page: citation.page,
        quads: citation.quads ?? [],
        isPrimary: true,
        documentUUID: sourceDocument.uuid.toString(),
      },
    ]

    dispatch(setSelectedSources(selectedSources))
  }, [
    citation,
    currentProject?.uuid,
    dispatch,
    navigate,
    searchParams,
    setSearchParams,
    sourceDocument?.id,
    sourceDocument?.uuid,
  ])

  return (
    <button
      onClick={onSelectSourceCitation}
      className={clsx(
        'flex w-full items-center justify-between gap-1 rounded p-1 py-2 pl-2 text-left text-sm text-black hover:bg-yellow-100'
      )}
    >
      <div className="relative mr-1 h-5 w-5 flex-shrink-0 cursor-pointer rounded-full bg-gray-200 px-1 text-center text-[0.6rem] text-xs text-black">
        <div className="relative top-0.5">{sourceIndex + 1}</div>
      </div>
      <div className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap">
        {citation.text}
      </div>
      <div className="flex flex-shrink-0 items-center text-gray-400">
        <Tooltip
          label={
            <>
              <span className="font-bold">Document Title:</span>
              <br />
              {sourceDocument?.title || ''}
            </>
          }
          className="overflow-hidden truncate text-ellipsis"
          multiline
          w={180}
          maw={400}
          h={'fit-content'}
          position="top"
        >
          <div className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
            {truncateText(150, sourceDocument?.title || '')}
          </div>
        </Tooltip>
        <span className="ml-1">- pg. {citation.page}</span>
      </div>
    </button>
  )
}

export { RiskReviewSource, RiskReviewSectionSource }
