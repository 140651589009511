import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentPage,
  selectHasZoomedRecently,
  selectNewRevision,
  selectSelectedAddendum,
  selectSelectedRevision,
  selectZoomLevel,
  setHasZoomedRecently,
} from '../redux/viewer-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../redux/application-slice'
import { useGetDocumentChangesByProjectQuery } from '../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import { useCoords } from './use-coords'
import {
  Revision,
  RevisionSegment,
} from '../shared/interfaces/project/document/revision/revision.interface'
import { Quads } from '../shared/interfaces/quads.interface'
import { DocumentSegment } from '../shared/interfaces/project/document/segments/document-segment.interface'
import { useGetRevisionsQuery } from '../redux/api/api-revisions-slice'

export interface SidelingTop {
  [id: string]: number | null
}

export interface SidelingData {
  page?: number
  quads: Quads[]
  id?: number | string
  data?: Revision | DocumentSegment
  segments?: RevisionSegment[]
  date_created?: string
  type: 'revision' | 'addenda_segment'
}

export const useSideling = (scrollView) => {
  const currentPage = useSelector(selectCurrentPage)
  const currentProject = useSelector(selectCurrentProject)
  const newRevision = useSelector(selectNewRevision) as Revision | null
  const selectedRevision = useSelector(selectSelectedRevision)
  const hasZoomedRecently = useSelector(selectHasZoomedRecently)
  const zoomLevel = useSelector(selectZoomLevel)
  const currentDocument = useSelector(selectCurrentDocument)
  const selectedAddendum = useSelector(selectSelectedAddendum)
  const [sidelingTop, setSidelingTop] = useState<SidelingTop | null>({})
  const [isSorted, setIsSorted] = useState<boolean>(false)
  const [previousPageState, setPreviousPageState] = useState<number | null>(
    null
  )
  const [sidelingsRef, setSidelingsRef] = useState<(HTMLDivElement | null)[]>(
    []
  )
  const { currentData: revisionsData } = useGetRevisionsQuery(
    currentDocument ? { documentIds: [currentDocument.id] } : skipToken
  )
  const { data: documentChanges } = useGetDocumentChangesByProjectQuery(
    currentProject?.uuid ?? skipToken
  )
  const [hasZoomedRecentlyTimeout, setHasZoomedRecentlyTimeout] =
    useState<NodeJS.Timeout | null>(null)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const dispatch = useDispatch()
  const { getTopOffset } = useCoords()
  const changeSideling = useCallback(() => {
    setIsSorted(true)
    setSidelingTop({})
    setTimeout(() => {
      setIsSorted(false)
    }, 1)
  }, [])

  const onChangePageUpdateSidelings = useCallback(() => {
    if (Math.abs(currentPage - (previousPageState ?? 0)) >= 7) {
      changeSideling()
      setPreviousPageState(currentPage)
    }
  }, [currentPage, changeSideling, previousPageState])

  const onZoomChangeUpdateSidelingPositions = useCallback(() => {
    if (zoomLevel) {
      dispatch(setHasZoomedRecently(true))
      changeSideling()
    }
  }, [changeSideling, dispatch, zoomLevel])

  useEffect(() => {
    changeSideling()
  }, [selectedAddendum, documentChanges, changeSideling])

  useEffect(() => {
    const documentLoadedCallback = () => {
      changeSideling()
    }
    documentViewer?.addEventListener('documentLoaded', documentLoadedCallback)
  }, [documentViewer, changeSideling])

  const sidelings = useMemo(() => {
    const sidelingToRender: SidelingData[] = []
    for (const revision of revisionsData ?? []) {
      const sideling: SidelingData = {
        page: revision.page ?? -1,
        quads: revision.quads ?? [],
        id: revision?.id ?? -1,
        date_created: revision.date_created ?? '',
        segments: revision.segments ?? [],
        type: 'revision',
        data: revision,
      }
      sidelingToRender.push(sideling)
    }
    if (newRevision) {
      const sideling: SidelingData = {
        page: newRevision.page ?? -1,
        quads: newRevision.quads ?? [],
        id: 'new_revision',
        date_created: newRevision.date_created ?? '',
        segments: newRevision.segments ?? [],
        type: 'revision',
        data: newRevision,
      }
      sidelingToRender.push(sideling)
    }
    for (const documentChange of documentChanges ?? []) {
      const addendaSegment = documentChange.destinations?.[0]
      if (!addendaSegment) {
        continue
      }
      if (!addendaSegment?.text) {
        continue
      }
      const revisionSegment: RevisionSegment = {
        page: addendaSegment.page ?? -1,
        quads: addendaSegment.quads ?? [],
        text: '',
        document: -1,
      }
      const sideling: SidelingData = {
        page: addendaSegment.page ?? -1,
        quads: addendaSegment.quads ?? [],
        id: addendaSegment.id ?? -1,
        date_created: '',
        segments: [revisionSegment],
        type: 'addenda_segment',
        data: { ...addendaSegment, sources: [documentChange] },
      }
      sidelingToRender.push(sideling)
    }
    return sidelingToRender
  }, [documentChanges, revisionsData, newRevision])

  const getSortedSidelings = useCallback(() => {
    return sidelingsRef.slice().sort((a, b) => {
      if (a === null) {
        return 1
      }
      if (b === null) {
        return -1
      }
      const id1 = a?.getAttribute('data-id') ?? ''
      const id2 = b?.getAttribute('data-id') ?? ''
      const sideling1 =
        sidelings?.find((r) => r.id?.toString() === id1) ?? newRevision
      const sideling2 =
        sidelings?.find((r) => r.id?.toString() === id2) ?? newRevision
      const rect1 = a.getBoundingClientRect()
      const rect2 = b.getBoundingClientRect()
      let sort = 0
      if (sideling1 && sideling2) {
        sort = (sideling2?.id?.toString() ?? '-1').localeCompare(
          sideling1?.id?.toString() ?? '-1'
        )
      }
      return rect1.top - rect2.top || sort
    })
  }, [newRevision, sidelings, sidelingsRef])

  const isEmptyBoundingRect = useCallback((rect1: DOMRect) => {
    return rect1.width === 0 && rect1.height === 0
  }, [])

  const findSidelingMinPageTopSegment = useCallback(
    (
      sidelingData1: SidelingData | undefined | null,
      sidelingData2: SidelingData | undefined | null
    ) => {
      let sideling1: SidelingData | undefined
      let sideling2: SidelingData | undefined
      if (sidelingData1) {
        const minPage = Math.min.apply(
          Math,
          (sidelingData1?.segments ?? []).map((s) => s.page)
        )
        const topSegment = (sidelingData1?.segments ?? [])
          .filter((r) => r.page === minPage)
          ?.sort((a, b) => {
            return (a?.quads?.[0]?.y3 ?? 0) - (b?.quads?.[0]?.y3 ?? 0)
          })
        sideling1 = {
          page: topSegment.length
            ? topSegment[0].page
            : sidelingData1.page ?? 1,
          quads: topSegment.length
            ? topSegment[0].quads ?? []
            : sidelingData1.quads ?? [],
          id: sidelingData1.id ?? -1,
          type: sidelingData1.type,
          data: sidelingData1.data,
          segments: sidelingData1.segments,
        }
      }
      if (sidelingData2) {
        const minPage = Math.min.apply(
          Math,
          (sidelingData2?.segments ?? []).map((s) => s.page)
        )
        const topSegment = (sidelingData2?.segments ?? [])
          .filter((r) => r.page === minPage)
          ?.sort((a, b) => {
            return (a?.quads?.[0]?.y3 ?? 0) - (b?.quads?.[0]?.y3 ?? 0)
          })
        sideling2 = {
          page: topSegment.length
            ? topSegment[0].page
            : sidelingData2?.page ?? 1,
          quads: topSegment.length
            ? topSegment[0].quads ?? []
            : sidelingData2?.quads ?? [],
          id: sidelingData2.id ?? -1,
          type: sidelingData2.type,
          data: sidelingData2.data,
          segments: sidelingData2.segments,
        }
      }
      return { sideling1, sideling2 }
    },
    []
  )

  const getSidelingData = useCallback(
    (
      sideling1: SidelingData | undefined,
      sideling2: SidelingData | undefined
    ) => {
      const sidelingData1 = sideling1 ?? newRevision
      const sidelingData2 = sideling2 ?? newRevision
      const segments1: RevisionSegment[] = sidelingData1?.segments?.length
        ? sidelingData1?.segments
        : [
            {
              page: sideling1?.page ?? 1,
              quads: sideling1?.quads,
              text: '',
              document: -1,
            },
          ] ?? []
      const minPage1 = Math.min.apply(
        Math,
        segments1.map((s) => s.page)
      )
      const segments2: RevisionSegment[] = sidelingData2?.segments?.length
        ? sidelingData2?.segments
        : [
            {
              page: sideling2?.page ?? 1,
              quads: sideling2?.quads,
              text: '',
              document: -1,
            },
          ] ?? []
      const topSegment1 = segments1
        .filter((r) => r.page === minPage1)
        ?.sort((a, b) => {
          return (a?.quads?.[0]?.y3 ?? 0) - (b?.quads?.[0]?.y3 ?? 0)
        })

      const minPage2 = Math.min.apply(
        Math,
        segments2.map((s) => s.page)
      )
      const topSegment2 = segments2
        .filter((r) => r.page === minPage2)
        ?.sort((a, b) => {
          return (a?.quads?.[0]?.y3 ?? 0) - (b?.quads?.[0]?.y3 ?? 0)
        })

      const sideling1Quads = topSegment1?.[0]?.quads?.[0]
      const sideling2Quads = topSegment2?.[0]?.quads?.[0]

      const sideling1Page = minPage1
      const sideling2Page = minPage2

      return { sideling1Quads, sideling2Quads, sideling1Page, sideling2Page }
    },
    [newRevision]
  )

  const isOverlap = useCallback(
    (
      sidelingEl1: HTMLDivElement,
      sidelingEl2: HTMLDivElement,
      newTops: SidelingTop,
      newBottoms: SidelingTop
    ) => {
      const id1 = sidelingEl1?.getAttribute('data-id') ?? ''
      const id2 = sidelingEl2?.getAttribute('data-id') ?? ''
      const sideling1 = sidelings?.find((r) => r.id?.toString() === id1)
      const sideling2 = sidelings?.find((r) => r.id?.toString() === id2)
      const {
        sideling1: sidelingWithMinPageTopSegment1,
        sideling2: sidelingWithMinPageTopSegment2,
      } = findSidelingMinPageTopSegment(sideling1, sideling2)
      if (!sidelingWithMinPageTopSegment1 && !sidelingWithMinPageTopSegment2) {
        return { overlap: false, bottom1: null }
      }
      const rect1 = sidelingEl1.getBoundingClientRect()
      const rect2 = sidelingEl2.getBoundingClientRect()
      if (isEmptyBoundingRect(rect1) || isEmptyBoundingRect(rect2)) {
        return { overlap: false, bottom1: null }
      }

      const { sideling1Page, sideling1Quads, sideling2Page, sideling2Quads } =
        getSidelingData(
          sidelingWithMinPageTopSegment1,
          sidelingWithMinPageTopSegment2
        )

      if (!sideling1Quads || !sideling2Quads) {
        return { overlap: false, bottom1: null }
      }

      const coords1 = getTopOffset(
        sideling1Quads,
        sideling1Page ? sideling1Page : 1,
        'document-viewer'
      )
      const coords2 = getTopOffset(
        sideling2Quads,
        sideling2Page ? sideling2Page : 1,
        'document-viewer'
      )

      const topOffset1 = coords1.topOffset
      const topOffset2 = coords2.topOffset
      const top1Value = newTops[id1]
      const top2Value = newTops[id2]
      const bottom1Value = newBottoms[id1]
      const bottom2Value = newBottoms[id2]
      const top1 = top1Value ? top1Value : topOffset1
      const top2 = top2Value ? top2Value : topOffset2
      const bottom1 = bottom1Value ? bottom1Value : top1 + rect1.height
      const bottom2 = bottom2Value ? bottom2Value : top2 + rect2.height
      const overlap = !(bottom1 <= top2 ?? top1 >= bottom2)
      return { overlap, bottom1 }
    },
    [
      sidelings,
      findSidelingMinPageTopSegment,
      isEmptyBoundingRect,
      getSidelingData,
      getTopOffset,
    ]
  )

  const sortSelectedRevision = useCallback(
    (topsToSet: SidelingTop) => {
      const revision = selectedRevision ?? newRevision
      const originalSelectedRevisionTop = parseFloat(
        document.getElementById(selectedRevision?.id?.toString() ?? '')?.style
          ?.top ?? '0'
      )
      const newSelectedRevisionTop = revision?.segments?.[0]?.quads?.[0]
        ? getTopOffset(
            revision?.segments?.[0]?.quads[0],
            revision?.segments?.[0]?.page ?? revision?.page ?? 1,
            'document-viewer'
          ).topOffset
        : null
      if (!newSelectedRevisionTop) {
        return
      }
      const sortedSidelings = getSortedSidelings()
      const selectedSidelingIndex = sortedSidelings.findIndex((s) => {
        return (
          s?.getAttribute('data-id') ===
          (revision?.id?.toString() ?? 'new_revision')
        )
      })
      const sidelingsAboveSelectedRevision = sortedSidelings.slice(
        0,
        selectedSidelingIndex
      )
      if (!originalSelectedRevisionTop) {
        return
      }
      const difference = Math.abs(
        newSelectedRevisionTop - (originalSelectedRevisionTop ?? 0)
      )
      if (!sidelingTop && !topsToSet) {
        setSidelingTop({})
      }
      const newSidelings: SidelingTop = {}
      newSidelings[revision?.id?.toString() ?? ''] = newSelectedRevisionTop
      const updatedRevisions = {}
      for (const sideling of sidelingsAboveSelectedRevision) {
        const id = sideling?.getAttribute('data-id') ?? ''
        if (updatedRevisions[id]) {
          continue
        }
        const top =
          topsToSet[id] ??
          parseFloat(sideling?.style?.top?.replace('px', '') ?? '0')
        if (!top) {
          continue
        }
        if (top >= originalSelectedRevisionTop) {
          continue
        }
        newSidelings[id] = top - difference
        updatedRevisions[id] = true
      }
      setSidelingTop({ ...sidelingTop, ...newSidelings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSortedSidelings, getTopOffset, selectedRevision, sidelings]
  )

  const sortSidelings = useCallback(
    // eslint-disable-next-line complexity
    (localTops?: SidelingTop) => {
      if (!documentViewer || !documentViewer?.getDocument()) {
        return
      }
      if (
        (isSorted && !localTops) ||
        !sidelingsRef ||
        sidelingsRef.length === 0
      ) {
        return
      }
      const sortedSidelings = getSortedSidelings()
      const newTops: SidelingTop = {}
      const newBottoms: SidelingTop = {}
      const hasNoOverlap: {
        [key: string]: boolean
      } = {}
      let hasMatchedSelectedRevision = false
      for (const sidelings of sidelingsRef) {
        hasNoOverlap[sidelings?.getAttribute('data-id') ?? ''] = true
      }
      for (let i = 0; i < sortedSidelings.length; i++) {
        for (let j = i + 1; j < sortedSidelings.length; j++) {
          const sidelingEl1 = sortedSidelings[i]
          const sidelingEl2 = sortedSidelings[j]
          if (!sidelingEl1 || !sidelingEl2) {
            continue
          }
          if (
            sidelingEl1?.getAttribute('data-id') ===
            sidelingEl2?.getAttribute('data-id')
          ) {
            continue
          }
          const { overlap, bottom1 } = isOverlap(
            sidelingEl1,
            sidelingEl2,
            newTops,
            newBottoms
          )
          const id1 = sidelingEl1?.getAttribute('data-id') ?? ''
          const id2 = sidelingEl2?.getAttribute('data-id') ?? ''
          const rect2 = sidelingEl2.getBoundingClientRect()
          if (!overlap) {
            continue
          }
          hasNoOverlap[id1] = false
          hasNoOverlap[id2] = false
          if (id2 === (selectedRevision?.id?.toString() ?? 'new_revision')) {
            if (hasMatchedSelectedRevision) {
              continue
            }
            hasMatchedSelectedRevision = true
            continue
          }
          const newTop = (bottom1 ?? 0) + 10
          const newBottom = newTop + rect2.height
          newTops[id2] = newTop
          newBottoms[id2] = newBottom
        }
      }
      for (const key in hasNoOverlap) {
        if (hasNoOverlap[key]) {
          newTops[key] = null
        }
      }
      const topsToSet: SidelingTop = {
        ...(localTops ?? sidelingTop),
        ...newTops,
      }
      setIsSorted(true)
      if (hasZoomedRecentlyTimeout) {
        clearTimeout(hasZoomedRecentlyTimeout)
      }
      setHasZoomedRecentlyTimeout(
        setTimeout(() => {
          dispatch(setHasZoomedRecently(false))
        }, 500)
      )
      if (selectedRevision) {
        setTimeout(() => {
          sortSelectedRevision(topsToSet)
        }, 1)
      } else {
        setSidelingTop((s) => {
          return { ...(localTops ?? s), ...newTops }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      documentViewer,
      getSortedSidelings,
      isOverlap,
      isSorted,
      sidelingsRef,
      hasZoomedRecently,
      dispatch,
      hasZoomedRecentlyTimeout,
      selectedRevision,
      getTopOffset,
      sortSelectedRevision,
    ]
  )

  useEffect(() => {
    sortSidelings()
  }, [
    documentViewer,
    getSortedSidelings,
    isOverlap,
    isSorted,
    sidelingsRef,
    hasZoomedRecently,
    dispatch,
    hasZoomedRecentlyTimeout,
    selectedRevision,
    getTopOffset,
    sortSelectedRevision,
    sortSidelings,
  ])

  useEffect(() => {
    if (!selectedRevision) {
      changeSideling()
      return
    }
    const revision = selectedRevision ?? newRevision
    const newSelectedRevisionTop = revision?.segments?.[0]?.quads?.[0]
      ? getTopOffset(
          revision?.segments?.[0]?.quads[0],
          revision?.segments?.[0]?.page ?? revision?.page ?? 1,
          'document-viewer'
        ).topOffset
      : null
    let newTops = {}
    const selectedRevisionID = revision?.id?.toString() ?? 'new_revision'
    if (!sidelingTop) {
      newTops = {
        [selectedRevisionID]: newSelectedRevisionTop,
      }
    } else {
      const newSidelings: SidelingTop = {}
      newSidelings[selectedRevisionID] = newSelectedRevisionTop
      newTops = {
        ...sidelingTop,
        ...newSidelings,
      }
    }
    sortSidelings(newTops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRevision, newRevision, getTopOffset, changeSideling])

  useEffect(() => {
    onZoomChangeUpdateSidelingPositions()
  }, [onZoomChangeUpdateSidelingPositions])

  useEffect(() => {
    onChangePageUpdateSidelings()
  }, [onChangePageUpdateSidelings])

  useEffect(() => {
    changeSideling()
  }, [changeSideling, revisionsData])

  const sortedSidelings: SidelingData[] = useMemo(() => {
    const sortedSidelings = sidelings
      .slice()
      .sort(
        (a, b) =>
          new Date(a?.date_created ?? '').getTime() -
          new Date(b?.date_created ?? '').getTime()
      )
    const filteredSidelings: SidelingData[] = sortedSidelings
      .map((sideling) => {
        const sidelingData: SidelingData = {
          page: sideling.page ?? 1,
          quads: sideling.quads ?? [],
          id: sideling.id ?? -1,
          type: sideling.type,
          data: sideling.data,
          segments: sideling.segments,
        }
        return sidelingData
      })
      .filter(
        (s) =>
          [
            (s?.page ?? 0) >= currentPage - 10 &&
              currentPage <= (s.page ?? 0) + 10,
          ] || s.id === -1
      )
    return filteredSidelings
  }, [sidelings, currentPage])

  return {
    sidelingTop,
    changeSideling,
    setSidelingsRef,
    sortedSidelings,
  }
}
