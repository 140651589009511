import { useDispatch } from 'react-redux'
import React, { useMemo } from 'react'
import { setSelectedCategoryFilter } from '../../../../redux/risks-search-slice'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { MenuPopover } from '../../../layout/menu-popover'
import { Paper, Text, Tooltip } from '@mantine/core'
import { ActiveDot } from '../../../workflow-sidebar/risk-review-header'
import { RiskCategory } from '../../../../shared/interfaces/project/risk/risk-inteface'

// Create a custom hook to share the memoized options
const useSectionsCardOptions = (riskCategories: RiskCategory[] | undefined) => {
  const dispatch = useDispatch()

  return useMemo(() => {
    const categoryOptions =
      riskCategories?.map((category) => ({
        label: category.name,
        rightSectionText: '',
        onClickMethod: () => {
          dispatch(setSelectedCategoryFilter(category.id ?? null))
        },
      })) || []

    return [
      ...categoryOptions,
      {
        label: 'Close Menu',
        leftSectionIcon: (
          <XMarkIcon className="h-5 w-5 text-gray-700 group-hover:text-black" />
        ),
        rightSectionText: '',
      },
    ]
  }, [riskCategories, dispatch])
}

// Update SectionsCardPopover to use the shared hook
export const SectionsCardPopover = ({
  selectedCategory,
  riskCategories,
}: {
  selectedCategory: string | null
  riskCategories: RiskCategory[] | undefined
}) => {
  const SECTIONS_CARD_POPOVER_OPTIONS = useSectionsCardOptions(riskCategories)

  return (
    <MenuPopover
      type="Sections"
      options={SECTIONS_CARD_POPOVER_OPTIONS}
      width={500}
      topLabel="Risks by Sections"
    >
      <Tooltip
        w={316}
        position="bottom"
        multiline
        openDelay={400}
        label="Sections are pre-defined categories for risks. Click to view all risks in a specific section."
      >
        <Paper
          className="group ml-2 cursor-pointer !rounded-lg border p-2.5 transition-colors hover:!border-blue-400 hover:!bg-blue-100 hover:bg-gray-50 active:border-blue-500 active:!bg-white"
          styles={() => ({
            root: {
              backgroundColor: '#e5e7eb',
              cursor: 'pointer',
            },
          })}
        >
          <div className="flex items-center justify-center">
            <div className="flex flex-col">
              <div className="flex text-gray-500 group-hover:text-blue-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M184,72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V88A16,16,0,0,0,184,72Zm0,128H40V88H184V200ZM232,56V176a8,8,0,0,1-16,0V56H64a8,8,0,0,1,0-16H216A16,16,0,0,1,232,56Z"></path>
                </svg>
                <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
                  Sections
                </Text>
              </div>
            </div>
            <ActiveDot
              active={selectedCategory !== null && selectedCategory !== ''}
            />
          </div>
        </Paper>
      </Tooltip>
    </MenuPopover>
  )
}
