import React, { useCallback } from 'react'
import {
  useGetNotificationsQuery,
  useGetProjectsQuery,
  useMarkNotificationsAsReadMutation,
} from '../../redux/api-slice'
import DataTable, {
  ConditionalStyles,
  TableColumn,
} from 'react-data-table-component'
import { ProvisionNotification } from '../../shared/interfaces/notification/notification.interface'
import dayjs from 'dayjs'
import TableSkeleton from '../loading/skeleton-table'
import { NavLink } from 'react-router-dom'

const NotificationCenter: React.FC = () => {
  const { currentData: notifications, refetch: notificationsRefetch } =
    useGetNotificationsQuery(true)
  const [markNotificationsAsRead] = useMarkNotificationsAsReadMutation()
  const { currentData: projects } = useGetProjectsQuery(undefined)

  const columns: TableColumn<ProvisionNotification>[] = [
    {
      name: '',
      cell: (notification: ProvisionNotification) => (
        <>
          {!notification.is_read && (
            <div
              data-tag="allowRowEvents"
              className="h-2 w-4 rounded-full bg-blue-500"
            />
          )}
        </>
      ),
      minWidth: '0',
      maxWidth: '2.5rem',
    },
    {
      name: 'Message',
      cell: (notification: ProvisionNotification) => {
        return (
          <div
            data-tag="allowRowEvents"
            className="h-full w-full whitespace-nowrap py-3 text-xs text-gray-900"
          >
            {notification?.message ?? 'Notification'}
          </div>
        )
      },
      minWidth: '0',
    },
    {
      name: 'Date',
      sortable: true,
      sortFunction: (a, b) =>
        dayjs(a.date_created).isBefore(dayjs(b.date_created)) ? -1 : 1,
      cell: (notification: ProvisionNotification) => (
        <div
          data-tag="allowRowEvents"
          className="h-full w-full whitespace-nowrap py-3 text-xs text-gray-700"
        >
          {dayjs(notification.date_created).format('MMMM D, YYYY h:mm A')}
        </div>
      ),
      minWidth: '0',
      maxWidth: '250px',
    },
    {
      name: '',
      sortable: true,
      sortFunction: (a, b) =>
        dayjs(a.date_created).isBefore(dayjs(b.date_created)) ? -1 : 1,
      cell: (notification: ProvisionNotification) => {
        if (!projects) {
          return null
        }
        const project = projects.find((p) => p.id === notification.project)
        if (!project?.uuid) {
          return null
        }
        return (
          <NavLink
            to={`/${notification?.route ?? project.uuid}`}
            className="h-full w-full cursor-pointer whitespace-nowrap py-3 text-xs text-gray-900 hover:underline"
          >
            Click to View
          </NavLink>
        )
      },
      minWidth: '0',
      maxWidth: '250px',
    },
  ]

  const conditionalRowStyles: ConditionalStyles<ProvisionNotification>[] = [
    {
      when: () => true,
      classNames: ['hover:bg-gray-100'],
    },
    {
      when: (notification) => !notification.is_read,
      classNames: ['cursor-pointer'],
    },
  ]

  const onRowClicked = useCallback(
    async (notification: ProvisionNotification) => {
      await markNotificationsAsRead([notification.id])
      notificationsRefetch()
    },
    [markNotificationsAsRead, notificationsRefetch]
  )

  const onRowClickedCallback = useCallback(
    (notification: ProvisionNotification) => {
      if (notification.is_read) {
        return
      }
      onRowClicked(notification)
    },
    [onRowClicked]
  )

  return (
    <>
      <div className={'container mx-auto'}>
        <div className="px-4 py-4">Notifications</div>
        <DataTable
          progressComponent={<TableSkeleton />}
          progressPending={!notifications}
          columns={columns}
          onRowClicked={onRowClickedCallback}
          data={notifications ?? []}
          noDataComponent={'You have no notifications'}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeaderScrollHeight="80vh"
          fixedHeader
        />
      </div>
    </>
  )
}

export default NotificationCenter
