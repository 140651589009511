import React, { useMemo } from 'react'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'

interface RevisionStatusBadgeProps {
  revision: Revision
}

const RevisionStatusBadge: React.FC<RevisionStatusBadgeProps> = ({
  revision,
}) => {
  const renderRevisionStatusBadge = useMemo(() => {
    switch (revision?.revision_status) {
      case 'NO_STATUS':
      case 'NOT_APPROVED':
        return (
          <span
            className="inline-flex  items-center rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700"
            data-tooltip-id="comment-badge-tooltip"
            data-tooltip-content={'This comment is still to be addressed'}
          >
            Open
          </span>
        )
      case 'APPROVED':
        return (
          <span
            className="inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600"
            data-tooltip-id="comment-badge-tooltip"
            data-tooltip-content={
              'This comment has been addressed and has been closed'
            }
          >
            Closed
          </span>
        )
      case 'NEEDS_RECON':
        return (
          <span
            className="inline-flex items-center rounded-full bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-800"
            data-tooltip-id="comment-badge-tooltip"
            data-tooltip-content={'This comment is still to be reconciled'}
          >
            Requires reconciliation
          </span>
        )
      default:
        return null
    }
  }, [revision])
  return renderRevisionStatusBadge
}

export default RevisionStatusBadge
