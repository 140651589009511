import React, { useState, useCallback } from 'react'
import { Popover } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { Task } from '../../../shared/interfaces/task/task.interface'
import { usePopper } from 'react-popper'
import {
  GetDocumentSegments,
  useDeleteTaskMutation,
} from '../../../redux/api-slice'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import { DocumentSegment } from '../../../shared/interfaces/project/document/segments/document-segment.interface'
import { selectCurrentProject } from '../../../redux/application-slice'
import { useSelector } from 'react-redux'
import {
  selectAssignedUsers,
  selectFilterLabelNames,
  selectSearchQuery,
} from '../../../redux/search-slice'
import { CustomLabel } from '../../../shared/interfaces/project/document/custom-label/custom-label.interface'

interface FilteringCardTaskPopoverProps {
  task: Task
  page: number
  documentSegment: DocumentSegment
  selectedDocuments?: ProjectDocumentMetadata[] | null
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const FilteringCardTaskPopover: React.FC<FilteringCardTaskPopoverProps> = ({
  task,
  documentSegment,
  page,
  selectedDocuments,
  setEditMode,
}) => {
  const filterLabelNames = useSelector(selectFilterLabelNames) as CustomLabel[]
  const searchQuery = useSelector(selectSearchQuery)
  const currentProject = useSelector(selectCurrentProject)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const [deleteTask] = useDeleteTaskMutation()
  const assignedUsers = useSelector(selectAssignedUsers)

  const onClickEditTask = useCallback(() => {
    setEditMode(true)
    referenceElement?.click()
  }, [referenceElement, setEditMode])

  const onDeleteTask = useCallback(() => {
    const documentQueryParams: GetDocumentSegments = {
      projectId: selectedDocuments?.length ? undefined : currentProject?.uuid,
      documentIds: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      query: searchQuery,
      labels: filterLabelNames?.map((f) => f.id),
      page: assignedUsers?.[0] || filterLabelNames?.length > 0 ? 1 : page,
      assigned_user: assignedUsers?.[0] ? assignedUsers[0].id : undefined,
    }
    deleteTask({
      ...task,
      segment: documentSegment?.id,
      getDocumentSegments: documentQueryParams,
      project: currentProject?.id,
    })
    referenceElement?.click()
  }, [
    selectedDocuments,
    currentProject?.uuid,
    currentProject?.id,
    searchQuery,
    filterLabelNames,
    assignedUsers,
    page,
    deleteTask,
    task,
    documentSegment?.id,
    referenceElement,
  ])
  return (
    <Popover>
      <Popover.Button
        className={' rounded text-gray-500 hover:bg-gray-100'}
        tabIndex={-1}
        ref={setReferenceElement}
        data-playwright={`task-popover-button_${task.id}`}
      >
        <EllipsisHorizontalIcon className="h-5 w-5" />
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 flex flex-col rounded border bg-white shadow"
      >
        <button
          className="cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
          onClick={onClickEditTask}
        >
          Edit
        </button>
        <button
          className="cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
          onClick={onDeleteTask}
        >
          Delete
        </button>
      </Popover.Panel>
    </Popover>
  )
}
export default FilteringCardTaskPopover
