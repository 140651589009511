import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { toast } from 'react-toastify'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectV1Document,
  setV1Document,
} from '../../redux/application-slice'
import axios from 'axios'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import {
  useCreateDocumentMutation,
  useGetDocumentStatusByProjectQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FilePondFile } from 'filepond'
import { useSearchParams } from 'react-router-dom'
import { auth0TokenHelper } from '../../utils/auth0-token-helper'

registerPlugin(FilePondPluginFileValidateType)
registerPlugin(FilePondPluginFileValidateSize)

interface UploaderProps {
  onDocumentUploaded: () => void
  files: FilePondFile[] | undefined
  setFiles: React.Dispatch<React.SetStateAction<FilePondFile[]>>
  shouldConfirmUpload?: boolean
  onProgressUpdate?: (
    fileName: string,
    { lengthComputable, loaded, total }
  ) => void
}

const Uploader = forwardRef<FilePond, UploaderProps>(
  (
    {
      onDocumentUploaded,
      shouldConfirmUpload = false,
      onProgressUpdate,
      files,
      setFiles,
    },
    ref
  ) => {
    const currentProject = useSelector(selectCurrentProject)
    const dispatch = useDispatch()
    const v1Document = useSelector(selectV1Document)
    const posthog = usePostHog()
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const folderId = useMemo(() => {
      return searchParams.get('folderId')
    }, [searchParams])
    const acceptedFileTypes = useMemo(
      () => [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ],
      []
    )

    const acceptedFileTypesMap = {
      'application/pdf': '.pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        '.docx',
    }
    const acceptedExtensions = acceptedFileTypes
      .map((mime) => acceptedFileTypesMap[mime])
      .join(', ')

    const { refetch: refetchDocuments } = useGetDocumentStatusByProjectQuery(
      currentProject && currentProject?.id ? currentProject?.id : skipToken
    )
    const [createDocument] = useCreateDocumentMutation()

    useEffect(() => {
      if (!isFileUploaded) {
        return
      }
      setFiles([])
      setIsFileUploaded(false)
    }, [isFileUploaded, setFiles])

    useEffect(() => {
      setTimeout(() => {
        if (!files) {
          return
        }
        const filesToAdd = files.filter(
          (f) => !files.find((file) => file.filename === f.filename)
        )
        if (filesToAdd.length === 0) {
          return
        }
        setFiles((f) => [...(f ?? []), ...filesToAdd])
      }, 100)
    }, [files, setFiles])

    const onUpdateFiles = useCallback(
      (updatedFiles) => {
        setFiles(updatedFiles)
      },
      [setFiles]
    )

    const onFileAddStart = useCallback(
      (file) => {
        setFiles((prev) => {
          if (prev?.find((f) => f.filename === file.filename)) {
            return prev
          }
          return [...(prev ?? []), file]
        })
      },
      [setFiles]
    )

    const validateFileType = useCallback(
      (file: File, type: string): Promise<string> =>
        new Promise((resolve, reject) => {
          if (!acceptedFileTypes.includes(type)) {
            reject('Invalid file type')
            toast.error(
              `File type ${type} not supported. Please upload only ${acceptedExtensions} files.`
            )
          } else {
            resolve(type)
          }
        }),
      [acceptedExtensions, acceptedFileTypes]
    )

    const serverProcess = useCallback(
      async (_, file, __, load, error, ___, abort) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        if (shouldConfirmUpload) {
          return
        }
        const formData = new FormData()
        const request = new XMLHttpRequest()
        const v1DocumentID = v1Document
        dispatch(setV1Document(null))
        const response = await createDocument({
          project: currentProject?.id,
          title: file.name,
          v1_document: v1DocumentID,
          previous_document_version: v1DocumentID,
          folder:
            folderId && currentProject?.id
              ? {
                  id: folderId,
                  folders: [],
                  project: currentProject?.id,
                  documents: [],
                }
              : undefined,
        }).unwrap()
        const uploadUrl = response.upload_url
        request.open('POST', response.upload_url.url)
        for (const key in uploadUrl.fields) {
          if (uploadUrl.fields[key]) {
            formData.append(key, uploadUrl.fields[key])
          }
        }
        formData.append('file', file, file.name)

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          // progress(e.lengthComputable, e.loaded, e.total);
          if (!onProgressUpdate) {
            return
          }
          onProgressUpdate(file.name, {
            lengthComputable: e.lengthComputable,
            loaded: e.loaded,
            total: e.total,
          })
        }

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = async function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            posthog?.capture(POSTHOG.document_uploaded, {
              document_uuid: response.uuid,
            })
            load(request.responseText)
            axios.post(
              `${process.env.REACT_APP_PROVISION_API_BASE_URL}documents/${response.uuid}/complete/`,
              {
                project: currentProject?.id,
                title: file.name,
                v1_document: v1DocumentID,
                previous_document_version: v1DocumentID,
              },
              {
                headers: {
                  Authorization: `Bearer ${await auth0TokenHelper.getAccessTokenSilently()()}`,
                },
              }
            )
            onDocumentUploaded()
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no')
          }
        }

        request.send(formData)
        refetchDocuments()

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort()

            // Let FilePond know the request has been cancelled
            abort()
          },
        }
      },
      [
        shouldConfirmUpload,
        v1Document,
        dispatch,
        createDocument,
        currentProject?.id,
        folderId,
        refetchDocuments,
        onProgressUpdate,
        posthog,
        onDocumentUploaded,
      ]
    )

    return (
      <div className={'w-96 rounded'}>
        <FilePond
          ref={ref}
          files={files?.map((x) => x.file)}
          onupdatefiles={onUpdateFiles}
          allowMultiple
          allowRevert={false}
          allowReorder={false}
          allowReplace={false}
          onaddfilestart={onFileAddStart}
          credits={false}
          server={{
            process: serverProcess,
          }}
          name="file" /* sets the file input name, it's filepond by default */
          labelIdle='Upload: Drag & Drop or <span class="filepond--label-action">Browse</span>'
          acceptedFileTypes={acceptedFileTypes}
          fileValidateTypeLabelExpectedTypesMap={acceptedFileTypesMap}
          fileValidateTypeDetectType={validateFileType}
          allowFileSizeValidation
          minFileSize={'1KB'}
          maxFileSize={'1024MB'}
        />
      </div>
    )
  }
)
Uploader.displayName = 'Uploader'
export default Uploader
