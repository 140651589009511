import React from 'react'
import { DocumentTextIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'

interface ContextMenuProps {
  positionX: string
  positionY: string
  onSelectReplaceText: () => void
  onSelectAddContext: () => void
}

const SecondaryContextMenu: React.FC<ContextMenuProps> = ({
  positionX,
  positionY,
  onSelectReplaceText,
  onSelectAddContext,
}) => {
  return (
    <div
      className={'absolute z-50 flex rounded border bg-white shadow-lg'}
      style={{
        top: positionY,
        left: positionX,
      }}
    >
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center rounded p-1 hover:bg-gray-100'
        }
        onMouseDown={onSelectAddContext}
        data-tooltip-id="add-context-id"
        data-tooltip-content="Add Text to Revision"
      >
        <PlusIcon width={20} />
        <Tooltip id={'add-context-id'} style={{ zIndex: 100 }} />
      </button>
      <button
        type={'button'}
        className={
          'z-50 flex h-8 w-8 cursor-pointer items-center justify-center rounded p-1 hover:bg-gray-100'
        }
        onMouseDown={onSelectReplaceText}
        data-tooltip-id="replace-id"
        data-tooltip-content="Replace Revision Text"
      >
        <DocumentTextIcon width={20} />
        <Tooltip id={'replace-id'} style={{ zIndex: 100 }} />
      </button>
    </div>
  )
}

export default SecondaryContextMenu
