//  Limit the display of a row of text to a certain maxWidth, and show ...
import React from 'react'

export const truncateText = (maxWidth: number, text?: string) => {
  return (
    <div
      className="max-w-full truncate text-ellipsis"
      style={{ maxWidth: `${maxWidth}px` }}
    >
      {text ?? 'Loading...'}
    </div>
  )
}
