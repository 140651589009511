import { useDispatch, useSelector } from 'react-redux'
import {
  addNavigationHistory,
  setNavigationHistory,
} from '../redux/viewer-slice'
import { useCallback, useContext, useEffect } from 'react'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../redux/application-slice'
import { useNavigate } from 'react-router-dom'

export type Route =
  | 'chat'
  | 'documents'
  | 'comments'
  | 'clause-filters'
  | 'supplementary-conditions'
  | 'riskreview'
  | 'quality-assurance'
  | 'commentviewer'
  | 'notifications'

export const useBackButton = () => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onProjectChangeResetNavigationHistory = useCallback(() => {
    dispatch(setNavigationHistory([]))
  }, [dispatch])

  useEffect(() => {
    onProjectChangeResetNavigationHistory()
  }, [currentProject?.uuid, onProjectChangeResetNavigationHistory])

  const addToNavigationHistoryAndNavigate = useCallback(
    (currentRoute: string, nextDocumentUUID: string, page?: number) => {
      const currentPage = documentViewer?.getCurrentPage()
      if (!currentPage) {
        return
      }

      const currentURL = `/${currentProject?.uuid}/${currentRoute}/${currentDocument?.uuid}?page=${currentPage}`
      const nextURL = `/${currentProject?.uuid}/${currentRoute}/${nextDocumentUUID}${page ? `?page=${page}` : ''}`

      if (currentURL === nextURL) {
        return
      }

      dispatch(
        addNavigationHistory({
          page: currentPage,
          route: currentURL,
        })
      )
      navigate(nextURL)
    },
    [
      currentDocument?.uuid,
      currentProject?.uuid,
      dispatch,
      documentViewer,
      navigate,
    ]
  )
  return {
    addToNavigationHistoryAndNavigate,
  }
}
