import { Popover } from '@headlessui/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { usePopper } from 'react-popper'
import LabelListbox from '../label-listbox.tsx/label-listbox'
import {
  CustomLabel,
  LabelSegment,
} from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import {
  CreateCustomLabelSegment,
  useCreateCustomLabelAndSegmentMutation,
  useCreateCustomLabelSegmentMutation,
  useGetCustomLabelsQuery,
} from '../../redux/api/custom-label-api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectClauseSelectedDocuments,
  selectCustomLabelOpen,
  selectTextSelected,
  setCustomLabelOpen,
  setTextSelected,
} from '../../redux/viewer-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { Project } from '../../shared/interfaces/project/project.interface'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { DocumentSegment } from '../../shared/interfaces/project/document/segments/document-segment.interface'
import { GetDocumentSegments } from '../../redux/api-slice'
import LoadingCircle from '../loading/loading-circle'
import { toast } from 'react-toastify'
import { selectSearchQuery } from '../../redux/search-slice'
import { useQuads } from '../../hooks/use-quads'

interface CustomLabelPopoverProps {
  referenceElement: HTMLElement | null
  documentSegment?: DocumentSegment
  labelFilters?: number[]
}

const CustomLabelPopover: React.FC<CustomLabelPopoverProps> = ({
  referenceElement,
  documentSegment,
  labelFilters,
}) => {
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, panelRef)
  const [newLabelRef, setNewLabelRef] = useState<HTMLElement | null>(null)
  const [selectedLabel, setSelectedLabel] = useState<CustomLabel | null>(null)
  const [createNewLabelMode, setCreateNewLabelMode] = useState<boolean>(false)
  const [newCustomLabel, setNewCustomLabel] = useState<string>('')
  const [createCustomLabelAndSegmentMutation] =
    useCreateCustomLabelAndSegmentMutation()
  const [createCustomLabelSegmentMutation] =
    useCreateCustomLabelSegmentMutation()
  const dispatch = useDispatch()
  const customLabelOpen = useSelector(selectCustomLabelOpen)
  const textSelected = useSelector(selectTextSelected)
  const document = useSelector(selectCurrentDocument)
  const currentProject = useSelector(selectCurrentProject) as Project
  const posthog = usePostHog()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const query = useSelector(selectSearchQuery)

  const { data: labels } = useGetCustomLabelsQuery(
    currentProject?.uuid
      ? { projectUUID: currentProject?.uuid, segmentsIncluded: false }
      : skipToken
  )

  const selectedDocuments = useSelector(selectClauseSelectedDocuments)
  const { mappedQuads } = useQuads()

  useEffect(() => {
    if (labels && labels.length > 0) {
      const sortedLabels = labels
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name))
      if (sortedLabels && sortedLabels.length > 0) {
        setSelectedLabel(sortedLabels[0])
      }
    }
    if (labels && labels.length === 0) {
      setCreateNewLabelMode(true)
    }
  }, [labels])

  const onSelectNewLabel = useCallback(
    (newLabel: boolean) => {
      setNewCustomLabel('')
      setCreateNewLabelMode(newLabel)
      if (newLabel) {
        newLabelRef?.focus()
      }
    },
    [newLabelRef]
  )

  const extractedQuads = useMemo(() => {
    if (documentSegment?.quads) {
      const quads = documentSegment.quads
      return {
        quads,
        text: documentSegment.text,
        pageNumber: documentSegment.page,
      }
    }
    if (!documentViewer) {
      return {
        quads: null,
        text: null,
        pageNumber: null,
      }
    }
    if (!textSelected?.pageNumber) {
      return {
        quads: null,
        text: null,
        pageNumber: null,
      }
    }
    return {
      quads: mappedQuads,
      text: textSelected?.text as string,
      pageNumber: textSelected?.pageNumber as number,
    }
  }, [
    documentSegment?.page,
    documentSegment?.quads,
    documentSegment?.text,
    documentViewer,
    mappedQuads,
    textSelected?.pageNumber,
    textSelected?.text,
  ])

  const onClose = useCallback(() => {
    setNewCustomLabel('')
    setCreateNewLabelMode(false)
    dispatch(setTextSelected(null))
    dispatch(setCustomLabelOpen(false))
  }, [dispatch, setNewCustomLabel, setCreateNewLabelMode])

  const onCustomLabelCreate = useCallback(async () => {
    const { quads, text, pageNumber } = extractedQuads
    if (!pageNumber || !text) {
      return
    }
    const quadsWithPage = quads?.map((q) => ({
      ...q,
      page: pageNumber,
    }))
    const labelSegment: LabelSegment = {
      quads: quadsWithPage ?? [],
      page: pageNumber,
      text,
      document: documentSegment?.document
        ? parseInt(documentSegment.document.toString())
        : document?.id ?? -1,
      document_segment: documentSegment,
      label: selectedLabel?.id,
      label_name: newCustomLabel ? newCustomLabel : selectedLabel?.name,
    }
    if (
      !currentProject?.id ||
      !currentProject?.uuid ||
      labelSegment?.document === -1
    ) {
      return
    }
    const getDocumentSegments: GetDocumentSegments = {
      query,
      projectId: selectedDocuments?.length ? undefined : currentProject?.uuid,
      documentIds: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      labels: labelFilters ?? [],
      page: pageNumber,
    }
    const createCustomLabelSegment: CreateCustomLabelSegment = {
      ...labelSegment,
      customLabel: {
        name: newCustomLabel,
        project: currentProject.id,
        projectUUID: currentProject.uuid,
      },
      getDocumentSegments,
    }
    if (createNewLabelMode && newCustomLabel) {
      posthog?.capture(POSTHOG.custom_label_created)
      const alreadyExists = labels?.find((l) => l.name === newCustomLabel)
      if (alreadyExists) {
        toast.error('Label already exists')
        return
      }
      setIsSubmitting(true)
      await createCustomLabelAndSegmentMutation(createCustomLabelSegment)
      setIsSubmitting(false)
      onClose()
    } else if (!createNewLabelMode && selectedLabel) {
      createCustomLabelSegment.label = selectedLabel.id
      const alreadyExists = documentSegment?.labels?.find(
        (l) => l.label_name === selectedLabel?.name
      )
      if (alreadyExists) {
        toast.error('Label already exists')
        return
      }
      setIsSubmitting(true)
      await createCustomLabelSegmentMutation(createCustomLabelSegment)
      posthog?.capture(POSTHOG.custom_label_applied, {
        project_uuid: currentProject.uuid,
        document_uuid: document?.uuid,
        label_name: selectedLabel?.name,
      })
      setIsSubmitting(false)
      onClose()
    }
  }, [
    extractedQuads,
    documentSegment,
    document?.id,
    selectedLabel,
    newCustomLabel,
    currentProject?.id,
    currentProject?.uuid,
    query,
    selectedDocuments,
    labelFilters,
    createNewLabelMode,
    posthog,
    labels,
    createCustomLabelAndSegmentMutation,
    onClose,
    createCustomLabelSegmentMutation,
    document?.uuid,
  ])
  const onEnterPressCustomLabel = useCallback(
    (e) => {
      if (e.key !== 'Enter') {
        return
      }
      onCustomLabelCreate()
    },
    [onCustomLabelCreate]
  )

  const onChangeCustomLabel = useCallback((e) => {
    setNewCustomLabel(e.target.value)
  }, [])

  const customLabelButtonContent = useMemo(() => {
    return (
      <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto"
          onClick={onCustomLabelCreate}
        >
          <LoadingCircle
            className="mr-2 mt-0.5 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
            isSpinning={isSubmitting}
          />
          Tag
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    )
  }, [isSubmitting, onClose, onCustomLabelCreate])

  return (
    <Popover id="custom-label-popover" className="relative">
      {customLabelOpen && (
        <Popover.Panel
          static
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 rounded border bg-white text-gray-700 shadow"
        >
          <div
            style={{
              minWidth: '20rem',
            }}
            className={'h-full w-full min-w-fit'}
          >
            <div className="p-3">
              <LabelListbox
                sorted
                onSelectNewLabel={onSelectNewLabel}
                labels={labels}
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
              />
              {createNewLabelMode && (
                <input
                  tabIndex={0}
                  autoFocus
                  ref={setNewLabelRef}
                  placeholder="New Custom Label"
                  maxLength={25}
                  className={
                    'block w-full resize-none rounded-md border-gray-300 p-2 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  }
                  value={newCustomLabel}
                  onKeyDown={onEnterPressCustomLabel}
                  onChange={onChangeCustomLabel}
                />
              )}

              {customLabelButtonContent}
            </div>
          </div>
        </Popover.Panel>
      )}
    </Popover>
  )
}
export default CustomLabelPopover
