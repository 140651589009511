// viewer slice

import { createSlice } from '@reduxjs/toolkit'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'

interface EditorState {
  editorMode: boolean
  ccdcVersion: string | null
  newAmendment: DocumentChange | null
}

const initialState: EditorState = {
  editorMode: false,
  ccdcVersion: null,
  newAmendment: null,
}

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditorMode: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.editorMode = action.payload
    },
    setNewAmendment: (
      state,
      action: {
        payload: DocumentChange | null
      }
    ) => {
      state.newAmendment = action.payload
    },
    setCCDCVersion: (
      state,
      action: {
        payload: string | null
      }
    ) => {
      state.ccdcVersion = action.payload
    },
  },
})

export const { setEditorMode, setNewAmendment, setCCDCVersion } =
  editorSlice.actions

export const selectEditorMode = (state) => state.editor.editorMode
export const selectNewAmendment = (state) => state.editor.newAmendment
export const selectCCDCVersion = (state) => state.editor.ccdcVersion

export const editorReducer = editorSlice.reducer
