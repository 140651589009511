import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import { useDeleteDocumentChangeCommentMutation } from '../../redux/api-slice'
import {
  DocumentChange,
  DocumentChangeComment,
} from '../../shared/interfaces/project/document/changes/document-change.interface'

interface DocumentChangePopoverProps {
  documentChangeComment: DocumentChangeComment
  documentChange: DocumentChange
  projectId?: string
  documentId?: string
  setCommentSelected: (comment: DocumentChangeComment) => void
  query?: string
  //   onApplyChecklistTemplate: (c: ChecklistTemplate) => void;
}

const DocumentChangePopover: React.FC<DocumentChangePopoverProps> = ({
  documentChange,
  documentChangeComment,
  projectId,
  documentId,
  setCommentSelected,
  query,
}) => {
  //   const currentProject = useSelector(selectCurrentProject) as Project;
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  const [deleteDocumentChangeComment] = useDeleteDocumentChangeCommentMutation()

  const onDeleteDocumentChangeComment = useCallback(async () => {
    referenceElement?.click()
    await deleteDocumentChangeComment({
      id: documentChangeComment.id,
      parent: documentChange,
      document: documentId,
      project: !documentId ? projectId : undefined,
      document_change_id: documentChange.id,
      page: documentChange.page,
      query,
    })
  }, [
    documentChange,
    documentChangeComment,
    deleteDocumentChangeComment,
    documentId,
    projectId,
    referenceElement,
    query,
  ])

  const onClickEditComment = useCallback(() => {
    setCommentSelected(documentChangeComment)
    referenceElement?.click()
  }, [documentChangeComment, referenceElement, setCommentSelected])

  return (
    <Popover>
      <Popover.Button
        className={'px-2 py-2 hover:bg-gray-200'}
        tabIndex={-1}
        ref={setReferenceElement}
      >
        <EllipsisVerticalIcon className="h-5 w-5" />
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 flex flex-col rounded border bg-white text-sm shadow"
      >
        <button
          className="w-36 cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
          onClick={onClickEditComment}
        >
          Edit
        </button>
        <button
          className="w-36 cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
          onClick={onDeleteDocumentChangeComment}
        >
          Delete
        </button>
      </Popover.Panel>
    </Popover>
  )
}

export default DocumentChangePopover
